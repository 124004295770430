import { type Toast, toastTypes } from "@components/Toast";
import Alert, { type AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import kebabCase from "lodash/kebabCase";
import { createContext, useCallback, useState } from "react";
import type { ToastContextApi } from "./types";

export const ToastsContext = createContext<ToastContextApi>(undefined);

const ToastTypeToSeverity = {
	[toastTypes.DANGER]: "error" as AlertColor,
	[toastTypes.INFO]: "info" as AlertColor,
	[toastTypes.SUCCESS]: "success" as AlertColor,
	[toastTypes.WARNING]: "warning" as AlertColor,
};

export const ToastsProvider = ({ children }) => {
	const [toasts, setToasts] = useState<ToastContextApi["toasts"]>([]);

	const handleOnClose = (id: string) => () => {
		setToasts((prevToasts) => {
			// Remove any existing toasts with the same id
			const currentToasts = prevToasts.filter(
				(prevToast) => prevToast.id !== id,
			);
			return currentToasts;
		});
	};

	const toast = useCallback(
		({ description, title, type }: Omit<Toast, "id">) => {
			setToasts((prevToasts) => {
				const id = kebabCase(title);

				// Remove any existing toasts with the same id
				const currentToasts = prevToasts.filter(
					(prevToast) => prevToast.id !== id,
				);

				return [
					{
						description,
						id,
						title,
						type,
					},
					...currentToasts,
				];
			});
		},
		[],
	);

	const toastError = useCallback(
		(title: Toast["title"], description?: Toast["description"]) => {
			return toast({ description, title, type: toastTypes.DANGER });
		},
		[toast],
	);

	const toastInfo = useCallback(
		(title: Toast["title"], description?: Toast["description"]) => {
			return toast({ description, title, type: toastTypes.INFO });
		},
		[toast],
	);

	const toastSuccess = useCallback(
		(title: Toast["title"], description?: Toast["description"]) => {
			return toast({ description, title, type: toastTypes.SUCCESS });
		},
		[toast],
	);

	const toastWarning = useCallback(
		(title: Toast["title"], description?: Toast["description"]) => {
			return toast({ description, title, type: toastTypes.WARNING });
		},
		[toast],
	);

	const clear = useCallback(() => setToasts([]), []);
	const remove = useCallback((id: string) => {
		setToasts((prevToasts) =>
			prevToasts.filter((prevToast) => prevToast.id !== id),
		);
	}, []);

	return (
		<ToastsContext.Provider
			value={{
				clear,
				remove,
				toastError,
				toastInfo,
				toastSuccess,
				toastWarning,
				toasts,
			}}
		>
			{children}
			{toasts.map(({ description, id, title, type }) => (
				<Snackbar
					key={id}
					open
					autoHideDuration={6000}
					onClose={handleOnClose(id)}
				>
					<Alert
						onClose={handleOnClose(id)}
						severity={ToastTypeToSeverity[type]}
						sx={{ width: "100%" }}
					>
						{title}
						{description}
					</Alert>
				</Snackbar>
			))}
		</ToastsContext.Provider>
	);
};
