"use client";

import { type HTMLProps, forwardRef, useEffect } from "react";
import { type VariantProps, tv } from "tailwind-variants";

const animatePresenceStyles = tv({
	base: "vapor-modal-animation z-100 fixed bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center will-change-[opacity]",
});
export interface AnimatePresenceProps
	extends VariantProps<typeof animatePresenceStyles>,
		HTMLProps<HTMLDivElement> {}

export const AnimatePresence = forwardRef<HTMLDivElement, AnimatePresenceProps>(
	({ children, className, ...rest }, ref) => {
		useEffect(() => {
			const element = (ref as typeof ref & { current?: HTMLDivElement })
				?.current;
			if (!element) return;
			element.classList.remove("disappear");
			element.classList.add("appear");
			return () => {
				element.classList.remove("appear");
				element.classList.add("disappear");
			};
		}, [ref]);

		return (
			<div
				{...rest}
				ref={ref}
				className={animatePresenceStyles({ class: className })}
			>
				{children}
			</div>
		);
	},
);
