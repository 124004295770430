import { type ComponentProps, forwardRef } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { ErrorIcon, InfoIcon, StratosphereIcon, WarningIcon } from "../Icons";
import { Box } from "../Layout";

type AlertCardVariants = VariantProps<typeof alertCardStyles>;
export type AlertCardProps = {
	showIcon?: boolean;
	stack?: string;
	itemsCenter?: boolean;
	css?: React.CSSProperties;
} & AlertCardVariants &
	ComponentProps<"div">;

const AlertIcons = {
	error: <ErrorIcon />,
	info: <InfoIcon />,
	stratosphere: <StratosphereIcon />,
	warning: <WarningIcon />,
};

const alertCardStyles = tv({
	base: "flex gap-3 p-5 rounded-[10px] box-border border-solid",
	defaultVariants: { variant: "info" },
	variants: {
		variant: {
			error: "border-2 border-red",
			info: "border-2 border-turquoise-500",
			plain: "border-0",
			stratosphere: "border-gradient-stratosphere shadow-inner",
			warning: "border-2 border-yellow",
		},
	},
});

const AlertCard = forwardRef<HTMLDivElement, AlertCardProps>(
	({ children, className, showIcon = true, variant, ...rest }, ref) => {
		return (
			<div
				className={alertCardStyles({ class: className, variant })}
				ref={ref}
				{...rest}
			>
				{showIcon &&
					variant !== "plain" &&
					AlertIcons[variant as keyof typeof AlertIcons]}
				<Box fullWidth>{children}</Box>
			</div>
		);
	},
);

export { AlertCard };
