import { type AnchorHTMLAttributes, forwardRef } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { Typography } from "../Typography";

export interface LinkProps
	extends VariantProps<typeof linkStyles>,
		AnchorHTMLAttributes<HTMLAnchorElement> {
	external?: boolean;
}

const linkStyles = tv({
	base: "flex items-center fit-content hover:underline",
	// Add more variants if needed
});

const getExternalLinkProps = (): { target: string; rel: string } => ({
	rel: "noreferrer noopener",
	target: "_blank",
});

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
	({ children, external, ...props }, ref) => {
		const internalProps = external ? getExternalLinkProps() : {};
		const linkClassName = linkStyles({ class: props.className });

		return (
			<a ref={ref} className={linkClassName} {...internalProps} {...props}>
				<Typography>{children}</Typography>
			</a>
		);
	},
);

Link.displayName = "Link";

export { Link };
