export const ZAP_ABI = [
	{
		inputs: [
			{
				internalType: "address",
				name: "_WAVAXAddress",
				type: "address",
			},
			{
				internalType: "address",
				name: "_vaporDEXRouter",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_maxZapReverseRatio",
				type: "uint256",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "tokenAddress",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amountTokens",
				type: "uint256",
			},
		],
		name: "AdminTokenRecovery",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "maxZapReverseRatio",
				type: "uint256",
			},
		],
		name: "NewMaxZapReverseRatio",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "tokenToZap",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "lpToken",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenAmountIn",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "lpTokenAmountReceived",
				type: "uint256",
			},
			{
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address",
			},
		],
		name: "ZapIn",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "token0ToZap",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "token1ToZap",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "lpToken",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "token0AmountIn",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "token1AmountIn",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "lpTokenAmountReceived",
				type: "uint256",
			},
			{
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address",
			},
		],
		name: "ZapInRebalancing",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "lpToken",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "tokenToReceive",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "lpTokenAmount",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenAmountReceived",
				type: "uint256",
			},
			{
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address",
			},
		],
		name: "ZapOut",
		type: "event",
	},
	{
		inputs: [],
		name: "MAX_INT",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MINIMUM_AMOUNT",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "WAVAX",
		outputs: [
			{
				internalType: "contract IWETH",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_token0ToZap",
				type: "address",
			},
			{
				internalType: "address",
				name: "_token1ToZap",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_token0AmountIn",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_token1AmountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
		],
		name: "estimateZapInRebalancingSwap",
		outputs: [
			{
				internalType: "uint256",
				name: "swapAmountIn",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "swapAmountOut",
				type: "uint256",
			},
			{
				internalType: "bool",
				name: "sellToken0",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_tokenToZap",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
		],
		name: "estimateZapInSwap",
		outputs: [
			{
				internalType: "uint256",
				name: "swapAmountIn",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "swapAmountOut",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "swapTokenOut",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_lpTokenAmount",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenToReceive",
				type: "address",
			},
		],
		name: "estimateZapOutSwap",
		outputs: [
			{
				internalType: "uint256",
				name: "swapAmountIn",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "swapAmountOut",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "swapTokenOut",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "maxZapReverseRatio",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_tokenAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmount",
				type: "uint256",
			},
		],
		name: "recoverWrongTokens",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_maxZapInverseRatio",
				type: "uint256",
			},
		],
		name: "updateMaxZapInverseRatio",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "vaporDEXRouter",
		outputs: [
			{
				internalType: "contract IVaporDEXRouter02",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
		],
		name: "zapInAVAX",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_token1ToZap",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_token1AmountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountInMax",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
			{
				internalType: "bool",
				name: "_isToken0Sold",
				type: "bool",
			},
		],
		name: "zapInAVAXRebalancing",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_tokenToZap",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
		],
		name: "zapInToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_token0ToZap",
				type: "address",
			},
			{
				internalType: "address",
				name: "_token1ToZap",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_token0AmountIn",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_token1AmountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountInMax",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
			{
				internalType: "bool",
				name: "_isToken0Sold",
				type: "bool",
			},
		],
		name: "zapInTokenRebalancing",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_lpTokenAmount",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
		],
		name: "zapOutAVAX",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_lpToken",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenToReceive",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_lpTokenAmount",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_tokenAmountOutMin",
				type: "uint256",
			},
		],
		name: "zapOutToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		stateMutability: "payable",
		type: "receive",
	},
] as const;
