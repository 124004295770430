import { env } from "@/env";
import { SUPABASE_URL } from "./apiKeys";

export const BASE_URL =
	typeof window === "undefined"
		? env.NEXT_PUBLIC_APP_URL
		: window.location.origin;

export const MEDIUM_URL = "https://medium.com/@VaporFi";
export const SUPABASE_AVATAR_BASE_URL = `https://${SUPABASE_URL}/storage/v1/object/public/avatars`;
export const SUPABASE_DEFAULT_AVATAR_URL = `${SUPABASE_AVATAR_BASE_URL}/default`;

export const DOCS_URL = "https://docs.vapordex.io/";
export const INTRODUCING_VAPE_ARTICLE =
	"https://medium.com/@VaporFi/introducing-vape-7266a2f9d12f";
export const REDDIT_URL = "https://www.reddit.com/r/VaporChain/";
export const POOLS_DOCS_URL = `${DOCS_URL}/vapordex/liquidity-pools`;
export const V2_POOLS_DOCS_URL = `${POOLS_DOCS_URL}/v2-pools`;
export const POOLS_VERSION_DIFFERENCE_DOCS_URL = `${POOLS_DOCS_URL}/concentrated-liquidity-pools/concentrated-liquidity-vs-standard-pools`;
