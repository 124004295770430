/* eslint-disable unicorn/consistent-destructuring */
import { ChainId, type Currency, NativeCurrency, Token } from "@vapordex/sdk";
import invariant from "tiny-invariant";

import { NETWORK_ICON } from "../networkIcons";
import { apeChain } from "./apechain";
import { curtis } from "./apechain-curtis";
import { avalanche } from "./avalanche";
import { avalancheFuji } from "./avalanche-fuji";
import { skaleEuropaTestnet } from "./skale-europa-testnet";
import { telos } from "./telos-mainnet";
import { telosTestnet } from "./telos-testnet";

const {
	AVALANCHE,
	AVALANCHE_TESTNET,
	APECHAIN,
	CURTIS,
	SKALE_EUROPA_TESTNET,
	TELOS,
	TELOS_TESTNET,
} = ChainId;

export const viemChains = {
	avalanche,
	apeChain,
	avalancheFuji,
	curtis,
	skaleEuropaTestnet,
	telos,
	telosTestnet,
};

/*
 * SupportedChainId must be defined inline, without using @vapordex/sdk, so that its members are their own types
 * {@see https://www.typescriptlang.org/docs/handbook/enums.html#union-enums-and-enum-member-types}. This allows the
 * derived const arrays and their types (eg {@link L1_CHAIN_IDS}, {@link SupportedL1ChainId}) to be narrowed and used
 * to enforce chain typing.
 *
 * Because this is not explicitly derived from @vapordex/sdk, there is a unit test to enforce conformance.
 */
export enum SupportedChainId {
	AVALANCHE = 43_114,
	AVALANCHE_TESTNET = 43_113,
	APECHAIN = 33139,
	CURTIS = 33_111,
	SKALE_EUROPA_TESTNET = 1_444_673_419,
	TELOS = 40,
	TELOS_TESTNET = 41,
}
export const DEFAULT_CHAIN_ID = SupportedChainId.AVALANCHE;
export const CHAIN_ID_TO_QUERY_NAME = {
	[SupportedChainId.AVALANCHE]: "avalanche",
	[SupportedChainId.AVALANCHE_TESTNET]: "avalanche-fuji",
	[SupportedChainId.APECHAIN]: "apechain",
	[SupportedChainId.CURTIS]: "curtis",
	[SupportedChainId.SKALE_EUROPA_TESTNET]: "skale-europa-testnet",
	[SupportedChainId.TELOS]: "telos",
	[SupportedChainId.TELOS_TESTNET]: "telos-testnet",
} as const satisfies Record<SupportedChainId, string>;

export const NATIVE_SYMBOLS = ["AVAX", "TLOS", "APE", "sFUEL"];

export const WRAPPED_NATIVE_CURRENCY: {
	[chainId: number]: Token | undefined;
} = {
	[SupportedChainId.AVALANCHE]: new Token(
		AVALANCHE,
		"0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
		18,
		"WAVAX",
		"Wrapped AVAX",
	),
	[SupportedChainId.AVALANCHE_TESTNET]: new Token(
		AVALANCHE_TESTNET,
		"0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
		18,
		"WAVAX",
		"Wrapped AVAX",
	),
	[SupportedChainId.CURTIS]: new Token(
		CURTIS,
		"0xC009a670E2B02e21E7e75AE98e254F467f7ae257",
		18,
		"WAPE",
		"Wrapped Ape",
	),
	[SupportedChainId.APECHAIN]: new Token(
		APECHAIN,
		"0x48b62137edfa95a428d35c09e44256a739f6b557",
		18,
		"WAPE",
		"Wrapped ApeCoin",
	),
	[SupportedChainId.SKALE_EUROPA_TESTNET]: new Token(
		SKALE_EUROPA_TESTNET,
		"0xba05e3c8033705017ea734f4041fcce7f5d43271",
		18,
		"wSKL",
		"Europa Wrapped SKL",
	),
	[SupportedChainId.TELOS]: new Token(
		TELOS,
		"0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
		18,
		"WTLOS",
		"Wrapped Telos",
	),
	[SupportedChainId.TELOS_TESTNET]: new Token(
		TELOS_TESTNET,
		"0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9",
		18,
		"WTLOS",
		"Wrapped Telos",
	),
};
export function isSkaleEuropaTestnet(chainId: number): boolean {
	return chainId === SupportedChainId.SKALE_EUROPA_TESTNET;
}
export function isCurtis(chainId: number): boolean {
	return chainId === SupportedChainId.CURTIS;
}

export function isAvax(chainId: number): boolean {
	return chainId === SupportedChainId.AVALANCHE;
}
export function isAvaxTestnet(chainId: number): boolean {
	return chainId === SupportedChainId.AVALANCHE_TESTNET;
}

export function isTelos(chainId: number): chainId is SupportedChainId.TELOS {
	return chainId === SupportedChainId.TELOS;
}

export function isApeChain(
	chainId: number,
): chainId is SupportedChainId.APECHAIN {
	return chainId === SupportedChainId.APECHAIN;
}
export function isTelosTestnet(
	chainId: number,
): chainId is SupportedChainId.TELOS_TESTNET {
	return chainId === SupportedChainId.TELOS_TESTNET;
}
export const isWrapRequiredByChainId = (chainId: SupportedChainId) =>
	chainId ? isTelos(chainId) || isTelosTestnet(chainId) : false;

export const getQueryParamsChainId = (chainName: string) => {
	if (!chainName) return;
	const chainId =
		CHAIN_QUERY_NAME_TO_ID[
			chainName.toLowerCase() as keyof typeof CHAIN_QUERY_NAME_TO_ID
		];
	return chainId ? +chainId : undefined;
};

export const CHAIN_QUERY_NAME_TO_ID = Object.entries(
	CHAIN_ID_TO_QUERY_NAME,
).reduce(
	(acc, [chainId, chainName]) => {
		return {
			[chainName.toLowerCase()]: chainId as unknown as ChainId,
			// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
			...acc,
		};
	},
	{} as const satisfies Record<string, SupportedChainId>,
);

const cachedNativeCurrency: { [chainId: number]: Currency } = {};

export function nativeOnChain(chainId: number): Currency {
	if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId];
	let nativeCurrency: Currency;

	if (isAvax(chainId) || isAvaxTestnet(chainId)) {
		nativeCurrency = new AvalancheNativeCurrency(chainId);
	}

	if (isTelos(chainId) || isTelosTestnet(chainId)) {
		nativeCurrency = new TelosNativeCurrency(chainId);
	}

	if (isCurtis(chainId) || isApeChain(chainId)) {
		nativeCurrency = new CurtisNativeCurrency(chainId);
	}
	if (isSkaleEuropaTestnet(chainId)) {
		nativeCurrency = new SkaleEuropaNativeCurrency(chainId);
	}
	if (!nativeCurrency) return nativeCurrency;
	nativeCurrency.logo = NETWORK_ICON?.[chainId];
	cachedNativeCurrency[chainId] = nativeCurrency;

	return nativeCurrency;
}

export class CurtisNativeCurrency extends NativeCurrency {
	get wrapped(): Token {
		if (!isCurtis(this.chainId) && !isApeChain(this.chainId))
			throw new Error("Not apechain or curtis");
		const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
		invariant(wrapped instanceof Token);
		return wrapped;
	}

	public constructor(chainId: number) {
		if (!isCurtis(chainId) && !isApeChain(chainId))
			throw new Error("Not curtis");
		super(chainId, 18, "APE", "ApeCoin");
	}
}

export class SkaleEuropaNativeCurrency extends NativeCurrency {
	get wrapped(): Token {
		if (!isSkaleEuropaTestnet(this.chainId))
			throw new Error("Not skale europa");
		const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
		invariant(wrapped instanceof Token);
		return wrapped;
	}

	public constructor(chainId: number) {
		if (!isSkaleEuropaTestnet(chainId)) throw new Error("Not skale europa");
		super(chainId, 18, "sFUEL", "Skale (Gas)");
	}
}

export class AvalancheNativeCurrency extends NativeCurrency {
	get wrapped(): Token {
		if (!isAvax(this.chainId) && !isAvaxTestnet(this.chainId))
			throw new Error("Not avax");
		const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
		invariant(wrapped instanceof Token);
		return wrapped;
	}

	public constructor(chainId: number) {
		if (!isAvax(chainId) && !isAvaxTestnet(chainId))
			throw new Error("Not avax");
		super(chainId, 18, "AVAX", "Avalanche Token");
	}
}

class TelosNativeCurrency extends NativeCurrency {
	get wrapped(): Token {
		if (!isTelos(this.chainId) && !isTelosTestnet(this.chainId))
			throw new Error("Not telos");
		const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
		invariant(wrapped instanceof Token);
		return wrapped;
	}

	public constructor(chainId: number) {
		if (!isTelos(chainId) && !isTelosTestnet(chainId))
			throw new Error("Not telos");
		super(chainId, 18, "TLOS", "Telos");
	}
}
