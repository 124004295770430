import { WalletModalView } from "@vaporfi/features";
import { useLowBalance } from "hooks/useTokenBalance";
import type React from "react";
import { useState } from "react";
import WalletInfo from "./WalletInfo";
import WalletTransactions from "./WalletTransactions";
import WalletWrongNetwork from "./WalletWrongNetwork";

export enum WalletView {
	WALLET_INFO = 0,
	TRANSACTIONS = 1,
	WRONG_NETWORK = 2,
}

interface WalletModalProps {
	initialView?: WalletView;
	UDID?: string;
	onDismiss: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({
	UDID,
	initialView = WalletView.WALLET_INFO,
	onDismiss,
}) => {
	const [view, setView] = useState(initialView);
	const hasLowBalance = useLowBalance();

	return (
		<WalletModalView
			view={view}
			setView={setView}
			renderWalletInfo={() =>
				view === WalletView.WALLET_INFO ? (
					<WalletInfo
						hasLowAVAXBalance={hasLowBalance}
						onDismiss={onDismiss}
						UDID={UDID}
					/>
				) : null
			}
			renderTransactions={() =>
				view === WalletView.TRANSACTIONS ? <WalletTransactions /> : null
			}
			renderWrongNetwork={() =>
				view === WalletView.WRONG_NETWORK ? (
					<WalletWrongNetwork onDismiss={onDismiss} />
				) : null
			}
			onDismiss={onDismiss}
		/>
	);
};

export default WalletModal;
