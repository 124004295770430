/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import { createStitches } from "@stitches/react";

export const {
	config,
	createTheme,
	css,
	getCssText,
	globalCss,
	keyframes,
	styled,
	theme,
} = createStitches({
	/**
	 * @dev Hitesh
	 * Don't change order, should always be minimum to maximum
	 */
	media: {
		//minWidths: atleast
		sm: "(min-width: 640px)",
		md: "(min-width: 768px)",
		lg: "(min-width: 1024px)",
		xl: "(min-width: 1280px)",
		"2xl": "(min-width: 1536px)",
		//maxWidths: atmost
		smMax: "(max-width: 640px)",
		mdMax: "(max-width: 768px)",
		lgMax: "(max-width: 1024px)",
		xlMax: "(max-width: 1280px)",
		"2xlMax": "(max-width: 1536px)",
	},
	theme: {
		borderWidth: {
			0: "0px",
			1: "1px",
			2: "2px",
			4: "4px",
			8: "8px",
		},
		colors: {
			background: "#FAF9FA",
			black: "#000000",
			blue50: "#BDD3FB",
			blue100: "#AAC6FA",
			blue200: "#83ADF7",
			blue300: "#5D93F5",
			blue400: "#367AF2",
			blue500: "#1060F0",
			blue600: "#0C4BBC",
			blue700: "#083687",
			blue800: "#052153",
			blue900: "#020C1E",
			blueGradient: "linear-gradient(246.16deg, #1060F0 0%, #0C4BBC 100%)",
			gradient:
				"linear-gradient(270deg, #1BCCB2 5.77%, #14D3ED 47%, #1060F0 90.17%)",
			gradientLeaderboard:
				"linear-gradient(360deg, rgba(27, 204, 178, 1) 0%, rgba(13, 96, 210, 1) 0%, rgba(16, 96, 240, 0) 120%)",
			gradientReverted:
				"linear-gradient(270deg, #1060F0 45.18%, #14D3ED 69.73%, #1BCCB2 94.28%)",
			light: "#EEF2F2",
			neutral50: "#FAFAFA",
			neutral100: "#F5F5F5",
			neutral200: "#E5E5E5",
			neutral300: "#D4D4D4",
			neutral400: "#A3A3A3",
			neutral500: "#737373",
			neutral600: "#525252",
			neutral700: "#404040",
			neutral800: "#262626",
			neutral900: "#171717",
			red: "#D40E0E",
			turquoise50: "#BFF3FA",
			turquoise100: "#ACEFF9",
			turquoise200: "#86E8F6",
			turquoise300: "#60E1F3",
			turquoise400: "#3ADAF0",
			turquoise500: "#14D3ED",
			turquoise600: "#0EA6BB",
			turquoise700: "#0A7886",
			turquoise800: "#064952",
			turquoise900: "#021B1E",
			white: "#FFF",
			yellow: "#EAB324",
			green500: "#1BCCB2",
		},
		fontSizes: {
			"2xl": "1.5rem", //24px
			"3xl": "1.875rem", //30px
			"4xl": "2.25rem", //36px
			"5xl": "3rem", //48px
			"6xl": "3.75rem", //60px
			"7xl": "4.5rem", //72px
			"8xl": "6rem", //96px,
			"9xl": "8rem", //128px
			base: "1rem", //16px
			lg: "1.125rem", //18px
			sm: "0.875rem", //14px
			xl: "1.25rem", //20px
			xs: "0.75rem", //12px
		},
		fontWeights: {
			black: "900",
			bold: "700",
			extrabold: "800",
			extralight: "200",
			light: "300",
			medium: "500",
			normal: "400",
			semibold: "600",
			thin: "100",
		},
		fonts: {
			manverse: "Manverse Normal",
		},
		letterSpacing: {
			normal: "0em",
			tight: "-0.025em",
			tighter: "-0.05em",
			wide: "0.025em",
			wider: "0.05em",
			widest: "0.1em",
		},
		lineHeights: {
			3: "0.75rem", //12px
			4: "1rem", //16px
			5: "1.25rem", //20px
			6: "1.5rem", //24px
			7: "1.75rem", //28px
			8: "2rem", //32px
			9: "2.25rem", //36px
			10: "2.5rem", //40px
			11: "3rem", //48px
			12: "3.75rem", //60px
			13: "4.5rem", //72px
			14: "6rem", //96px,
			15: "8rem", //128px
			loose: "2",
			none: "1",
			normal: "1.5",
			relaxed: "1.625",
			snug: "1.375",
			tight: "1.25",
		},
		radii: {
			"2xl": "1rem", //16px
			"3xl": "1.5rem", //24px
			default: "0.25rem", //4px
			full: "9999px",
			lg: "0.5rem", //8px
			md: "0.375rem", //6px
			none: "0px",
			sm: "0.125rem", //2px
			xl: "0.75rem", //12px
		},
		shadows: {
			drop: "0px 0px 32px 0px rgba(16, 96, 240, 0.85)",
			modal:
				"0px 20px 36px -8px rgba(14, 14, 44, 0.1),0px 1px 1px rgba(0, 0, 0, 0.05)",
		},
		space: {
			"0": "0px", //0px
			"1": "0.25rem", //4px
			"2": "0.5rem", //8px
			"3": "0.75rem", //12px
			"4": "1rem", //16px
			"5": "1.25rem", //20px
			"6": "1.5rem", //24px
			"7": "1.75rem", //28px
			"8": "2rem", //32px
			"9": "2.25rem", //36px
			"10": "2.5rem", //40px
			"11": "2.75rem", //44px
			"12": "3rem", //48px
			"14": "3.5rem", //56px
			"16": "4rem", //64px
			"20": "5rem", //80px
			"24": "6rem", //96px
			"25": "6.25rem", //100px
			"28": "7rem", //112px
			"32": "8rem", //128px
			"36": "9rem", //144px
			"40": "10rem", //160px
			"44": "11rem", //176px
			"48": "12rem", //192px
			"50": "12.5rem", //200px
			"52": "13rem", //208px
			"56": "14rem", //224px
			"60": "15rem", //240px
			"64": "16rem", //256px
			"70": "17.5rem", //280px
			"72": "18rem", //288px
			"75": "18.75rem", //300px
			"80": "20rem", //320px
			"87AndHalf": "21.875rem", //350px
			"96": "24rem", //384px
			"125": "31.25rem", //500px
			"150": "37.5rem", //600px
			"450": "112.5rem", //1800px
			fourAndHalf: "1.125rem", //18px
			gap: "1.875rem", //30px
			half: "0.125rem", //2px
			oneAndHalf: "0.375rem", //6px
			px: "1px",
			threeAndHalf: "0.875rem", //14px
			twelveAndHalf: "3.125rem", //50px
			twoAndHalf: "0.625rem", //10px
		},
		zIndices: {
			0: "0",
			10: "10",
			17: "17",
			20: "20",
			30: "30",
			40: "40",
			50: "50",
			100: "100",
			auto: "auto",
		},
	},
	utils: {
		paddingX: (value: string) => ({
			paddingLeft: value,
			paddingRight: value,
		}),
	},
});
