export const copyText = (text: string, cb?: () => void) => {
	if (navigator.clipboard && navigator.permissions) {
		navigator.clipboard.writeText(text).then(cb);
	} else if (document.queryCommandSupported("copy")) {
		const ele = document.createElement("textarea");
		ele.value = text;
		document.body.append(ele);
		ele.select();
		document.execCommand("copy");
		ele.remove();
		cb?.();
	}
};
