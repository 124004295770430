import {
	Avatar,
	Box,
	Container,
	GradientCrownIcon,
	Typography,
} from "@vaporfi/uikit";
import { type VariantProps, tv } from "tailwind-variants";

import { type ComponentProps, memo, useMemo } from "react";

const leaderboardStyles = tv({
	defaultVariants: { isAccountConnected: false, isFirstRank: false },
	slots: {
		avatar: "w-full h-full",
		avatarContainer: "border-gradient-stratosphere rounded-full border-4",
		itemsContainer:
			"justify-between border-solid border-neutral-800 p-4 border-1",
		topRankContainer:
			"border-1 justify-center gap-12 border-solid border-neutral-800 pb-4 pb-4",
		topRankStatsContainer: "",
		wrapper: "pt-8",
	},
	variants: {
		isAccountConnected: {
			true: {
				itemsContainer: "bg-gradient-stratosphere",
				topRankStatsContainer:
					"bg-gradient-to-b from-black via-green/30 to-blue-500 p-4",
			},
		},
		isFirstRank: {
			false: {
				avatarContainer: "h-[90px] w-[91px]",
				topRankStatsContainer: "mt-16",
			},
			true: {
				avatarContainer: "h-[122px] w-[122px]",
				topRankStatsContainer: "gap-3",
			},
		},
	},
});

/**
 * Leaderboard items N x 1 (N rows, 1 column)
 */
export interface LeaderboardItemProps
	extends VariantProps<typeof leaderboardStyles>,
		ComponentProps<typeof Container> {
	rank: string;
	points: string;
	name: string;
	combinedPoints?: string;
	isMainWallet?: boolean;
	tokenId: number;
	avatarUrl: string;
	isAccountConnected?: boolean;
}
export const LeaderboardItem = memo(function InnerLeaderboardItem({
	avatarUrl,
	isAccountConnected,
	name,
	points,
	rank,
	...rest
}: LeaderboardItemProps) {
	return (
		<Container
			stack="row"
			fullWidth
			className={leaderboardStyles().itemsContainer({ isAccountConnected })}
			itemsCenter
			{...rest}
		>
			<Container stack="row" itemsCenter className="gap-7">
				<Typography
					size="sm"
					color={isAccountConnected ? "light" : "neutral"}
					weight="semibold"
				>
					{rank}
				</Typography>
				<Container stack="row" itemsCenter className="gap-3">
					<Avatar className="h-[38px] w-[38px]" src={avatarUrl} alt="avatar" />
					<Typography size="sm" weight="semibold">
						{name}
					</Typography>
				</Container>
			</Container>
			<Container stack="row" className="gap-2">
				<Typography size="sm" weight="semibold">
					{points}
				</Typography>
				<Typography size="sm">Points</Typography>
			</Container>
		</Container>
	);
});

/**
 * Leaderboard top ranks view: rank 1, 2 & 3
 */
export interface LeaderboardTopRankProps
	extends Partial<LeaderboardProps>,
		ComponentProps<typeof Container> {
	second: LeaderboardItemProps;
	first: LeaderboardItemProps;
	third: LeaderboardItemProps;
}
const LeaderboardTopRank = ({
	activeName,
	first,
	formatter,
	second,
	third,
	...rest
}: LeaderboardTopRankProps) => {
	const { avatar, avatarContainer, topRankContainer, topRankStatsContainer } =
		leaderboardStyles();
	return (
		<Container stack="row" fullWidth className={topRankContainer()} {...rest}>
			{[second, first, third]
				.filter(Boolean)
				.map(({ avatarUrl, name, points, rank, tokenId }) => {
					const isAccountConnected =
						activeName?.toLowerCase() === name?.toLowerCase();
					const isStratosphereMember = tokenId && +tokenId > 0;
					name = isStratosphereMember ? tokenId?.toString() : name?.slice(0, 7);

					points = formatter ? formatter(points) : points;
					const isFirstRank = rank === "1";

					return (
						<Container
							stack="column"
							key={`lb-top-3-rank-${rank}-${name}`}
							className={topRankStatsContainer({
								isAccountConnected,
								isFirstRank,
							})}
							itemsCenter
						>
							{isFirstRank && <GradientCrownIcon />}
							<Box
								className={avatarContainer({
									isAccountConnected,
									isFirstRank,
								})}
								style={{
									boxShadow: "var(--space-drop)",
								}}
							>
								<Avatar
									className={avatar({ isAccountConnected, isFirstRank })}
									src={avatarUrl}
									alt="avatar"
								/>
							</Box>
							<Box className="text-center">
								<Typography
									size="sm"
									weight="semibold"
									color={isAccountConnected ? "light" : "neutral"}
								>
									{rank}
								</Typography>
								<Typography
									size="base"
									weight="semibold"
									color={isAccountConnected ? "light" : "turquoise"}
								>
									{name}
								</Typography>
								<Typography size="base" weight="semibold">
									{points}
								</Typography>
							</Box>
						</Container>
					);
				})}
		</Container>
	);
};

/**
 * Main Leaderboard Component
 */
export interface LeaderboardProps
	extends VariantProps<typeof leaderboardStyles> {
	leaderboardItems: LeaderboardItemProps[];
	activeName: string;
	formatter: (value: string | number) => string;
	isDisplayTopRank: boolean;
}

export const Leaderboard = ({
	activeName,
	formatter,
	isDisplayTopRank = true, //default
	leaderboardItems,
}: LeaderboardProps) => {
	const [first, second, third, ...restItems] = leaderboardItems;
	const { wrapper } = leaderboardStyles();

	const leaderboardItemsProps = useMemo(() => {
		if (isDisplayTopRank) {
			return restItems;
		}
		return leaderboardItems;
	}, [isDisplayTopRank, leaderboardItems, restItems]);

	return (
		<Container className={wrapper()}>
			{isDisplayTopRank && (
				<LeaderboardTopRank
					{...{ activeName, first, formatter, isDisplayTopRank, second, third }}
				/>
			)}
			{leaderboardItemsProps.map((item) => (
				<LeaderboardItem
					key={`lb-rank-${item.name?.slice(7)}`}
					{...item}
					rank={item?.rank}
					points={formatter ? formatter(item?.points) : item?.points}
					name={
						item?.tokenId > 0
							? `Stratosphere #${item?.tokenId}`
							: item?.name?.slice(0, 7)
					}
					isAccountConnected={
						activeName?.toLowerCase() === item?.name?.toLowerCase()
					}
				/>
			))}
		</Container>
	);
};
