import { CogIcon } from "@vapordex/uikit";
import { useModal } from "@vaporfi/hooks";
import { Button } from "@vaporfi/uikit";
import { noop } from "lodash";
import SettingsModal from "./SettingsModal";

type Props = {
	color?: string;
	mr?: string;
	width?: string;
	height?: string;
};

const GlobalSettings = ({
	color,
	height = "16px",
	mr = "var(--space-2)",
	width = "16px",
}: Props) => {
	const [onPresentSettingsModal] = useModal(<SettingsModal onDismiss={noop} />);

	return (
		<Button
			color="transparent"
			data-testid="open-settings-btn"
			onClick={onPresentSettingsModal}
			size="sm"
			css={{
				marginLeft: "var(--space-twoAndHalf)",
				marginRight: mr,
				padding: 0,
			}}
			name="open settings dialog button"
		>
			<CogIcon {...{ color: color || "textSubtle", height, width }} />
		</Button>
	);
};

export default GlobalSettings;
