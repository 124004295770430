export const ApeChain = [
	{
		chainId: 33139,
		decimals: 18,
		address: "0xA2235d059F80e176D931Ef76b6C51953Eb3fBEf4",
		symbol: "ApeUSD",
		name: "Ape USD",
		logoURI:
			"https://static.vapordex.io/91f49222-3ed4-4821-9570-c4f502554e73.jpg",
	},
	{
		chainId: 33139,
		decimals: 18,
		address: "0xcF800F4948D16F23333508191B1B1591daF70438",
		symbol: "ApeETH",
		name: "Ape ETH",
		logoURI:
			"https://static.vapordex.io/e0312ff0-3edc-4cf8-a8b5-1294c04ee3f4.jpg",
	},
	{
		address: "0x48b62137edfa95a428d35c09e44256a739f6b557",
		chainId: 33139,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/8efae81a-b8f1-400a-9c9e-59c4e93a516b.png",
		name: "Wrapped ApeCoin",
		symbol: "WAPE",
	},
] as const;
