import { walletLocalStorageKey } from "./config";
import type { Config } from "./types";

const getPriority = (priority: Config["priority"]) =>
	typeof priority === "function" ? priority() : priority;

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
export const getPreferredConfig = (walletConfig: Config[]) => {
	const sortedConfig = walletConfig.sort(
		(a: Config, b: Config) => getPriority(a.priority) - getPriority(b.priority),
	);

	const preferredWalletName = localStorage?.getItem(walletLocalStorageKey);

	if (!preferredWalletName) {
		return sortedConfig;
	}

	const preferredWallet = sortedConfig.find(
		(sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName,
	);

	if (!preferredWallet) {
		return sortedConfig;
	}

	return [
		preferredWallet,
		...sortedConfig.filter(
			(sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName,
		),
	];
};
