import { Grid, Typography, type TypographyProps } from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import { forwardRef } from "react";

export const HeaderItem = forwardRef<HTMLParagraphElement, TypographyProps>(
	({ children, className }, ref) => (
		<Typography
			color="dark"
			className={cn("min-w-fit text-center", className)}
			ref={ref}
		>
			{children}
		</Typography>
	),
);

export default function TradesHeader({ symbol }: { symbol: string }) {
	return (
		<Grid
			className="bg-purple-5 border-purple-4 w-full grid-cols-6 gap-4 overflow-x-auto rounded-lg border-2 p-4"
			isContainer
		>
			<HeaderItem>Account</HeaderItem>
			<HeaderItem>Type</HeaderItem>
			<HeaderItem>AVAX</HeaderItem>
			<HeaderItem>{symbol}</HeaderItem>
			<HeaderItem>Date</HeaderItem>
			<HeaderItem>Transaction</HeaderItem>
		</Grid>
	);
}
