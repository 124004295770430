import { Svg, type SvgProps } from "./Svg";

export const WhaleIcon = (props: SvgProps) => {
	return (
		<Svg
			width="60"
			height="80"
			viewBox="0 0 60 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1617_15127)">
				<path
					d="M25.767 68.0653C27.5901 70.9866 31.4825 74.189 36.4208 73.813C37.9703 73.6949 39.8043 73.7769 40.0965 72.459C40.286 71.6103 39.5909 70.7885 38.9706 70.2594C36.735 68.3564 32.5771 68.3356 30.2499 66.4331C28.8152 65.2601 27.9777 65.5592 25.7655 68.0655L25.767 68.0653Z"
					fill="#B2C8F0"
				/>
				<path
					d="M44.6063 12.4831C43.8687 11.399 40.9411 8.75039 37.3653 8.27742C33.9035 7.81991 30.9948 4.76898 30.5915 2.92869C29.7878 -0.743423 29.3105 3.99799 30.7431 8.45093C32.1742 12.9041 37.003 18.6735 43.7541 20.0227C44.6784 19.8736 44.5825 19.7715 46.0263 21.2461C47.4686 22.7209 52.416 25.2219 55.9949 21.3455C59.5742 17.4707 59.6057 10.3434 59.2463 9.22679C58.8883 8.10996 58.253 11.8752 55.8102 12.8913C53.3674 13.9074 51.7736 12.8366 49.9168 11.6229C48.0615 10.409 44.9661 11.3093 44.6057 12.4797L44.6063 12.4831Z"
					fill="#C7D6F0"
				/>
				<path
					d="M41.8541 54.1877C35.2168 61.7694 25.8041 70.4831 18.8841 75.2051C11.9712 79.9331 2.24986 79.3232 0.514526 74.5003C0.459449 74.3487 0.409286 74.1804 0.363868 74.013C-1.12192 68.6935 2.20297 58.1332 5.62318 50.7284C8.74184 43.9799 17.4508 36.7788 26.8881 34.5578C28.1256 34.2673 29.376 34.0656 30.6302 33.9542C41.4223 33.026 42.4761 28.7887 43.0261 27.4398C43.5808 26.0831 42.8258 16.3374 43.0262 14.4995C43.1456 13.3877 44.1133 12.7984 44.6795 12.7766C45.055 12.7659 45.2536 12.9924 44.9472 13.4678C44.1657 14.6793 45.5308 20.3431 48.6131 30.2976C51.1507 38.4916 49.9887 44.9102 41.8556 54.1875L41.8541 54.1877Z"
					fill="#90B2F0"
				/>
				<path
					d="M19.6337 66.8231C21.1 67.7576 18.8529 70.1805 18.3157 68.6363C18.0103 67.7587 18.7566 66.2641 19.6337 66.8231Z"
					fill="#39474D"
				/>
				<path
					d="M18.7417 67.555C19.0694 67.7302 18.8603 68.3629 18.5731 68.1365C18.3772 67.9827 18.4657 67.407 18.7417 67.555Z"
					fill="#A1C8D9"
				/>
				<path
					d="M43.8277 14.4995C43.7643 14.9571 43.9157 18.4816 43.9886 18.9315C44.4174 21.543 44.9831 24.1128 45.3978 26.7123C45.9041 29.8781 47.2065 34.0365 45.905 36.9984C45.2678 38.4309 44.1954 39.7036 43.2266 40.833C42.0464 42.214 40.3278 42.9386 38.8522 43.8628C36.0692 45.6076 33.6219 47.6813 31.2919 50.1585C29.8218 51.7111 28.6349 53.569 27.3812 55.354C26.3624 56.807 25.0962 58.3266 24.2911 59.9483C23.9612 60.6182 23.8113 61.391 23.4318 62.019C23.1143 62.539 22.6621 62.9345 22.3445 63.4545C21.9558 64.0911 21.8277 64.8194 21.3275 65.3617C20.8477 65.8794 20.4724 65.8633 19.9019 65.9339C18.1668 66.1514 18.1112 68.3651 17.5923 69.9336C16.6258 72.861 14.5893 75.0664 11.8557 75.739C10.1015 76.1682 8.19633 76.0709 6.3999 75.7582C4.83735 75.4845 3.1132 75.3919 1.61089 74.7449C1.41045 74.6578 0.826328 74.3046 0.365576 74.0154C-1.12021 68.6959 2.20467 58.1356 5.62489 50.7307C8.74354 43.9822 17.4525 36.7812 26.8898 34.5601C28.1273 34.2696 29.3777 34.0679 30.6319 33.9565C41.424 33.0283 42.4778 28.791 43.0278 27.4422C43.5825 26.0854 42.5569 16.7981 42.7574 14.9603C42.8767 13.8484 43.8492 13.1241 44.4154 13.1023C44.4116 13.1171 44.404 13.4583 44.4003 13.4732C44.3179 13.9267 43.8975 14.0199 43.83 14.4995L43.8277 14.4995Z"
					fill="#B2C8F0"
				/>
				<path
					d="M14.9888 46.1253C15.2844 46.4983 14.8023 46.7472 14.5556 46.8369C14.2207 46.9568 13.5979 46.9878 13.3087 46.6922C12.8712 46.2442 13.4587 45.9676 13.7847 45.858C14.1398 45.7401 14.7111 45.778 14.9873 46.1256L14.9888 46.1253Z"
					fill="#90B2F0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1617_15127">
					<rect width="59.3939" height="80" fill="white" />
				</clipPath>
			</defs>
		</Svg>
	);
};
