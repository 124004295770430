import type { Colors } from "./types";

export const baseColors = {
	failure: "#D40E0E",
	primary: "#1060F0",
	primaryBright: "#53DEE9",
	primaryDark: "#0098A1",
	secondary: "#14D3ED",
	success: "#14D3ED",
	warning: "#FFB237",
};

export const additionalColors = {
	binance: "#F0B90B",
	bronze: "#E7974D",
	gold: "#FFC700",
	overlay: "#A3A3A3",
	silver: "#B2B2B2",
};

export const lightColors: Colors = {
	...baseColors,
	...additionalColors,
	background: "#FAF9FA",
	backgroundAlt: "#FFFFFF",
	backgroundAlt2: "rgba(255, 255, 255, 0.7)",
	backgroundDisabled: "#E9EAEB",
	cardBorder: "#E7E3EB",
	contrast: "#020C1E",
	disabled: "#E9EAEB",
	dropdown: "#F6F6F6",
	dropdownDeep: "#EEEEEE",
	gradients: {
		background:
			"linear-gradient(180.01deg, #101010 82.52%, rgba(0, 0, 0, 0) 98.81%)",
		blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
		bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
		cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
		gold: "linear-gradient(180deg, #ffc400 0%, #FDAB32 100%)",
		inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
		violet: "linear-gradient(180deg, #AAC6FA 0%, #5D93F5 100%)",
		violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
	},
	input: "#eeeaf4",
	inputSecondary: "#d7caec",
	invertedContrast: "#FFFFFF",
	tertiary: "#EFF4F5",
	text: "#052153",
	textDisabled: "#BDC2C4",
	textSubtle: "#083687",
};

export const darkColors: Colors = {
	...baseColors,
	...additionalColors,
	background: "#010100",
	backgroundAlt: "#171717",
	backgroundAlt2: "rgba(39, 38, 44, 0.7)",
	backgroundDisabled: "#3c3742",
	cardBorder: "#383241",
	contrast: "#FFFFFF",
	disabled: "#524B63",
	dropdown: "#1E1D20",
	dropdownDeep: "#100C18",
	gradients: {
		background:
			"linear-gradient(180.01deg, #101010 82.52%, rgba(0, 0, 0, 0) 98.81%)",
		blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
		bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #020C1E 100%)",
		cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
		gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
		inverseBubblegum: "linear-gradient(139.73deg, #020C1E 0%, #313D5C 100%)",
		violet: "linear-gradient(180deg, #1060F0 0%, #0C4BBC 100%)",
		violetAlt: "linear-gradient(180deg, #1060F0 0%, #052153 100%)",
	},
	input: "#525252",
	inputSecondary: "#262130",
	invertedContrast: "#191326",
	primaryDark: "#064952",
	secondary: "#367AF2",
	tertiary: "#353547",
	text: "#F4EEFF",
	textDisabled: "#666171",
	textSubtle: "#B8ADD2",
};
