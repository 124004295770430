import { createSelector } from "@reduxjs/toolkit";
import type { Token } from "@vapordex/sdk";
import type { ChainId } from "@vapordex/sdk";
import { getChainIdCookie } from "config/constants/networks";
import { useSelector } from "react-redux";
import type { AppState } from "../../index";
import { deserializeToken } from "./helpers";
const selectUserTokens = ({ user: { tokens } }: AppState) => tokens;

export const userAddedTokenSelector = createSelector(
	selectUserTokens,
	(serializedTokensMap) =>
		Object.values(
			serializedTokensMap?.[getChainIdCookie() as unknown as ChainId] ?? {},
		).map(deserializeToken),
);
export default function useUserAddedTokens(): Token[] {
	return useSelector(userAddedTokenSelector);
}
