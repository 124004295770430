const timeUnits = [
	{ divisor: 31_536_000, unit: "year" }, // Seconds in a year
	{ divisor: 2_592_000, unit: "month" }, // Seconds in a month (average)
	{ divisor: 604_800, unit: "week" }, // Seconds in a week
	{ divisor: 86_400, unit: "day" }, // Seconds in a day
	{ divisor: 3600, unit: "hour" }, // Seconds in an hour
	{ divisor: 60, unit: "minute" }, // Seconds in a minute
];

export function getDate(timestamp: number): number {
	return new Date(timestamp * 1000).getDate();
}
export function getCurrentTimestamp(): number {
	return Math.floor(Date.now() / 1000);
}

export function getTimeAgo(timestamp: number, prefix = "Created"): string {
	const now = Date.now();
	const timeCreated = timestamp * 1000;
	const diff = Math.floor((now - timeCreated) / 1000);

	let timeUnit: { unit: string; divisor: number } | null = null;
	for (const unit of timeUnits) {
		if (diff >= unit.divisor) {
			timeUnit = unit;
			break;
		}
	}

	if (timeUnit) {
		const value = Math.floor(diff / timeUnit.divisor);
		return `${prefix} ${value} ${timeUnit.unit}${value > 1 ? "s" : ""} ago`;
	}
	return `${prefix} just now`;
}

export const months = {
	0: "January",
	1: "February",
	2: "March",
	3: "April",
	4: "May",
	5: "June",
	6: "July",
	7: "August",
	8: "September",
	9: "October",
	10: "November",
	11: "December",
} as const;

export function getMonth(timestamp: number): string {
	const monthIndex = new Date(
		timestamp * 1000,
	).getUTCMonth() as keyof typeof months;
	return months[monthIndex];
}

export function getAnteMeridiem(hours: number, minutes: number | string) {
	const ampm = hours >= 12 ? "PM" : "AM";
	const adjustedHours = hours % 12 || 12;
	const formattedMinutes =
		typeof minutes === "number" && minutes < 10 ? `0${minutes}` : minutes;
	return `${adjustedHours}:${formattedMinutes} ${ampm}`;
}

export function timestampToAMOrPM(timestamp: number) {
	const date = new Date(timestamp);
	const hours = date.getHours();
	const minutes: number | string = date.getMinutes();
	return getAnteMeridiem(hours, minutes);
}

export function dateToAMOrPM(date: Date) {
	const hours = date.getHours();
	const minutes: number | string = date.getMinutes();
	return getAnteMeridiem(hours, minutes);
}
