"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ADDRESS_ZERO: () => ADDRESS_ZERO,
  FACTORY_ADDRESS: () => FACTORY_ADDRESS,
  FeeAmount: () => FeeAmount,
  FullMath: () => FullMath,
  LiquidityMath: () => LiquidityMath,
  Multicall: () => Multicall,
  NoTickDataProvider: () => NoTickDataProvider,
  NonfungiblePositionManager: () => NonfungiblePositionManager,
  POOL_INIT_CODE_HASH: () => POOL_INIT_CODE_HASH,
  Payments: () => Payments,
  Pool: () => Pool,
  Position: () => Position,
  PositionLibrary: () => PositionLibrary,
  Route: () => Route,
  SelfPermit: () => SelfPermit,
  SqrtPriceMath: () => SqrtPriceMath,
  Staker: () => Staker,
  SwapMath: () => SwapMath,
  SwapQuoter: () => SwapQuoter,
  SwapRouter: () => SwapRouter,
  TICK_SPACINGS: () => TICK_SPACINGS,
  Tick: () => Tick,
  TickLibrary: () => TickLibrary,
  TickList: () => TickList,
  TickListDataProvider: () => TickListDataProvider,
  TickMath: () => TickMath,
  Trade: () => Trade,
  computePoolAddress: () => computePoolAddress,
  encodeRouteToPath: () => encodeRouteToPath,
  encodeSqrtRatioX96: () => encodeSqrtRatioX96,
  isSorted: () => isSorted,
  maxLiquidityForAmounts: () => maxLiquidityForAmounts,
  mostSignificantBit: () => mostSignificantBit,
  nearestUsableTick: () => nearestUsableTick,
  priceToClosestTick: () => priceToClosestTick,
  subIn256: () => subIn256,
  tickToPrice: () => tickToPrice,
  toHex: () => toHex,
  tradeComparator: () => tradeComparator
});
module.exports = __toCommonJS(src_exports);

// src/entities/pool.ts
var import_sdk6 = require("@vapordex/sdk");
var import_jsbi16 = __toESM(require("jsbi"));
var import_tiny_invariant7 = __toESM(require("tiny-invariant"));

// src/constants.ts
var FACTORY_ADDRESS = "0x4919087742f7b9cEDcE9458A3717D1fD573725f7";
var ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";
var POOL_INIT_CODE_HASH = "0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54";
var FeeAmount = /* @__PURE__ */ ((FeeAmount4) => {
  FeeAmount4[FeeAmount4["LOWEST"] = 100] = "LOWEST";
  FeeAmount4[FeeAmount4["LOW"] = 500] = "LOW";
  FeeAmount4[FeeAmount4["MEDIUM"] = 3e3] = "MEDIUM";
  FeeAmount4[FeeAmount4["HIGH"] = 1e4] = "HIGH";
  return FeeAmount4;
})(FeeAmount || {});
var TICK_SPACINGS = {
  [1e4 /* HIGH */]: 200,
  [500 /* LOW */]: 10,
  [100 /* LOWEST */]: 1,
  [3e3 /* MEDIUM */]: 60
};

// src/internalConstants.ts
var import_jsbi = __toESM(require("jsbi"));
var NEGATIVE_ONE = import_jsbi.default.BigInt(-1);
var ZERO = import_jsbi.default.BigInt(0);
var ONE = import_jsbi.default.BigInt(1);
var Q96 = import_jsbi.default.exponentiate(import_jsbi.default.BigInt(2), import_jsbi.default.BigInt(96));
var Q192 = import_jsbi.default.exponentiate(Q96, import_jsbi.default.BigInt(2));

// src/utils/computePoolAddress.ts
var import_abi = require("@ethersproject/abi");
var import_address = require("@ethersproject/address");
var import_solidity = require("@ethersproject/solidity");
function computePoolAddress({
  factoryAddress,
  tokenA,
  tokenB,
  fee,
  initCodeHashManualOverride
}) {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
  return (0, import_address.getCreate2Address)(
    factoryAddress,
    (0, import_solidity.keccak256)(
      ["bytes"],
      [
        import_abi.defaultAbiCoder.encode(
          ["address", "address", "uint24"],
          [token0.address, token1.address, fee]
        )
      ]
    ),
    initCodeHashManualOverride != null ? initCodeHashManualOverride : POOL_INIT_CODE_HASH
  );
}

// src/utils/liquidityMath.ts
var import_jsbi2 = __toESM(require("jsbi"));
var LiquidityMath = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static addDelta(x, y) {
    if (import_jsbi2.default.lessThan(y, ZERO)) {
      return import_jsbi2.default.subtract(x, import_jsbi2.default.multiply(y, NEGATIVE_ONE));
    } else {
      return import_jsbi2.default.add(x, y);
    }
  }
};

// src/utils/swapMath.ts
var import_jsbi5 = __toESM(require("jsbi"));

// src/utils/fullMath.ts
var import_jsbi3 = __toESM(require("jsbi"));
var FullMath = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static mulDivRoundingUp(a, b, denominator) {
    const product = import_jsbi3.default.multiply(a, b);
    let result = import_jsbi3.default.divide(product, denominator);
    if (import_jsbi3.default.notEqual(import_jsbi3.default.remainder(product, denominator), ZERO))
      result = import_jsbi3.default.add(result, ONE);
    return result;
  }
};

// src/utils/sqrtPriceMath.ts
var import_sdk = require("@vapordex/sdk");
var import_jsbi4 = __toESM(require("jsbi"));
var import_tiny_invariant = __toESM(require("tiny-invariant"));
var MaxUint160 = import_jsbi4.default.subtract(
  import_jsbi4.default.exponentiate(import_jsbi4.default.BigInt(2), import_jsbi4.default.BigInt(160)),
  ONE
);
function multiplyIn256(x, y) {
  const product = import_jsbi4.default.multiply(x, y);
  return import_jsbi4.default.bitwiseAnd(product, import_sdk.MaxUint256);
}
function addIn256(x, y) {
  const sum = import_jsbi4.default.add(x, y);
  return import_jsbi4.default.bitwiseAnd(sum, import_sdk.MaxUint256);
}
var SqrtPriceMath = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static getAmount0Delta(sqrtRatioAX96, sqrtRatioBX96, liquidity, roundUp) {
    if (import_jsbi4.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
      [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
    }
    const numerator1 = import_jsbi4.default.leftShift(liquidity, import_jsbi4.default.BigInt(96));
    const numerator2 = import_jsbi4.default.subtract(sqrtRatioBX96, sqrtRatioAX96);
    return roundUp ? FullMath.mulDivRoundingUp(
      FullMath.mulDivRoundingUp(numerator1, numerator2, sqrtRatioBX96),
      ONE,
      sqrtRatioAX96
    ) : import_jsbi4.default.divide(
      import_jsbi4.default.divide(import_jsbi4.default.multiply(numerator1, numerator2), sqrtRatioBX96),
      sqrtRatioAX96
    );
  }
  static getAmount1Delta(sqrtRatioAX96, sqrtRatioBX96, liquidity, roundUp) {
    if (import_jsbi4.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
      [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
    }
    return roundUp ? FullMath.mulDivRoundingUp(
      liquidity,
      import_jsbi4.default.subtract(sqrtRatioBX96, sqrtRatioAX96),
      Q96
    ) : import_jsbi4.default.divide(
      import_jsbi4.default.multiply(liquidity, import_jsbi4.default.subtract(sqrtRatioBX96, sqrtRatioAX96)),
      Q96
    );
  }
  static getNextSqrtPriceFromInput(sqrtPX96, liquidity, amountIn, zeroForOne) {
    (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(sqrtPX96, ZERO));
    (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(liquidity, ZERO));
    return zeroForOne ? this.getNextSqrtPriceFromAmount0RoundingUp(
      sqrtPX96,
      liquidity,
      amountIn,
      true
    ) : this.getNextSqrtPriceFromAmount1RoundingDown(
      sqrtPX96,
      liquidity,
      amountIn,
      true
    );
  }
  static getNextSqrtPriceFromOutput(sqrtPX96, liquidity, amountOut, zeroForOne) {
    (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(sqrtPX96, ZERO));
    (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(liquidity, ZERO));
    return zeroForOne ? this.getNextSqrtPriceFromAmount1RoundingDown(
      sqrtPX96,
      liquidity,
      amountOut,
      false
    ) : this.getNextSqrtPriceFromAmount0RoundingUp(
      sqrtPX96,
      liquidity,
      amountOut,
      false
    );
  }
  static getNextSqrtPriceFromAmount0RoundingUp(sqrtPX96, liquidity, amount, add) {
    if (import_jsbi4.default.equal(amount, ZERO))
      return sqrtPX96;
    const numerator1 = import_jsbi4.default.leftShift(liquidity, import_jsbi4.default.BigInt(96));
    if (add) {
      let product = multiplyIn256(amount, sqrtPX96);
      if (import_jsbi4.default.equal(import_jsbi4.default.divide(product, amount), sqrtPX96)) {
        const denominator = addIn256(numerator1, product);
        if (import_jsbi4.default.greaterThanOrEqual(denominator, numerator1)) {
          return FullMath.mulDivRoundingUp(numerator1, sqrtPX96, denominator);
        }
      }
      return FullMath.mulDivRoundingUp(
        numerator1,
        ONE,
        import_jsbi4.default.add(import_jsbi4.default.divide(numerator1, sqrtPX96), amount)
      );
    } else {
      let product = multiplyIn256(amount, sqrtPX96);
      (0, import_tiny_invariant.default)(import_jsbi4.default.equal(import_jsbi4.default.divide(product, amount), sqrtPX96));
      (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(numerator1, product));
      const denominator = import_jsbi4.default.subtract(numerator1, product);
      return FullMath.mulDivRoundingUp(numerator1, sqrtPX96, denominator);
    }
  }
  static getNextSqrtPriceFromAmount1RoundingDown(sqrtPX96, liquidity, amount, add) {
    if (add) {
      const quotient = import_jsbi4.default.lessThanOrEqual(amount, MaxUint160) ? import_jsbi4.default.divide(import_jsbi4.default.leftShift(amount, import_jsbi4.default.BigInt(96)), liquidity) : import_jsbi4.default.divide(import_jsbi4.default.multiply(amount, Q96), liquidity);
      return import_jsbi4.default.add(sqrtPX96, quotient);
    } else {
      const quotient = FullMath.mulDivRoundingUp(amount, Q96, liquidity);
      (0, import_tiny_invariant.default)(import_jsbi4.default.greaterThan(sqrtPX96, quotient));
      return import_jsbi4.default.subtract(sqrtPX96, quotient);
    }
  }
};

// src/utils/swapMath.ts
var MAX_FEE = import_jsbi5.default.exponentiate(import_jsbi5.default.BigInt(10), import_jsbi5.default.BigInt(6));
var SwapMath = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static computeSwapStep(sqrtRatioCurrentX96, sqrtRatioTargetX96, liquidity, amountRemaining, feePips) {
    const returnValues = {};
    const zeroForOne = import_jsbi5.default.greaterThanOrEqual(
      sqrtRatioCurrentX96,
      sqrtRatioTargetX96
    );
    const exactIn = import_jsbi5.default.greaterThanOrEqual(amountRemaining, ZERO);
    if (exactIn) {
      const amountRemainingLessFee = import_jsbi5.default.divide(
        import_jsbi5.default.multiply(
          amountRemaining,
          import_jsbi5.default.subtract(MAX_FEE, import_jsbi5.default.BigInt(feePips))
        ),
        MAX_FEE
      );
      returnValues.amountIn = zeroForOne ? SqrtPriceMath.getAmount0Delta(
        sqrtRatioTargetX96,
        sqrtRatioCurrentX96,
        liquidity,
        true
      ) : SqrtPriceMath.getAmount1Delta(
        sqrtRatioCurrentX96,
        sqrtRatioTargetX96,
        liquidity,
        true
      );
      if (import_jsbi5.default.greaterThanOrEqual(amountRemainingLessFee, returnValues.amountIn)) {
        returnValues.sqrtRatioNextX96 = sqrtRatioTargetX96;
      } else {
        returnValues.sqrtRatioNextX96 = SqrtPriceMath.getNextSqrtPriceFromInput(
          sqrtRatioCurrentX96,
          liquidity,
          amountRemainingLessFee,
          zeroForOne
        );
      }
    } else {
      returnValues.amountOut = zeroForOne ? SqrtPriceMath.getAmount1Delta(
        sqrtRatioTargetX96,
        sqrtRatioCurrentX96,
        liquidity,
        false
      ) : SqrtPriceMath.getAmount0Delta(
        sqrtRatioCurrentX96,
        sqrtRatioTargetX96,
        liquidity,
        false
      );
      if (import_jsbi5.default.greaterThanOrEqual(
        import_jsbi5.default.multiply(amountRemaining, NEGATIVE_ONE),
        returnValues.amountOut
      )) {
        returnValues.sqrtRatioNextX96 = sqrtRatioTargetX96;
      } else {
        returnValues.sqrtRatioNextX96 = SqrtPriceMath.getNextSqrtPriceFromOutput(
          sqrtRatioCurrentX96,
          liquidity,
          import_jsbi5.default.multiply(amountRemaining, NEGATIVE_ONE),
          zeroForOne
        );
      }
    }
    const max = import_jsbi5.default.equal(sqrtRatioTargetX96, returnValues.sqrtRatioNextX96);
    if (zeroForOne) {
      returnValues.amountIn = max && exactIn ? returnValues.amountIn : SqrtPriceMath.getAmount0Delta(
        returnValues.sqrtRatioNextX96,
        sqrtRatioCurrentX96,
        liquidity,
        true
      );
      returnValues.amountOut = max && !exactIn ? returnValues.amountOut : SqrtPriceMath.getAmount1Delta(
        returnValues.sqrtRatioNextX96,
        sqrtRatioCurrentX96,
        liquidity,
        false
      );
    } else {
      returnValues.amountIn = max && exactIn ? returnValues.amountIn : SqrtPriceMath.getAmount1Delta(
        sqrtRatioCurrentX96,
        returnValues.sqrtRatioNextX96,
        liquidity,
        true
      );
      returnValues.amountOut = max && !exactIn ? returnValues.amountOut : SqrtPriceMath.getAmount0Delta(
        sqrtRatioCurrentX96,
        returnValues.sqrtRatioNextX96,
        liquidity,
        false
      );
    }
    if (!exactIn && import_jsbi5.default.greaterThan(
      returnValues.amountOut,
      import_jsbi5.default.multiply(amountRemaining, NEGATIVE_ONE)
    )) {
      returnValues.amountOut = import_jsbi5.default.multiply(amountRemaining, NEGATIVE_ONE);
    }
    if (exactIn && import_jsbi5.default.notEqual(returnValues.sqrtRatioNextX96, sqrtRatioTargetX96)) {
      returnValues.feeAmount = import_jsbi5.default.subtract(
        amountRemaining,
        returnValues.amountIn
      );
    } else {
      returnValues.feeAmount = FullMath.mulDivRoundingUp(
        returnValues.amountIn,
        import_jsbi5.default.BigInt(feePips),
        import_jsbi5.default.subtract(MAX_FEE, import_jsbi5.default.BigInt(feePips))
      );
    }
    return [
      returnValues.sqrtRatioNextX96,
      returnValues.amountIn,
      returnValues.amountOut,
      returnValues.feeAmount
    ];
  }
};

// src/utils/tickMath.ts
var import_sdk3 = require("@vapordex/sdk");
var import_jsbi7 = __toESM(require("jsbi"));
var import_tiny_invariant3 = __toESM(require("tiny-invariant"));

// src/utils/mostSignificantBit.ts
var import_sdk2 = require("@vapordex/sdk");
var import_jsbi6 = __toESM(require("jsbi"));
var import_tiny_invariant2 = __toESM(require("tiny-invariant"));
var TWO = import_jsbi6.default.BigInt(2);
var POWERS_OF_2 = [128, 64, 32, 16, 8, 4, 2, 1].map(
  (pow) => [
    pow,
    import_jsbi6.default.exponentiate(TWO, import_jsbi6.default.BigInt(pow))
  ]
);
function mostSignificantBit(x) {
  (0, import_tiny_invariant2.default)(import_jsbi6.default.greaterThan(x, ZERO), "ZERO");
  (0, import_tiny_invariant2.default)(import_jsbi6.default.lessThanOrEqual(x, import_sdk2.MaxUint256), "MAX");
  let msb = 0;
  for (const [power, min] of POWERS_OF_2) {
    if (import_jsbi6.default.greaterThanOrEqual(x, min)) {
      x = import_jsbi6.default.signedRightShift(x, import_jsbi6.default.BigInt(power));
      msb += power;
    }
  }
  return msb;
}

// src/utils/tickMath.ts
function mulShift(val, mulBy) {
  return import_jsbi7.default.signedRightShift(
    import_jsbi7.default.multiply(val, import_jsbi7.default.BigInt(mulBy)),
    import_jsbi7.default.BigInt(128)
  );
}
var Q32 = import_jsbi7.default.exponentiate(import_jsbi7.default.BigInt(2), import_jsbi7.default.BigInt(32));
var _TickMath = class _TickMath {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  /**
   * Returns the sqrt ratio as a Q64.96 for the given tick. The sqrt ratio is computed as sqrt(1.0001)^tick
   * @param tick the tick for which to compute the sqrt ratio
   */
  static getSqrtRatioAtTick(tick) {
    (0, import_tiny_invariant3.default)(
      tick >= _TickMath.MIN_TICK && tick <= _TickMath.MAX_TICK && Number.isInteger(tick),
      "TICK"
    );
    const absTick = tick < 0 ? tick * -1 : tick;
    let ratio = (absTick & 1) != 0 ? import_jsbi7.default.BigInt("0xfffcb933bd6fad37aa2d162d1a594001") : import_jsbi7.default.BigInt("0x100000000000000000000000000000000");
    if ((absTick & 2) != 0)
      ratio = mulShift(ratio, "0xfff97272373d413259a46990580e213a");
    if ((absTick & 4) != 0)
      ratio = mulShift(ratio, "0xfff2e50f5f656932ef12357cf3c7fdcc");
    if ((absTick & 8) != 0)
      ratio = mulShift(ratio, "0xffe5caca7e10e4e61c3624eaa0941cd0");
    if ((absTick & 16) != 0)
      ratio = mulShift(ratio, "0xffcb9843d60f6159c9db58835c926644");
    if ((absTick & 32) != 0)
      ratio = mulShift(ratio, "0xff973b41fa98c081472e6896dfb254c0");
    if ((absTick & 64) != 0)
      ratio = mulShift(ratio, "0xff2ea16466c96a3843ec78b326b52861");
    if ((absTick & 128) != 0)
      ratio = mulShift(ratio, "0xfe5dee046a99a2a811c461f1969c3053");
    if ((absTick & 256) != 0)
      ratio = mulShift(ratio, "0xfcbe86c7900a88aedcffc83b479aa3a4");
    if ((absTick & 512) != 0)
      ratio = mulShift(ratio, "0xf987a7253ac413176f2b074cf7815e54");
    if ((absTick & 1024) != 0)
      ratio = mulShift(ratio, "0xf3392b0822b70005940c7a398e4b70f3");
    if ((absTick & 2048) != 0)
      ratio = mulShift(ratio, "0xe7159475a2c29b7443b29c7fa6e889d9");
    if ((absTick & 4096) != 0)
      ratio = mulShift(ratio, "0xd097f3bdfd2022b8845ad8f792aa5825");
    if ((absTick & 8192) != 0)
      ratio = mulShift(ratio, "0xa9f746462d870fdf8a65dc1f90e061e5");
    if ((absTick & 16384) != 0)
      ratio = mulShift(ratio, "0x70d869a156d2a1b890bb3df62baf32f7");
    if ((absTick & 32768) != 0)
      ratio = mulShift(ratio, "0x31be135f97d08fd981231505542fcfa6");
    if ((absTick & 65536) != 0)
      ratio = mulShift(ratio, "0x9aa508b5b7a84e1c677de54f3e99bc9");
    if ((absTick & 131072) != 0)
      ratio = mulShift(ratio, "0x5d6af8dedb81196699c329225ee604");
    if ((absTick & 262144) != 0)
      ratio = mulShift(ratio, "0x2216e584f5fa1ea926041bedfe98");
    if ((absTick & 524288) != 0)
      ratio = mulShift(ratio, "0x48a170391f7dc42444e8fa2");
    if (tick > 0)
      ratio = import_jsbi7.default.divide(import_sdk3.MaxUint256, ratio);
    return import_jsbi7.default.greaterThan(import_jsbi7.default.remainder(ratio, Q32), ZERO) ? import_jsbi7.default.add(import_jsbi7.default.divide(ratio, Q32), ONE) : import_jsbi7.default.divide(ratio, Q32);
  }
  /**
   * Returns the tick corresponding to a given sqrt ratio, s.t. #getSqrtRatioAtTick(tick) <= sqrtRatioX96
   * and #getSqrtRatioAtTick(tick + 1) > sqrtRatioX96
   * @param sqrtRatioX96 the sqrt ratio as a Q64.96 for which to compute the tick
   */
  static getTickAtSqrtRatio(sqrtRatioX96) {
    (0, import_tiny_invariant3.default)(
      import_jsbi7.default.greaterThanOrEqual(sqrtRatioX96, _TickMath.MIN_SQRT_RATIO) && import_jsbi7.default.lessThan(sqrtRatioX96, _TickMath.MAX_SQRT_RATIO),
      "SQRT_RATIO"
    );
    const sqrtRatioX128 = import_jsbi7.default.leftShift(sqrtRatioX96, import_jsbi7.default.BigInt(32));
    const msb = mostSignificantBit(sqrtRatioX128);
    let r;
    if (import_jsbi7.default.greaterThanOrEqual(import_jsbi7.default.BigInt(msb), import_jsbi7.default.BigInt(128))) {
      r = import_jsbi7.default.signedRightShift(sqrtRatioX128, import_jsbi7.default.BigInt(msb - 127));
    } else {
      r = import_jsbi7.default.leftShift(sqrtRatioX128, import_jsbi7.default.BigInt(127 - msb));
    }
    let log_2 = import_jsbi7.default.leftShift(
      import_jsbi7.default.subtract(import_jsbi7.default.BigInt(msb), import_jsbi7.default.BigInt(128)),
      import_jsbi7.default.BigInt(64)
    );
    for (let i = 0; i < 14; i++) {
      r = import_jsbi7.default.signedRightShift(import_jsbi7.default.multiply(r, r), import_jsbi7.default.BigInt(127));
      const f = import_jsbi7.default.signedRightShift(r, import_jsbi7.default.BigInt(128));
      log_2 = import_jsbi7.default.bitwiseOr(log_2, import_jsbi7.default.leftShift(f, import_jsbi7.default.BigInt(63 - i)));
      r = import_jsbi7.default.signedRightShift(r, f);
    }
    const log_sqrt10001 = import_jsbi7.default.multiply(
      log_2,
      import_jsbi7.default.BigInt("255738958999603826347141")
    );
    const tickLow = import_jsbi7.default.toNumber(
      import_jsbi7.default.signedRightShift(
        import_jsbi7.default.subtract(
          log_sqrt10001,
          import_jsbi7.default.BigInt("3402992956809132418596140100660247210")
        ),
        import_jsbi7.default.BigInt(128)
      )
    );
    const tickHigh = import_jsbi7.default.toNumber(
      import_jsbi7.default.signedRightShift(
        import_jsbi7.default.add(
          log_sqrt10001,
          import_jsbi7.default.BigInt("291339464771989622907027621153398088495")
        ),
        import_jsbi7.default.BigInt(128)
      )
    );
    return tickLow === tickHigh ? tickLow : import_jsbi7.default.lessThanOrEqual(
      _TickMath.getSqrtRatioAtTick(tickHigh),
      sqrtRatioX96
    ) ? tickHigh : tickLow;
  }
};
/**
 * The minimum tick that can be used on any pool.
 */
_TickMath.MIN_TICK = -887272;
/**
 * The maximum tick that can be used on any pool.
 */
_TickMath.MAX_TICK = -_TickMath.MIN_TICK;
/**
 * The sqrt ratio corresponding to the minimum tick that could be used on any pool.
 */
_TickMath.MIN_SQRT_RATIO = import_jsbi7.default.BigInt("4295128739");
/**
 * The sqrt ratio corresponding to the maximum tick that could be used on any pool.
 */
_TickMath.MAX_SQRT_RATIO = import_jsbi7.default.BigInt(
  "1461446703485210103287273052203988822378723970342"
);
var TickMath = _TickMath;

// src/entities/tickDataProvider.ts
var _NoTickDataProvider = class _NoTickDataProvider {
  async getTick(_tick) {
    throw new Error(_NoTickDataProvider.ERROR_MESSAGE);
  }
  async nextInitializedTickWithinOneWord(_tick, _lte, _tickSpacing) {
    throw new Error(_NoTickDataProvider.ERROR_MESSAGE);
  }
};
_NoTickDataProvider.ERROR_MESSAGE = "No tick data provider was given";
var NoTickDataProvider = _NoTickDataProvider;

// src/utils/tickList.ts
var import_jsbi8 = __toESM(require("jsbi"));
var import_tiny_invariant4 = __toESM(require("tiny-invariant"));

// src/utils/isSorted.ts
function isSorted(list, comparator) {
  for (let i = 0; i < list.length - 1; i++) {
    if (comparator(list[i], list[i + 1]) > 0) {
      return false;
    }
  }
  return true;
}

// src/utils/tickList.ts
function tickComparator(a, b) {
  return a.index - b.index;
}
var TickList = class _TickList {
  /**
   * Cannot be constructed
   */
  constructor() {
  }
  static validateList(ticks, tickSpacing) {
    (0, import_tiny_invariant4.default)(tickSpacing > 0, "TICK_SPACING_NONZERO");
    (0, import_tiny_invariant4.default)(
      ticks.every(({ index }) => index % tickSpacing === 0),
      "TICK_SPACING"
    );
    (0, import_tiny_invariant4.default)(
      import_jsbi8.default.equal(
        ticks.reduce(
          (accumulator, { liquidityNet }) => import_jsbi8.default.add(accumulator, liquidityNet),
          ZERO
        ),
        ZERO
      ),
      "ZERO_NET"
    );
    (0, import_tiny_invariant4.default)(isSorted(ticks, tickComparator), "SORTED");
  }
  static isBelowSmallest(ticks, tick) {
    (0, import_tiny_invariant4.default)(ticks.length > 0, "LENGTH");
    return tick < ticks[0].index;
  }
  static isAtOrAboveLargest(ticks, tick) {
    (0, import_tiny_invariant4.default)(ticks.length > 0, "LENGTH");
    return tick >= ticks[ticks.length - 1].index;
  }
  static getTick(ticks, index) {
    const tick = ticks[this.binarySearch(ticks, index)];
    (0, import_tiny_invariant4.default)(tick.index === index, "NOT_CONTAINED");
    return tick;
  }
  /**
   * Finds the largest tick in the list of ticks that is less than or equal to tick
   * @param ticks list of ticks
   * @param tick tick to find the largest tick that is less than or equal to tick
   * @private
   */
  static binarySearch(ticks, tick) {
    (0, import_tiny_invariant4.default)(!this.isBelowSmallest(ticks, tick), "BELOW_SMALLEST");
    let l = 0;
    let r = ticks.length - 1;
    let i;
    while (true) {
      i = Math.floor((l + r) / 2);
      if (ticks[i].index <= tick && (i === ticks.length - 1 || ticks[i + 1].index > tick)) {
        return i;
      }
      if (ticks[i].index < tick) {
        l = i + 1;
      } else {
        r = i - 1;
      }
    }
  }
  static nextInitializedTick(ticks, tick, lte) {
    if (lte) {
      (0, import_tiny_invariant4.default)(!_TickList.isBelowSmallest(ticks, tick), "BELOW_SMALLEST");
      if (_TickList.isAtOrAboveLargest(ticks, tick)) {
        return ticks[ticks.length - 1];
      }
      const index = this.binarySearch(ticks, tick);
      return ticks[index];
    } else {
      (0, import_tiny_invariant4.default)(!this.isAtOrAboveLargest(ticks, tick), "AT_OR_ABOVE_LARGEST");
      if (this.isBelowSmallest(ticks, tick)) {
        return ticks[0];
      }
      const index = this.binarySearch(ticks, tick);
      return ticks[index + 1];
    }
  }
  static nextInitializedTickWithinOneWord(ticks, tick, lte, tickSpacing) {
    const compressed = Math.floor(tick / tickSpacing);
    if (lte) {
      const wordPos = compressed >> 8;
      const minimum = (wordPos << 8) * tickSpacing;
      if (_TickList.isBelowSmallest(ticks, tick)) {
        return [minimum, false];
      }
      const index = _TickList.nextInitializedTick(ticks, tick, lte).index;
      const nextInitializedTick = Math.max(minimum, index);
      return [nextInitializedTick, nextInitializedTick === index];
    } else {
      const wordPos = compressed + 1 >> 8;
      const maximum = ((wordPos + 1 << 8) - 1) * tickSpacing;
      if (this.isAtOrAboveLargest(ticks, tick)) {
        return [maximum, false];
      }
      const index = this.nextInitializedTick(ticks, tick, lte).index;
      const nextInitializedTick = Math.min(maximum, index);
      return [nextInitializedTick, nextInitializedTick === index];
    }
  }
};

// src/entities/tick.ts
var import_jsbi15 = __toESM(require("jsbi"));
var import_tiny_invariant6 = __toESM(require("tiny-invariant"));

// src/utils/calldata.ts
var import_jsbi9 = __toESM(require("jsbi"));
function toHex(bigintIsh) {
  const bigInt = import_jsbi9.default.BigInt(bigintIsh);
  let hex = bigInt.toString(16);
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }
  return `0x${hex}`;
}

// src/utils/encodeRouteToPath.ts
var import_solidity2 = require("@ethersproject/solidity");
function encodeRouteToPath(route, exactOutput) {
  const firstInputToken = route.input.wrapped;
  const { path, types } = route.pools.reduce(
    ({
      inputToken,
      path: path2,
      types: types2
    }, pool, index) => {
      const outputToken = pool.token0.equals(inputToken) ? pool.token1 : pool.token0;
      if (index === 0) {
        return {
          inputToken: outputToken,
          types: ["address", "uint24", "address"],
          path: [inputToken.address, pool.fee, outputToken.address]
        };
      } else {
        return {
          inputToken: outputToken,
          types: [...types2, "uint24", "address"],
          path: [...path2, pool.fee, outputToken.address]
        };
      }
    },
    { inputToken: firstInputToken, path: [], types: [] }
  );
  return exactOutput ? (0, import_solidity2.pack)(types.reverse(), path.reverse()) : (0, import_solidity2.pack)(types, path);
}

// src/utils/encodeSqrtRatioX96.ts
var import_jsbi10 = __toESM(require("jsbi"));
var import_sdk4 = require("@vapordex/sdk");
function encodeSqrtRatioX96(amount1, amount0) {
  const numerator = import_jsbi10.default.leftShift(import_jsbi10.default.BigInt(amount1), import_jsbi10.default.BigInt(192));
  const denominator = import_jsbi10.default.BigInt(amount0);
  const ratioX192 = import_jsbi10.default.divide(numerator, denominator);
  return (0, import_sdk4.sqrt)(ratioX192);
}

// src/utils/maxLiquidityForAmounts.ts
var import_jsbi11 = __toESM(require("jsbi"));
function maxLiquidityForAmount0Imprecise(sqrtRatioAX96, sqrtRatioBX96, amount0) {
  if (import_jsbi11.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
  }
  const intermediate = import_jsbi11.default.divide(
    import_jsbi11.default.multiply(sqrtRatioAX96, sqrtRatioBX96),
    Q96
  );
  return import_jsbi11.default.divide(
    import_jsbi11.default.multiply(import_jsbi11.default.BigInt(amount0), intermediate),
    import_jsbi11.default.subtract(sqrtRatioBX96, sqrtRatioAX96)
  );
}
function maxLiquidityForAmount0Precise(sqrtRatioAX96, sqrtRatioBX96, amount0) {
  if (import_jsbi11.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
  }
  const numerator = import_jsbi11.default.multiply(
    import_jsbi11.default.multiply(import_jsbi11.default.BigInt(amount0), sqrtRatioAX96),
    sqrtRatioBX96
  );
  const denominator = import_jsbi11.default.multiply(
    Q96,
    import_jsbi11.default.subtract(sqrtRatioBX96, sqrtRatioAX96)
  );
  return import_jsbi11.default.divide(numerator, denominator);
}
function maxLiquidityForAmount1(sqrtRatioAX96, sqrtRatioBX96, amount1) {
  if (import_jsbi11.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
  }
  return import_jsbi11.default.divide(
    import_jsbi11.default.multiply(import_jsbi11.default.BigInt(amount1), Q96),
    import_jsbi11.default.subtract(sqrtRatioBX96, sqrtRatioAX96)
  );
}
function maxLiquidityForAmounts(sqrtRatioCurrentX96, sqrtRatioAX96, sqrtRatioBX96, amount0, amount1, useFullPrecision) {
  if (import_jsbi11.default.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    [sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96];
  }
  const maxLiquidityForAmount0 = useFullPrecision ? maxLiquidityForAmount0Precise : maxLiquidityForAmount0Imprecise;
  if (import_jsbi11.default.lessThanOrEqual(sqrtRatioCurrentX96, sqrtRatioAX96)) {
    return maxLiquidityForAmount0(sqrtRatioAX96, sqrtRatioBX96, amount0);
  } else if (import_jsbi11.default.lessThan(sqrtRatioCurrentX96, sqrtRatioBX96)) {
    const liquidity0 = maxLiquidityForAmount0(
      sqrtRatioCurrentX96,
      sqrtRatioBX96,
      amount0
    );
    const liquidity1 = maxLiquidityForAmount1(
      sqrtRatioAX96,
      sqrtRatioCurrentX96,
      amount1
    );
    return import_jsbi11.default.lessThan(liquidity0, liquidity1) ? liquidity0 : liquidity1;
  } else {
    return maxLiquidityForAmount1(sqrtRatioAX96, sqrtRatioBX96, amount1);
  }
}

// src/utils/nearestUsableTick.ts
var import_tiny_invariant5 = __toESM(require("tiny-invariant"));
function nearestUsableTick(tick, tickSpacing) {
  (0, import_tiny_invariant5.default)(
    Number.isInteger(tick) && Number.isInteger(tickSpacing),
    "INTEGERS"
  );
  (0, import_tiny_invariant5.default)(tickSpacing > 0, "TICK_SPACING");
  (0, import_tiny_invariant5.default)(
    tick >= TickMath.MIN_TICK && tick <= TickMath.MAX_TICK,
    "TICK_BOUND"
  );
  const rounded = Math.round(tick / tickSpacing) * tickSpacing;
  if (rounded < TickMath.MIN_TICK)
    return rounded + tickSpacing;
  else if (rounded > TickMath.MAX_TICK)
    return rounded - tickSpacing;
  else
    return rounded;
}

// src/utils/position.ts
var import_jsbi13 = __toESM(require("jsbi"));

// src/utils/tickLibrary.ts
var import_jsbi12 = __toESM(require("jsbi"));
var Q256 = import_jsbi12.default.exponentiate(import_jsbi12.default.BigInt(2), import_jsbi12.default.BigInt(256));
function subIn256(x, y) {
  const difference = import_jsbi12.default.subtract(x, y);
  if (import_jsbi12.default.lessThan(difference, ZERO)) {
    return import_jsbi12.default.add(Q256, difference);
  } else {
    return difference;
  }
}
var TickLibrary = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static getFeeGrowthInside(feeGrowthOutsideLower, feeGrowthOutsideUpper, tickLower, tickUpper, tickCurrent, feeGrowthGlobal0X128, feeGrowthGlobal1X128) {
    let feeGrowthBelow0X128;
    let feeGrowthBelow1X128;
    if (tickCurrent >= tickLower) {
      feeGrowthBelow0X128 = feeGrowthOutsideLower.feeGrowthOutside0X128;
      feeGrowthBelow1X128 = feeGrowthOutsideLower.feeGrowthOutside1X128;
    } else {
      feeGrowthBelow0X128 = subIn256(
        feeGrowthGlobal0X128,
        feeGrowthOutsideLower.feeGrowthOutside0X128
      );
      feeGrowthBelow1X128 = subIn256(
        feeGrowthGlobal1X128,
        feeGrowthOutsideLower.feeGrowthOutside1X128
      );
    }
    let feeGrowthAbove0X128;
    let feeGrowthAbove1X128;
    if (tickCurrent < tickUpper) {
      feeGrowthAbove0X128 = feeGrowthOutsideUpper.feeGrowthOutside0X128;
      feeGrowthAbove1X128 = feeGrowthOutsideUpper.feeGrowthOutside1X128;
    } else {
      feeGrowthAbove0X128 = subIn256(
        feeGrowthGlobal0X128,
        feeGrowthOutsideUpper.feeGrowthOutside0X128
      );
      feeGrowthAbove1X128 = subIn256(
        feeGrowthGlobal1X128,
        feeGrowthOutsideUpper.feeGrowthOutside1X128
      );
    }
    return [
      subIn256(
        subIn256(feeGrowthGlobal0X128, feeGrowthBelow0X128),
        feeGrowthAbove0X128
      ),
      subIn256(
        subIn256(feeGrowthGlobal1X128, feeGrowthBelow1X128),
        feeGrowthAbove1X128
      )
    ];
  }
};

// src/utils/position.ts
var Q128 = import_jsbi13.default.exponentiate(import_jsbi13.default.BigInt(2), import_jsbi13.default.BigInt(128));
var PositionLibrary = class {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  // replicates the portions of Position#update required to compute unaccounted fees
  static getTokensOwed(feeGrowthInside0LastX128, feeGrowthInside1LastX128, liquidity, feeGrowthInside0X128, feeGrowthInside1X128) {
    const tokensOwed0 = import_jsbi13.default.divide(
      import_jsbi13.default.multiply(
        subIn256(feeGrowthInside0X128, feeGrowthInside0LastX128),
        liquidity
      ),
      Q128
    );
    const tokensOwed1 = import_jsbi13.default.divide(
      import_jsbi13.default.multiply(
        subIn256(feeGrowthInside1X128, feeGrowthInside1LastX128),
        liquidity
      ),
      Q128
    );
    return [tokensOwed0, tokensOwed1];
  }
};

// src/utils/priceTickConversions.ts
var import_sdk5 = require("@vapordex/sdk");
var import_jsbi14 = __toESM(require("jsbi"));
function tickToPrice(baseToken, quoteToken, tick) {
  const sqrtRatioX96 = TickMath.getSqrtRatioAtTick(tick);
  const ratioX192 = import_jsbi14.default.multiply(sqrtRatioX96, sqrtRatioX96);
  return baseToken.sortsBefore(quoteToken) ? new import_sdk5.Price(baseToken, quoteToken, Q192, ratioX192) : new import_sdk5.Price(baseToken, quoteToken, ratioX192, Q192);
}
function priceToClosestTick(price) {
  const sorted = price.baseCurrency.sortsBefore(price.quoteCurrency);
  const sqrtRatioX96 = sorted ? encodeSqrtRatioX96(price.numerator, price.denominator) : encodeSqrtRatioX96(price.denominator, price.numerator);
  let tick = TickMath.getTickAtSqrtRatio(sqrtRatioX96);
  const nextTickPrice = tickToPrice(
    price.baseCurrency,
    price.quoteCurrency,
    tick + 1
  );
  if (sorted) {
    if (!price.lessThan(nextTickPrice)) {
      tick++;
    }
  } else {
    if (!price.greaterThan(nextTickPrice)) {
      tick++;
    }
  }
  return tick;
}

// src/entities/tick.ts
var Tick = class {
  constructor({ index, liquidityGross, liquidityNet }) {
    (0, import_tiny_invariant6.default)(index >= TickMath.MIN_TICK && index <= TickMath.MAX_TICK, "TICK");
    this.index = index;
    this.liquidityGross = import_jsbi15.default.BigInt(liquidityGross);
    this.liquidityNet = import_jsbi15.default.BigInt(liquidityNet);
  }
};

// src/entities/tickListDataProvider.ts
var TickListDataProvider = class {
  constructor(ticks, tickSpacing) {
    const ticksMapped = ticks.map(
      (t) => t instanceof Tick ? t : new Tick(t)
    );
    TickList.validateList(ticksMapped, tickSpacing);
    this.ticks = ticksMapped;
  }
  async getTick(tick) {
    return TickList.getTick(this.ticks, tick);
  }
  async nextInitializedTickWithinOneWord(tick, lte, tickSpacing) {
    return TickList.nextInitializedTickWithinOneWord(
      this.ticks,
      tick,
      lte,
      tickSpacing
    );
  }
};

// src/entities/pool.ts
var NO_TICK_DATA_PROVIDER_DEFAULT = new NoTickDataProvider();
var Pool = class _Pool {
  static getAddress(tokenA, tokenB, fee, initCodeHashManualOverride, factoryAddressOverride) {
    return computePoolAddress({
      factoryAddress: factoryAddressOverride != null ? factoryAddressOverride : FACTORY_ADDRESS,
      fee,
      tokenA,
      tokenB,
      initCodeHashManualOverride
    });
  }
  /**
   * Construct a pool
   * @param tokenA One of the tokens in the pool
   * @param tokenB The other token in the pool
   * @param fee The fee in hundredths of a bips of the input amount of every swap that is collected by the pool
   * @param sqrtRatioX96 The sqrt of the current ratio of amounts of token1 to token0
   * @param liquidity The current value of in range liquidity
   * @param tickCurrent The current tick of the pool
   * @param ticks The current state of the pool ticks or a data provider that can return tick data
   */
  constructor(tokenA, tokenB, fee, sqrtRatioX96, liquidity, tickCurrent, ticks = NO_TICK_DATA_PROVIDER_DEFAULT) {
    (0, import_tiny_invariant7.default)(Number.isInteger(fee) && fee < 1e6, "FEE");
    const tickCurrentSqrtRatioX96 = TickMath.getSqrtRatioAtTick(tickCurrent);
    const nextTickSqrtRatioX96 = TickMath.getSqrtRatioAtTick(tickCurrent + 1);
    (0, import_tiny_invariant7.default)(
      import_jsbi16.default.greaterThanOrEqual(
        import_jsbi16.default.BigInt(sqrtRatioX96),
        tickCurrentSqrtRatioX96
      ) && import_jsbi16.default.lessThanOrEqual(import_jsbi16.default.BigInt(sqrtRatioX96), nextTickSqrtRatioX96),
      "PRICE_BOUNDS"
    );
    [this.token0, this.token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
    this.fee = fee;
    this.sqrtRatioX96 = import_jsbi16.default.BigInt(sqrtRatioX96);
    this.liquidity = import_jsbi16.default.BigInt(liquidity);
    this.tickCurrent = tickCurrent;
    this.tickDataProvider = Array.isArray(ticks) ? new TickListDataProvider(ticks, TICK_SPACINGS[fee]) : ticks;
  }
  /**
   * Returns true if the token is either token0 or token1
   * @param token The token to check
   * @returns True if token is either token0 or token
   */
  involvesToken(token) {
    return token.equals(this.token0) || token.equals(this.token1);
  }
  /**
   * Returns the current mid price of the pool in terms of token0, i.e. the ratio of token1 over token0
   */
  get token0Price() {
    var _a;
    return (_a = this._token0Price) != null ? _a : this._token0Price = new import_sdk6.Price(
      this.token0,
      this.token1,
      Q192,
      import_jsbi16.default.multiply(this.sqrtRatioX96, this.sqrtRatioX96)
    );
  }
  /**
   * Returns the current mid price of the pool in terms of token1, i.e. the ratio of token0 over token1
   */
  get token1Price() {
    var _a;
    return (_a = this._token1Price) != null ? _a : this._token1Price = new import_sdk6.Price(
      this.token1,
      this.token0,
      import_jsbi16.default.multiply(this.sqrtRatioX96, this.sqrtRatioX96),
      Q192
    );
  }
  /**
   * Return the price of the given token in terms of the other token in the pool.
   * @param token The token to return price of
   * @returns The price of the given token, in terms of the other.
   */
  priceOf(token) {
    (0, import_tiny_invariant7.default)(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.token0Price : this.token1Price;
  }
  /**
   * Returns the chain ID of the tokens in the pool.
   */
  get chainId() {
    return this.token0.chainId;
  }
  /**
   * Given an input amount of a token, return the computed output amount, and a pool with state updated after the trade
   * @param inputAmount The input amount for which to quote the output amount
   * @param sqrtPriceLimitX96 The Q64.96 sqrt price limit
   * @returns The output amount and the pool with updated state
   */
  async getOutputAmount(inputAmount, sqrtPriceLimitX96) {
    (0, import_tiny_invariant7.default)(this.involvesToken(inputAmount.currency), "TOKEN");
    const zeroForOne = inputAmount.currency.equals(this.token0);
    const {
      amountCalculated: outputAmount,
      liquidity,
      sqrtRatioX96,
      tickCurrent
    } = await this.swap(zeroForOne, inputAmount.quotient, sqrtPriceLimitX96);
    const outputToken = zeroForOne ? this.token1 : this.token0;
    return [
      import_sdk6.CurrencyAmount.fromRawAmount(
        outputToken,
        import_jsbi16.default.multiply(outputAmount, NEGATIVE_ONE)
      ),
      new _Pool(
        this.token0,
        this.token1,
        this.fee,
        sqrtRatioX96,
        liquidity,
        tickCurrent,
        this.tickDataProvider
      )
    ];
  }
  /**
   * Given a desired output amount of a token, return the computed input amount and a pool with state updated after the trade
   * @param outputAmount the output amount for which to quote the input amount
   * @param sqrtPriceLimitX96 The Q64.96 sqrt price limit. If zero for one, the price cannot be less than this value after the swap. If one for zero, the price cannot be greater than this value after the swap
   * @returns The input amount and the pool with updated state
   */
  async getInputAmount(outputAmount, sqrtPriceLimitX96) {
    (0, import_tiny_invariant7.default)(
      outputAmount.currency.isToken && this.involvesToken(outputAmount.currency),
      "TOKEN"
    );
    const zeroForOne = outputAmount.currency.equals(this.token1);
    const {
      amountCalculated: inputAmount,
      sqrtRatioX96,
      liquidity,
      tickCurrent
    } = await this.swap(
      zeroForOne,
      import_jsbi16.default.multiply(outputAmount.quotient, NEGATIVE_ONE),
      sqrtPriceLimitX96
    );
    const inputToken = zeroForOne ? this.token0 : this.token1;
    return [
      import_sdk6.CurrencyAmount.fromRawAmount(inputToken, inputAmount),
      new _Pool(
        this.token0,
        this.token1,
        this.fee,
        sqrtRatioX96,
        liquidity,
        tickCurrent,
        this.tickDataProvider
      )
    ];
  }
  /**
   * Executes a swap
   * @param zeroForOne Whether the amount in is token0 or token1
   * @param amountSpecified The amount of the swap, which implicitly configures the swap as exact input (positive), or exact output (negative)
   * @param sqrtPriceLimitX96 The Q64.96 sqrt price limit. If zero for one, the price cannot be less than this value after the swap. If one for zero, the price cannot be greater than this value after the swap
   * @returns amountCalculated
   * @returns sqrtRatioX96
   * @returns liquidity
   * @returns tickCurrent
   */
  async swap(zeroForOne, amountSpecified, sqrtPriceLimitX96) {
    if (!sqrtPriceLimitX96)
      sqrtPriceLimitX96 = zeroForOne ? import_jsbi16.default.add(TickMath.MIN_SQRT_RATIO, ONE) : import_jsbi16.default.subtract(TickMath.MAX_SQRT_RATIO, ONE);
    if (zeroForOne) {
      (0, import_tiny_invariant7.default)(
        import_jsbi16.default.greaterThan(sqrtPriceLimitX96, TickMath.MIN_SQRT_RATIO),
        "RATIO_MIN"
      );
      (0, import_tiny_invariant7.default)(
        import_jsbi16.default.lessThan(sqrtPriceLimitX96, this.sqrtRatioX96),
        "RATIO_CURRENT"
      );
    } else {
      (0, import_tiny_invariant7.default)(
        import_jsbi16.default.lessThan(sqrtPriceLimitX96, TickMath.MAX_SQRT_RATIO),
        "RATIO_MAX"
      );
      (0, import_tiny_invariant7.default)(
        import_jsbi16.default.greaterThan(sqrtPriceLimitX96, this.sqrtRatioX96),
        "RATIO_CURRENT"
      );
    }
    const exactInput = import_jsbi16.default.greaterThanOrEqual(amountSpecified, ZERO);
    const state = {
      amountSpecifiedRemaining: amountSpecified,
      amountCalculated: ZERO,
      sqrtPriceX96: this.sqrtRatioX96,
      tick: this.tickCurrent,
      liquidity: this.liquidity
    };
    while (import_jsbi16.default.notEqual(state.amountSpecifiedRemaining, ZERO) && state.sqrtPriceX96 != sqrtPriceLimitX96) {
      let step = {};
      step.sqrtPriceStartX96 = state.sqrtPriceX96;
      [step.tickNext, step.initialized] = await this.tickDataProvider.nextInitializedTickWithinOneWord(
        state.tick,
        zeroForOne,
        this.tickSpacing
      );
      if (step.tickNext < TickMath.MIN_TICK) {
        step.tickNext = TickMath.MIN_TICK;
      } else if (step.tickNext > TickMath.MAX_TICK) {
        step.tickNext = TickMath.MAX_TICK;
      }
      step.sqrtPriceNextX96 = TickMath.getSqrtRatioAtTick(step.tickNext);
      [state.sqrtPriceX96, step.amountIn, step.amountOut, step.feeAmount] = SwapMath.computeSwapStep(
        state.sqrtPriceX96,
        (zeroForOne ? import_jsbi16.default.lessThan(step.sqrtPriceNextX96, sqrtPriceLimitX96) : import_jsbi16.default.greaterThan(step.sqrtPriceNextX96, sqrtPriceLimitX96)) ? sqrtPriceLimitX96 : step.sqrtPriceNextX96,
        state.liquidity,
        state.amountSpecifiedRemaining,
        this.fee
      );
      if (exactInput) {
        state.amountSpecifiedRemaining = import_jsbi16.default.subtract(
          state.amountSpecifiedRemaining,
          import_jsbi16.default.add(step.amountIn, step.feeAmount)
        );
        state.amountCalculated = import_jsbi16.default.subtract(
          state.amountCalculated,
          step.amountOut
        );
      } else {
        state.amountSpecifiedRemaining = import_jsbi16.default.add(
          state.amountSpecifiedRemaining,
          step.amountOut
        );
        state.amountCalculated = import_jsbi16.default.add(
          state.amountCalculated,
          import_jsbi16.default.add(step.amountIn, step.feeAmount)
        );
      }
      if (import_jsbi16.default.equal(state.sqrtPriceX96, step.sqrtPriceNextX96)) {
        if (step.initialized) {
          let liquidityNet = import_jsbi16.default.BigInt(
            (await this.tickDataProvider.getTick(step.tickNext)).liquidityNet
          );
          if (zeroForOne)
            liquidityNet = import_jsbi16.default.multiply(liquidityNet, NEGATIVE_ONE);
          state.liquidity = LiquidityMath.addDelta(
            state.liquidity,
            liquidityNet
          );
        }
        state.tick = zeroForOne ? step.tickNext - 1 : step.tickNext;
      } else if (import_jsbi16.default.notEqual(state.sqrtPriceX96, step.sqrtPriceStartX96)) {
        state.tick = TickMath.getTickAtSqrtRatio(state.sqrtPriceX96);
      }
    }
    return {
      amountCalculated: state.amountCalculated,
      sqrtRatioX96: state.sqrtPriceX96,
      liquidity: state.liquidity,
      tickCurrent: state.tick
    };
  }
  get tickSpacing() {
    return TICK_SPACINGS[this.fee];
  }
};

// src/entities/position.ts
var import_sdk7 = require("@vapordex/sdk");
var import_jsbi17 = __toESM(require("jsbi"));
var import_tiny_invariant8 = __toESM(require("tiny-invariant"));
var Position = class _Position {
  /**
   * Constructs a position for a given pool with the given liquidity
   * @param pool For which pool the liquidity is assigned
   * @param liquidity The amount of liquidity that is in the position
   * @param tickLower The lower tick of the position
   * @param tickUpper The upper tick of the position
   */
  constructor({
    pool,
    liquidity,
    tickLower,
    tickUpper
  }) {
    // cached resuts for the getters
    this._token0Amount = null;
    this._token1Amount = null;
    this._mintAmounts = null;
    (0, import_tiny_invariant8.default)(tickLower < tickUpper, "TICK_ORDER");
    (0, import_tiny_invariant8.default)(
      tickLower >= TickMath.MIN_TICK && tickLower % pool.tickSpacing === 0,
      "TICK_LOWER"
    );
    (0, import_tiny_invariant8.default)(
      tickUpper <= TickMath.MAX_TICK && tickUpper % pool.tickSpacing === 0,
      "TICK_UPPER"
    );
    this.pool = pool;
    this.tickLower = tickLower;
    this.tickUpper = tickUpper;
    this.liquidity = import_jsbi17.default.BigInt(liquidity);
  }
  /**
   * Returns the price of token0 at the lower tick
   */
  get token0PriceLower() {
    return tickToPrice(this.pool.token0, this.pool.token1, this.tickLower);
  }
  /**
   * Returns the price of token0 at the upper tick
   */
  get token0PriceUpper() {
    return tickToPrice(this.pool.token0, this.pool.token1, this.tickUpper);
  }
  /**
   * Returns the amount of token0 that this position's liquidity could be burned for at the current pool price
   */
  get amount0() {
    if (this._token0Amount === null) {
      if (this.pool.tickCurrent < this.tickLower) {
        this._token0Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token0,
          SqrtPriceMath.getAmount0Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            false
          )
        );
      } else if (this.pool.tickCurrent < this.tickUpper) {
        this._token0Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token0,
          SqrtPriceMath.getAmount0Delta(
            this.pool.sqrtRatioX96,
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            false
          )
        );
      } else {
        this._token0Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token0,
          ZERO
        );
      }
    }
    return this._token0Amount;
  }
  /**
   * Returns the amount of token1 that this position's liquidity could be burned for at the current pool price
   */
  get amount1() {
    if (this._token1Amount === null) {
      if (this.pool.tickCurrent < this.tickLower) {
        this._token1Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token1,
          ZERO
        );
      } else if (this.pool.tickCurrent < this.tickUpper) {
        this._token1Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token1,
          SqrtPriceMath.getAmount1Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            this.pool.sqrtRatioX96,
            this.liquidity,
            false
          )
        );
      } else {
        this._token1Amount = import_sdk7.CurrencyAmount.fromRawAmount(
          this.pool.token1,
          SqrtPriceMath.getAmount1Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            false
          )
        );
      }
    }
    return this._token1Amount;
  }
  /**
   * Returns the lower and upper sqrt ratios if the price 'slips' up to slippage tolerance percentage
   * @param slippageTolerance The amount by which the price can 'slip' before the transaction will revert
   * @returns The sqrt ratios after slippage
   */
  ratiosAfterSlippage(slippageTolerance) {
    const priceLower = this.pool.token0Price.asFraction.multiply(
      new import_sdk7.Percent(1).subtract(slippageTolerance)
    );
    const priceUpper = this.pool.token0Price.asFraction.multiply(
      slippageTolerance.add(1)
    );
    let sqrtRatioX96Lower = encodeSqrtRatioX96(
      priceLower.numerator,
      priceLower.denominator
    );
    if (import_jsbi17.default.lessThanOrEqual(sqrtRatioX96Lower, TickMath.MIN_SQRT_RATIO)) {
      sqrtRatioX96Lower = import_jsbi17.default.add(TickMath.MIN_SQRT_RATIO, import_jsbi17.default.BigInt(1));
    }
    let sqrtRatioX96Upper = encodeSqrtRatioX96(
      priceUpper.numerator,
      priceUpper.denominator
    );
    if (import_jsbi17.default.greaterThanOrEqual(sqrtRatioX96Upper, TickMath.MAX_SQRT_RATIO)) {
      sqrtRatioX96Upper = import_jsbi17.default.subtract(
        TickMath.MAX_SQRT_RATIO,
        import_jsbi17.default.BigInt(1)
      );
    }
    return {
      sqrtRatioX96Lower,
      sqrtRatioX96Upper
    };
  }
  /**
   * Returns the minimum amounts that must be sent in order to safely mint the amount of liquidity held by the position
   * with the given slippage tolerance
   * @param slippageTolerance Tolerance of unfavorable slippage from the current price
   * @returns The amounts, with slippage
   */
  mintAmountsWithSlippage(slippageTolerance) {
    const { sqrtRatioX96Upper, sqrtRatioX96Lower } = this.ratiosAfterSlippage(slippageTolerance);
    const poolLower = new Pool(
      this.pool.token0,
      this.pool.token1,
      this.pool.fee,
      sqrtRatioX96Lower,
      0,
      TickMath.getTickAtSqrtRatio(sqrtRatioX96Lower)
    );
    const poolUpper = new Pool(
      this.pool.token0,
      this.pool.token1,
      this.pool.fee,
      sqrtRatioX96Upper,
      0,
      TickMath.getTickAtSqrtRatio(sqrtRatioX96Upper)
    );
    const positionThatWillBeCreated = _Position.fromAmounts({
      pool: this.pool,
      tickLower: this.tickLower,
      tickUpper: this.tickUpper,
      ...this.mintAmounts,
      // the mint amounts are what will be passed as calldata
      useFullPrecision: false
    });
    const { amount0 } = new _Position({
      pool: poolUpper,
      liquidity: positionThatWillBeCreated.liquidity,
      tickLower: this.tickLower,
      tickUpper: this.tickUpper
    }).mintAmounts;
    const { amount1 } = new _Position({
      pool: poolLower,
      liquidity: positionThatWillBeCreated.liquidity,
      tickLower: this.tickLower,
      tickUpper: this.tickUpper
    }).mintAmounts;
    return { amount0, amount1 };
  }
  /**
   * Returns the minimum amounts that should be requested in order to safely burn the amount of liquidity held by the
   * position with the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the current price
   * @returns The amounts, with slippage
   */
  burnAmountsWithSlippage(slippageTolerance) {
    const { sqrtRatioX96Upper, sqrtRatioX96Lower } = this.ratiosAfterSlippage(slippageTolerance);
    const poolLower = new Pool(
      this.pool.token0,
      this.pool.token1,
      this.pool.fee,
      sqrtRatioX96Lower,
      0,
      TickMath.getTickAtSqrtRatio(sqrtRatioX96Lower)
    );
    const poolUpper = new Pool(
      this.pool.token0,
      this.pool.token1,
      this.pool.fee,
      sqrtRatioX96Upper,
      0,
      TickMath.getTickAtSqrtRatio(sqrtRatioX96Upper)
    );
    const amount0 = new _Position({
      pool: poolUpper,
      liquidity: this.liquidity,
      tickLower: this.tickLower,
      tickUpper: this.tickUpper
    }).amount0;
    const amount1 = new _Position({
      pool: poolLower,
      liquidity: this.liquidity,
      tickLower: this.tickLower,
      tickUpper: this.tickUpper
    }).amount1;
    return { amount0: amount0.quotient, amount1: amount1.quotient };
  }
  /**
   * Returns the minimum amounts that must be sent in order to mint the amount of liquidity held by the position at
   * the current price for the pool
   */
  get mintAmounts() {
    if (this._mintAmounts === null) {
      if (this.pool.tickCurrent < this.tickLower) {
        return {
          amount0: SqrtPriceMath.getAmount0Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            true
          ),
          amount1: ZERO
        };
      } else if (this.pool.tickCurrent < this.tickUpper) {
        return {
          amount0: SqrtPriceMath.getAmount0Delta(
            this.pool.sqrtRatioX96,
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            true
          ),
          amount1: SqrtPriceMath.getAmount1Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            this.pool.sqrtRatioX96,
            this.liquidity,
            true
          )
        };
      } else {
        return {
          amount0: ZERO,
          amount1: SqrtPriceMath.getAmount1Delta(
            TickMath.getSqrtRatioAtTick(this.tickLower),
            TickMath.getSqrtRatioAtTick(this.tickUpper),
            this.liquidity,
            true
          )
        };
      }
    }
    return this._mintAmounts;
  }
  /**
   * Computes the maximum amount of liquidity received for a given amount of token0, token1,
   * and the prices at the tick boundaries.
   * @param pool The pool for which the position should be created
   * @param tickLower The lower tick of the position
   * @param tickUpper The upper tick of the position
   * @param amount0 token0 amount
   * @param amount1 token1 amount
   * @param useFullPrecision If false, liquidity will be maximized according to what the router can calculate,
   * not what core can theoretically support
   * @returns The amount of liquidity for the position
   */
  static fromAmounts({
    pool,
    tickLower,
    tickUpper,
    amount0,
    amount1,
    useFullPrecision
  }) {
    const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(tickLower);
    const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(tickUpper);
    return new _Position({
      pool,
      tickLower,
      tickUpper,
      liquidity: maxLiquidityForAmounts(
        pool.sqrtRatioX96,
        sqrtRatioAX96,
        sqrtRatioBX96,
        amount0,
        amount1,
        useFullPrecision
      )
    });
  }
  /**
   * Computes a position with the maximum amount of liquidity received for a given amount of token0, assuming an unlimited amount of token1
   * @param pool The pool for which the position is created
   * @param tickLower The lower tick
   * @param tickUpper The upper tick
   * @param amount0 The desired amount of token0
   * @param useFullPrecision If true, liquidity will be maximized according to what the router can calculate,
   * not what core can theoretically support
   * @returns The position
   */
  static fromAmount0({
    pool,
    tickLower,
    tickUpper,
    amount0,
    useFullPrecision
  }) {
    return _Position.fromAmounts({
      pool,
      tickLower,
      tickUpper,
      amount0,
      amount1: import_sdk7.MaxUint256,
      useFullPrecision
    });
  }
  /**
   * Computes a position with the maximum amount of liquidity received for a given amount of token1, assuming an unlimited amount of token0
   * @param pool The pool for which the position is created
   * @param tickLower The lower tick
   * @param tickUpper The upper tick
   * @param amount1 The desired amount of token1
   * @returns The position
   */
  static fromAmount1({
    pool,
    tickLower,
    tickUpper,
    amount1
  }) {
    return _Position.fromAmounts({
      pool,
      tickLower,
      tickUpper,
      amount0: import_sdk7.MaxUint256,
      amount1,
      useFullPrecision: true
    });
  }
};

// src/entities/route.ts
var import_tiny_invariant9 = __toESM(require("tiny-invariant"));
var import_sdk8 = require("@vapordex/sdk");
var Route = class {
  /**
   * Creates an instance of route.
   * @param pools An array of `Pool` objects, ordered by the route the swap will take
   * @param input The input token
   * @param output The output token
   */
  constructor(pools, input, output) {
    this._midPrice = null;
    (0, import_tiny_invariant9.default)(pools.length > 0, "POOLS");
    const chainId = pools[0].chainId;
    const allOnSameChain = pools.every((pool) => pool.chainId === chainId);
    (0, import_tiny_invariant9.default)(allOnSameChain, "CHAIN_IDS");
    const wrappedInput = input.wrapped;
    (0, import_tiny_invariant9.default)(pools[0].involvesToken(wrappedInput), "INPUT");
    (0, import_tiny_invariant9.default)(pools[pools.length - 1].involvesToken(output.wrapped), "OUTPUT");
    const tokenPath = [wrappedInput];
    for (const [i, pool] of pools.entries()) {
      const currentInputToken = tokenPath[i];
      (0, import_tiny_invariant9.default)(
        currentInputToken.equals(pool.token0) || currentInputToken.equals(pool.token1),
        "PATH"
      );
      const nextToken = currentInputToken.equals(pool.token0) ? pool.token1 : pool.token0;
      tokenPath.push(nextToken);
    }
    this.pools = pools;
    this.tokenPath = tokenPath;
    this.input = input;
    this.output = output != null ? output : tokenPath[tokenPath.length - 1];
  }
  get chainId() {
    return this.pools[0].chainId;
  }
  /**
   * Returns the mid price of the route
   */
  get midPrice() {
    if (this._midPrice !== null)
      return this._midPrice;
    const price = this.pools.slice(1).reduce(
      ({ nextInput, price: price2 }, pool) => {
        return nextInput.equals(pool.token0) ? {
          nextInput: pool.token1,
          price: price2.multiply(pool.token0Price)
        } : {
          nextInput: pool.token0,
          price: price2.multiply(pool.token1Price)
        };
      },
      this.pools[0].token0.equals(this.input.wrapped) ? {
        nextInput: this.pools[0].token1,
        price: this.pools[0].token0Price
      } : {
        nextInput: this.pools[0].token0,
        price: this.pools[0].token1Price
      }
    ).price;
    return this._midPrice = new import_sdk8.Price(
      this.input,
      this.output,
      price.denominator,
      price.numerator
    );
  }
};

// src/entities/trade.ts
var import_sdk9 = require("@vapordex/sdk");
var import_tiny_invariant10 = __toESM(require("tiny-invariant"));
function tradeComparator(a, b) {
  (0, import_tiny_invariant10.default)(
    a.inputAmount.currency.equals(b.inputAmount.currency),
    "INPUT_CURRENCY"
  );
  (0, import_tiny_invariant10.default)(
    a.outputAmount.currency.equals(b.outputAmount.currency),
    "OUTPUT_CURRENCY"
  );
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      const aHops = a.swaps.reduce(
        (total, cur) => total + cur.route.tokenPath.length,
        0
      );
      const bHops = b.swaps.reduce(
        (total, cur) => total + cur.route.tokenPath.length,
        0
      );
      return aHops - bHops;
    }
    return a.inputAmount.lessThan(b.inputAmount) ? -1 : 1;
  } else {
    return a.outputAmount.lessThan(b.outputAmount) ? 1 : -1;
  }
}
var Trade = class _Trade {
  /**
   * @deprecated Deprecated in favor of 'swaps' property. If the trade consists of multiple routes
   * this will return an error.
   *
   * When the trade consists of just a single route, this returns the route of the trade,
   * i.e. which pools the trade goes through.
   */
  get route() {
    (0, import_tiny_invariant10.default)(this.swaps.length == 1, "MULTIPLE_ROUTES");
    return this.swaps[0].route;
  }
  /**
   * The input amount for the trade assuming no slippage.
   */
  get inputAmount() {
    if (this._inputAmount) {
      return this._inputAmount;
    }
    const inputCurrency = this.swaps[0].inputAmount.currency;
    const totalInputFromRoutes = this.swaps.map(({ inputAmount }) => inputAmount).reduce(
      (total, cur) => total.add(cur),
      import_sdk9.CurrencyAmount.fromRawAmount(inputCurrency, 0)
    );
    this._inputAmount = totalInputFromRoutes;
    return this._inputAmount;
  }
  /**
   * The output amount for the trade assuming no slippage.
   */
  get outputAmount() {
    if (this._outputAmount) {
      return this._outputAmount;
    }
    const outputCurrency = this.swaps[0].outputAmount.currency;
    const totalOutputFromRoutes = this.swaps.map(({ outputAmount }) => outputAmount).reduce(
      (total, cur) => total.add(cur),
      import_sdk9.CurrencyAmount.fromRawAmount(outputCurrency, 0)
    );
    this._outputAmount = totalOutputFromRoutes;
    return this._outputAmount;
  }
  /**
   * The price expressed in terms of output amount/input amount.
   */
  get executionPrice() {
    var _a;
    return (_a = this._executionPrice) != null ? _a : this._executionPrice = new import_sdk9.Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.quotient,
      this.outputAmount.quotient
    );
  }
  /**
   * Returns the percent difference between the route's mid price and the price impact
   */
  get priceImpact() {
    if (this._priceImpact) {
      return this._priceImpact;
    }
    let spotOutputAmount = import_sdk9.CurrencyAmount.fromRawAmount(
      this.outputAmount.currency,
      0
    );
    for (const { inputAmount, route } of this.swaps) {
      const midPrice = route.midPrice;
      spotOutputAmount = spotOutputAmount.add(midPrice.quote(inputAmount));
    }
    const priceImpact = spotOutputAmount.subtract(this.outputAmount).divide(spotOutputAmount);
    this._priceImpact = new import_sdk9.Percent(
      priceImpact.numerator,
      priceImpact.denominator
    );
    return this._priceImpact;
  }
  /**
   * Constructs an exact in trade with the given amount in and route
   * @template TInput The input token, either Ether or an ERC-20
   * @template TOutput The output token, either Ether or an ERC-20
   * @param route The route of the exact in trade
   * @param amountIn The amount being passed in
   * @returns The exact in trade
   */
  static async exactIn(route, amountIn) {
    return _Trade.fromRoute(route, amountIn, import_sdk9.TradeType.EXACT_INPUT);
  }
  /**
   * Constructs an exact out trade with the given amount out and route
   * @template TInput The input token, either Ether or an ERC-20
   * @template TOutput The output token, either Ether or an ERC-20
   * @param route The route of the exact out trade
   * @param amountOut The amount returned by the trade
   * @returns The exact out trade
   */
  static async exactOut(route, amountOut) {
    return _Trade.fromRoute(route, amountOut, import_sdk9.TradeType.EXACT_OUTPUT);
  }
  /**
   * Constructs a trade by simulating swaps through the given route
   * @template TInput The input token, either Ether or an ERC-20.
   * @template TOutput The output token, either Ether or an ERC-20.
   * @template TTradeType The type of the trade, either exact in or exact out.
   * @param route route to swap through
   * @param amount the amount specified, either input or output, depending on tradeType
   * @param tradeType whether the trade is an exact input or exact output swap
   * @returns The route
   */
  static async fromRoute(route, amount, tradeType) {
    const amounts = Array.from({
      length: route.tokenPath.length
    });
    let inputAmount;
    let outputAmount;
    if (tradeType === import_sdk9.TradeType.EXACT_INPUT) {
      (0, import_tiny_invariant10.default)(amount.currency.equals(route.input), "INPUT");
      amounts[0] = amount.wrapped;
      for (let i = 0; i < route.tokenPath.length - 1; i++) {
        const pool = route.pools[i];
        const [outputAmount2] = await pool.getOutputAmount(amounts[i]);
        amounts[i + 1] = outputAmount2;
      }
      inputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
        route.input,
        amount.numerator,
        amount.denominator
      );
      outputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
        route.output,
        amounts[amounts.length - 1].numerator,
        amounts[amounts.length - 1].denominator
      );
    } else {
      (0, import_tiny_invariant10.default)(amount.currency.equals(route.output), "OUTPUT");
      amounts[amounts.length - 1] = amount.wrapped;
      for (let i = route.tokenPath.length - 1; i > 0; i--) {
        const pool = route.pools[i - 1];
        const [inputAmount2] = await pool.getInputAmount(amounts[i]);
        amounts[i - 1] = inputAmount2;
      }
      inputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
        route.input,
        amounts[0].numerator,
        amounts[0].denominator
      );
      outputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
        route.output,
        amount.numerator,
        amount.denominator
      );
    }
    return new _Trade({
      routes: [{ inputAmount, outputAmount, route }],
      tradeType
    });
  }
  /**
   * Constructs a trade from routes by simulating swaps
   *
   * @template TInput The input token, either Ether or an ERC-20.
   * @template TOutput The output token, either Ether or an ERC-20.
   * @template TTradeType The type of the trade, either exact in or exact out.
   * @param routes the routes to swap through and how much of the amount should be routed through each
   * @param tradeType whether the trade is an exact input or exact output swap
   * @returns The trade
   */
  static async fromRoutes(routes, tradeType) {
    const populatedRoutes = [];
    for (const { amount, route } of routes) {
      const amounts = Array.from({
        length: route.tokenPath.length
      });
      let inputAmount;
      let outputAmount;
      if (tradeType === import_sdk9.TradeType.EXACT_INPUT) {
        (0, import_tiny_invariant10.default)(amount.currency.equals(route.input), "INPUT");
        inputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.input,
          amount.numerator,
          amount.denominator
        );
        amounts[0] = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.input.wrapped,
          amount.numerator,
          amount.denominator
        );
        for (let i = 0; i < route.tokenPath.length - 1; i++) {
          const pool = route.pools[i];
          const [outputAmount2] = await pool.getOutputAmount(amounts[i]);
          amounts[i + 1] = outputAmount2;
        }
        outputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.output,
          amounts[amounts.length - 1].numerator,
          amounts[amounts.length - 1].denominator
        );
      } else {
        (0, import_tiny_invariant10.default)(amount.currency.equals(route.output), "OUTPUT");
        outputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.output,
          amount.numerator,
          amount.denominator
        );
        amounts[amounts.length - 1] = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.output.wrapped,
          amount.numerator,
          amount.denominator
        );
        for (let i = route.tokenPath.length - 1; i > 0; i--) {
          const pool = route.pools[i - 1];
          const [inputAmount2] = await pool.getInputAmount(amounts[i]);
          amounts[i - 1] = inputAmount2;
        }
        inputAmount = import_sdk9.CurrencyAmount.fromFractionalAmount(
          route.input,
          amounts[0].numerator,
          amounts[0].denominator
        );
      }
      populatedRoutes.push({ inputAmount, outputAmount, route });
    }
    return new _Trade({
      routes: populatedRoutes,
      tradeType
    });
  }
  /**
   * Creates a trade without computing the result of swapping through the route. Useful when you have simulated the trade
   * elsewhere and do not have any tick data
   * @template TInput The input token, either Ether or an ERC-20
   * @template TOutput The output token, either Ether or an ERC-20
   * @template TTradeType The type of the trade, either exact in or exact out
   * @param constructorArguments The arguments passed to the trade constructor
   * @returns The unchecked trade
   */
  static createUncheckedTrade(constructorArguments) {
    return new _Trade({
      ...constructorArguments,
      routes: [
        {
          inputAmount: constructorArguments.inputAmount,
          outputAmount: constructorArguments.outputAmount,
          route: constructorArguments.route
        }
      ]
    });
  }
  /**
   * Creates a trade without computing the result of swapping through the routes. Useful when you have simulated the trade
   * elsewhere and do not have any tick data
   * @template TInput The input token, either Ether or an ERC-20
   * @template TOutput The output token, either Ether or an ERC-20
   * @template TTradeType The type of the trade, either exact in or exact out
   * @param constructorArguments The arguments passed to the trade constructor
   * @returns The unchecked trade
   */
  static createUncheckedTradeWithMultipleRoutes(constructorArguments) {
    return new _Trade(constructorArguments);
  }
  /**
   * Construct a trade by passing in the pre-computed property values
   * @param routes The routes through which the trade occurs
   * @param tradeType The type of trade, exact input or exact output
   */
  constructor({
    routes,
    tradeType
  }) {
    const inputCurrency = routes[0].inputAmount.currency;
    const outputCurrency = routes[0].outputAmount.currency;
    (0, import_tiny_invariant10.default)(
      routes.every(
        ({ route }) => inputCurrency.wrapped.equals(route.input.wrapped)
      ),
      "INPUT_CURRENCY_MATCH"
    );
    (0, import_tiny_invariant10.default)(
      routes.every(
        ({ route }) => outputCurrency.wrapped.equals(route.output.wrapped)
      ),
      "OUTPUT_CURRENCY_MATCH"
    );
    const numPools = routes.map(({ route }) => route.pools.length).reduce((total, cur) => total + cur, 0);
    const poolAddressSet = /* @__PURE__ */ new Set();
    for (const { route } of routes) {
      for (const pool of route.pools) {
        poolAddressSet.add(Pool.getAddress(pool.token0, pool.token1, pool.fee));
      }
    }
    (0, import_tiny_invariant10.default)(numPools == poolAddressSet.size, "POOLS_DUPLICATED");
    this.swaps = routes;
    this.tradeType = tradeType;
  }
  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance The tolerance of unfavorable slippage from the execution price of this trade
   * @returns The amount out
   */
  minimumAmountOut(slippageTolerance, amountOut = this.outputAmount) {
    (0, import_tiny_invariant10.default)(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === import_sdk9.TradeType.EXACT_OUTPUT) {
      return amountOut;
    } else {
      const slippageAdjustedAmountOut = new import_sdk9.Fraction(ONE).add(slippageTolerance).invert().multiply(amountOut.quotient).quotient;
      return import_sdk9.CurrencyAmount.fromRawAmount(
        amountOut.currency,
        slippageAdjustedAmountOut
      );
    }
  }
  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance The tolerance of unfavorable slippage from the execution price of this trade
   * @returns The amount in
   */
  maximumAmountIn(slippageTolerance, amountIn = this.inputAmount) {
    (0, import_tiny_invariant10.default)(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === import_sdk9.TradeType.EXACT_INPUT) {
      return amountIn;
    } else {
      const slippageAdjustedAmountIn = new import_sdk9.Fraction(ONE).add(slippageTolerance).multiply(amountIn.quotient).quotient;
      return import_sdk9.CurrencyAmount.fromRawAmount(
        amountIn.currency,
        slippageAdjustedAmountIn
      );
    }
  }
  /**
   * Return the execution price after accounting for slippage tolerance
   * @param slippageTolerance the allowed tolerated slippage
   * @returns The execution price
   */
  worstExecutionPrice(slippageTolerance) {
    return new import_sdk9.Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient
    );
  }
  /**
   * Given a list of pools, and a fixed amount in, returns the top `maxNumResults` trades that go from an input token
   * amount to an output token, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pools the pools to consider in finding the best trade
   * @param nextAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pool
   * @param currentPools used in recursion; the current list of pools
   * @param currencyAmountIn used in recursion; the original value of the currencyAmountIn parameter
   * @param bestTrades used in recursion; the current list of best trades
   * @returns The exact in trade
   */
  static async bestTradeExactIn(pools, currencyAmountIn, currencyOut, { maxHops = 3, maxNumResults = 3 } = {}, currentPools = [], nextAmountIn = currencyAmountIn, bestTrades = []) {
    (0, import_tiny_invariant10.default)(pools.length > 0, "POOLS");
    (0, import_tiny_invariant10.default)(maxHops > 0, "MAX_HOPS");
    (0, import_tiny_invariant10.default)(
      currencyAmountIn === nextAmountIn || currentPools.length > 0,
      "INVALID_RECURSION"
    );
    const amountIn = nextAmountIn.wrapped;
    const tokenOut = currencyOut.wrapped;
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];
      if (!pool.token0.equals(amountIn.currency) && !pool.token1.equals(amountIn.currency))
        continue;
      let amountOut;
      try {
        [amountOut] = await pool.getOutputAmount(amountIn);
      } catch (error) {
        if (error instanceof import_sdk9.InsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountOut.currency.isToken && amountOut.currency.equals(tokenOut)) {
        (0, import_sdk9.sortedInsert)(
          bestTrades,
          await _Trade.fromRoute(
            new Route(
              [...currentPools, pool],
              currencyAmountIn.currency,
              currencyOut
            ),
            currencyAmountIn,
            import_sdk9.TradeType.EXACT_INPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pools.length > 1) {
        const poolsExcludingThisPool = [
          ...pools.slice(0, i),
          ...pools.slice(i + 1, pools.length)
        ];
        await _Trade.bestTradeExactIn(
          poolsExcludingThisPool,
          currencyAmountIn,
          currencyOut,
          {
            maxHops: maxHops - 1,
            maxNumResults
          },
          [...currentPools, pool],
          amountOut,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
  /**
   * similar to the above method but instead targets a fixed output amount
   * given a list of pools, and a fixed amount out, returns the top `maxNumResults` trades that go from an input token
   * to an output token amount, making at most `maxHops` hops
   * note this does not consider aggregation, as routes are linear. it's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pools the pools to consider in finding the best trade
   * @param currencyIn the currency to spend
   * @param currencyAmountOut the desired currency amount out
   * @param nextAmountOut the exact amount of currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pool
   * @param currentPools used in recursion; the current list of pools
   * @param bestTrades used in recursion; the current list of best trades
   * @returns The exact out trade
   */
  static async bestTradeExactOut(pools, currencyIn, currencyAmountOut, { maxHops = 3, maxNumResults = 3 } = {}, currentPools = [], nextAmountOut = currencyAmountOut, bestTrades = []) {
    (0, import_tiny_invariant10.default)(pools.length > 0, "POOLS");
    (0, import_tiny_invariant10.default)(maxHops > 0, "MAX_HOPS");
    (0, import_tiny_invariant10.default)(
      currencyAmountOut === nextAmountOut || currentPools.length > 0,
      "INVALID_RECURSION"
    );
    const amountOut = nextAmountOut.wrapped;
    const tokenIn = currencyIn.wrapped;
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];
      if (!pool.token0.equals(amountOut.currency) && !pool.token1.equals(amountOut.currency))
        continue;
      let amountIn;
      try {
        [amountIn] = await pool.getInputAmount(amountOut);
      } catch (error) {
        if (error instanceof import_sdk9.InsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountIn.currency.equals(tokenIn)) {
        (0, import_sdk9.sortedInsert)(
          bestTrades,
          await _Trade.fromRoute(
            new Route(
              [pool, ...currentPools],
              currencyIn,
              currencyAmountOut.currency
            ),
            currencyAmountOut,
            import_sdk9.TradeType.EXACT_OUTPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pools.length > 1) {
        const poolsExcludingThisPool = pools.slice(0, i).concat(pools.slice(i + 1, pools.length));
        await _Trade.bestTradeExactOut(
          poolsExcludingThisPool,
          currencyIn,
          currencyAmountOut,
          {
            maxHops: maxHops - 1,
            maxNumResults
          },
          [pool, ...currentPools],
          amountIn,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
};

// src/multicall.ts
var import_abi2 = require("@ethersproject/abi");
var import_IMulticall = __toESM(require("@uniswap/v3-periphery/artifacts/contracts/interfaces/IMulticall.sol/IMulticall.json"));
var _Multicall = class _Multicall {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static encodeMulticall(calldatas) {
    if (!Array.isArray(calldatas)) {
      calldatas = [calldatas];
    }
    return calldatas.length === 1 ? calldatas[0] : _Multicall.INTERFACE.encodeFunctionData("multicall", [calldatas]);
  }
};
_Multicall.INTERFACE = new import_abi2.Interface(import_IMulticall.default.abi);
var Multicall = _Multicall;

// src/nonfungiblePositionManager.ts
var import_sdk11 = require("@vapordex/sdk");
var import_jsbi18 = __toESM(require("jsbi"));
var import_tiny_invariant11 = __toESM(require("tiny-invariant"));
var import_abi5 = require("@ethersproject/abi");

// src/abis/NonfungiblePositionManager.json
var NonfungiblePositionManager_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_factory",
        type: "address"
      },
      {
        internalType: "address",
        name: "_WETH9",
        type: "address"
      },
      {
        internalType: "address",
        name: "_tokenDescriptor_",
        type: "address"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "ApprovalForAll",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "Collect",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "DecreaseLiquidity",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "IncreaseLiquidity",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount0Owed",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1Owed",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "VaporDEXV2MintCallback",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "WETH9",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "burn",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint128",
            name: "amount0Max",
            type: "uint128"
          },
          {
            internalType: "uint128",
            name: "amount1Max",
            type: "uint128"
          }
        ],
        internalType: "struct INonfungiblePositionManager.CollectParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "collect",
    outputs: [
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token0",
        type: "address"
      },
      {
        internalType: "address",
        name: "token1",
        type: "address"
      },
      {
        internalType: "uint24",
        name: "fee",
        type: "uint24"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96",
        type: "uint160"
      }
    ],
    name: "createAndInitializePoolIfNecessary",
    outputs: [
      {
        internalType: "address",
        name: "pool",
        type: "address"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256"
          },
          {
            internalType: "uint128",
            name: "liquidity",
            type: "uint128"
          },
          {
            internalType: "uint256",
            name: "amount0Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          }
        ],
        internalType: "struct INonfungiblePositionManager.DecreaseLiquidityParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "decreaseLiquidity",
    outputs: [
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount0Desired",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Desired",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount0Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          }
        ],
        internalType: "struct INonfungiblePositionManager.IncreaseLiquidityParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "increaseLiquidity",
    outputs: [
      {
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      },
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "operator",
        type: "address"
      }
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token0",
            type: "address"
          },
          {
            internalType: "address",
            name: "token1",
            type: "address"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "int24",
            name: "tickLower",
            type: "int24"
          },
          {
            internalType: "int24",
            name: "tickUpper",
            type: "int24"
          },
          {
            internalType: "uint256",
            name: "amount0Desired",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Desired",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount0Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Min",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          }
        ],
        internalType: "struct INonfungiblePositionManager.MintParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "mint",
    outputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      },
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "permit",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "positions",
    outputs: [
      {
        internalType: "uint96",
        name: "nonce",
        type: "uint96"
      },
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "address",
        name: "token0",
        type: "address"
      },
      {
        internalType: "address",
        name: "token1",
        type: "address"
      },
      {
        internalType: "uint24",
        name: "fee",
        type: "uint24"
      },
      {
        internalType: "int24",
        name: "tickLower",
        type: "int24"
      },
      {
        internalType: "int24",
        name: "tickUpper",
        type: "int24"
      },
      {
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      },
      {
        internalType: "uint256",
        name: "feeGrowthInside0LastX128",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "feeGrowthInside1LastX128",
        type: "uint256"
      },
      {
        internalType: "uint128",
        name: "tokensOwed0",
        type: "uint128"
      },
      {
        internalType: "uint128",
        name: "tokensOwed1",
        type: "uint128"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "refundETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermit",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowed",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowedIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      }
    ],
    name: "tokenByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      }
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    stateMutability: "payable",
    type: "receive"
  }
];

// src/selfPermit.ts
var import_abi3 = require("@ethersproject/abi");

// src/abis/SelfPermit.json
var SelfPermit_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermit",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowed",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowedIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  }
];

// src/selfPermit.ts
function isAllowedPermit(permitOptions) {
  return "nonce" in permitOptions;
}
var _SelfPermit = class _SelfPermit {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static encodePermit(token, options) {
    return isAllowedPermit(options) ? _SelfPermit.INTERFACE.encodeFunctionData("selfPermitAllowed", [
      token.address,
      toHex(options.nonce),
      toHex(options.expiry),
      options.v,
      options.r,
      options.s
    ]) : _SelfPermit.INTERFACE.encodeFunctionData("selfPermit", [
      token.address,
      toHex(options.amount),
      toHex(options.deadline),
      options.v,
      options.r,
      options.s
    ]);
  }
};
_SelfPermit.INTERFACE = new import_abi3.Interface(SelfPermit_default);
var SelfPermit = _SelfPermit;

// src/payments.ts
var import_abi4 = require("@ethersproject/abi");

// src/abis/IPeripheryPaymentsWithFee.json
var IPeripheryPaymentsWithFee_default = [
  {
    inputs: [],
    name: "refundETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  }
];

// src/payments.ts
var import_sdk10 = require("@vapordex/sdk");
var _Payments = class _Payments {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static encodeFeeBips(fee) {
    return toHex(fee.multiply(1e4).quotient);
  }
  static encodeUnwrapWETH9(amountMinimum, recipient, feeOptions) {
    recipient = (0, import_sdk10.validateAndParseAddress)(recipient);
    if (feeOptions) {
      const feeBips = this.encodeFeeBips(feeOptions.fee);
      const feeRecipient = (0, import_sdk10.validateAndParseAddress)(
        feeOptions.recipient
      );
      return _Payments.INTERFACE.encodeFunctionData("unwrapWETH9WithFee", [
        toHex(amountMinimum),
        recipient,
        feeBips,
        feeRecipient
      ]);
    } else {
      return _Payments.INTERFACE.encodeFunctionData("unwrapWETH9", [
        toHex(amountMinimum),
        recipient
      ]);
    }
  }
  static encodeSweepToken(token, amountMinimum, recipient, feeOptions) {
    recipient = (0, import_sdk10.validateAndParseAddress)(recipient);
    if (feeOptions) {
      const feeBips = this.encodeFeeBips(feeOptions.fee);
      const feeRecipient = (0, import_sdk10.validateAndParseAddress)(
        feeOptions.recipient
      );
      return _Payments.INTERFACE.encodeFunctionData("sweepTokenWithFee", [
        token.address,
        toHex(amountMinimum),
        recipient,
        feeBips,
        feeRecipient
      ]);
    } else {
      return _Payments.INTERFACE.encodeFunctionData("sweepToken", [
        token.address,
        toHex(amountMinimum),
        recipient
      ]);
    }
  }
  static encodeRefundETH() {
    return _Payments.INTERFACE.encodeFunctionData("refundETH");
  }
};
_Payments.INTERFACE = new import_abi4.Interface(IPeripheryPaymentsWithFee_default);
var Payments = _Payments;

// src/nonfungiblePositionManager.ts
var MaxUint128 = toHex(
  import_jsbi18.default.subtract(
    import_jsbi18.default.exponentiate(import_jsbi18.default.BigInt(2), import_jsbi18.default.BigInt(128)),
    import_jsbi18.default.BigInt(1)
  )
);
function isMint(options) {
  return Object.keys(options).includes("recipient");
}
var _NonfungiblePositionManager = class _NonfungiblePositionManager {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  static encodeCreate(pool) {
    return this.INTERFACE.encodeFunctionData(
      "createAndInitializePoolIfNecessary",
      [
        pool.token0.address,
        pool.token1.address,
        pool.fee,
        toHex(pool.sqrtRatioX96)
      ]
    );
  }
  static createCallParameters(pool) {
    return {
      calldata: this.encodeCreate(pool),
      value: toHex(0)
    };
  }
  static addCallParameters(position, options) {
    (0, import_tiny_invariant11.default)(import_jsbi18.default.greaterThan(position.liquidity, ZERO), "ZERO_LIQUIDITY");
    const calldatas = [];
    const { amount0: amount0Desired, amount1: amount1Desired } = position.mintAmounts;
    const minimumAmounts = position.mintAmountsWithSlippage(
      options.slippageTolerance
    );
    const amount0Min = toHex(minimumAmounts.amount0);
    const amount1Min = toHex(minimumAmounts.amount1);
    const deadline = toHex(options.deadline);
    if (isMint(options) && options.createPool) {
      calldatas.push(this.encodeCreate(position.pool));
    }
    if (options.token0Permit) {
      calldatas.push(
        SelfPermit.encodePermit(position.pool.token0, options.token0Permit)
      );
    }
    if (options.token1Permit) {
      calldatas.push(
        SelfPermit.encodePermit(position.pool.token1, options.token1Permit)
      );
    }
    if (isMint(options)) {
      const recipient = (0, import_sdk11.validateAndParseAddress)(options.recipient);
      calldatas.push(
        _NonfungiblePositionManager.INTERFACE.encodeFunctionData("mint", [
          {
            amount0Desired: toHex(amount0Desired),
            amount0Min,
            amount1Desired: toHex(amount1Desired),
            amount1Min,
            deadline,
            fee: position.pool.fee,
            recipient,
            tickLower: position.tickLower,
            tickUpper: position.tickUpper,
            token0: position.pool.token0.address,
            token1: position.pool.token1.address
          }
        ])
      );
    } else {
      calldatas.push(
        _NonfungiblePositionManager.INTERFACE.encodeFunctionData(
          "increaseLiquidity",
          [
            {
              amount0Desired: toHex(amount0Desired),
              amount0Min,
              amount1Desired: toHex(amount1Desired),
              amount1Min,
              deadline,
              tokenId: toHex(options.tokenId)
            }
          ]
        )
      );
    }
    let value = toHex(0);
    if (options.useNative) {
      const wrapped = options.useNative.wrapped;
      (0, import_tiny_invariant11.default)(
        position.pool.token0.equals(wrapped) || position.pool.token1.equals(wrapped),
        "NO_WETH"
      );
      const wrappedValue = position.pool.token0.equals(wrapped) ? amount0Desired : amount1Desired;
      if (import_jsbi18.default.greaterThan(wrappedValue, ZERO)) {
        calldatas.push(Payments.encodeRefundETH());
      }
      value = toHex(wrappedValue);
    }
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value
    };
  }
  static encodeCollect(options) {
    const calldatas = [];
    const tokenId = toHex(options.tokenId);
    const involvesETH = options.expectedCurrencyOwed0.currency.isNative || options.expectedCurrencyOwed1.currency.isNative;
    const recipient = (0, import_sdk11.validateAndParseAddress)(options.recipient);
    calldatas.push(
      _NonfungiblePositionManager.INTERFACE.encodeFunctionData("collect", [
        {
          amount0Max: MaxUint128,
          amount1Max: MaxUint128,
          recipient: involvesETH ? ADDRESS_ZERO : recipient,
          tokenId
        }
      ])
    );
    if (involvesETH) {
      const ethAmount = options.expectedCurrencyOwed0.currency.isNative ? options.expectedCurrencyOwed0.quotient : options.expectedCurrencyOwed1.quotient;
      const token = options.expectedCurrencyOwed0.currency.isNative ? options.expectedCurrencyOwed1.currency : options.expectedCurrencyOwed0.currency;
      const tokenAmount = options.expectedCurrencyOwed0.currency.isNative ? options.expectedCurrencyOwed1.quotient : options.expectedCurrencyOwed0.quotient;
      calldatas.push(
        Payments.encodeUnwrapWETH9(ethAmount, recipient),
        Payments.encodeSweepToken(token, tokenAmount, recipient)
      );
    }
    return calldatas;
  }
  static collectCallParameters(options) {
    const calldatas = _NonfungiblePositionManager.encodeCollect(options);
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(0)
    };
  }
  /**
   * Produces the calldata for completely or partially exiting a position
   * @param position The position to exit
   * @param options Additional information necessary for generating the calldata
   * @returns The call parameters
   */
  static removeCallParameters(position, options) {
    const calldatas = [];
    const deadline = toHex(options.deadline);
    const tokenId = toHex(options.tokenId);
    const partialPosition = new Position({
      liquidity: options.liquidityPercentage.multiply(position.liquidity).quotient,
      pool: position.pool,
      tickLower: position.tickLower,
      tickUpper: position.tickUpper
    });
    (0, import_tiny_invariant11.default)(
      import_jsbi18.default.greaterThan(partialPosition.liquidity, ZERO),
      "ZERO_LIQUIDITY"
    );
    const { amount0: amount0Min, amount1: amount1Min } = partialPosition.burnAmountsWithSlippage(options.slippageTolerance);
    if (options.permit) {
      calldatas.push(
        _NonfungiblePositionManager.INTERFACE.encodeFunctionData("permit", [
          (0, import_sdk11.validateAndParseAddress)(options.permit.spender),
          tokenId,
          toHex(options.permit.deadline),
          options.permit.v,
          options.permit.r,
          options.permit.s
        ])
      );
    }
    calldatas.push(
      _NonfungiblePositionManager.INTERFACE.encodeFunctionData(
        "decreaseLiquidity",
        [
          {
            amount0Min: toHex(amount0Min),
            amount1Min: toHex(amount1Min),
            deadline,
            liquidity: toHex(partialPosition.liquidity),
            tokenId
          }
        ]
      )
    );
    const { expectedCurrencyOwed0, expectedCurrencyOwed1, ...rest } = options.collectOptions;
    calldatas.push(
      ..._NonfungiblePositionManager.encodeCollect({
        // add the underlying value to the expected currency already owed
        expectedCurrencyOwed0: expectedCurrencyOwed0.add(
          import_sdk11.CurrencyAmount.fromRawAmount(
            expectedCurrencyOwed0.currency,
            amount0Min
          )
        ),
        expectedCurrencyOwed1: expectedCurrencyOwed1.add(
          import_sdk11.CurrencyAmount.fromRawAmount(
            expectedCurrencyOwed1.currency,
            amount1Min
          )
        ),
        tokenId: toHex(options.tokenId),
        ...rest
      })
    );
    if (options.liquidityPercentage.equalTo(ONE)) {
      if (options.burnToken) {
        calldatas.push(
          _NonfungiblePositionManager.INTERFACE.encodeFunctionData("burn", [
            tokenId
          ])
        );
      }
    } else {
      (0, import_tiny_invariant11.default)(options.burnToken !== true, "CANNOT_BURN");
    }
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(0)
    };
  }
  static safeTransferFromParameters(options) {
    const recipient = (0, import_sdk11.validateAndParseAddress)(options.recipient);
    const sender = (0, import_sdk11.validateAndParseAddress)(options.sender);
    const calldata = options.data ? _NonfungiblePositionManager.INTERFACE.encodeFunctionData(
      "safeTransferFrom(address,address,uint256,bytes)",
      [sender, recipient, toHex(options.tokenId), options.data]
    ) : _NonfungiblePositionManager.INTERFACE.encodeFunctionData(
      "safeTransferFrom(address,address,uint256)",
      [sender, recipient, toHex(options.tokenId)]
    );
    return {
      calldata,
      value: toHex(0)
    };
  }
};
_NonfungiblePositionManager.INTERFACE = new import_abi5.Interface(
  NonfungiblePositionManager_default
);
var NonfungiblePositionManager = _NonfungiblePositionManager;

// src/quoter.ts
var import_abi6 = require("@ethersproject/abi");
var import_sdk12 = require("@vapordex/sdk");

// src/abis/Quoter.json
var Quoter_default = [
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    name: "quoteExactInput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160[]",
        name: "sqrtPriceX96AfterList",
        type: "uint160[]"
      },
      {
        internalType: "uint32[]",
        name: "initializedTicksCrossedList",
        type: "uint32[]"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct IQuoterV2.QuoteExactInputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactInputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96After",
        type: "uint160"
      },
      {
        internalType: "uint32",
        name: "initializedTicksCrossed",
        type: "uint32"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    name: "quoteExactOutput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      },
      {
        internalType: "uint160[]",
        name: "sqrtPriceX96AfterList",
        type: "uint160[]"
      },
      {
        internalType: "uint32[]",
        name: "initializedTicksCrossedList",
        type: "uint32[]"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct IQuoterV2.QuoteExactOutputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactOutputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96After",
        type: "uint160"
      },
      {
        internalType: "uint32",
        name: "initializedTicksCrossed",
        type: "uint32"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/abis/QuoterV1.json
var QuoterV1_default = [
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    name: "quoteExactInput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenIn",
        type: "address"
      },
      {
        internalType: "address",
        name: "tokenOut",
        type: "address"
      },
      {
        internalType: "uint24",
        name: "fee",
        type: "uint24"
      },
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceLimitX96",
        type: "uint160"
      }
    ],
    name: "quoteExactInputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    name: "quoteExactOutput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenIn",
        type: "address"
      },
      {
        internalType: "address",
        name: "tokenOut",
        type: "address"
      },
      {
        internalType: "uint24",
        name: "fee",
        type: "uint24"
      },
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceLimitX96",
        type: "uint160"
      }
    ],
    name: "quoteExactOutputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/quoter.ts
var import_tiny_invariant12 = __toESM(require("tiny-invariant"));
var SwapQuoter = class {
  /**
   * Produces the on-chain method name of the appropriate function within QuoterV2,
   * and the relevant hex encoded parameters.
   * @template TInput The input token, either Ether or an ERC-20
   * @template TOutput The output token, either Ether or an ERC-20
   * @param route The swap route, a list of pools through which a swap can occur
   * @param amount The amount of the quote, either an amount in, or an amount out
   * @param tradeType The trade type, either exact input or exact output
   * @param options The optional params including price limit and Quoter contract switch
   * @returns The formatted calldata
   */
  static quoteCallParameters(route, amount, tradeType, options = {}) {
    var _a;
    const singleHop = route.pools.length === 1;
    const quoteAmount = toHex(amount.quotient);
    let calldata;
    const swapInterface = options.useQuoterV2 ? this.V1INTERFACE : this.INTERFACE;
    if (singleHop) {
      const baseQuoteParams = {
        tokenIn: route.tokenPath[0].address,
        tokenOut: route.tokenPath[1].address,
        fee: route.pools[0].fee,
        sqrtPriceLimitX96: toHex((_a = options == null ? void 0 : options.sqrtPriceLimitX96) != null ? _a : 0)
      };
      const v1QuoteParams = {
        ...baseQuoteParams,
        ...tradeType == import_sdk12.TradeType.EXACT_INPUT ? { amountIn: quoteAmount } : { amount: quoteAmount }
      };
      const QuoteParams = [
        baseQuoteParams.tokenIn,
        baseQuoteParams.tokenOut,
        baseQuoteParams.fee,
        quoteAmount,
        baseQuoteParams.sqrtPriceLimitX96
      ];
      const tradeTypeFunctionName = tradeType === import_sdk12.TradeType.EXACT_INPUT ? "quoteExactInputSingle" : "quoteExactOutputSingle";
      calldata = swapInterface.encodeFunctionData(
        tradeTypeFunctionName,
        options.useQuoterV2 ? [v1QuoteParams] : QuoteParams
      );
    } else {
      (0, import_tiny_invariant12.default)(
        (options == null ? void 0 : options.sqrtPriceLimitX96) === void 0,
        "MULTIHOP_PRICE_LIMIT"
      );
      const path = encodeRouteToPath(
        route,
        tradeType === import_sdk12.TradeType.EXACT_OUTPUT
      );
      const tradeTypeFunctionName = tradeType === import_sdk12.TradeType.EXACT_INPUT ? "quoteExactInput" : "quoteExactOutput";
      calldata = swapInterface.encodeFunctionData(tradeTypeFunctionName, [
        path,
        quoteAmount
      ]);
    }
    return {
      calldata,
      value: toHex(0)
    };
  }
};
SwapQuoter.INTERFACE = new import_abi6.Interface(Quoter_default);
SwapQuoter.V1INTERFACE = new import_abi6.Interface(QuoterV1_default);

// src/staker.ts
var import_sdk13 = require("@vapordex/sdk");
var import_abi7 = require("@ethersproject/abi");

// src/abis/V2Staker.json
var V2Staker_default = [
  {
    inputs: [
      {
        internalType: "contract IVaporDEXV2Factory",
        name: "_factory",
        type: "address"
      },
      {
        internalType: "contract INonfungiblePositionManager",
        name: "_nonfungiblePositionManager",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_maxIncentiveStartLeadTime",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_maxIncentiveDuration",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "oldOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "DepositTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract IERC20Minimal",
        name: "rewardToken",
        type: "address"
      },
      {
        indexed: true,
        internalType: "contract IVaporDEXV2Pool",
        name: "pool",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "startTime",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "endTime",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "address",
        name: "refundee",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256"
      }
    ],
    name: "IncentiveCreated",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "incentiveId",
        type: "bytes32"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "refund",
        type: "uint256"
      }
    ],
    name: "IncentiveEnded",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256"
      }
    ],
    name: "RewardClaimed",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "incentiveId",
        type: "bytes32"
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      }
    ],
    name: "TokenStaked",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "incentiveId",
        type: "bytes32"
      }
    ],
    name: "TokenUnstaked",
    type: "event"
  },
  {
    inputs: [
      {
        internalType: "contract IERC20Minimal",
        name: "rewardToken",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountRequested",
        type: "uint256"
      }
    ],
    name: "claimReward",
    outputs: [
      {
        internalType: "uint256",
        name: "reward",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20Minimal",
            name: "rewardToken",
            type: "address"
          },
          {
            internalType: "contract IVaporDEXV2Pool",
            name: "pool",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "refundee",
            type: "address"
          }
        ],
        internalType: "struct IVaporDEXV2Staker.IncentiveKey",
        name: "key",
        type: "tuple"
      },
      {
        internalType: "uint256",
        name: "reward",
        type: "uint256"
      }
    ],
    name: "createIncentive",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "deposits",
    outputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "uint48",
        name: "numberOfStakes",
        type: "uint48"
      },
      {
        internalType: "int24",
        name: "tickLower",
        type: "int24"
      },
      {
        internalType: "int24",
        name: "tickUpper",
        type: "int24"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20Minimal",
            name: "rewardToken",
            type: "address"
          },
          {
            internalType: "contract IVaporDEXV2Pool",
            name: "pool",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "refundee",
            type: "address"
          }
        ],
        internalType: "struct IVaporDEXV2Staker.IncentiveKey",
        name: "key",
        type: "tuple"
      }
    ],
    name: "endIncentive",
    outputs: [
      {
        internalType: "uint256",
        name: "refund",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "contract IVaporDEXV2Factory",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20Minimal",
            name: "rewardToken",
            type: "address"
          },
          {
            internalType: "contract IVaporDEXV2Pool",
            name: "pool",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "refundee",
            type: "address"
          }
        ],
        internalType: "struct IVaporDEXV2Staker.IncentiveKey",
        name: "key",
        type: "tuple"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "getRewardInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "reward",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "secondsInsideX128",
        type: "uint160"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    name: "incentives",
    outputs: [
      {
        internalType: "uint256",
        name: "totalRewardUnclaimed",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "totalSecondsClaimedX128",
        type: "uint160"
      },
      {
        internalType: "uint96",
        name: "numberOfStakes",
        type: "uint96"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxIncentiveDuration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxIncentiveStartLeadTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "nonfungiblePositionManager",
    outputs: [
      {
        internalType: "contract INonfungiblePositionManager",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "contract IERC20Minimal",
        name: "",
        type: "address"
      },
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "rewards",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20Minimal",
            name: "rewardToken",
            type: "address"
          },
          {
            internalType: "contract IVaporDEXV2Pool",
            name: "pool",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "refundee",
            type: "address"
          }
        ],
        internalType: "struct IVaporDEXV2Staker.IncentiveKey",
        name: "key",
        type: "tuple"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "stakeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "bytes32",
        name: "incentiveId",
        type: "bytes32"
      }
    ],
    name: "stakes",
    outputs: [
      {
        internalType: "uint160",
        name: "secondsPerLiquidityInsideInitialX128",
        type: "uint160"
      },
      {
        internalType: "uint128",
        name: "liquidity",
        type: "uint128"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "transferDeposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20Minimal",
            name: "rewardToken",
            type: "address"
          },
          {
            internalType: "contract IVaporDEXV2Pool",
            name: "pool",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "refundee",
            type: "address"
          }
        ],
        internalType: "struct IVaporDEXV2Staker.IncentiveKey",
        name: "key",
        type: "tuple"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "unstakeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "withdrawToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/staker.ts
var _Staker = class _Staker {
  constructor() {
  }
  /**
   *  To claim rewards, must unstake and then claim.
   * @param incentiveKey The unique identifier of a staking program.
   * @param options Options for producing the calldata to claim. Can't claim unless you unstake.
   * @returns The calldatas for 'unstakeToken' and 'claimReward'.
   */
  static encodeClaim(incentiveKey, options) {
    var _a;
    const calldatas = [];
    calldatas.push(
      _Staker.INTERFACE.encodeFunctionData("unstakeToken", [
        this._encodeIncentiveKey(incentiveKey),
        toHex(options.tokenId)
      ])
    );
    const recipient = (0, import_sdk13.validateAndParseAddress)(options.recipient);
    const amount = (_a = options.amount) != null ? _a : 0;
    calldatas.push(
      _Staker.INTERFACE.encodeFunctionData("claimReward", [
        incentiveKey.rewardToken.address,
        recipient,
        toHex(amount)
      ])
    );
    return calldatas;
  }
  /**
   *
   * Note:  A `tokenId` can be staked in many programs but to claim rewards and continue the program you must unstake, claim, and then restake.
   * @param incentiveKeys An IncentiveKey or array of IncentiveKeys that `tokenId` is staked in.
   * Input an array of IncentiveKeys to claim rewards for each program.
   * @param options ClaimOptions to specify tokenId, recipient, and amount wanting to collect.
   * Note that you can only specify one amount and one recipient across the various programs if you are collecting from multiple programs at once.
   * @returns
   */
  static collectRewards(incentiveKeys, options) {
    incentiveKeys = Array.isArray(incentiveKeys) ? incentiveKeys : [incentiveKeys];
    let calldatas = [];
    for (const incentiveKey of incentiveKeys) {
      calldatas = [...calldatas, ...this.encodeClaim(incentiveKey, options)];
      calldatas.push(
        _Staker.INTERFACE.encodeFunctionData("stakeToken", [
          this._encodeIncentiveKey(incentiveKey),
          toHex(options.tokenId)
        ])
      );
    }
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(0)
    };
  }
  /**
   *
   * @param incentiveKeys A list of incentiveKeys to unstake from. Should include all incentiveKeys (unique staking programs) that `options.tokenId` is staked in.
   * @param withdrawOptions Options for producing claim calldata and withdraw calldata. Can't withdraw without unstaking all programs for `tokenId`.
   * @returns Calldata for unstaking, claiming, and withdrawing.
   */
  static withdrawToken(incentiveKeys, withdrawOptions) {
    var _a;
    let calldatas = [];
    incentiveKeys = Array.isArray(incentiveKeys) ? incentiveKeys : [incentiveKeys];
    const claimOptions = {
      amount: withdrawOptions.amount,
      recipient: withdrawOptions.recipient,
      tokenId: withdrawOptions.tokenId
    };
    for (const incentiveKey of incentiveKeys) {
      calldatas = [
        ...calldatas,
        ...this.encodeClaim(incentiveKey, claimOptions)
      ];
    }
    const owner = (0, import_sdk13.validateAndParseAddress)(withdrawOptions.owner);
    calldatas.push(
      _Staker.INTERFACE.encodeFunctionData("withdrawToken", [
        toHex(withdrawOptions.tokenId),
        owner,
        (_a = withdrawOptions.data) != null ? _a : toHex(0)
      ])
    );
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(0)
    };
  }
  /**
   *
   * @param incentiveKeys A single IncentiveKey or array of IncentiveKeys to be encoded and used in the data parameter in `safeTransferFrom`
   * @returns An IncentiveKey as a string
   */
  static encodeDeposit(incentiveKeys) {
    incentiveKeys = Array.isArray(incentiveKeys) ? incentiveKeys : [incentiveKeys];
    let data;
    if (incentiveKeys.length > 1) {
      const keys = [];
      for (const incentiveKey of incentiveKeys) {
        keys.push(this._encodeIncentiveKey(incentiveKey));
      }
      data = import_abi7.defaultAbiCoder.encode([`${_Staker.INCENTIVE_KEY_ABI}[]`], [keys]);
    } else {
      data = import_abi7.defaultAbiCoder.encode(
        [_Staker.INCENTIVE_KEY_ABI],
        [this._encodeIncentiveKey(incentiveKeys[0])]
      );
    }
    return data;
  }
  /**
   *
   * @param incentiveKey An `IncentiveKey` which represents a unique staking program.
   * @returns An encoded IncentiveKey to be read by ethers
   */
  static _encodeIncentiveKey(incentiveKey) {
    const { fee, token0, token1 } = incentiveKey.pool;
    const refundee = (0, import_sdk13.validateAndParseAddress)(incentiveKey.refundee);
    return {
      endTime: toHex(incentiveKey.endTime),
      pool: Pool.getAddress(token0, token1, fee),
      refundee,
      rewardToken: incentiveKey.rewardToken.address,
      startTime: toHex(incentiveKey.startTime)
    };
  }
};
_Staker.INTERFACE = new import_abi7.Interface(V2Staker_default);
_Staker.INCENTIVE_KEY_ABI = "tuple(address rewardToken, address pool, uint256 startTime, uint256 endTime, address refundee)";
var Staker = _Staker;

// src/swapRouter.ts
var import_abi8 = require("@ethersproject/abi");
var import_sdk14 = require("@vapordex/sdk");
var import_tiny_invariant13 = __toESM(require("tiny-invariant"));

// src/abis/SwapRouter.json
var SwapRouter_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_factory",
        type: "address"
      },
      {
        internalType: "address",
        name: "_WETH9",
        type: "address"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "amount0Delta",
        type: "int256"
      },
      {
        internalType: "int256",
        name: "amount1Delta",
        type: "int256"
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes"
      }
    ],
    name: "VaporDEXV2SwapCallback",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "WETH9",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "path",
            type: "bytes"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountOutMinimum",
            type: "uint256"
          }
        ],
        internalType: "struct ISwapRouter.ExactInputParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactInput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountOutMinimum",
            type: "uint256"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct ISwapRouter.ExactInputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactInputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "path",
            type: "bytes"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountOut",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountInMaximum",
            type: "uint256"
          }
        ],
        internalType: "struct ISwapRouter.ExactOutputParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactOutput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountOut",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amountInMaximum",
            type: "uint256"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct ISwapRouter.ExactOutputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactOutputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "refundETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermit",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowed",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitAllowedIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "selfPermitIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    stateMutability: "payable",
    type: "receive"
  }
];

// src/swapRouter.ts
var _SwapRouter = class _SwapRouter {
  /**
   * Cannot be constructed.
   */
  constructor() {
  }
  /**
   * Produces the on-chain method name to call and the hex encoded parameters to pass as arguments for a given trade.
   * @param trade to produce call parameters for
   * @param options options for the call parameters
   */
  static swapCallParameters(trades, options) {
    var _a, _b;
    if (!Array.isArray(trades)) {
      trades = [trades];
    }
    const sampleTrade = trades[0];
    const tokenIn = sampleTrade.inputAmount.currency.wrapped;
    const tokenOut = sampleTrade.outputAmount.currency.wrapped;
    (0, import_tiny_invariant13.default)(
      trades.every(
        (trade) => trade.inputAmount.currency.wrapped.equals(tokenIn)
      ),
      "TOKEN_IN_DIFF"
    );
    (0, import_tiny_invariant13.default)(
      trades.every(
        (trade) => trade.outputAmount.currency.wrapped.equals(tokenOut)
      ),
      "TOKEN_OUT_DIFF"
    );
    const calldatas = [];
    const ZERO_IN = import_sdk14.CurrencyAmount.fromRawAmount(
      trades[0].inputAmount.currency,
      0
    );
    const ZERO_OUT = import_sdk14.CurrencyAmount.fromRawAmount(
      trades[0].outputAmount.currency,
      0
    );
    const totalAmountOut = trades.reduce(
      (sum, trade) => sum.add(trade.minimumAmountOut(options.slippageTolerance)),
      ZERO_OUT
    );
    const mustRefund = sampleTrade.inputAmount.currency.isNative && sampleTrade.tradeType === import_sdk14.TradeType.EXACT_OUTPUT;
    const inputIsNative = sampleTrade.inputAmount.currency.isNative;
    const outputIsNative = sampleTrade.outputAmount.currency.isNative;
    const routerMustCustody = outputIsNative || !!options.fee;
    const totalValue = inputIsNative ? trades.reduce(
      (sum, trade) => sum.add(trade.maximumAmountIn(options.slippageTolerance)),
      ZERO_IN
    ) : ZERO_IN;
    if (options.inputTokenPermit) {
      (0, import_tiny_invariant13.default)(sampleTrade.inputAmount.currency.isToken, "NON_TOKEN_PERMIT");
      calldatas.push(
        SelfPermit.encodePermit(
          sampleTrade.inputAmount.currency,
          options.inputTokenPermit
        )
      );
    }
    const recipient = (0, import_sdk14.validateAndParseAddress)(options.recipient);
    const deadline = toHex(options.deadline);
    for (const trade of trades) {
      for (const { inputAmount, outputAmount, route } of trade.swaps) {
        const amountIn = toHex(
          trade.maximumAmountIn(options.slippageTolerance, inputAmount).quotient
        );
        const amountOut = toHex(
          trade.minimumAmountOut(options.slippageTolerance, outputAmount).quotient
        );
        const singleHop = route.pools.length === 1;
        if (singleHop) {
          if (trade.tradeType === import_sdk14.TradeType.EXACT_INPUT) {
            const exactInputSingleParams = {
              amountIn,
              amountOutMinimum: amountOut,
              deadline,
              fee: route.pools[0].fee,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              sqrtPriceLimitX96: toHex((_a = options.sqrtPriceLimitX96) != null ? _a : 0),
              tokenIn: route.tokenPath[0].address,
              tokenOut: route.tokenPath[1].address
            };
            calldatas.push(
              _SwapRouter.INTERFACE.encodeFunctionData("exactInputSingle", [
                exactInputSingleParams
              ])
            );
          } else {
            const exactOutputSingleParams = {
              amountInMaximum: amountIn,
              amountOut,
              deadline,
              fee: route.pools[0].fee,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              sqrtPriceLimitX96: toHex((_b = options.sqrtPriceLimitX96) != null ? _b : 0),
              tokenIn: route.tokenPath[0].address,
              tokenOut: route.tokenPath[1].address
            };
            calldatas.push(
              _SwapRouter.INTERFACE.encodeFunctionData("exactOutputSingle", [
                exactOutputSingleParams
              ])
            );
          }
        } else {
          (0, import_tiny_invariant13.default)(
            options.sqrtPriceLimitX96 === void 0,
            "MULTIHOP_PRICE_LIMIT"
          );
          const path = encodeRouteToPath(
            route,
            trade.tradeType === import_sdk14.TradeType.EXACT_OUTPUT
          );
          if (trade.tradeType === import_sdk14.TradeType.EXACT_INPUT) {
            const exactInputParams = {
              amountIn,
              amountOutMinimum: amountOut,
              deadline,
              path,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient
            };
            calldatas.push(
              _SwapRouter.INTERFACE.encodeFunctionData("exactInput", [
                exactInputParams
              ])
            );
          } else {
            const exactOutputParams = {
              amountInMaximum: amountIn,
              amountOut,
              deadline,
              path,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient
            };
            calldatas.push(
              _SwapRouter.INTERFACE.encodeFunctionData("exactOutput", [
                exactOutputParams
              ])
            );
          }
        }
      }
    }
    if (routerMustCustody) {
      if (options.fee) {
        if (outputIsNative) {
          calldatas.push(
            Payments.encodeUnwrapWETH9(
              totalAmountOut.quotient,
              recipient,
              options.fee
            )
          );
        } else {
          calldatas.push(
            Payments.encodeSweepToken(
              sampleTrade.outputAmount.currency.wrapped,
              totalAmountOut.quotient,
              recipient,
              options.fee
            )
          );
        }
      } else {
        calldatas.push(
          Payments.encodeUnwrapWETH9(totalAmountOut.quotient, recipient)
        );
      }
    }
    if (mustRefund) {
      calldatas.push(Payments.encodeRefundETH());
    }
    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(totalValue.quotient)
    };
  }
};
_SwapRouter.INTERFACE = new import_abi8.Interface(SwapRouter_default);
var SwapRouter = _SwapRouter;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ADDRESS_ZERO,
  FACTORY_ADDRESS,
  FeeAmount,
  FullMath,
  LiquidityMath,
  Multicall,
  NoTickDataProvider,
  NonfungiblePositionManager,
  POOL_INIT_CODE_HASH,
  Payments,
  Pool,
  Position,
  PositionLibrary,
  Route,
  SelfPermit,
  SqrtPriceMath,
  Staker,
  SwapMath,
  SwapQuoter,
  SwapRouter,
  TICK_SPACINGS,
  Tick,
  TickLibrary,
  TickList,
  TickListDataProvider,
  TickMath,
  Trade,
  computePoolAddress,
  encodeRouteToPath,
  encodeSqrtRatioX96,
  isSorted,
  maxLiquidityForAmounts,
  mostSignificantBit,
  nearestUsableTick,
  priceToClosestTick,
  subIn256,
  tickToPrice,
  toHex,
  tradeComparator
});
