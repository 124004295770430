import { defaultImageFormats } from "@vaporfi/hooks";
import { Span, Typography } from "@vaporfi/uikit";
import { DISCORD_URL } from "@vaporfi/utils";
import Link from "next/link";
import { z } from "zod";
import type { FormShapeKeys } from "./types";

export const validateFields = (
	chainId: number,
	fields: string[],
	values: string[],
) => {
	if (!fields || !values) {
		return false;
	}

	return fields.every((field, index) => {
		const fieldValue = values[index];
		const fieldSchema = formSchema(chainId).shape[field as FormShapeKeys];

		if (fieldSchema && !fieldSchema.safeParse(fieldValue)?.success) {
			return false;
		}
		return true;
	});
};

export const tokenFactoryFAQContent = [
	{
		heading: "How much does it cost to launch with TokenFactory?",
		text: "The cost to launch is 250 USDC with half of this sum allocated for the purchase of VAPE. Subsequently, both the acquired VAPE and the remaining USDC are added to the VAPE/USDC Liquidity Pool, serving the interests of our community.",
	},
	{
		heading: "How long does it take to launch with TokenFactory?",
		text: "The token is generated automatically upon completing the final step of the creation form. It becomes eligible for trading at the launch date specified during the creation process.",
	},
	{
		heading: "How can I get in touch if I have other questions?",
		text: (
			<Typography className="font-semibold">
				You can always contact the team using Discord by creating a ticket{" "}
				<Link href={DISCORD_URL} target="_blank">
					<Span color="turquoise">here.</Span>
				</Link>
			</Typography>
		),
	},
	{
		heading: "Where can I see the tokens I have created?",
		text: "Your tokens are available in the TokenFactory page, under the section ‘My Tokens’.",
	},
	{
		heading: "Why do I need to provide AVAX?",
		text: "Once the tokens are generated, an AVAX/Your Token liquidity pool is created, utilizing both the provided AVAX and the newly generated tokens. The greater the amount of AVAX you contribute, the higher your token's price and the more robust your pairing becomes.",
	},
];

const MINIMUM_NATIVE_LIQUIDITY = { [43_113]: 0.01, [43_114]: 10 };

export const socialsSchema = z.object({
	discord: z.string().optional(),
	telegram: z.string().optional(),
	website: z.string().optional(),
	x: z.string().optional(),
});

export const formSchema = (chainId: number, nativeSymbol?: string) => {
	const minNativeLp =
		MINIMUM_NATIVE_LIQUIDITY[chainId as keyof typeof MINIMUM_NATIVE_LIQUIDITY];

	return socialsSchema.extend({
		chain: z.enum(["43114", "43113"]),
		decimals: z.number().readonly().optional(),

		initialSupply: z.string().min(1, { message: "Missing initial supply." }),
		isStratosphereWhitelist: z.boolean().default(true).readonly(),
		launchDate: z.number().refine((num) => {
			return num >= Date.now();
		}, "Launch date cannot be in the past."),
		lockDuration: z.enum(["90", "180", "365"]).optional(),
		logo: z
			.any()
			.refine(
				(file: File) =>
					file && defaultImageFormats.includes(file.type as `image/${string}`),
				`Only ${defaultImageFormats.join(", ")} formats are supported.`,
			),
		maximumSupply: z.string().readonly().optional(),
		name: z.string().min(2, {
			message: "Token name must be at least 2 characters.",
		}),
		nativeAmount: z
			.string()
			.refine(
				(lp) => Number(lp) >= minNativeLp,
				`Minimum ${minNativeLp} ${nativeSymbol || "AVAX"} is required.`,
			),
		symbol: z.string().min(3, {
			message: "Token symbol must be at least 3 characters.",
		}),

		type: z.enum(["burned", "locked"], {
			required_error: "You need to select a liquidity state.",
		}),
	});
};
