import { Token as V2Token } from "@vapordex/sdk";
import type { SerializedToken } from "config/constants/types";
import { WrappedTokenInfo } from "state/types";
import type { Address } from "viem";

export function serializeToken(token: V2Token): SerializedToken {
	return {
		address: token.address,
		chainId: token.chainId,
		decimals: token.decimals,
		// projectLink: token.projectLink,
		logoURI: token instanceof WrappedTokenInfo ? token.logoURI : undefined,
		name: token.name,
		symbol: token.symbol,
	};
}

export function deserializeToken(serializedToken: SerializedToken): V2Token {
	if (serializedToken.logoURI) {
		return new WrappedTokenInfo(
			{
				address: serializedToken.address,
				chainId: serializedToken.chainId,
				decimals: serializedToken.decimals,
				logoURI: serializedToken.logoURI,
				name: serializedToken.name,
				symbol: serializedToken.symbol,
			},
			[],
		);
	}
	return new V2Token(
		serializedToken.chainId,
		serializedToken.address as Address,
		serializedToken.decimals,
		serializedToken.symbol,
		serializedToken.name,
		// serializedToken.projectLink,
	);
}
