import type { TokenList } from "../types";
import { TelosMainnet } from "./40";
import { TelosTestnet } from "./41";
import { Curtis } from "./33111";
import { ApeChain } from "./33139";
import { FujiTokens } from "./43113";
import { AvalancheTokens } from "./43114";
import { SkaleEuropaTestnet } from "./1444673419";

export const DEFAULT_TOKEN_LIST = {
	name: "Default Token List",
	tokens: [
		...ApeChain,
		...AvalancheTokens,
		...FujiTokens,
		...TelosMainnet,
		...TelosTestnet,
		...Curtis,
		...SkaleEuropaTestnet,
	],
} as TokenList;

export const get_DIRECTUS_TOKEN_LIST_URL = (baseUrl: string) =>
	`${baseUrl}/api/directus/token-list`;

export const UNSUPPORTED_TOKEN_LIST = {} as TokenList;
export const WARNING_TOKEN_LIST = {} as TokenList;

// List of official tokens list
export const get_OFFICIAL_LISTS = (baseUrl: string) => [
	...get_DEFAULT_ACTIVE_LIST_URLS(baseUrl),
];

export const UNSUPPORTED_LIST_URLS: string[] = [];
export const WARNING_LIST_URLS: string[] = [];

// lower index == higher priority for token import
export const get_DEFAULT_LIST_OF_LISTS = (baseUrl: string) => [
	...get_OFFICIAL_LISTS(baseUrl),
	...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
	...WARNING_LIST_URLS,
];

// default lists to be 'active' aka searched across
export const get_DEFAULT_ACTIVE_LIST_URLS = (baseUrl: string) => [
	get_DIRECTUS_TOKEN_LIST_URL(baseUrl),
];
