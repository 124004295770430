"use client";

import { Grid, Modal, type ModalProps } from "@vaporfi/uikit";
import { WalletCard } from "./WalletCard";
import { connectors } from "./config";
import type { Login } from "./types";
import { getPreferredConfig } from "./utils";

export const WalletConnectModal = ({
	color,
	login,
	onDismiss,
}: Partial<ModalProps> & {
	onDismiss?: () => void;
	login: Login | (() => void);
}) => {
	const sortedConfig = getPreferredConfig(connectors);
	// Filter out WalletConnect if user is inside TrustWallet built-in browser
	const walletsToShow = window.ethereum?.isTrust
		? sortedConfig.filter((wallet) => wallet.title !== "WalletConnect")
		: sortedConfig;

	return (
		<Modal
			title="Connect Wallet"
			color={color}
			body={
				<Grid isContainer className="mt-4 grid-cols-2 gap-4">
					{walletsToShow.map((connector) => (
						<WalletCard
							walletConfig={connector}
							key={`wallet-connection-connector-${connector.connectorId}`}
							onDismiss={onDismiss}
							login={login}
							color={color}
						/>
					))}
				</Grid>
			}
			onDismiss={onDismiss}
		/>
	);
};
