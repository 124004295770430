"use client";

import {
	Box,
	type BoxProps,
	CandyNotSelected,
	CandySelected,
	Checkbox,
	ChevronDownIcon,
	Container,
	Typography,
} from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useState, useMemo, useCallback } from "react";
import { Input } from "./components/shared";

interface Social {
	key: string;
	param: string;
	label: string;
}

const socials: Social[] = [
	{ key: "website", label: "Website", param: "website" },
	{ key: "telegram", label: "Telegram", param: "telegram" },
	{ key: "twitter", label: "Twitter", param: "x" },
	{ key: "discord", label: "Discord", param: "discord" },
];

type CheckedSocials = {
	[key: string]: boolean;
};

const useSocialChecks = () => {
	const searchParams = useSearchParams();

	const initialState = useMemo(() => {
		return socials.reduce((acc: CheckedSocials, social) => {
			acc[social.key] = searchParams.has(social.param);
			return acc;
		}, {} as CheckedSocials);
	}, [searchParams]);

	const [checkedSocials, setCheckedSocials] =
		useState<CheckedSocials>(initialState);

	const toggleSocial = (key: string) => {
		setCheckedSocials((prev: CheckedSocials) => ({
			...prev,
			[key]: !prev[key],
		}));
	};

	return { checkedSocials, toggleSocial };
};

const Socials = ({ className }: BoxProps) => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { checkedSocials, toggleSocial } = useSocialChecks();

	const handleFilterChange = (filter: string, value: boolean) => {
		const params = new URLSearchParams(searchParams.toString());
		if (value) {
			params.set(filter, "true");
		} else {
			params.delete(filter);
		}
		router.push(`${pathname}?${params.toString()}`);
	};

	return (
		<Box className={cn("flex flex-col gap-3", className)}>
			<Typography size="base">Socials</Typography>
			{socials.map((social) => (
				<Box
					key={social.key}
					className="flex cursor-pointer items-center gap-2"
					onClick={() => {
						toggleSocial(social.key);
						handleFilterChange(social.param, !checkedSocials[social.key]);
					}}
				>
					<Checkbox variant="candy" isChecked={checkedSocials[social.key]}>
						{checkedSocials[social.key] ? (
							<CandySelected width={32} height={26.7} />
						) : (
							<CandyNotSelected width={32} height={26.7} />
						)}
					</Checkbox>
					<Typography size="base" className="ml-2">
						{social.label}
					</Typography>
				</Box>
			))}
		</Box>
	);
};

const FilterInput = ({
	label,
	maxValue,
	minValue,
	onMaxChange,
	onMinChange,
	placeholderMax,
	placeholderMin,
}: {
	label: string;
	placeholderMin: string;
	placeholderMax: string;
	minValue: string;
	maxValue: string;
	onMinChange: (value: string) => void;
	onMaxChange: (value: string) => void;
}) => (
	<Box className="mt-2">
		<Typography size="base">{label}</Typography>
		<Box className="mt-2 flex gap-2">
			<Box className="relative flex-1">
				<Input
					type="text"
					placeholder={placeholderMin}
					value={minValue}
					onChange={(e) => onMinChange(e.target.value)}
					className="text-dark bg-light w-full p-2"
					textAlign="left"
				/>
			</Box>
			<Box className="relative flex-1">
				<Input
					type="text"
					placeholder={placeholderMax}
					value={maxValue}
					onChange={(e) => onMaxChange(e.target.value)}
					className="text-dark bg-light w-full p-2"
					textAlign="left"
				/>
			</Box>
		</Box>
	</Box>
);
export interface Filters {
	myTokens?: boolean;
	lpLaunched?: boolean;
	devHolding?: string;
	candyKing?: boolean;
	website?: string;
	telegram?: string;
	x?: string;
	discord?: string;
	minMarketCap: string;
	maxMarketCap: string;
	minHolders: string;
	maxHolders: string;
	minBondingCurve: string;
	maxBondingCurve: string;
	minDevHolding: string;
	maxDevHolding: string;
	minVolume: string;
	maxVolume: string;
	new?: string;
	aboutToBound?: string;
	bonded?: string;
	watchList?: string;
	category?: string;
}
const TokenFilters = () => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();

	const initialState = useMemo(
		() =>
			[...searchParams.keys()].reduce((acc: Record<string, any>, curr) => {
				acc[curr] = searchParams.get(curr) ?? undefined;
				return acc;
			}, {}) as Filters,
		[searchParams],
	);

	const [filterState, setFilterState] = useState<Filters>(initialState);

	const handleFilterChange = useCallback(
		(key: string, value: boolean | string) => {
			setFilterState((prevState) => ({ ...prevState, [key]: value }));
			const params = new URLSearchParams(searchParams.toString());
			if (value) {
				params.set(key, value?.toString());
			} else {
				params.delete(key);
			}
			router.push(`${pathname}?${params.toString()}`);
		},
		[pathname, router, searchParams],
	);

	return (
		<Container className="flex flex-col gap-2">
			<Box
				className="flex cursor-pointer items-center gap-2"
				onClick={() => {
					handleFilterChange("myTokens", !filterState?.myTokens);
				}}
			>
				<Checkbox variant="candy" isChecked={filterState?.myTokens}>
					{filterState?.myTokens ? (
						<CandySelected width={32} height={26.7} />
					) : (
						<CandyNotSelected width={32} height={26.7} />
					)}
				</Checkbox>
				<Typography size="base" className="ml-2">
					My Tokens
				</Typography>
			</Box>

			<Box
				className="flex cursor-pointer items-center gap-2"
				onClick={() => {
					handleFilterChange("lpLaunched", !filterState?.lpLaunched);
				}}
			>
				<Checkbox variant="candy" isChecked={filterState?.lpLaunched}>
					{filterState?.lpLaunched ? (
						<CandySelected width={32} height={26.7} />
					) : (
						<CandyNotSelected width={32} height={26.7} />
					)}
				</Checkbox>
				<Typography size="base" className="ml-2">
					LP Launched
				</Typography>
			</Box>
			<Box
				className="flex cursor-pointer items-center gap-2"
				onClick={() => {
					handleFilterChange("devHolding", !filterState?.devHolding);
				}}
			>
				<Checkbox variant="candy" isChecked={Boolean(filterState?.devHolding)}>
					{filterState?.devHolding ? (
						<CandySelected width={32} height={26.7} />
					) : (
						<CandyNotSelected width={32} height={26.7} />
					)}
				</Checkbox>
				<Typography size="base" className="ml-2">
					Dev is still holding
				</Typography>
			</Box>
			<Box
				className="flex cursor-pointer items-center gap-2"
				onClick={() => {
					handleFilterChange("candyKing", !filterState?.candyKing);
				}}
			>
				<Checkbox variant="candy" isChecked={filterState?.candyKing}>
					{filterState?.candyKing ? (
						<CandySelected width={32} height={26.7} />
					) : (
						<CandyNotSelected width={32} height={26.7} />
					)}
				</Checkbox>
				<Typography size="base" className="ml-2">
					Candy King
				</Typography>
			</Box>

			<FilterInput
				label="Market Cap"
				placeholderMin="Min"
				placeholderMax="Max"
				minValue={filterState?.minMarketCap}
				maxValue={filterState?.maxMarketCap}
				onMinChange={(value) => {
					handleFilterChange("minMarketCap", value);
				}}
				onMaxChange={(value) => {
					handleFilterChange("maxMarketCap", value);
				}}
			/>
			<FilterInput
				label="Dev Holding %"
				placeholderMin="Min"
				placeholderMax="Max"
				minValue={filterState?.minDevHolding}
				maxValue={filterState?.maxDevHolding}
				onMinChange={(value) => {
					handleFilterChange("minDevHolding", value);
				}}
				onMaxChange={(value) => {
					handleFilterChange("maxDevHolding", value);
				}}
			/>
			<FilterInput
				label="Holders"
				placeholderMin="Min"
				placeholderMax="Max"
				minValue={filterState?.minHolders}
				maxValue={filterState?.maxHolders}
				onMinChange={(value) => {
					handleFilterChange("minHolders", value);
				}}
				onMaxChange={(value) => {
					handleFilterChange("maxHolders", value);
				}}
			/>
			<FilterInput
				label="Bonding Curve %"
				placeholderMin="Min"
				placeholderMax="Max"
				minValue={filterState?.minBondingCurve}
				maxValue={filterState?.maxBondingCurve}
				onMinChange={(value) => {
					handleFilterChange("minBondingCurve", value);
				}}
				onMaxChange={(value) => {
					handleFilterChange("maxBondingCurve", value);
				}}
			/>
			<FilterInput
				label="Volume"
				placeholderMin="Min"
				placeholderMax="Max"
				minValue={filterState?.minVolume}
				maxValue={filterState?.maxVolume}
				onMinChange={(value) => {
					handleFilterChange("minVolume", value);
				}}
				onMaxChange={(value) => {
					handleFilterChange("maxVolume", value);
				}}
			/>
		</Container>
	);
};

const Filters = ({ className }: BoxProps) => {
	const [isOpen, toggleOpen] = useState<boolean>(false);

	return (
		<Box className={cn("flex flex-col gap-6 px-4 py-8", className)}>
			<Typography size="2xl" weight="bold" className="max-lg:hidden">
				Filters
			</Typography>
			<Typography
				className="flex cursor-pointer font-bold lg:hidden"
				onClick={() => toggleOpen((prev) => !prev)}
			>
				Filters
				<ChevronDownIcon
					className={isOpen ? "rotate-180 transition-transform delay-100" : ""}
				/>
			</Typography>
			<Container
				className={cn(
					"flex flex-col gap-4",
					`${isOpen ? "" : "max-lg:hidden"}`,
				)}
			>
				<Container className="grid grid-cols-2 gap-4 lg:flex lg:flex-col">
					<TokenFilters />
					<Socials className="lg:hidden" />
				</Container>
				<Socials className="max-lg:hidden" />
			</Container>
		</Box>
	);
};

export default Filters;
