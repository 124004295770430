import { env } from "@/env";
import Cookies from "js-cookie";
import type { NextApiRequest } from "next";

export const NETWORK_COOKIE_ID = "chain-id";
export const WALLET_ACCOUNT_COOKIE_ID = "wallet-account";
export const setNetworkCookie = (networkId?: number | string) => {
	const chainId = networkId ?? env.NEXT_PUBLIC_CHAIN_ID;
	Cookies.set(NETWORK_COOKIE_ID, chainId.toString());
};

export const setWalletAccountCookie = (walletAccount: string) => {
	Cookies.set(WALLET_ACCOUNT_COOKIE_ID, `${walletAccount}`);
};
export const deleteWalletAccountCookie = () => {
	Cookies.remove(WALLET_ACCOUNT_COOKIE_ID);
};
export const deleteNetworkCookie = () => {
	Cookies.remove(NETWORK_COOKIE_ID);
};
export const getChainIdCookie = (req?: NextApiRequest): string => {
	return (
		(req?.cookies[NETWORK_COOKIE_ID] || Cookies.get(NETWORK_COOKIE_ID)) ??
		env.NEXT_PUBLIC_CHAIN_ID
	);
};

export const chainIdToBlockchainName = {
	"40": "telos",
	"43114": "avalanche-2",
};

export const chainIdToCoinGeckoId = {
	"40": "telos",
	"43114": "avalanche",
};

export const chainIdToName = {
	"40": "telos-mainnet",
	"41": "telos-testnet",
	"43113": "avalanche-testnet",
	"43114": "avalanche-mainnet",
};
