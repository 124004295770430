"use client";

import { Button, ClipboardIcon, Typography } from "@vaporfi/uikit";

import React, { useState } from "react";

export default function CopyToClipbard({
	content,
	trigger,
}: {
	content: string;
	trigger: string;
}) {
	const [isCopied, setIsCopied] = useState<boolean>(false);
	return (
		<Button
			variant="outline"
			onClick={() => {
				navigator?.clipboard?.writeText(content);
				setIsCopied(true);
			}}
			className="flex gap-1"
		>
			{isCopied ? (
				<Typography color="dark" className="transition-all delay-75 ease-in">
					Copied!
				</Typography>
			) : (
				<>
					<Typography className="text-dark">{trigger}</Typography>
					<ClipboardIcon className="ml-2 h-4 w-4 transition-all delay-300 ease-out" />
				</>
			)}
		</Button>
	);
}
