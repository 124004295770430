import memoize from "lodash/memoize";
import { TEN } from "./constants";

export const getFullDecimalMultiplier = memoize((decimals: number): bigint => {
	let result = BigInt(1);
	const base = TEN;
	for (let i = 0; i < decimals; i++) {
		result *= base;
	}
	return result;
});
