import { Box, Button, Typography } from "@vaporfi/uikit";
import { CreateTokenInfo } from "../../Info";
import type { ConfirmationFieldsProps } from "../../types";
import { CancelButton, FieldsContainer } from "./shared";

export const ConfirmationFields = ({
	connectWalletButton,
	isApproved,
	isConnected,
	isLiquidityBurn,
	isStratosphereWhitelist,
	lockDuration,
	onCancel,
	onLaunch,
	tokenApprovalButton,
}: ConfirmationFieldsProps) => {
	return (
		<>
			<FieldsContainer className="lg:flex lg:flex-col">
				<CreateTokenInfo
					lockDuration={lockDuration}
					isStratosphereWhitelist={isStratosphereWhitelist}
					isLiquidityBurn={isLiquidityBurn}
				/>

				<Box className="flex items-center justify-between">
					<CancelButton onCancel={onCancel} />
					{isConnected ? (
						isApproved ? (
							<Button type="submit" color="blue" size="base" onClick={onLaunch}>
								<Typography>Create Token</Typography>
							</Button>
						) : (
							tokenApprovalButton()
						)
					) : (
						connectWalletButton()
					)}
				</Box>
			</FieldsContainer>
		</>
	);
};
