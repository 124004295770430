import { Button, Modal, Typography } from "@vaporfi/uikit";
import type React from "react";
import { useCallback } from "react";

export enum WalletView {
	WALLET_INFO = 0,
	TRANSACTIONS = 1,
	WRONG_NETWORK = 2,
}

interface WalletModalViewProps {
	view: WalletView;
	setView: (view: WalletView) => void;
	renderWalletInfo: () => JSX.Element | null;
	renderTransactions: () => JSX.Element | null;
	renderWrongNetwork: () => JSX.Element | null;
	onDismiss: () => void;
}

export const WalletModalView: React.FC<WalletModalViewProps> = ({
	onDismiss,
	renderTransactions,
	renderWalletInfo,
	renderWrongNetwork,
	setView,
	view,
}) => {
	const renderTabButton = useCallback(
		(tabView: WalletView, label: string) => {
			const isActive = view === tabView;
			return (
				<div
					className={`flex-1 ${isActive ? "border-b-2 border-cyan-500" : ""}`}
					onClick={() => setView(tabView)}
				>
					<Button variant="outline" fullWidth={true}>
						<Typography
							color={isActive ? "light" : "neutral"}
							weight="semibold"
							size="sm"
						>
							{label}
						</Typography>
					</Button>
				</div>
			);
		},
		[setView, view],
	);

	return (
		<Modal
			title="Your Wallet"
			onDismiss={onDismiss}
			showCloseIcon
			className="border-cardBorder min-w-[378px] max-w-[400px] border"
			body={
				<>
					{view !== WalletView.WRONG_NETWORK && (
						<div className="mb-6 flex border-b border-gray-400">
							{renderTabButton(WalletView.WALLET_INFO, "Wallet")}
							{renderTabButton(WalletView.TRANSACTIONS, "Recent Transactions")}
						</div>
					)}
					{view === WalletView.WALLET_INFO && renderWalletInfo()}
					{view === WalletView.TRANSACTIONS && renderTransactions()}
					{view === WalletView.WRONG_NETWORK && renderWrongNetwork()}
				</>
			}
		/>
	);
};
