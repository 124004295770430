import { css } from "styled-components";
import { darkDeprecatedTheme, lightDeprecatedTheme } from "./deprecatedColors";
import { opacify } from "./utils";
import { colors } from "./v2colors";
export const MEDIA_WIDTHS = {
	deprecated_upToExtraSmall: 500,
	deprecated_upToSmall: 720,
	deprecated_upToMedium: 960,
	deprecated_upToLarge: 1280,
};

const deprecated_mediaWidthTemplates: {
	[width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((acc, size) => {
	acc[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
	return acc;
}, {} as any);

export const BREAKPOINTS = {
	xs: 396,
	sm: 640,
	md: 768,
	lg: 1024,
	navSearchInputVisible: 1100,
	xl: 1280,
	xxl: 1536,
	xxxl: 1920,
};

// deprecated - please use the ones in styles.ts file
const transitions = {
	duration: {
		slow: "500ms",
		medium: "250ms",
		fast: "125ms",
	},
	timing: {
		ease: "ease",
		in: "ease-in",
		out: "ease-out",
		inOut: "ease-in-out",
	},
};

const opacities = {
	hover: 0.6,
	click: 0.4,
	disabled: 0.5,
	enabled: 1,
};

const fonts = {
	code: "courier, courier new, serif",
};

export function getSettings(darkMode: boolean) {
	return {
		grids: {
			xs: "4px",
			sm: "8px",
			md: "12px",
			lg: "24px",
			xl: "32px",
		},
		fonts,

		// shadows
		shadow1: darkMode ? "#000" : "#2F80ED",

		// media queries
		deprecated_mediaWidth: deprecated_mediaWidthTemplates,

		navHeight: 72,
		mobileBottomBarHeight: 52,

		// deprecated - please use hardcoded exported values instead of
		// adding to the theme object
		breakpoint: BREAKPOINTS,
		transition: transitions,
		opacity: opacities,
	};
}

type Theme = typeof darkThemeUni;

const commonTheme = {
	white: colors.white,
	black: colors.black,

	chain_1: colors.networkEthereum,
	chain_3: colors.yellow400,
	chain_4: colors.pink400,
	chain_5: colors.green400,
	chain_10: colors.networkOptimism,
	chain_137: colors.networkPolygon,
	chain_42: colors.networkArbitrum,
	chain_56: colors.networkBsc,
	chain_420: colors.networkOptimism,
	chain_42161: colors.networkArbitrum,
	chain_421613: colors.networkArbitrum,
	chain_80001: colors.networkPolygon,
	chain_137_background: colors.purple900,
	chain_10_background: colors.red900,
	chain_42161_background: colors.blue900,
	chain_56_background: colors.networkBsc,

	brandedGradient: "linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);",
	promotionalGradient:
		"radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%);",

	hoverState: opacify(24, colors.blue200),
	hoverDefault: opacify(8, colors.gray300),
};

export const darkThemeUni = {
	...commonTheme,
	...getSettings(true),
	userThemeColor: colors.magentaVibrant,
	background: colors.gray800,
	backgroundBackdrop: colors.gray950,
	backgroundSurface: colors.gray900,
	backgroundModule: colors.gray800,
	backgroundInteractive: colors.gray700,
	backgroundFloating: opacify(12, colors.black),
	backgroundOutline: opacify(24, colors.gray300),
	backgroundScrim: opacify(72, colors.gray900),
	backgroundScrolledSurface: opacify(72, colors.gray900),
	textPrimary: colors.white,
	textSecondary: colors.gray300,
	textTertiary: colors.gray500,
	accentAction: colors.blue400,
	accentActive: colors.blue400,
	accentSuccess: colors.green200,
	accentWarning: colors.gold200,
	accentFailure: colors.red300,
	accentCritical: colors.red300,

	accentActionSoft: opacify(24, colors.blue400),
	accentActiveSoft: opacify(24, colors.blue400),
	accentSuccessSoft: opacify(24, colors.green400),
	accentWarningSoft: opacify(24, colors.gold200),
	accentFailureSoft: opacify(12, colors.red300),

	accentTextDarkPrimary: opacify(80, colors.gray900),
	accentTextDarkSecondary: opacify(60, colors.gray900),
	accentTextDarkTertiary: opacify(24, colors.gray900),

	accentTextLightPrimary: colors.gray50,
	accentTextLightSecondary: opacify(72, colors.gray50),
	accentTextLightTertiary: opacify(12, colors.gray50),

	deepShadow:
		"12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);",
	shallowShadow:
		"4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);",

	networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.blue400)}`,

	stateOverlayHover: opacify(8, colors.gray300),
	stateOverlayPressed: opacify(24, colors.gray200),

	searchBackground: "rgba(255,255,255,0.07)",
	searchOutline: "rgba(255,255,255,0.07)",
	...darkDeprecatedTheme,
};

export const lightThemeUni: Theme = {
	...commonTheme,
	...getSettings(false),
	userThemeColor: colors.magentaVibrant,
	background: "#faf9fa", //INTENTIONALLY OFF THEME TO GIVE WHITE BG A SOFTER VISUAL
	backgroundBackdrop: colors.white,
	backgroundSurface: colors.white,
	backgroundModule: colors.gray50,
	backgroundInteractive: colors.gray100,
	backgroundFloating: opacify(8, colors.gray700),
	backgroundOutline: colors.gray150,
	backgroundScrim: opacify(60, colors.gray900),
	backgroundScrolledSurface: opacify(72, colors.white),

	textPrimary: colors.gray900,
	textSecondary: colors.gray400,
	textTertiary: colors.gray300,

	accentAction: colors.pink400,
	accentActive: colors.blue400,
	accentSuccess: colors.green300,
	accentWarning: colors.gold400,
	accentFailure: colors.red400,
	accentCritical: colors.red400,

	accentActionSoft: opacify(12, colors.pink400),
	accentActiveSoft: opacify(24, colors.blue400),
	accentSuccessSoft: opacify(24, colors.green300),
	accentWarningSoft: opacify(24, colors.gold400),
	accentFailureSoft: opacify(12, colors.red400),

	accentTextDarkPrimary: opacify(80, colors.gray900),
	accentTextDarkSecondary: opacify(60, colors.gray900),
	accentTextDarkTertiary: opacify(24, colors.gray900),

	accentTextLightPrimary: colors.gray50,
	accentTextLightSecondary: opacify(72, colors.gray50),
	accentTextLightTertiary: opacify(12, colors.gray50),

	deepShadow:
		"8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02), 4px 4px 8px rgba(51, 53, 72, 0.04);",
	shallowShadow:
		"6px 6px 10px rgba(51, 53, 72, 0.01), 2px 2px 6px rgba(51, 53, 72, 0.02), 1px 2px 2px rgba(51, 53, 72, 0.02);",

	networkDefaultShadow: `0px 40px 120px ${opacify(12, colors.pink400)}`,

	stateOverlayHover: opacify(8, colors.gray300),
	stateOverlayPressed: opacify(24, colors.gray200),

	searchBackground: opacify(4, colors.white),
	searchOutline: opacify(1, colors.black),
	...lightDeprecatedTheme,
};
