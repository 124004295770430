import { FACTORY_ADDRESS_MAP, ROUTER_ADDRESS_MAP } from "@vapordex/sdk";
import { SupportedChainId } from "@vaporfi/utils";

const stratosphere = {
	rewardsController: {
		[SupportedChainId.AVALANCHE]: "0x2392716b86bE94c6EB996667742FA8fe71872cAb",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x2392716b86bE94c6EB996667742FA8fe71872cAb",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0xe2311878882F94e418f7c6eD1B7dC0EE8a93c304",
	},
	stratosphere: {
		[SupportedChainId.AVALANCHE]: "0x08e287adCf9BF6773a87e1a278aa9042BEF44b60",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x26b794235422e7c6f3ac6c717b10598C2a144203",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0x0c8ee8d6B056580E40eE4B21D210e0F9D77AfC59",
		[SupportedChainId.TELOS]: "0x08e287adCf9BF6773a87e1a278aa9042BEF44b60",
		[SupportedChainId.TELOS_TESTNET]:
			"0x8a43B6948244df8876763adD1f307eF3F6B140c6",
	},
	stratosphereAuthenticationController: {
		[SupportedChainId.AVALANCHE]: "0xBE10198DC8BA90a0b8427583bD745140aa4544CF",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x996C6894C0bD1Cf7a86B3E13cAaF444bd9A15c8C",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0x93d497d273B823eB771a226FbB1ea698292e030A",
		[SupportedChainId.TELOS]: "0xBE10198DC8BA90a0b8427583bD745140aa4544CF",
		[SupportedChainId.TELOS_TESTNET]:
			"0x2df465bA0Cc3161F9b87ddefd7FED4A9E0396Eb9",
	},
};

const sablierLinear = {
	[SupportedChainId.AVALANCHE]: "0xB24B65E015620455bB41deAAd4e1902f1Be9805f",
	[SupportedChainId.AVALANCHE_TESTNET]:
		"0xebf7ed508a0Bb1c4e66b9E6F8C6a73342E7049ac",
};
const tokenFactory = {
	[SupportedChainId.AVALANCHE]: "0x2606a3095540c49d509BAa8e47593f7C8d8f5f16",
	[SupportedChainId.AVALANCHE_TESTNET]:
		"0xd2730fF2D92A942cACF653e03F0d592DBDb9E3a0",
};
const v2Pools = {
	nftPositionMananger: {
		[SupportedChainId.AVALANCHE]: "0xC967b23826DdAB00d9AAd3702CbF5261B7Ed9a3a",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x7a0A7C4273B25b3a71Daeaa387c7855081AC4E56",
		[SupportedChainId.APECHAIN]: "0xC967b23826DdAB00d9AAd3702CbF5261B7Ed9a3a",
		[SupportedChainId.CURTIS]: "0xC967b23826DdAB00d9AAd3702CbF5261B7Ed9a3a",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0xdA92E2Fb76999c78b62Fd6435057a9C3edBE7B5a",
		[SupportedChainId.TELOS]: "0xC967b23826DdAB00d9AAd3702CbF5261B7Ed9a3a",
		[SupportedChainId.TELOS_TESTNET]:
			"0x8703A2Bbc875CE0e4DDDfe53Bfe0426E9Cd5FaA7",
	},
	tickLens: {
		[SupportedChainId.AVALANCHE]: "0xBb017b46375d510093c285D1e2b11D8c4EE9036F",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x60137f266c27616DAdDaaC9C3CC751710E5F4c8f",
		[SupportedChainId.APECHAIN]: "0xBb017b46375d510093c285D1e2b11D8c4EE9036F",
		[SupportedChainId.CURTIS]: "0xBb017b46375d510093c285D1e2b11D8c4EE9036F",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0xDd6483D2Aba8d45c6b2ecffc1D14C7778809d068",
		[SupportedChainId.TELOS]: "0xBb017b46375d510093c285D1e2b11D8c4EE9036F",
		[SupportedChainId.TELOS_TESTNET]:
			"0x1e34a0fFe4316A2a542DcE655024ac383dDb027d",
	},
	v2FactoryAddress: {
		[SupportedChainId.AVALANCHE]: "0x62B672E531f8c11391019F6fba0b8B6143504169",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0xF292f18eba8bDB337A7D7b47a58Be72eDdB37Db8",
		[SupportedChainId.APECHAIN]: "0x62B672E531f8c11391019F6fba0b8B6143504169",
		[SupportedChainId.CURTIS]: "0x62B672E531f8c11391019F6fba0b8B6143504169",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0x8a43B6948244df8876763adD1f307eF3F6B140c6",
		[SupportedChainId.TELOS]: "0x62B672E531f8c11391019F6fba0b8B6143504169",
		[SupportedChainId.TELOS_TESTNET]:
			"0x36bDeB542B7c0Da5961c2EB4Efad2C9F6Ac2aE6c",
	},
	v2MigratorAddress: {
		[SupportedChainId.AVALANCHE]: "0xEA3379a026cb3E6c5aC847f8E24e523c762b0C6E",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x6b38444313F69aFBad39fA83129409bbb610a002",
		[SupportedChainId.APECHAIN]: "0xEA3379a026cb3E6c5aC847f8E24e523c762b0C6E",
		[SupportedChainId.CURTIS]: "0xEA3379a026cb3E6c5aC847f8E24e523c762b0C6E",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0x08AA0F4e62C73eC62213C6A907D877a21869580a",
		[SupportedChainId.TELOS]: "0xEA3379a026cb3E6c5aC847f8E24e523c762b0C6E",
		[SupportedChainId.TELOS_TESTNET]:
			"0xa814C213bB2452a5A16Ba0C0B7c423Ea40B99A2B",
	},
};

const v1Pools = {
	v1FactoryAddress: FACTORY_ADDRESS_MAP,
	v1RouterAddress: ROUTER_ADDRESS_MAP,
};

const addresses = {
	...v1Pools,
	...v2Pools,
	...stratosphere,
	aggregatorRouter: {
		[SupportedChainId.AVALANCHE]: "0xDef9ee39FD82ee57a1b789Bc877E2Cbd88fd5caE",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x184eaB8D97cE56Cf77e2571e8f1D6F697076a831",
	},
	aggregatorRouterV2: {
		[SupportedChainId.AVALANCHE]: "0x55477d8537ede381784b448876AfAa98aa450E63",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x55477d8537ede381784b448876AfAa98aa450E63",
		[SupportedChainId.CURTIS]: "0xfb459325787db6CE6B4E7aFeFF75BB25d13b1233",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0xAF5A5C4E30Ac3CA67D24A23CBf131950eD2065F5",
		[SupportedChainId.TELOS]: "0x55477d8537ede381784b448876AfAa98aa450E63",
		[SupportedChainId.TELOS_TESTNET]:
			"0x55477d8537ede381784b448876AfAa98aa450E63",
		[SupportedChainId.APECHAIN]: "0x55477d8537ede381784b448876AfAa98aa450E63",
	},
	genesisExchange: {
		[SupportedChainId.AVALANCHE]: "0xD21Fe537C97054f40890f012955A536d80D1Bf00",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x45Da22502ea7601E3A64eAe6657411cd7a32aE8D",
	},
	liquidMining: {
		[SupportedChainId.AVALANCHE]: "0xAe950fdd0CC79DDE64d3Fffd40fabec3f7ba368B",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0xEd98549D4dE52811b3b417A717E3d74AC90F9Ffe",
	},
	multiCall: {
		[SupportedChainId.AVALANCHE]: "0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x758c9F170A9c306EDfCC5cd7A7A5134757b5eAce",
		[SupportedChainId.APECHAIN]: "0xAb051074eb3540E56636Ab18a6d6FC86d4735FB6",
		[SupportedChainId.CURTIS]: "0x1A0D0A52CBED45C25068CA3d8BdB2152838FccDC",
		[SupportedChainId.SKALE_EUROPA_TESTNET]:
			"0x1A0D0A52CBED45C25068CA3d8BdB2152838FccDC",
		[SupportedChainId.TELOS]: "0xf070654b08595f8F358Ff90170829892F3254C67",
		[SupportedChainId.TELOS_TESTNET]:
			"0x1A0D0A52CBED45C25068CA3d8BdB2152838FccDC",
	},
	nodeController: {
		[SupportedChainId.AVALANCHE]: "0xCd5E168dA3456cD2d5A8ab400f9cebdDC453720d",
	},
	oldVAPE: {
		[SupportedChainId.AVALANCHE]: "0x0DB60699E7bE209cce1a43829e2Dd3a50E2B51c3",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x399f2b451361d6f56f0845DC6C27AD929Bd185d0",
	},
	sablierLinear,
	tokenFactory,
	vapeEmissionsManager: {
		[SupportedChainId.AVALANCHE]: "0x9f0EDB45c2DC0f56bA7C48368c26426f366Bb788",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x5f0B48D82AdF039DBc0eb007C9e4bdb2E7dDCd55",
	},
	vapeToken: {
		[SupportedChainId.AVALANCHE]: "0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0xbA3136bE37807f46849a549a1733178A7A25803F",
	},
	vaporDexAggregatorAdapter: {
		[SupportedChainId.AVALANCHE]: "0x01e5C45cB25E30860c2Fb80369A9C27628911a2b",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x3F1aF4D92c91511A0BCe4B21bc256bF63bcab470",
	},
	vaporNodesDiamond: {
		[SupportedChainId.AVALANCHE]: "0x0efC8Ef83d7318121449e9c5dbDF7135Bcc1fA90",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0xE2a8B8f738168a75d8B15b0192324599b3f02868",
	},
	xVAPE: {
		[SupportedChainId.AVALANCHE]: "0x1C9Cba0CEc8aD45f75D5F5bdb0c539AcB55B8D94",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0xe80f20830B6713c4C25fD99b63f23d76079062B7",
	},
	xvapeReplenishmentPool: {
		[SupportedChainId.AVALANCHE]: "0x0fA2CCC39Cc3B225A7649eD84ec76Ee5217d07c4",
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x0fA2CCC39Cc3B225A7649eD84ec76Ee5217d07c4", //this is c chain address
	},
	zap: {
		[SupportedChainId.AVALANCHE]: "", //wut
		[SupportedChainId.AVALANCHE_TESTNET]:
			"0x607ee9DcBaD7d62c29D07e258Be93fd5a902Bf00",
	},
};

export default addresses;
