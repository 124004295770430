import { ChainId, ChainKey } from "@vapordex/sdk";
import type { Chain } from "viem";

export const curtis = {
	blockExplorers: {
		default: {
			name: "Curtis Explorer",
			url: "https://curtis.explorer.caldera.xyz",
		},
	},
	contracts: {
		multicall3: {
			address: "0x0852B26500Bf3625a1538FE22E245B9CCd826fbe",
			blockCreated: 7_266_087,
		},
	},
	id: ChainId.CURTIS,
	name: "Curtis",
	nativeCurrency: {
		decimals: 18,
		name: "ApeCoin",
		symbol: "APE",
	},
	network: ChainKey.CURTIS,
	rpcUrls: {
		default: { http: ["https://curtis.rpc.caldera.xyz/http"] },
		public: { http: ["https://curtis.rpc.caldera.xyz/http"] },
	} as const,
	testnet: true,
} as Chain;
