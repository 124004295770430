import {
	Avatar,
	Box,
	Button,
	ChevronDownIcon,
	Container,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	TwitterXIcon,
	Typography,
	WebsiteIcon,
} from "@vaporfi/uikit";
import { DiscordIcon, TelegramIcon } from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import Link from "next/link";
import React, { memo, useMemo } from "react";
import { tv } from "tailwind-variants";
import { BlankRow } from "../Stats";
import type { RecentLaunchesItemProps, RecentLaunchesProps } from "./types";

const recentLaunchesStyles = tv({
	slots: {
		itemContainer:
			"grid w-full gap-7 auto-cols-[200px] grid-flow-col items-center border-b border-b-neutral-800 p-4 w-[1372.67px]",
	},
});

export interface LaunchListProps
	extends RecentLaunchesProps,
		Pick<
			RecentLaunchesItemProps,
			"onUpdateSocialsClick" | "onUnlockLiquidityClick"
		> {
	title: string;
	isSkeleton: boolean;
	emptyListMessage: string;
	isRecentLaunches: boolean;
}
export const LaunchList = ({
	emptyListMessage,
	isRecentLaunches,
	isSkeleton,
	items,
	onUnlockLiquidityClick,
	onUpdateSocialsClick,
	title,
}: LaunchListProps) => {
	return (
		<Container stack="column" fullWidth>
			<Typography className="font-manverse" size="2xl">
				{title}
			</Typography>
			<Container stack="column" fullWidth className="overflow-x-auto">
				<ItemHeader isRecentLaunches={isRecentLaunches} />
				{isSkeleton ? (
					<Typography className="ml-4">
						{emptyListMessage || "No launches"}
					</Typography>
				) : (
					items.map((props, index) => (
						<Item
							{...props}
							key={`token-factory-${title}-list-${props.id}`}
							onUpdateSocialsClick={onUpdateSocialsClick}
							onUnlockLiquidityClick={onUnlockLiquidityClick}
						/>
					))
				)}
			</Container>
		</Container>
	);
};

export const MyTokens = ({
	items,
	onUnlockLiquidityClick,
	onUpdateSocialsClick,
}: RecentLaunchesProps &
	Pick<
		RecentLaunchesItemProps,
		"onUpdateSocialsClick" | "onUnlockLiquidityClick"
	>) => {
	const isSkeleton = useMemo(() => {
		return !items || items?.length === 0;
	}, [items]);
	return (
		<LaunchList
			title="MY TOKENS"
			isSkeleton={isSkeleton}
			items={items}
			emptyListMessage="No tokens"
			onUpdateSocialsClick={onUpdateSocialsClick}
			onUnlockLiquidityClick={onUnlockLiquidityClick}
			isRecentLaunches={false}
		/>
	);
};

export const UpcomingLaunches = ({ items }: RecentLaunchesProps) => {
	const isSkeleton = useMemo(() => {
		return !items || items?.length === 0;
	}, [items]);
	return (
		<LaunchList
			title="UPCOMING LAUNCHES"
			isSkeleton={isSkeleton}
			items={items}
			emptyListMessage="No upcoming launches"
			isRecentLaunches={false}
		/>
	);
};

export const RecentLaunches = ({ items }: RecentLaunchesProps) => {
	const isSkeleton = useMemo(() => {
		return !items || items?.length === 0;
	}, [items]);
	return (
		<LaunchList
			title="RECENT LAUNCHES"
			isSkeleton={isSkeleton}
			items={items}
			isRecentLaunches
			emptyListMessage="No recent launches"
		/>
	);
};

export const BlankItem = () => {
	const { itemContainer } = recentLaunchesStyles();
	return (
		<Box className={itemContainer()}>
			<BlankRow />
			<Box className="flex justify-center">
				<Avatar src={""} alt={`meme-factory-leaderboard-${name}`} size={38} />
			</Box>
			<BlankRow />
			<BlankRow />

			<Button color="gradient" size="sm" className="w-fit">
				<Typography>Swap</Typography>
			</Button>
		</Box>
	);
};
export const ItemHeader = ({
	className,
	isRecentLaunches,
	...props
}: {
	className?: string;
	isRecentLaunches: boolean;
}) => {
	const { itemContainer } = recentLaunchesStyles();
	return (
		<Box
			{...props}
			className={itemContainer({ className: cn("-mb-2", className) })}
		>
			{[
				isRecentLaunches ? "Date" : "Date (In your timezone)",
				"Token",
				"Socials",
				"Stratosphere whitelist?",
				"Liquidity",
			].map((label, index) => (
				<Typography
					key={`token-factory-item-${index}-${label}`}
					weight="semibold"
					color="neutral"
				>
					{label}
				</Typography>
			))}
		</Box>
	);
};
export const Item = memo(
	({
		address,
		className,
		id,
		is_lp_burned,
		launchDate,
		liquidityHref,
		logoURI,
		name,
		onUnlockLiquidityClick,
		onUpdateSocialsClick,
		socials_discord_url,
		socials_telegram_url,
		socials_x_url,
		swapHref,
		symbol,
		tokenExplorerUrl,
		website,
		...props
	}: RecentLaunchesItemProps) => {
		const { itemContainer } = recentLaunchesStyles();
		const isNoSocials = useMemo(
			() =>
				!socials_discord_url &&
				!socials_telegram_url &&
				!socials_x_url &&
				!website,
			[socials_discord_url, socials_telegram_url, socials_x_url, website],
		);

		const updateSocialsProps = useMemo(
			() => ({
				id,
				onUpdateSocialsClick,
				socials_discord_url,
				socials_telegram_url,
				socials_x_url,
				website,
			}),
			[
				id,
				onUpdateSocialsClick,
				socials_discord_url,
				socials_telegram_url,
				socials_x_url,
				website,
			],
		);
		return (
			<Box {...props} className={itemContainer(className)}>
				<Typography color="neutral" className="w-[190px] pr-2">
					{launchDate}
				</Typography>

				<Box className="flex gap-4">
					<Avatar
						src={logoURI || ""}
						alt={`token-factory-leaderboard-${name}`}
						size={38}
					/>
					<Box>
						{tokenExplorerUrl ? (
							<Link href={tokenExplorerUrl} target="_blank">
								<Typography weight="semibold" className="cursor-pointer">
									{name}
								</Typography>
							</Link>
						) : (
							<Typography weight="semibold">{name || "-"}</Typography>
						)}
						<Typography weight="semibold" size="sm" color="neutral">
							({symbol})
						</Typography>
					</Box>
				</Box>
				<Box className="flex items-center gap-4">
					{isNoSocials ? (
						<Typography>None</Typography>
					) : (
						<>
							{website && (
								<Link href={website} target="_blank">
									<WebsiteIcon />
								</Link>
							)}
							{socials_x_url && (
								<Link
									href={socials_x_url}
									target="_blank"
									className="max-h-8 max-w-[32px]"
								>
									<TwitterXIcon />
								</Link>
							)}

							{socials_discord_url && (
								<Link
									href={socials_discord_url}
									target="_blank"
									className="max-h-8 max-w-[32px]"
								>
									<DiscordIcon />
								</Link>
							)}
							{socials_telegram_url && (
								<Link
									href={socials_telegram_url}
									target="_blank"
									className="max-h-8 max-w-[32px]"
								>
									<TelegramIcon />
								</Link>
							)}
						</>
					)}
				</Box>
				<Typography weight="semibold">Yes</Typography>
				<Typography weight="semibold">
					{is_lp_burned ? "Burned" : "Locked"}
				</Typography>

				<DropdownMenuButton
					{...{
						address,
						is_lp_burned,
						liquidityHref,
						onUnlockLiquidityClick,
						onUpdateSocialsClick,
						swapHref,
						updateSocialsProps,
					}}
				/>
			</Box>
		);
	},
);

export function DropdownMenuButton({
	address,
	is_lp_burned,
	liquidityHref,
	onUnlockLiquidityClick,
	onUpdateSocialsClick,
	swapHref,
	updateSocialsProps,
}: Pick<
	RecentLaunchesItemProps,
	| "swapHref"
	| "liquidityHref"
	| "onUpdateSocialsClick"
	| "onUnlockLiquidityClick"
	| "is_lp_burned"
	| "address"
> & { updateSocialsProps: any }) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button color="blue" size="sm" className="w-fit">
					<Typography>Action</Typography>
					<ChevronDownIcon />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<DropdownMenuItem>
					{swapHref && (
						<Link href={swapHref} target="_blank">
							<Typography className="hover:text-turquoise-500">Swap</Typography>
						</Link>
					)}
				</DropdownMenuItem>
				<DropdownMenuItem>
					{liquidityHref && (
						<Link href={liquidityHref} target="_blank">
							<Typography className="hover:text-turquoise-500">
								Liquidity
							</Typography>
						</Link>
					)}
				</DropdownMenuItem>

				{!is_lp_burned && onUnlockLiquidityClick && (
					<DropdownMenuItem>
						<Typography
							className="hover:text-turquoise-500"
							onClick={() => onUnlockLiquidityClick({ address })}
						>
							Unlock Liquidity
						</Typography>
					</DropdownMenuItem>
				)}

				{onUpdateSocialsClick && (
					<DropdownMenuItem>
						<Typography
							className="hover:text-turquoise-500"
							onClick={() => onUpdateSocialsClick(updateSocialsProps)}
						>
							Update Socials
						</Typography>
					</DropdownMenuItem>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
