import type React from "react";
import { type VariantProps, tv } from "tailwind-variants";

const copyrightStyles = tv({
	base: "text-center text-gray-300",
});

type CopyrightVariants = VariantProps<typeof copyrightStyles>;

interface CopyrightProps extends CopyrightVariants {
	className?: string;
}

export const Copyright: React.FC<CopyrightProps> = ({ className }) => {
	return (
		<div className={copyrightStyles({ class: className })}>
			© VaporFi {new Date().getFullYear()}
		</div>
	);
};
