import type {
	Breakpoints,
	MediaQueries,
	Radii,
	Shadows,
	Spacing,
	ZIndices,
} from "./types";

export const breakpointMap: { [key: string]: number } = {
	xs: 370,
	sm: 576,
	md: 852,
	lg: 968,
	xl: 1080,
	xxl: 1200,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map(
	(breakpoint) => `${breakpoint}px`,
);

const mediaQueries: MediaQueries = {
	xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
	sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
	md: `@media screen and (min-width: ${breakpointMap.md}px)`,
	lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
	xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
	xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
	nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
};

export const shadows: Shadows = {
	level1:
		"0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)",
	active: "0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)",
	success: "0px 0px 0px 1px #1BCCB2, 0px 0px 0px 4px rgba(27, 204, 178, 0.2)",
	warning: "0px 0px 0px 1px #D40E0E, 0px 0px 0px 4px rgba(212, 14, 14, 0.2)",
	focus: "0px 0px 0px 1px #1060F0, 0px 0px 0px 4px rgba(16, 96, 240, 0.6)",
	inset: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
	tooltip:
		"0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1)",
};

const spacing: Spacing = [0, 4, 8, 16, 24, 32, 48, 64];

const radii: Radii = {
	small: "4px",
	default: "16px",
	card: "10px",
	circle: "50%",
};

const zIndices: ZIndices = {
	ribbon: 9,
	dropdown: 10,
	modal: 100,
};

export default {
	siteWidth: 1200,
	breakpoints,
	mediaQueries,
	spacing,
	shadows,
	radii,
	zIndices,
};
