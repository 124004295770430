import type React from "react";
import { CopyButton } from "../../CopyButton";

interface CopyAddressProps {
	account: string;
	className?: string;
}

const CopyAddress: React.FC<CopyAddressProps> = ({ account, className }) => {
	return (
		<div className={`relative ${className}`}>
			<div className="relative flex items-center rounded-md bg-neutral-900">
				<div
					className="relative flex-1 pl-1"
					data-testid="copy-address-address"
					title={account}
				>
					<input
						className="block w-full border-0 bg-transparent p-0 text-sm font-normal text-gray-300 focus:outline-none"
						type="text"
						readOnly
						value={account}
					/>
				</div>
				<div className="m-2">
					<CopyButton
						data-testid="copy-address-button"
						width="16px"
						buttonColor="white"
						text={account}
						tooltipMessage={"Copied"}
						tooltipTop={-40}
					/>
				</div>
			</div>
		</div>
	);
};

export default CopyAddress;
