"use client";
import type { MoarCandyToken } from "@vaporfi/db";
import {
	Box,
	Button,
	Container,
	TextArea,
	Typography,
	Modal as UikitModal,
} from "@vaporfi/uikit";
import React, { memo, useCallback, useState } from "react";
import type { Address } from "viem";

export const PostCommentModal = memo(
	({
		account,
		onCreate,
		onDismiss,
		token,
	}: {
		account: Address;
		onCreate: (formData: FormData) => Promise<void>;
		onDismiss?: () => void;
		token: MoarCandyToken;
	}) => {
		const [text, setText] = useState<string>();

		const onSubmit = useCallback(() => {
			if (!text || !account || !token) return;
			const formData = new FormData();
			formData.set("thread", text);
			formData.set("author", account);
			formData.set("token", token?.address?.toLowerCase());

			return onCreate(formData).finally(() => {
				onDismiss?.();
			});
		}, [text, account, token, onCreate, onDismiss]);
		return (
			<UikitModal
				title="Post a delicious comment."
				color="neutral"
				body={
					<Container fullWidth className="mt-4 gap-4" stack="column">
						<TextArea
							id="thread"
							value={text}
							border="purple"
							text="dark"
							onChange={({ target }) => setText(target?.value)}
						/>
						<Box className="flex items-center justify-between">
							<Button size="base" onClick={onDismiss}>
								<Typography className="text-dark">Cancel</Typography>
							</Button>
							<Button
								color="purple"
								size="base"
								variant="squared"
								onClick={onSubmit}
							>
								<Typography className="text-dark">Submit</Typography>
							</Button>
						</Box>
					</Container>
				}
				onDismiss={onDismiss}
			/>
		);
	},
);
