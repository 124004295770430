import {
	Avatar,
	Button,
	Grid,
	type GridProps,
	Input,
	PencilIcon,
	Typography,
} from "@vaporfi/uikit";

import {
	STRATOSPHERE_MEMBER_MANAGE_WALLETS_URL,
	areAllTruthy,
} from "@vaporfi/utils";
import Link from "next/link";
import { useMemo } from "react";
import { type VariantProps, tv } from "tailwind-variants";

const profileStyles = tv({
	slots: {
		avatarInputContainer: "flex gap-7",
		fileContainer: "p-1 gap-2 flex flex-col content-center",
		fileSelector: "hidden",
		iconButton:
			"bg-light item-center absolute flex h-8 w-8 translate-x-12 justify-center rounded-full p-1",
		label: "flex relative",
		pencilIcon: "z-1 h-5 w-5 cursor-pointer",
		uploadButton: "self-end w-fit",
		wrapper: "bg-black rounded-[10px] p-4",
	},
});
export interface ProfileProps
	extends VariantProps<typeof profileStyles>,
		GridProps {
	isAvatarManagementEnabled?: boolean;
	isUploaded?: boolean;
	onFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onUpload?: () => void;
	selectedFile?: any;
	tokenId?: number | string;
	validAvatar?: string;
	isReadOnly?: boolean;
}
export const Profile = ({
	isAvatarManagementEnabled,
	isReadOnly = false,
	isUploaded,
	onFileSelect,
	onUpload,
	selectedFile,
	tokenId,
	validAvatar,
}: ProfileProps) => {
	const {
		avatarInputContainer,
		fileContainer,
		fileSelector,
		iconButton,
		label,
		pencilIcon,
		uploadButton,
		wrapper,
	} = profileStyles();
	const isValidUpload = useMemo(
		() =>
			areAllTruthy(
				isAvatarManagementEnabled,
				!isUploaded,
				selectedFile,
				!isReadOnly,
			),
		[isAvatarManagementEnabled, isReadOnly, isUploaded, selectedFile],
	);

	return (
		<Grid className={wrapper()}>
			{!isReadOnly && (
				<Typography size="base" weight="semibold">
					Your Profile
				</Typography>
			)}

			<div className={avatarInputContainer()}>
				<div className={fileContainer()}>
					<label htmlFor="avatar-upload" className={label()}>
						<Avatar
							key={`${validAvatar}-${selectedFile?.name}`}
							src={validAvatar || ""}
							alt={"Discount swap fees"}
						/>
						{isAvatarManagementEnabled && !isReadOnly && (
							<div className={iconButton()}>
								<PencilIcon fill="black" className={pencilIcon()} />
							</div>
						)}
					</label>
					{isAvatarManagementEnabled && !isReadOnly && (
						<Input
							id="avatar-upload"
							type="file"
							accept="image/*"
							onChange={isAvatarManagementEnabled ? onFileSelect : () => null}
							className={fileSelector()}
						/>
					)}
					{isValidUpload && (
						<Button
							variant="outline"
							color="light"
							size="sm"
							onClick={isAvatarManagementEnabled ? onUpload : () => null}
							className={uploadButton()}
						>
							<Typography>Confirm</Typography>
						</Button>
					)}
				</div>

				<div>
					<Typography
						size="xs"
						color="neutral"
						weight="semibold"
						className="mb-2"
					>
						{"Stratosphere ID"}
					</Typography>
					<Input
						disabled
						value={tokenId}
						style={{
							background: "#171717",
							textAlign: "start",
							width: "100%",
						}}
					/>
					{isReadOnly && (
						<Link href={STRATOSPHERE_MEMBER_MANAGE_WALLETS_URL} prefetch>
							<Typography size="sm" color="turquoise" className="mt-1">
								Edit profile and wallets
							</Typography>
						</Link>
					)}
				</div>
			</div>
		</Grid>
	);
};
