export const SkaleEuropaTestnet = [
	{
		address: "0x6CE77Fc7970F6984eF3E8748A3826972Ec409Fb9",
		chainId: 1_444_673_419,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo_24.png",
		name: "USDC Coin",
		symbol: "USDC",
	},
	{
		address: "0xbEE0FB0C095405A17c079Cd5C3cc89525e5A9a8C",
		chainId: 1_444_673_419,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/1444673419/0xbEE0FB0C095405A17c079Cd5C3cc89525e5A9a8C/logo.png",
		name: "Pax Dollar",
		symbol: "USDP",
	},
	{
		address: "0xbA05e3C8033705017eA734F4041fCcE7f5D43271",
		chainId: 1_444_673_419,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/10cbe74c-6b8d-4c80-931b-49e582cc6699.png",
		name: "Europa Wrapped SKL",
		symbol: "wSKL",
	},
] as const;
