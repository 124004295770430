"use client";

import { cn } from "@vaporfi/utils";
import type React from "react";
import type { ComponentProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import {
	Select as RadixSelect,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./shadcn";

const selectStyles = tv({
	compoundVariants: [
		{ class: { item: "bg-blue-500" }, isSelected: true, theme: "dark" },
		{ class: { item: "bg-purple-3" }, isSelected: true, theme: "light" },
	],
	defaultVariants: { theme: "dark" },
	slots: {
		content: "z-[150] rounded-md shadow-lg outline-none",
		item: "cursor-pointer p-2",
		trigger: "",
	},
	variants: {
		isSelected: { true: {} },
		theme: {
			dark: {
				content: "bg-neutral-700",
				item: "hover:bg-blue-500 text-white",
				trigger: "bg-neutral-900 text-light",
			},
			light: {
				content: "bg-neutral-100",
				item: "hover:bg-purple-4 text-dark",
				trigger: "bg-white text-dark placeholder:text-dark",
			},
		},
	},
});

type SelectVariants = VariantProps<typeof selectStyles>;

export interface Option {
	label: string;
	value: string;
}

export interface SelectDropdownProps
	extends SelectVariants,
		ComponentProps<typeof RadixSelect> {
	label?: string;
	value?: string;
	defaultValue?: string;
	options: Array<Option>;
	icon?: React.ReactNode;
	includeLabelAsOption?: boolean;
	onSelect?: (value: React.Key) => void;
	selectedValue?: React.Key;
	className?: string;
}

export const Select: React.FC<SelectDropdownProps> = ({
	className,
	icon,
	includeLabelAsOption = false,
	label,
	onSelect,
	options,
	theme,
	value,
	...rest
}) => {
	const enhancedOptions =
		includeLabelAsOption && label
			? [{ label: label, value: "label-option" }, ...options]
			: options;

	const { content, item, trigger } = selectStyles({ theme });
	return (
		<RadixSelect onValueChange={onSelect} value={value} {...rest}>
			<SelectTrigger
				className={cn(trigger(), className)}
				aria-label={label || "Select option"}
				icon={icon}
			>
				<SelectValue
					placeholder={label || "Select option"}
					className="placeholder:text-dark"
				/>
			</SelectTrigger>

			<SelectContent className={content()}>
				{enhancedOptions.map((option) => (
					<SelectItem
						key={option.value}
						value={String(option.value)}
						className={cn(item({ isSelected: option.value === value }))}
					>
						{option.label}
					</SelectItem>
				))}
			</SelectContent>
		</RadixSelect>
	);
};
