import styled from "styled-components";
import Button from "./Button";
import type { BaseButtonProps } from "./types";

const IconButton = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ scale }) => (scale === "sm" ? "32px" : "48px")};
`;

export default IconButton;
