"use client";
import { useModal } from "@vaporfi/hooks";
import {
	Box,
	Button,
	Calendar,
	Container,
	Modal,
	Typography,
} from "@vaporfi/uikit";
import { dateToAMOrPM } from "@vaporfi/utils";
import {
	type ChangeEventHandler,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";

const setTimeWithDate = (date: Date | undefined, time: string) => {
	if (!date) return null;

	const [hours, minutes] = time.split(":");
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		Number(hours),
		Number(minutes),
	);
};

const DatePickerModal = memo(
	({
		handleDaySelect,
		handleTimeChange,
		onConfirm,
		onDismiss,
		selected,
		timeValue,
		...rest
	}: {
		handleDaySelect: (date: Date | undefined) => void;
		handleTimeChange: ChangeEventHandler<HTMLInputElement>;
		onConfirm: () => void;
		onDismiss?: () => void;
		selected: Date | undefined;
		timeValue: string;
	}) => {
		return (
			<Modal
				title="Select launch date and time"
				body={
					<Container stack="column" className="p-4">
						<Calendar
							{...rest}
							/* @ts-expect-error:DIX */
							selected={selected}
							onSelect={handleDaySelect}
							footer={
								<Box className="mt-2 flex w-full justify-center">
									<input
										type="time"
										value={timeValue}
										onChange={handleTimeChange}
										className="bg-turquoise-500 m-auto w-fit cursor-pointer appearance-none rounded-md border px-4 py-2 leading-tight text-black"
									/>
								</Box>
							}
						/>
						<Box className="flex justify-between">
							<Button color="red" size="sm" onClick={onDismiss}>
								<Typography>Cancel</Typography>
							</Button>
							<Button
								color="blue"
								size="sm"
								onClick={() => {
									onConfirm();
									onDismiss?.();
								}}
							>
								<Typography>Confirm</Typography>
							</Button>
						</Box>
					</Container>
				}
				showCloseIcon
				onDismiss={onDismiss}
			/>
		);
	},
);

export function DatePicker({
	onChange,
	value,
	...rest
}: {
	value: number;
	onChange: (newDate: number | undefined) => void;
}) {
	const [selected, setSelected] = useState<Date>();
	const [timeValue, setTimeValue] = useState<string>("00:00");

	const onConfirm = useCallback(
		() => onChange(selected?.getTime?.()),
		[onChange, selected],
	);

	const handleTimeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			const time = e.target.value;

			if (!selected) {
				const currentDate = value ? new Date(value) : new Date();
				const newSelectedDate = setTimeWithDate(currentDate, time);
				if (newSelectedDate) {
					setTimeValue(time);
					setSelected(newSelectedDate);
				}
				return;
			}

			const newSelectedDate = setTimeWithDate(selected, time);
			if (newSelectedDate) {
				setTimeValue(time);
				setSelected(newSelectedDate);
			}
		},
		[selected, value],
	);

	const handleDaySelect = useCallback(
		(date: Date | undefined) => {
			if (!timeValue || !date) {
				setSelected(date as Date);
				return;
			}

			const newDate = setTimeWithDate(date, timeValue);
			if (newDate) {
				setSelected(newDate);
			}
		},
		[setSelected, timeValue],
	);

	const [onCalenderModal] = useModal(
		<DatePickerModal
			{...{
				...rest,
				handleDaySelect,
				handleTimeChange,
				onConfirm,
				selected,
				timeValue,
			}}
		/>,
		true,
		true,
		"meme-factory-date-time-picker",
	);

	const dateTime = useMemo(() => {
		if (selected) {
			return `${selected?.toLocaleDateString()} ${dateToAMOrPM(selected)}`;
		}
		if (value) {
			const currentDate = new Date(value);
			return `${currentDate?.toLocaleDateString()} ${dateToAMOrPM(
				currentDate,
			)}`;
		}
		return "--/--/-- 00:00 AM";
	}, [selected, value]);
	return (
		<Box
			className="justify-start rounded-none bg-neutral-900 p-1 text-left"
			fullWidth
			onClick={onCalenderModal}
		>
			<Typography>{dateTime}</Typography>
		</Box>
	);
}
