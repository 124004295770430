import type { Order } from "@gelatonetwork/limit-orders-lib";
import { createReducer } from "@reduxjs/toolkit";
import {
	confirmOrderCancellation,
	confirmOrderSubmission,
	saveOrder,
} from "utils/localStorageOrders";
/* eslint-disable no-param-reassign */
import { resetUserState } from "../global/actions";
import {
	type SerializableTransactionReceipt,
	type TransactionType,
	addTransaction,
	checkedTransaction,
	clearAllTransactions,
	finalizeTransaction,
} from "./actions";

const now = () => Date.now();

export interface TransactionDetails {
	hash: string;
	approval?: { tokenAddress: string; spender: string };
	type?: TransactionType;
	order?: Order;
	summary?: string;
	claim?: { recipient: string };
	receipt?: SerializableTransactionReceipt;
	lastCheckedBlockNumber?: number;
	addedTime: number;
	confirmedTime?: number;
	from: string;
}

export interface TransactionState {
	[chainId: number]: {
		[txHash: string]: TransactionDetails;
	};
}

export const initialState: TransactionState = {};

export default createReducer(initialState, (builder) =>
	builder
		.addCase(
			addTransaction,
			(
				transactions,
				{
					payload: {
						approval,
						chainId,
						claim,
						from,
						hash,
						order,
						summary,
						type,
					},
				},
			) => {
				if (transactions[chainId]?.[hash]) {
					throw new Error("Attempted to add existing transaction.");
				}
				const txs = transactions[chainId] ?? {};
				txs[hash] = {
					addedTime: now(),
					approval,
					claim,
					from,
					hash,
					order,
					summary,
					type,
				};
				transactions[chainId] = txs;
				if (order) saveOrder(chainId, from, order, true);
			},
		)
		.addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
			if (!transactions[chainId]) return;
			transactions[chainId] = {};
		})
		.addCase(
			checkedTransaction,
			(transactions, { payload: { blockNumber, chainId, hash } }) => {
				const tx = transactions[chainId]?.[hash];
				if (!tx) {
					return;
				}
				tx.lastCheckedBlockNumber = tx.lastCheckedBlockNumber
					? Math.max(blockNumber, tx.lastCheckedBlockNumber)
					: blockNumber;
			},
		)
		.addCase(
			finalizeTransaction,
			(transactions, { payload: { chainId, hash, receipt } }) => {
				const tx = transactions[chainId]?.[hash];
				if (!tx) {
					return;
				}
				tx.receipt = receipt;
				tx.confirmedTime = now();

				if (transactions[chainId]?.[hash].type === "limit-order-submission") {
					confirmOrderSubmission(
						chainId,
						receipt.from,
						hash,
						receipt.status !== 0,
					);
				} else if (
					transactions[chainId]?.[hash].type === "limit-order-cancellation"
				) {
					confirmOrderCancellation(
						chainId,
						receipt.from,
						hash,
						receipt.status !== 0,
					);
				}
			},
		)
		.addCase(resetUserState, (transactions, { payload: { chainId } }) => {
			if (transactions[chainId]) {
				transactions[chainId] = {};
			}
		}),
);
