import { Button, Typography } from "@vaporfi/uikit";
import Image from "next/legacy/image";
import type React from "react";

interface StratosphereIndicatorProps {
	tier: string;
}

export const StratosphereIndicator: React.FC<StratosphereIndicatorProps> = ({
	tier,
}) => {
	const displayTier = tier === "Unknown" ? "All" : tier;
	const iconSrc =
		tier === "Unknown"
			? "/images/stratosphere/levels/basic.png"
			: `/images/stratosphere/levels/${tier}.png`;

	return (
		<Button className="flex items-center justify-start gap-3 rounded-full bg-gray-200 p-1 pr-4 hover:bg-gray-300">
			<Image
				data-testid="strat-indicator-logo"
				src={iconSrc}
				height={24}
				width={24}
				alt={`${displayTier} Stratosphere tier logo`}
			/>
			<Typography
				data-testid="strat-indicator-tier"
				color="black"
				weight="normal"
				size="sm"
			>
				{displayTier}
			</Typography>
		</Button>
	);
};
