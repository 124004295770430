import type { HTMLProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";

const gridStyles = tv(
	{
		variants: {
			colSpan: {
				1: "col-span-1",
				2: "col-span-2",
				3: "col-span-3",
				4: "col-span-4",
				5: "col-span-5",
				6: "col-span-6",
				full: "col-span-full",
			},
			cols: {
				1: "grid-cols-1",
				2: "grid-cols-2",
				3: "grid-cols-3",
				4: "grid-cols-4",
				5: "grid-cols-5",
				6: "grid-cols-6",
				7: "grid-cols-7",
				8: "grid-cols-8",
				9: "grid-cols-9",
				10: "grid-cols-10",
				11: "grid-cols-11",
				12: "grid-cols-12",
				none: "grid-cols-none",
			},
			isContainer: { true: "grid" },
		},
	},
	{ responsiveVariants: true },
);

export interface GridProps
	extends VariantProps<typeof gridStyles>,
		Omit<HTMLProps<HTMLDivElement>, "cols" | "colSpan"> {}

const Grid = ({
	children,
	className,
	colSpan,
	cols,
	isContainer,

	...rest
}: GridProps) => {
	return (
		<div
			className={gridStyles({
				class: className,
				colSpan,
				cols,
				isContainer,
			})}
			{...rest}
		>
			{children}
		</div>
	);
};

export { Grid };
