import { env } from "@/env";
import { ConnectorNames, getConnectors } from "@vaporfi/features";
import { viemChains } from "@vaporfi/utils";
import type { Chain } from "viem/chains";
import { configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { filterTruthy } from ".";
import { mockClient } from "./viem";

const availableChainIDs = env.NEXT_PUBLIC_AVAILABLE_CHAINS?.split(",");
const tokenFactoryChainIDs = env.NEXT_PUBLIC_TOKEN_FACTORY_CHAINS.split(",");

export const getChainsByID = (chainIds: string[]) => {
	if (!chainIds) return [];
	return filterTruthy<Chain>(
		Object.values(viemChains)?.filter(({ id }) =>
			chainIds?.includes(id?.toString()),
		),
	);
};

export const availableChains = getChainsByID(availableChainIDs);
export const tokenFactoryChains = getChainsByID(tokenFactoryChainIDs);

export const SupportedChainsByFeature = {
	TOKEN_FACTORY: tokenFactoryChains,
};

const { chains, publicClient } = configureChains(availableChains, [
	publicProvider(),
]);
const walletConnectProjectId = "a8939e88323f259f90b5eeafb696a896";

export const connectorsByName = getConnectors({
	chains,
	mockClient,
	walletConnectProjectId,
});
export const injected = connectorsByName[ConnectorNames.Injected];
export const client = createConfig({
	autoConnect: true,
	connectors: Object.values(connectorsByName),
	publicClient,
});
