import { BaseCurrency } from './baseCurrency';
import { Currency } from './currency';

/**
 * Represents the native currency of the chain on which it resides, e.g.
 */
export abstract class NativeCurrency extends BaseCurrency {
  public readonly isNative = true as const;
  public readonly isToken = false as const;

  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
}
