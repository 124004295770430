/* eslint-disable perfectionist/sort-objects */
import {
	type Events,
	OneTimeEvent,
	type StratosphereAttributes,
	type StratosphereTierName,
	StratosphereTiers,
} from "./types";

type AvatarPath =
	| `/images/stratosphere/${number}/${Lowercase<StratosphereTierName>}.png`
	| null;

export function getStratosphereAvatar(
	tier: StratosphereTiers,
	chainId = 43_114,
): AvatarPath {
	const label = tierToLabel[tier];
	if (!label) return null;
	const tierName = label.toLowerCase() as Lowercase<StratosphereTierName>;
	return `/images/stratosphere/${chainId}/${tierName}.png`;
}

export const tierToMinPoints: StratosphereAttributes<number> = {
	[StratosphereTiers.BASIC]: 0,
	[StratosphereTiers.SILVER]: 1200,
	[StratosphereTiers.GOLD]: 15_000,
	[StratosphereTiers.PLATINUM]: 200_000,
	[StratosphereTiers.DIAMOND]: 500_000,
	[StratosphereTiers.OBSIDIAN]: 1_000_000,
} as const;

export const tierToLabel: StratosphereAttributes<StratosphereTierName> = {
	[StratosphereTiers.BASIC]: "Basic",
	[StratosphereTiers.DIAMOND]: "Diamond",
	[StratosphereTiers.GOLD]: "Gold",
	[StratosphereTiers.OBSIDIAN]: "Obsidian",
	[StratosphereTiers.PLATINUM]: "Platinum",
	[StratosphereTiers.SILVER]: "Silver",
} as const;

// as per PRD (ticket-718)
export const swapDiscounts: StratosphereAttributes<string> = {
	[StratosphereTiers.BASIC]: "1%",
	[StratosphereTiers.DIAMOND]: "2.75%",
	[StratosphereTiers.GOLD]: "1.50%",
	[StratosphereTiers.OBSIDIAN]: "4%",
	[StratosphereTiers.PLATINUM]: "2%",
	[StratosphereTiers.SILVER]: "1.2%",
} as const;
// as per PRD (ticket-718)
export const vpndLiquidMiningUnlockTimeReductions: StratosphereAttributes<string> =
	{
		[StratosphereTiers.BASIC]: "5%",
		[StratosphereTiers.SILVER]: "6.5%",
		[StratosphereTiers.GOLD]: "9%",
		[StratosphereTiers.PLATINUM]: "13%",
		[StratosphereTiers.DIAMOND]: "20%",
		[StratosphereTiers.OBSIDIAN]: "32.5%",
	} as const;

export const vpndLiquidMiningBaseBoost: StratosphereAttributes<string> = {
	[StratosphereTiers.BASIC]: "0.2%",
	[StratosphereTiers.SILVER]: "0.25%",
	[StratosphereTiers.GOLD]: "0.33%",
	[StratosphereTiers.PLATINUM]: "0.45%",
	[StratosphereTiers.DIAMOND]: "0.65%",
	[StratosphereTiers.OBSIDIAN]: "1%",
} as const;

export const stratosphereBenefits = [
	"Rewards back VaporDEX swap fees",
	"Reduction in VPND Liquid Mining unlocking period",
	"Increased base boost points in VPND Liquid Mining",
] as const;

export const oneTimeEvents: Events = {
	[OneTimeEvent.ADD_PROFILE_PICTURE]: {
		description: "User adds a profile picture",
		name: "Add profile picture",
		points: 50,
	},
	[OneTimeEvent.CHAIN_FIRST_WALLET]: {
		description: "The first wallet to have swapped on each chain",
		name: "Chain first wallet",
		points: 500,
	},
	[OneTimeEvent.EARLY_MEMBER]: {
		description: "User joined Strat program before the official launch",
		name: "Early member",
		points: 100,
	},
	[OneTimeEvent.ENROLLMENT]: {
		description: "Create a new Stratosphere membership",
		name: "Enrollment",
		points: 150,
	},
	[OneTimeEvent.FIRST_DEPOSIT_IN_VAPE_LM]: {
		description: "User deposits in VAPE LM for the first time",
		name: "First deposit in VAPE LM",
		points: 100,
	},
	[OneTimeEvent.FIRST_DEPOSIT_IN_VPND_LM]: {
		description: "User deposits in VPND LM for the first time",
		name: "First deposit in VPND LM",
		points: 100,
	},
	[OneTimeEvent.FIRST_SWAP]: {
		description: "User completes first swap on VaporDEX",
		name: "First swap",
		points: 50,
	},
	[OneTimeEvent.FIRST_WALLET_IN_VAPE_LM]: {
		description: "The first wallet to have deposited in VAPE LM",
		name: "First wallet in VAPE LM",
		points: 500,
	},
	[OneTimeEvent.FIRST_WALLET_IN_VPND_LM]: {
		description: "The first wallet to have deposited in VPND LM",
		name: "First wallet in VPND LM",
		points: 500,
	},
	[OneTimeEvent.LOCKED_1_SEASON_IN_VPND_LM]: {
		description: "User participates in one season",
		name: "Locked for 1 season in VPND LM",
		points: 100,
	},
	[OneTimeEvent.LOCKED_1_YEAR_IN_VPND_LM]: {
		description: "User participates in twelve seasons in a row",
		name: "Locked for 1 year in VPND LM",
		points: 1000,
	},
	[OneTimeEvent.LOCKED_3_SEASONS_IN_VPND_LM]: {
		description: "User participates in three seasons in a row",
		name: "Locked for 3 seasons in VPND LM",
		points: 200,
	},
	[OneTimeEvent.LOCKED_6_SEASONS_IN_VPND_LM]: {
		description: "User participates in six seasons in a row",
		name: "Locked for 6 seasons in VPND LM",
		points: 300,
	},
	// [OneTimeEvent.REFERRAL_POINTS]: {
	//   description: 'User earns points for each person they refer to VaporDEX',
	//   name: 'Referral points',
	//   points: 25,
	// },
	// [OneTimeEvent.SOCIAL_MEDIA_POST_ON_X]: {
	//   description:
	//     'User completes a post on X (“I just completed a swap on @VaporDEX and earned points. Check out The World’s Most Rewarding DEX!”',
	//   name: 'Social media post on X',
	//   points: 25,
	// },
	[OneTimeEvent.SWAP_1K_CUMULATED]: {
		description: "User swaps a total of $1K on VaporDEX",
		name: "Swap 1K cumulated",
		points: 500,
	},
	[OneTimeEvent.SWAP_10K_CUMULATED]: {
		description: "User swaps a total of $10K on VaporDEX",
		name: "Swap 10K cumulated",
		points: 1000,
	},
	[OneTimeEvent.SWAP_100K_CUMULATED]: {
		description: "User swaps a total of $100K on VaporDEX",
		name: "Swap 100K cumulated",
		points: 5000,
	},
} as const;
