"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var next_image_loader_exports = {};
__export(next_image_loader_exports, {
  default: () => myImageLoader
});
module.exports = __toCommonJS(next_image_loader_exports);
function myImageLoader({
  quality,
  src,
  width
}) {
  const isLocal = !src.startsWith("http");
  const query = new URLSearchParams();
  const imageOptimizationApi = "https://image-loader.vaporfi.dev/image";
  const baseUrl = process.env.NEXT_PUBLIC_APP_URL;
  const fullSrc = `${baseUrl}${src}`;
  if (width) query.set("width", width.toString());
  if (quality) query.set("quality", quality.toString());
  if (isLocal && process.env.NODE_ENV === "development") {
    return src;
  }
  if (isLocal) {
    return `${imageOptimizationApi}/${fullSrc}?${query.toString()}`;
  }
  return `${imageOptimizationApi}/${src}?${query.toString()}`;
}
