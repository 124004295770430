import { AlertCard, Typography } from "@vaporfi/uikit";
import { VAPE_V3_ANNOUNCEMENT } from "@vaporfi/utils";
import Link from "next/link";

export const VAPEMigrationAlert = () => {
	return (
		<AlertCard className="w-full" variant="warning">
			<Typography>
				Liquidity of VAPE pools will need to be removed and added to the new
				pool for V3 of the token. We are sorry for the confusion.{" "}
				<span className="font-semibold">
					Read the announcement for more information.
				</span>
				<Link href={VAPE_V3_ANNOUNCEMENT} target="_blank">
					<span className="text-yellow ml-2 text-xs">PDF</span>
				</Link>
			</Typography>
		</AlertCard>
	);
};
