"use client";

import React, { createContext, useRef, useState, useEffect } from "react";
import { Overlay } from "../Overlay";
import { AnimatePresence } from "../Presence";

export type Handler = () => void;

export interface ModalsContext {
	isOpen: boolean;
	nodeId: string;
	modalNode: React.ReactNode;
	setModalNode: React.Dispatch<React.SetStateAction<React.ReactNode>>;
	onPresent: (
		node: React.ReactNode,
		newNodeId: string,
		closeOverlayClick: boolean,
	) => void;
	onDismiss: Handler;
}

export const ModalContext = createContext<ModalsContext>({
	isOpen: false,
	modalNode: null,
	nodeId: "",
	onDismiss: () => null,
	onPresent: () => null,
	setModalNode: () => null,
});

export default function ModalProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [modalNode, setModalNode] = useState<React.ReactNode>();
	const [nodeId, setNodeId] = useState("");
	const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);
	const animationRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const setViewportHeight = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};
		setViewportHeight();
		window.addEventListener("resize", setViewportHeight);
		return () => window.removeEventListener("resize", setViewportHeight);
	}, []);

	const handlePresent = (
		node: React.ReactNode,
		newNodeId: string,
		closeOverlayClick: boolean,
	) => {
		setModalNode(node);
		setIsOpen(true);
		setNodeId(newNodeId);
		setCloseOnOverlayClick(closeOverlayClick);
	};

	const handleDismiss = () => {
		setModalNode(null);
		setIsOpen(false);
		setNodeId("");
		setCloseOnOverlayClick(true);
	};

	const handleOverlayDismiss = () => {
		if (closeOnOverlayClick) {
			handleDismiss();
		}
	};

	return (
		<ModalContext.Provider
			value={{
				isOpen,
				modalNode,
				nodeId,
				onDismiss: handleDismiss,
				onPresent: handlePresent,
				setModalNode,
			}}
		>
			{isOpen && (
				<AnimatePresence ref={animationRef}>
					<Overlay onClick={handleOverlayDismiss} />
					{React.isValidElement(modalNode) &&
						React.cloneElement<any>(modalNode, {
							onDismiss: handleDismiss,
						})}
				</AnimatePresence>
			)}

			{children}
		</ModalContext.Provider>
	);
}
