import type { Chain } from "viem";
import { avalanche as _avalanche } from "viem/chains";
export const avalanche = {
	..._avalanche,
	contracts: {
		..._avalanche.contracts,
		ensUniversalResolver: {
			address: "0x24DFa1455A75f64800BFdB2447958D2B632b94f6",
		} as const,
	},
} as Chain;
