import { isSupportedChain } from "@/config/constants/chains";
import {
	CHAIN_ID_TO_QUERY_NAME,
	DEFAULT_CHAIN_ID,
	SupportedChainId,
	getQueryParamsChainId,
	nativeOnChain,
} from "@vaporfi/utils";
import {
	deleteWalletAccountCookie,
	getChainIdCookie,
	setWalletAccountCookie,
} from "config/constants/networks";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { isAddress } from "viem";
import {
	type Address,
	useAccount,
	useNetwork,
	usePublicClient,
	useWalletClient,
} from "wagmi";
const useActiveWagmi = () => {
	const { address, connector, isConnected, isConnecting } = useAccount({
		onConnect: ({ address }) => {
			setWalletAccountCookie(address);
		},
		onDisconnect: () => {
			deleteWalletAccountCookie();
		},
	});
	const { chain, chains } = useNetwork();

	const { isReady, pathname, query, replace } = useRouter();
	const { account: addressFromUrl, chain: chainQuery } = query;
	const chainIdQuery = getQueryParamsChainId(chainQuery as string);

	const chainIdEthereum = useMemo(() => {
		if (typeof window !== "undefined" && window.ethereum?.chainId) {
			return Number.parseInt(window.ethereum.chainId, 16);
		}
		return 43_114; // Default value if window.ethereum.chainId is not available
	}, []);

	const chainId = useMemo(() => {
		if (chain && isConnected) return chain?.id;
		return chainIdQuery || chainIdEthereum;
	}, [chain, chainIdEthereum, chainIdQuery, isConnected]);

	const provider = usePublicClient({ chainId });
	const { data: signer } = useWalletClient({ chainId });

	const [supportedChainId, nativeCurrency] = useMemo(() => {
		let _chainId: number;

		const chainIdCookie = Number.parseInt(getChainIdCookie(), 10);
		const nativeCurrency = nativeOnChain(chainId);
		// if connected chain id or query params is supported
		if (isSupportedChain(chainId)) {
			_chainId = chainId;
		} else if (isSupportedChain(chainIdCookie)) {
			_chainId = chainIdCookie;
		} else {
			_chainId = DEFAULT_CHAIN_ID;
		}

		return [_chainId, nativeCurrency];
	}, [chainId]);

	const assumedAccount = isAddress(addressFromUrl as Address)
		? (addressFromUrl as Address)
		: null;

	const account = assumedAccount ?? address;
	const isValid = account && isConnected && chainId in SupportedChainId;

	useEffect(() => {
		address && setWalletAccountCookie(address);
	}, [address]);

	useEffect(() => {
		if (!isReady) return;
		if (isConnected && chainId !== chainIdQuery) {
			replace(
				{
					pathname,
					query: {
						...query,
						chain: CHAIN_ID_TO_QUERY_NAME[chainId],
					},
				},
				undefined,
				{
					scroll: false,
					shallow: true,
				},
			);
		}
	}, [chainId, isConnected, chainIdQuery, query, isReady, replace, pathname]);

	return {
		account,
		chain,
		chainId: supportedChainId as unknown as number,
		chains,
		connector,
		isConnected: assumedAccount ? true : isConnected,
		isConnecting,
		isValid,
		library: provider,
		nativeCurrency,
		signer,
	};
};

export default useActiveWagmi;
