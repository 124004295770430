import { MulticallABI } from "@config/abi/Multicall";
import { NFTPositionManagerABI } from "@config/abi/NFTPositionManagerABI";
import { VaporDEXPairABI } from "@config/abi/VaporDEXPair";
import { EIP_2612_ABI } from "@config/abi/eip_2612";
import { ERC20ABI } from "@config/abi/erc20ABI";
import useActiveWagmi from "@hooks/useActiveWagmi";
import { getContract } from "@utils";
import {
	getAggregatorDiamondAddress,
	getMulticallAddress,
	getNFTPositionManagerAddress,
	getTickLensAddress,
	getV2MigratorAddress,
} from "@utils/addressHelpers";
import { getZapContract } from "@utils/contractHelpers";
import { useMemo } from "react";
import { type Address, useContractWrite, useWaitForTransaction } from "wagmi";

import type { SupportedChainId } from "@/config/constants/chains";
import AggregatorDiamondABI from "@config/abi/AggregatorDiamond";
import { TickLensABI } from "@config/abi/TickLens";
import { V2MigratorABI } from "@config/abi/V2MigratorABI";
import { ERC20_BYTES32_ABI } from "@config/abi/erc20";
import type { Abi } from "viem";

/**
 * Helpers
 */

export function useTickLens() {
	const { chainId } = useActiveWagmi();
	const address = chainId ? getTickLensAddress(chainId) : undefined;
	return useContract<typeof TickLensABI>(address, TickLensABI);
}

export function useV2NFTPositionManagerContract(
	withSignerIfPossible?: boolean,
) {
	const { chainId } = useActiveWagmi();
	return useContract<typeof NFTPositionManagerABI>(
		getNFTPositionManagerAddress(chainId),
		NFTPositionManagerABI,
		withSignerIfPossible,
	);
}

export const useContractWriteWithTransactionWatch = (config) => {
	const contractWrite = useContractWrite(config);
	const waitForTransaction = useWaitForTransaction({
		hash: contractWrite?.data?.hash,
	});

	return {
		...waitForTransaction,
		isLoading: contractWrite?.isLoading || waitForTransaction?.isLoading,
		write: contractWrite.write,
	};
};

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract<Tbi extends Abi | readonly unknown[] = Abi>(
	address: Address | undefined,
	ABI: Tbi,
	withSignerIfPossible = true,
) {
	const { library, signer } = useActiveWagmi();

	const canReturnContract = useMemo(
		() => address && ABI && (withSignerIfPossible ? signer : true),
		[address, ABI, signer, withSignerIfPossible],
	);

	return useMemo(() => {
		if (!canReturnContract) return null;
		try {
			return getContract<Tbi>(address, ABI, signer, library);
		} catch (error) {
			console.error("Failed to get contract", error);
			return null;
		}
	}, [address, ABI, canReturnContract, signer, library]);
}

export function useTokenContract(
	tokenAddress?: Address,
	withSignerIfPossible?: boolean,
) {
	return useContract<typeof ERC20ABI>(
		tokenAddress,
		ERC20ABI,
		withSignerIfPossible,
	);
}

export function useV2MigratorContract() {
	const { chainId } = useActiveWagmi();
	return useContract<typeof V2MigratorABI>(
		getV2MigratorAddress(chainId),
		V2MigratorABI,
		true,
	);
}
export function useEIP2612Contract(tokenAddress?: Address) {
	return useContract<typeof EIP_2612_ABI>(tokenAddress, EIP_2612_ABI, false);
}

export function usePairContract(
	pairAddress?: Address,
	withSignerIfPossible?: boolean,
) {
	return useContract<typeof VaporDEXPairABI>(
		pairAddress,
		VaporDEXPairABI,
		withSignerIfPossible,
	);
}

export function useMulticallContract(chainId?: SupportedChainId) {
	return useContract<typeof MulticallABI>(
		getMulticallAddress(chainId),
		MulticallABI,
		false,
	);
}

export function useZapContract() {
	return useMemo(() => getZapContract(), []);
}
export function useBytes32TokenContract(
	tokenAddress?: Address,
	withSignerIfPossible?: boolean,
) {
	return useContract<typeof ERC20_BYTES32_ABI>(
		tokenAddress,
		ERC20_BYTES32_ABI,
		withSignerIfPossible,
	);
}

export function useAggregatorRouterAddress(): Address {
	const { chainId } = useActiveWagmi();

	return useMemo(() => getAggregatorDiamondAddress(chainId), [chainId]);
}

export function useAggregatorRouterContract(withSignerIfPossible = true) {
	const address = useAggregatorRouterAddress();

	return useContract<typeof AggregatorDiamondABI>(
		address,
		AggregatorDiamondABI,
		withSignerIfPossible,
	);
}
