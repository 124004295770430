const abi = [
	{
		inputs: [],
		name: "LegacyRouterFacet__InvalidFee",
		type: "error",
	},
	{
		inputs: [],
		name: "LegacyRouterFacet__InvalidMaxSteps",
		type: "error",
	},
	{
		inputs: [],
		name: "LegacyRouterFacet__InvalidOutput",
		type: "error",
	},
	{
		inputs: [],
		name: "LegacyRouterFacet__InvalidPath",
		type: "error",
	},
	{
		inputs: [],
		name: "LegacyRouterFacet__NothingToRecover",
		type: "error",
	},
	{
		inputs: [],
		name: "LibDiamond__OnlyOwner",
		type: "error",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "asset",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "Recovered",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "tokenIn",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "tokenOut",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amountIn",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amountOut",
				type: "uint256",
			},
		],
		name: "RouterSwap",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address[]",
				name: "_newAdapters",
				type: "address[]",
			},
		],
		name: "UpdatedAdapters",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "oldFeeClaimer",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "newFeeClaimer",
				type: "address",
			},
		],
		name: "UpdatedFeeClaimer",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "oldMinFee",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "newMinFee",
				type: "uint256",
			},
		],
		name: "UpdatedMinFee",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address[]",
				name: "newTrustedTokens",
				type: "address[]",
			},
		],
		name: "UpdatedTrustedTokens",
		type: "event",
	},
	{
		inputs: [],
		name: "adaptersCount",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenIn",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenOut",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_maxSteps",
				type: "uint256",
			},
		],
		name: "findBestPath",
		outputs: [
			{
				components: [
					{
						internalType: "uint256[]",
						name: "amounts",
						type: "uint256[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "uint256",
						name: "gasEstimate",
						type: "uint256",
					},
				],
				internalType: "struct LegacyRouterFacet.FormattedOffer",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenIn",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenOut",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_maxSteps",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_gasPrice",
				type: "uint256",
			},
		],
		name: "findBestPathWithGas",
		outputs: [
			{
				components: [
					{
						internalType: "uint256[]",
						name: "amounts",
						type: "uint256[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "uint256",
						name: "gasEstimate",
						type: "uint256",
					},
				],
				internalType: "struct LegacyRouterFacet.FormattedOffer",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes",
				name: "a",
				type: "bytes",
			},
			{
				internalType: "bytes",
				name: "b",
				type: "bytes",
			},
		],
		name: "mergeBytes",
		outputs: [
			{
				internalType: "bytes",
				name: "c",
				type: "bytes",
			},
		],
		stateMutability: "pure",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenIn",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenOut",
				type: "address",
			},
			{
				internalType: "uint8",
				name: "_index",
				type: "uint8",
			},
		],
		name: "queryAdapter",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenIn",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenOut",
				type: "address",
			},
			{
				internalType: "uint8[]",
				name: "_options",
				type: "uint8[]",
			},
		],
		name: "queryNoSplit",
		outputs: [
			{
				components: [
					{
						internalType: "address",
						name: "adapter",
						type: "address",
					},
					{
						internalType: "address",
						name: "tokenIn",
						type: "address",
					},
					{
						internalType: "address",
						name: "tokenOut",
						type: "address",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
				],
				internalType: "struct LegacyRouterFacet.Query",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountIn",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_tokenIn",
				type: "address",
			},
			{
				internalType: "address",
				name: "_tokenOut",
				type: "address",
			},
		],
		name: "queryNoSplit",
		outputs: [
			{
				components: [
					{
						internalType: "address",
						name: "adapter",
						type: "address",
					},
					{
						internalType: "address",
						name: "tokenIn",
						type: "address",
					},
					{
						internalType: "address",
						name: "tokenOut",
						type: "address",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
				],
				internalType: "struct LegacyRouterFacet.Query",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amount",
				type: "uint256",
			},
		],
		name: "recoverAVAX",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_tokenAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_tokenAmount",
				type: "uint256",
			},
		],
		name: "recoverERC20",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "_adapters",
				type: "address[]",
			},
		],
		name: "setAdapters",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_claimer",
				type: "address",
			},
		],
		name: "setFeeClaimer",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
		],
		name: "setMinFee",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "_trustedTokens",
				type: "address[]",
			},
		],
		name: "setTrustedTokens",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "amountIn",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
				],
				internalType: "struct LegacyRouterFacet.Trade",
				name: "_trade",
				type: "tuple",
			},
			{
				internalType: "address",
				name: "_to",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
		],
		name: "swapNoSplit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "amountIn",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
				],
				internalType: "struct LegacyRouterFacet.Trade",
				name: "_trade",
				type: "tuple",
			},
			{
				internalType: "address",
				name: "_to",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
		],
		name: "swapNoSplitFromAVAX",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "amountIn",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
				],
				internalType: "struct LegacyRouterFacet.Trade",
				name: "_trade",
				type: "tuple",
			},
			{
				internalType: "address",
				name: "_to",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
		],
		name: "swapNoSplitToAVAX",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "amountIn",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
				],
				internalType: "struct LegacyRouterFacet.Trade",
				name: "_trade",
				type: "tuple",
			},
			{
				internalType: "address",
				name: "_to",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_deadline",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "_v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "_r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "_s",
				type: "bytes32",
			},
		],
		name: "swapNoSplitToAVAXWithPermit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "amountIn",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amountOut",
						type: "uint256",
					},
					{
						internalType: "address[]",
						name: "path",
						type: "address[]",
					},
					{
						internalType: "address[]",
						name: "adapters",
						type: "address[]",
					},
				],
				internalType: "struct LegacyRouterFacet.Trade",
				name: "_trade",
				type: "tuple",
			},
			{
				internalType: "address",
				name: "_to",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_fee",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_deadline",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "_v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "_r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "_s",
				type: "bytes32",
			},
		],
		name: "swapNoSplitWithPermit",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "trustedTokensCount",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
] as const;

export default abi;
