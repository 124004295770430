import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Typography,
} from "@vaporfi/uikit";
import { DatePicker } from "../../../DatePicker";
import type { CommonFieldsProps } from "../../types";
import {
	CancelButton,
	FieldsContainer,
	useWithFieldsValidation,
} from "./shared";

export const LaunchFields = ({
	chainId,
	form,
	onCancel,
	onNext,
}: CommonFieldsProps) => {
	const onNextWithValidate = useWithFieldsValidation(chainId, "launch", onNext);
	return (
		<FieldsContainer>
			<FormField
				control={form.control}
				name="isStratosphereWhitelist"
				render={({ field }) => (
					<FormItem>
						<Box className="flex items-center gap-2">
							<FormControl>
								<Checkbox
									className="text-light h-[26px] w-[26px] rounded-[5px] bg-neutral-900"
									checked={field.value}
									{...field}
									onChange={field.onChange}
									value=""
								/>
							</FormControl>
							<FormLabel>
								1-hour Whitelist for Stratosphere members? (*)
							</FormLabel>
						</Box>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="launchDate"
				render={({ field }) => {
					return (
						<FormItem className="flex flex-col">
							<FormLabel>Launch Date (*)</FormLabel>
							<FormControl>
								<DatePicker
									{...field}
									onChange={(date) => {
										form.setValue("launchDate", date, { shouldValidate: true });
									}}
								/>
							</FormControl>
							<Typography size="sm" color="turquoise">
								Date and time are in your local timezone
							</Typography>
							<FormMessage />
						</FormItem>
					);
				}}
			/>

			<Box className="col-span-2 flex justify-between">
				<CancelButton onCancel={onCancel} />
				<Button
					color="blue"
					size="sm"
					className="w-fit"
					onClick={onNextWithValidate}
				>
					<Typography>Next Step</Typography>
				</Button>
			</Box>
		</FieldsContainer>
	);
};
