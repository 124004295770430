import { BLOCKED_ADDRESSES } from "@config/constants";
import useActiveWagmi from "hooks/useActiveWagmi";
import { type ReactNode, useMemo } from "react";

function Blocklist({ children }: { children: ReactNode }) {
	const { account } = useActiveWagmi();
	const blockedWallet: boolean = useMemo(
		() => Boolean(account && BLOCKED_ADDRESSES.includes(account)),
		[account],
	);

	if (blockedWallet) {
		return <div>Blocked address</div>;
	}

	return <>{children}</>;
}

export default Blocklist;
