"use client";

import { Button, type ButtonProps, Typography } from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import { forwardRef } from "react";

const ConnectWalletButton = forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, className, onClick, ...props }, ref) => {
		return (
			<Button
				data-testid="connect-wallet-button"
				onClick={onClick}
				color="purple"
				size="base"
				border={2}
				className={cn("h-10", className)}
				{...props}
				ref={ref}
			>
				{children ?? (
					<Typography color="turquoise" weight="semibold">
						Connect Wallet
					</Typography>
				)}
			</Button>
		);
	},
);

export default ConnectWalletButton;
