import type React from "react";
import { Link, type LinkProps } from "./index";

const LinkExternal: React.FC<
	React.PropsWithChildren<LinkProps> & { className?: string }
> = ({ children, className, ...props }) => {
	const combinedClassName = `flex items-center cursor-pointer ${
		className || ""
	}`;

	return (
		<Link external {...props} className={combinedClassName}>
			{children}
		</Link>
	);
};

export { LinkExternal };
