import { Svg, type SvgProps } from "./Svg";

export const DoubleRingIcon = (props: SvgProps) => {
	return (
		<Svg
			width="67"
			height="65"
			viewBox="0 0 67 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M33.5059 0.00012207C15.4958 0.00012207 0.878906 14.5601 0.878906 32.5001C0.878906 50.4401 15.4958 65.0001 33.5059 65.0001C51.516 65.0001 66.1329 50.4401 66.1329 32.5001C66.1329 14.5601 51.516 0.00012207 33.5059 0.00012207ZM13.7992 52.1301C5.7077 44.0701 5.7077 31.0051 13.7992 22.9451C21.8907 14.8851 35.0068 14.8851 43.0983 22.9451C51.1898 31.0051 51.1898 44.0701 43.0983 52.1301C35.0068 60.1901 21.8907 60.1901 13.7992 52.1301ZM52.919 50.7651C51.0919 52.5851 49.069 54.1126 46.8504 55.3151C51.3529 50.7001 54.0936 44.4276 54.0936 37.5376C54.0936 23.4326 42.5762 11.9926 28.4487 11.9926C21.5318 11.9926 15.2022 14.7551 10.6018 19.2076C11.809 16.9976 13.3424 14.9501 15.1695 13.1626C17.6166 10.7251 20.4877 8.80762 23.6526 7.44262C26.9479 6.04512 30.439 5.36262 34.0279 5.36262C37.6169 5.36262 41.1406 6.07762 44.436 7.44262C47.6008 8.77512 50.472 10.6926 52.919 13.1626C55.366 15.6001 57.291 18.4601 58.6613 21.6126C60.0643 24.8951 60.7495 28.3726 60.7495 31.9801C60.7495 35.5876 60.0317 39.0651 58.6613 42.3476C57.3236 45.5001 55.3986 48.3276 52.919 50.7651Z"
				fill="white"
			/>
		</Svg>
	);
};
