import Link from "next/link";
import { type HTMLProps, useMemo } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { Typography } from "../../Typography";

const flyoutStyles = tv({
	slots: {
		container:
			"absolute bottom-[51px] left-0 right-0 mx-4 backdrop-blur-sm bg-gray-300/90 rounded-md shadow-lg flex flex-col items-center",
		item: "border-b border-neutral-400 cursor-pointer flex items-center justify-center h-12.5 py-4 pb-2 w-full",
	},
});

export interface FlyoutProps
	extends VariantProps<typeof flyoutStyles>,
		HTMLProps<HTMLDivElement> {
	items: FlyoutItem[];
	toggler: () => void;
}

export interface FlyoutItem {
	label: string;
	url: string;
}
export const Flyout = ({ className, items, toggler, ...rest }: FlyoutProps) => {
	const { container, item } = useMemo(() => {
		return flyoutStyles();
	}, []);

	return (
		<div className={container({ class: className })} {...rest}>
			{items?.map(
				({ label, url }, i) =>
					url &&
					label && (
						<Link href={url} passHref legacyBehavior key={label}>
							<div className={item()} onClick={toggler}>
								<Typography
									color="black"
									weight="semibold"
									size="sm"
									className="leading-5"
								>
									{label}
								</Typography>
							</div>
						</Link>
					),
			)}
		</div>
	);
};
