import memoize from "lodash/memoize";
import type { PageMeta } from "./types";

// TODO: use VaporDEX meta data
export const DEFAULT_META: PageMeta = {
	description: "The World’s Most Rewarding DEX",
	image: "/images/DEX_meta_image.jpeg",
	title: "VaporDEX",
};

const PATH_LIST = {
	defaultTitleSuffix: "VaporDEX",
	paths: {
		"/": { title: "Home" },
		"/add": { basePath: true, title: "Add Liquidity" },
		"/bridge": { title: "Bridge" },
		"/fiat": { title: "Fiat" },
		"/find": { title: "Import Pool" },
		"/liquidity": { title: "Liquidity" },
		"/remove": { basePath: true, title: "Remove Liquidity" },
		"/swap": { basePath: true, title: "Swap" },
	},
};

export const getCustomMeta = memoize(
	(path: string): PageMeta => {
		const pathMetadata =
			PATH_LIST.paths[path] ??
			PATH_LIST.paths[
				Object.entries(PATH_LIST.paths).find(
					([url, data]) =>
						// Using a type guard here
						"basePath" in data && data.basePath && path.startsWith(url),
				)?.[0]
			];

		if (pathMetadata) {
			return {
				title: `${pathMetadata.title} | ${PATH_LIST.defaultTitleSuffix}`,
				...(pathMetadata.description && {
					description: pathMetadata.description,
				}),
			};
		}
		return null;
	},
	(path) => path,
);
