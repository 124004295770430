import { env } from "@/env";
import Router from "next/router";
import NProgress from "nprogress";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { type FC, type PropsWithChildren, useEffect } from "react";

if (typeof window !== "undefined") {
	posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
		api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
	});
}

// DO NOT convert this into a hook. I know it makes sense. Please don't.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterProvider: FC<PropsWithChildren<any>> = ({ children }) => {
	useEffect(() => {
		const handleRouteStart = () => NProgress.start();
		const handleRouteDone = () => NProgress.done();
		const handleRouteChange = () => posthog?.capture("$pageview");

		Router.events.on("routeChangeStart", handleRouteStart);
		Router.events.on("routeChangeComplete", handleRouteDone);
		Router.events.on("routeChangeError", handleRouteDone);
		Router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			// Make sure to remove the event handler on unmount!
			Router.events.off("routeChangeStart", handleRouteStart);
			Router.events.off("routeChangeComplete", handleRouteDone);
			Router.events.off("routeChangeError", handleRouteDone);
			Router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, []);

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default RouterProvider;
