import type { VaporDEXTheme } from "@vapordex/uikit";
import { createGlobalStyle } from "styled-components";

declare module "styled-components" {
	export interface DefaultTheme extends VaporDEXTheme {}
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

export default GlobalStyle;
