"use client";
import { Button, Typography } from "@vaporfi/uikit";
import {
	SLIPPAGE_TOLERANCE_HIGH,
	SLIPPAGE_TOLERANCE_LOW,
	SLIPPAGE_TOLERANCE_MEDIUM,
} from "@vaporfi/utils";
import type React from "react";
import { Input } from "../../../vapordex-uikit";
import { QuestionHelper } from "./QuestionHelper";

enum SlippageError {
	InvalidInput = "InvalidInput",
	RiskyLow = "RiskyLow",
	RiskyHigh = "RiskyHigh",
}

export interface SlippageTabsViewProps {
	userSlippageTolerance: number;
	slippageInput: string;
	isSlippageInputValid: boolean;
	setSlippageInput: (slippageInput: string) => void;
	slippageError: string | null;
	setUserSlippageTolerance: (slippage: number) => void;
	parseCustomSlippage: (value: string) => void;
	transactionTTL: number;
	setTransactionTTL: (ttl: number) => void;
	deadlineInput: string;
	deadlineError: string | null;
	parseCustomDeadline: (value: string) => void;
}

export const SlippageTabsView: React.FC<SlippageTabsViewProps> = ({
	deadlineError,
	deadlineInput,
	isSlippageInputValid,
	parseCustomDeadline,
	parseCustomSlippage,
	setSlippageInput,
	setUserSlippageTolerance,
	slippageError,
	slippageInput,
	transactionTTL,
	userSlippageTolerance,
}) => (
	<div className="flex flex-col">
		<div className="mb-6 flex flex-col">
			<div className="mb-3 flex">
				<Typography className="mr-1">{"Slippage Tolerance"}</Typography>
				<QuestionHelper
					text={
						"Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution."
					}
				/>
			</div>
			<div className="flex flex-wrap">
				<Button
					size="base"
					variant={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_LOW
							? "filled"
							: "outline"
					}
					color={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_LOW ? "blue" : "light"
					}
					data-testid="slippage-low-btn"
					className="mr-1 mt-1"
					onClick={() => {
						setSlippageInput("");
						setUserSlippageTolerance(SLIPPAGE_TOLERANCE_LOW);
					}}
				>
					<Typography>{SLIPPAGE_TOLERANCE_LOW / 100}%</Typography>
				</Button>

				<Button
					size="base"
					variant={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_MEDIUM
							? "filled"
							: "outline"
					}
					color={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_MEDIUM
							? "blue"
							: "light"
					}
					data-testid="slippage-medium-btn"
					className="mr-1 mt-1"
					onClick={() => {
						setSlippageInput("");
						setUserSlippageTolerance(SLIPPAGE_TOLERANCE_MEDIUM);
					}}
				>
					<Typography>{`${SLIPPAGE_TOLERANCE_MEDIUM / 100}%`}</Typography>
				</Button>

				<Button
					size="base"
					variant={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_HIGH
							? "filled"
							: "outline"
					}
					color={
						userSlippageTolerance === SLIPPAGE_TOLERANCE_HIGH ? "blue" : "light"
					}
					data-testid="slippage-high-btn"
					className="mr-1 mt-1"
					onClick={() => {
						setSlippageInput("");
						setUserSlippageTolerance(SLIPPAGE_TOLERANCE_HIGH);
					}}
				>
					<Typography>{`${SLIPPAGE_TOLERANCE_HIGH / 100}%`}</Typography>
				</Button>
				<div className="flex items-center">
					<div className="mt-1" style={{ width: "76px" }}>
						<Input
							data-testid="slippage-custom-input"
							scale="sm"
							inputMode="decimal"
							pattern="^[0-9]*[.,]?[0-9]{0,2}$"
							placeholder={(userSlippageTolerance / 100).toFixed(2)}
							value={slippageInput}
							onBlur={() => {
								parseCustomSlippage(slippageInput);
							}}
							onChange={(event) => {
								if (event.currentTarget.validity.valid) {
									parseCustomSlippage(event.target.value.replaceAll(",", "."));
								}
							}}
							isWarning={!isSlippageInputValid}
							isSuccess={![10, 30, 100].includes(userSlippageTolerance)}
							style={{
								background: "transparent",
								textAlign: "right",
							}}
						/>
					</div>
					<Typography color="blue" style={{ marginLeft: "4px" }}>
						%
					</Typography>
				</div>
			</div>
			{!!slippageError && (
				<Typography
					size="sm"
					color={slippageError === SlippageError.InvalidInput ? "red" : "blue"}
					className="mt-2"
				>
					{slippageError === SlippageError.InvalidInput
						? "Enter a valid slippage percentage"
						: slippageError === SlippageError.RiskyLow
							? "Your transaction may fail"
							: "Your transaction may be frontrun"}
				</Typography>
			)}
		</div>
		<div className="mb-6 flex items-center justify-between">
			<div className="flex items-center">
				<Typography>{"Tx deadline (mins)"}</Typography>
				<QuestionHelper
					text={
						"Your transaction will revert if it is left confirming for longer than this time."
					}
					placement="top-start"
					className="ml-1"
				/>
			</div>

			<div className="mt-1" style={{ width: "52px" }}>
				<Input
					data-testid="deadline-custom-input"
					scale="sm"
					inputMode="numeric"
					pattern="^[0-9]+$"
					isWarning={!!deadlineError}
					onBlur={() => {
						parseCustomDeadline((transactionTTL / 60).toString());
					}}
					placeholder={(transactionTTL / 60).toString()}
					value={deadlineInput}
					onChange={(event) => {
						if (event.currentTarget.validity.valid) {
							parseCustomDeadline(event.target.value);
						}
					}}
				/>
			</div>
		</div>
	</div>
);
