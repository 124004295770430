import * as React from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { escapeRegExp } from "../../utils";

export const inputStyles = tv({
	base: "box-border w-full rounded-[5px] appearance-none bg-transparent text-light flex-1 flex-shrink-0 font-semibold outline-none overflow-hidden p-0 relative text-right text-ellipsis whitespace-nowrap",
	variants: {
		border: {
			none: "border-none",
		},
		disabled: {
			true: "cursor-not-allowed opacity-50",
		},
		textAlign: {
			left: "text-left",
			right: "text-right",
		},
	},
});

export const numericalInputRegex = /^\d*(?:\\[.])?\d*$/; // match escaped "." characters via in a non-capturing group

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputStyles> {
	onUserInput?: (input: string) => void;
}

export const numericalEnforcer = (
	nextUserInput: string,
	onUserInput?: (val: string) => void,
) => {
	if (
		(onUserInput && nextUserInput === "") ||
		numericalInputRegex.test(escapeRegExp(nextUserInput))
	) {
		onUserInput?.(nextUserInput);
	}
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			border,
			className,
			disabled,
			onUserInput,
			placeholder = "0.0",
			textAlign = "right",
			type = "text",
			...props
		},
		ref,
	) => {
		const enforcer = React.useCallback(
			(nextUserInput: string) => {
				return numericalEnforcer(nextUserInput, onUserInput);
			},
			[onUserInput],
		);

		return (
			<input
				type={type}
				ref={ref}
				className={inputStyles({
					border,
					class: className,
					disabled,
					textAlign,
				})}
				// universal input options
				inputMode="decimal"
				autoComplete="off"
				autoCorrect="off"
				// text-specific options
				// type="text"
				pattern="^[0-9]*[.,]?[0-9]*$"
				placeholder={placeholder}
				minLength={1}
				maxLength={79}
				spellCheck="false"
				onChange={
					disabled
						? undefined
						: (event) => {
								// replace commas with periods, because we exclusively uses period as the decimal separator
								enforcer(event.target.value.replaceAll(",", "."));
							}
				}
				disabled={disabled}
				{...props}
			/>
		);
	},
);
Input.displayName = "Input";
