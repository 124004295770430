import JSBI from "jsbi";
import { Address } from "abitype";

export interface AddressMap {
  readonly [chainId: number]: Address;
}

// exports for external consumption
export type BigintIsh = JSBI | number | string;

export const MaxUint256 = JSBI.BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

export enum ChainId {
  ETHEREUM = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  MATIC = 137,
  SKALE_EUROPA_TESTNET = 1_444_673_419,
  MATIC_TESTNET = 80_001,
  FANTOM = 250,
  FANTOM_TESTNET = 4002,
  XDAI = 100,
  BSC = 56,
  BSC_TESTNET = 97,
  ARBITRUM = 42_161,
  ARBITRUM_TESTNET = 79_377_087_078_960,
  MOONBEAM_TESTNET = 1287,
  AVALANCHE = 43_114,
  AVALANCHE_TESTNET = 43_113,
  HECO = 128,
  HECO_TESTNET = 256,
  HARMONY = 1_666_600_000,
  HARMONY_TESTNET = 1_666_700_000,
  OKEX = 66,
  OKEX_TESTNET = 65,
  APECHAIN = 33139,
  CELO = 42_220,
  PALM = 11_297_108_109,
  PALM_TESTNET = 11_297_108_099,
  MOONRIVER = 1285,
  FUSE = 122,
  TELOS = 40,
  HARDHAT = 31_337,
  MOONBEAM = 1284,
  OPTIMISM = 10,
  KAVA = 2222,
  METIS = 1088,
  ARBITRUM_NOVA = 42_170,
  TELOS_TESTNET = 41,
  CURTIS = 33_111,
}

export enum ChainKey {
  ARBITRUM = "arbitrum",
  ARBITRUM_TESTNET = "arbitrum-testnet",
  AVALANCHE = "avalanche",
  AVALANCHE_TESTNET = "avalance-testnet",
  CURTIS = "curtis",
  BSC = "bsc",
  BSC_TESTNET = "bsc-testnet",
  CELO = "celo",
  ETHEREUM = "ethereum",
  FANTOM = "fantom",
  FANTOM_TESTNET = "fantom-testnet",
  FUSE = "fuse",
  GÖRLI = "goerli",
  HARMONY = "harmony",
  HARMONY_TESTNET = "harmony-testnet",
  HECO = "heco",
  HECO_TESTNET = "heco-testnet",
  KOVAN = "kovan",
  ROPSTEN = "ropsten",
  MATIC = "polygon",
  MATIC_TESTNET = "matic-testnet",
  MOONBEAM_TESTNET = "moonbeam-testnet",
  MOONRIVER = "moonriver",
  OKEX = "okex",
  OKEX_TESTNET = "okex-testnet",
  PALM = "palm",
  PALM_TESTNET = "palm-testnet",
  RINKEBY = "rinkeby",
  TELOS = "telos",
  XDAI = "xdai",
  MOONBEAM = "moonbeam",
  OPTIMISM = "optimism",
  KAVA = "kava",
  METIS = "metis",
  ARBITRUM_NOVA = "arbitrum-nova",
  TELOS_TESTNET = "telos-testnet",
}

export const Chains: Record<
  number,
  {
    id: number;
    name: string;
    network: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: { default: string };
    blockExplorers: {
      default: { name: string; url: string };
    };
    ens?: {
      address: Address;
    };
    multicall?: {
      address: Address;
      blockCreated: number;
    };
    testnet?: boolean;
  }
> = {
  [ChainId.ARBITRUM]: {
    blockExplorers: {
      default: { name: "Arbiscan", url: "https://arbiscan.io" },
    },
    id: ChainId.ARBITRUM,
    name: "Arbitrum",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.ARBITRUM,
    rpcUrls: { default: "https://arb1.arbitrum.io/rpc" },
    testnet: false,
  },
  [ChainId.ARBITRUM_NOVA]: {
    blockExplorers: {
      default: {
        name: "Arbitrum Nova Explorer",
        url: "https://nova-explorer.arbitrum.io",
      },
    },
    id: ChainId.ARBITRUM_NOVA,
    name: "Arbitrum Nova",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
    network: ChainKey.ARBITRUM_NOVA,
    rpcUrls: { default: "https://a4ba.arbitrum.io/rpc" },
    testnet: false,
  },

  [ChainId.AVALANCHE]: {
    blockExplorers: {
      default: { name: "Avascan", url: "https://avascan.info/blockchain/c" },
    },
    id: ChainId.AVALANCHE,
    multicall: {
      address: "0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4",
      blockCreated: 4_458_029,
    },
    name: "Avalanche C-Chain",
    nativeCurrency: {
      decimals: 18,
      name: "Avalanche",
      symbol: "AVAX",
    },
    network: ChainKey.AVALANCHE,
    rpcUrls: { default: "https://api.avax.network/ext/bc/C/rpc" },
    testnet: false,
  },
  [ChainId.AVALANCHE_TESTNET]: {
    // rpcUrls: { default: 'https://rpc.ankr.com/avalanche_fuji' },
    blockExplorers: {
      default: {
        name: "Avascan",
        url: "https://testnet.avascan.info/blockchain/c",
      },
    },
    id: ChainId.AVALANCHE_TESTNET,
    multicall: {
      address: "0x758c9F170A9c306EDfCC5cd7A7A5134757b5eAce",
      blockCreated: 12_919_620,
    },
    name: "Avalanche Fuji",
    nativeCurrency: {
      decimals: 18,
      name: "Avalanche",
      symbol: "AVAX",
    },
    network: ChainKey.AVALANCHE_TESTNET,
    rpcUrls: { default: "https://api.avax-test.network/ext/bc/C/rpc" },
    testnet: true,
  },
  [ChainId.BSC]: {
    blockExplorers: {
      default: { name: "BSCScan", url: "https://bscscan.com" },
    },
    id: ChainId.BSC,
    name: "Binance Smart Chain",
    nativeCurrency: {
      decimals: 18,
      name: "Binance Coin",
      symbol: "BNB",
    },
    network: ChainKey.BSC,
    rpcUrls: { default: "https://bsc-dataseed.binance.org" },
    testnet: false,
  },
  [ChainId.CELO]: {
    blockExplorers: {
      default: { name: "Celo Explorer", url: "https://explorer.celo.org" },
    },
    id: ChainId.CELO,
    name: "Celo",
    nativeCurrency: {
      decimals: 18,
      name: "Celo",
      symbol: "CELO",
    },
    network: ChainKey.CELO,
    rpcUrls: { default: "https://forno.celo.org" },
    testnet: false,
  },
  [ChainId.CURTIS]: {
    blockExplorers: {
      default: {
        name: "Curtis Explorer",
        url: "https://curtis.explorer.caldera.xyz",
      },
    },
    id: ChainId.CURTIS,
    name: "Curtis",
    nativeCurrency: {
      decimals: 18,
      name: "ApeCoin",
      symbol: "APE",
    },
    network: ChainKey.CURTIS,
    rpcUrls: { default: "https://curtis.rpc.caldera.xyz/http" },
    testnet: false,
  },
  [ChainId.ETHEREUM]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://etherscan.com" },
    },
    id: ChainId.ETHEREUM,
    name: "Ethereum",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.ETHEREUM,
    rpcUrls: { default: "https://mainnet.infura.io/v3" },
    testnet: false,
  },
  [ChainId.FANTOM]: {
    blockExplorers: {
      default: { name: "FTMScan", url: "https://ftmscan.com" },
    },
    id: ChainId.FANTOM,
    name: "Fantom",
    nativeCurrency: {
      decimals: 18,
      name: "Fantom",
      symbol: "FTM",
    },
    network: ChainKey.FANTOM,
    rpcUrls: { default: "https://rpcapi.fantom.network" },
    testnet: false,
  },
  [ChainId.FUSE]: {
    blockExplorers: {
      default: { name: "Fuse Explorer", url: "https://explorer.fuse.io" },
    },
    id: ChainId.FUSE,
    name: "Fuse",
    nativeCurrency: {
      decimals: 18,
      name: "Fuse",
      symbol: "FUSE",
    },
    network: ChainKey.FUSE,
    rpcUrls: { default: "https://rpc.fuse.io" },
    testnet: false,
  },
  [ChainId.GÖRLI]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://goerli.etherscan.com" },
    },
    id: ChainId.GÖRLI,
    name: "Görli",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.GÖRLI,
    rpcUrls: { default: "https://goerli.infura.io/v3" },
    testnet: true,
  },
  [ChainId.HARMONY]: {
    blockExplorers: {
      default: {
        name: "Harmony Explorer",
        url: "https://explorer.harmony.one",
      },
    },
    id: ChainId.HARMONY,
    name: "Harmony",
    nativeCurrency: {
      decimals: 18,
      name: "One Token",
      symbol: "ONE",
    },
    network: ChainKey.HARMONY,
    rpcUrls: { default: "https://api.harmony.one" },
    testnet: false,
  },
  [ChainId.HECO]: {
    blockExplorers: {
      default: { name: "HecoInfo", url: "https://hecoinfo.com" },
    },
    id: ChainId.HECO,
    name: "Heco",
    nativeCurrency: {
      decimals: 18,
      name: "Heco Token",
      symbol: "HT",
    },
    network: ChainKey.HECO,
    rpcUrls: { default: "https://http-mainnet.hecochain.com" },
    testnet: false,
  },
  [ChainId.KAVA]: {
    blockExplorers: {
      default: { name: "Kava Explorer", url: "https://explorer.kava.io" },
    },
    id: ChainId.KAVA,
    name: "Kava",
    nativeCurrency: {
      decimals: 18,
      name: "Kava",
      symbol: "KAVA",
    },
    network: ChainKey.KAVA,
    rpcUrls: { default: "https://evm.kava.io" },
    testnet: false,
  },
  [ChainId.KOVAN]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://kovan.etherscan.com" },
    },
    id: ChainId.KOVAN,
    name: "Kovan",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.KOVAN,
    rpcUrls: { default: "https://kovan.infura.io/v3" },
    testnet: true,
  },
  [ChainId.MATIC]: {
    blockExplorers: {
      default: { name: "Polygonscan", url: "https://polygonscan.com" },
    },
    id: ChainId.MATIC,
    name: "Polygon",
    nativeCurrency: {
      decimals: 18,
      name: "Matic",
      symbol: "MATIC",
    },
    network: ChainKey.MATIC,
    rpcUrls: { default: "https://polygon-rpc.com" },
    testnet: false,
  },
  [ChainId.METIS]: {
    blockExplorers: {
      default: {
        name: "Metis Explorer",
        url: "https://andromeda-explorer.metis.io",
      },
    },
    id: ChainId.METIS,
    name: "Metis",
    nativeCurrency: {
      decimals: 18,
      name: "Metis",
      symbol: "METIS",
    },
    network: ChainKey.METIS,
    rpcUrls: { default: "https://andromeda.metis.io/?owner=1088" },
    testnet: false,
  },
  [ChainId.MOONBEAM]: {
    blockExplorers: {
      default: {
        name: "Moonbeam Explorer",
        url: "https://moonbeam.moonscan.io",
      },
    },
    id: ChainId.MOONBEAM,
    name: "Moonbeam",
    nativeCurrency: {
      decimals: 18,
      name: "Glimmer",
      symbol: "GLMR",
    },
    network: ChainKey.MOONBEAM,
    rpcUrls: { default: "https://rpc.api.moonbeam.network" },
    testnet: false,
  },
  [ChainId.MOONRIVER]: {
    blockExplorers: {
      default: {
        name: "Moonriver explorer",
        url: "https://moonriver.moonscan.io",
      },
    },
    id: ChainId.MOONRIVER,
    name: "Moonriver",
    nativeCurrency: {
      decimals: 18,
      name: "Moonriver",
      symbol: "MOVR",
    },
    network: ChainKey.MOONRIVER,
    rpcUrls: { default: "https://rpc.moonriver.moonbeam.network" },
    testnet: false,
  },
  [ChainId.OKEX]: {
    blockExplorers: {
      default: { name: "OKLink", url: "https://www.oklink.com/okexchain" },
    },
    id: ChainId.OKEX,
    name: "OKEx",
    nativeCurrency: {
      decimals: 18,
      name: "OKEx Token",
      symbol: "OKT",
    },
    network: ChainKey.OKEX,
    rpcUrls: { default: "https://exchainrpc.okex.org" },
    testnet: false,
  },
  [ChainId.OPTIMISM]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://optimistic.etherscan.io" },
    },
    id: ChainId.OPTIMISM,
    name: "Optimism",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
    network: ChainKey.OPTIMISM,
    rpcUrls: { default: "https://mainnet.optimism.io" },
    testnet: false,
  },
  [ChainId.PALM]: {
    blockExplorers: {
      default: { name: "Palm Explorer", url: "https://explorer.palm.io" },
    },
    id: ChainId.PALM,
    name: "Palm",
    nativeCurrency: {
      decimals: 18,
      name: "Palm",
      symbol: "PALM",
    },
    network: ChainKey.PALM,
    rpcUrls: {
      default:
        "https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267",
    },
    testnet: false,
  },
  [ChainId.RINKEBY]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://rinkeby.etherscan.com" },
    },
    id: ChainId.RINKEBY,
    name: "Rinkeby",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.RINKEBY,
    rpcUrls: { default: "https://rinkeby.infura.io/v3" },
    testnet: true,
  },
  [ChainId.ROPSTEN]: {
    blockExplorers: {
      default: { name: "Etherscan", url: "https://ropsten.etherscan.com" },
    },
    id: ChainId.ROPSTEN,
    name: "Ropsten",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    network: ChainKey.ROPSTEN,
    rpcUrls: { default: "https://ropsten.infura.io/v3" },
    testnet: true,
  },
  [ChainId.TELOS]: {
    blockExplorers: {
      default: {
        name: "Teloscan",
        url: "https://www.teloscan.io/",
      },
    },
    id: ChainId.TELOS,
    name: "Telos",
    nativeCurrency: {
      decimals: 18,
      name: "Telos",
      symbol: "TLOS",
    },
    network: ChainKey.TELOS,
    rpcUrls: { default: "https://mainnet15.telos.net/evm" },
    testnet: false,
  },
  [ChainId.TELOS_TESTNET]: {
    blockExplorers: {
      default: {
        name: "Telos Explorer",
        url: "https://testnet.teloscan.io/",
      },
    },
    id: ChainId.TELOS_TESTNET,
    name: "Teloscan (testnet)",
    nativeCurrency: {
      decimals: 18,
      name: "Telos",
      symbol: "TLOS",
    },
    network: ChainKey.TELOS_TESTNET,
    rpcUrls: { default: "https://testnet.telos.net/evm	" },
    testnet: true,
  },
  [ChainId.XDAI]: {
    blockExplorers: {
      default: { name: "Blockscout", url: "https://blockscout.com/poa/xdai" },
    },
    id: ChainId.XDAI,
    name: "xDai",
    nativeCurrency: {
      decimals: 18,
      name: "xDai Token",
      symbol: "xDai",
    },
    network: ChainKey.XDAI,
    rpcUrls: { default: "https://rpc.gnosischain.com" },
    testnet: false,
  },
};

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const VAPE_TOKEN_ADDRESS_MAP = {
  [ChainId.AVALANCHE]: "0xE5353268d40Fd34f88818910Cd20eEcF02678AcE",
  [ChainId.AVALANCHE_TESTNET]: "0x",
};

export const STABLE_VAPE_STAKING_ADDRESS_MAP: AddressMap = {
  [ChainId.AVALANCHE]: "0x5Af56D5574fF13D3b979b85856f043f2ca457bE2",
  [ChainId.AVALANCHE_TESTNET]: "0x5Af56D5574fF13D3b979b85856f043f2ca457bE2",
};

export const FACTORY_ADDRESS_MAP: AddressMap = {
  [ChainId.AVALANCHE]: "0xC009a670E2B02e21E7e75AE98e254F467f7ae257",
  [ChainId.AVALANCHE_TESTNET]: "0xC009a670E2B02e21E7e75AE98e254F467f7ae257",
  [ChainId.CURTIS]: "0x19C0FC4562A4b76F27f86c676eF5a7e38D12a20d",
  [ChainId.APECHAIN]: "0xC009a670E2B02e21E7e75AE98e254F467f7ae257",
  [ChainId.SKALE_EUROPA_TESTNET]: "0xaacBE7601F589464cd27B09Ba87478fA1396Ed3C",
  [ChainId.TELOS]: "0xDef9ee39FD82ee57a1b789Bc877E2Cbd88fd5caE",
  [ChainId.TELOS_TESTNET]: "0xf24Ebb2B7dF7ab655905a5946EA50D70f4db23c7",
};

export const ROUTER_ADDRESS_MAP = {
  [ChainId.AVALANCHE]: "0x19C0FC4562A4b76F27f86c676eF5a7e38D12a20d",
  [ChainId.AVALANCHE_TESTNET]: "0x19C0FC4562A4b76F27f86c676eF5a7e38D12a20d",
  [ChainId.APECHAIN]: "0x19C0FC4562A4b76F27f86c676eF5a7e38D12a20d",
  [ChainId.CURTIS]: "0xe9FD2c0dF51dfEFB6Ae63033893e6b8D248F9b8B",
  [ChainId.SKALE_EUROPA_TESTNET]: "0xA5F30ab3453B68BA604770bcb52D911c471F7e65",
  [ChainId.TELOS]: "0xA05CF3F2d0B7b41458A60CB1991ccbBefD807C73",
  [ChainId.TELOS_TESTNET]: "0x7eAdEcEC3D20d965a65459a71bcA8386155Bf624",
};

export const INIT_CODE_HASH_MAP: AddressMap = {
  [ChainId.AVALANCHE]:
    "0x5535878326bb95214a15436b03ccd939cc50793ba2e1c97c33a23473a9d7f4ad",
  [ChainId.AVALANCHE_TESTNET]:
    "0x5535878326bb95214a15436b03ccd939cc50793ba2e1c97c33a23473a9d7f4ad",
  [ChainId.CURTIS]:
    "0x66645ab15d7cacccce15526e9c314eac11cd206d554f679383c58397237bcece",
  [ChainId.APECHAIN]:
    "0x66645ab15d7cacccce15526e9c314eac11cd206d554f679383c58397237bcece",
  [ChainId.SKALE_EUROPA_TESTNET]:
    "0x66645ab15d7cacccce15526e9c314eac11cd206d554f679383c58397237bcece",
  [ChainId.TELOS]:
    "0x66645ab15d7cacccce15526e9c314eac11cd206d554f679383c58397237bcece",
  [ChainId.TELOS_TESTNET]:
    "0x66645ab15d7cacccce15526e9c314eac11cd206d554f679383c58397237bcece",
};

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const TWO = JSBI.BigInt(2);
export const THREE = JSBI.BigInt(3);
export const FIVE = JSBI.BigInt(5);
export const TEN = JSBI.BigInt(10);
export const _100 = JSBI.BigInt(100);
export const _997 = JSBI.BigInt(997);
export const _1000 = JSBI.BigInt(1000);
export const FEES_NUMERATOR = JSBI.BigInt(9975);
export const FEES_DENOMINATOR = JSBI.BigInt(10_000);

export enum SolidityType {
  uint8 = "uint8",
  uint256 = "uint256",
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt("0xff"),
  [SolidityType.uint256]: JSBI.BigInt(
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  ),
};
