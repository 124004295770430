import ListsUpdater from "@state/lists/updater";
import MulticallUpdater from "@state/multicall/updater";
import TransactionUpdater from "@state/transactions/updater";

function Updaters() {
	return (
		<>
			<ListsUpdater />
			<TransactionUpdater />
			<MulticallUpdater />
		</>
	);
}

export default Updaters;
