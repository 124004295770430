import {
	Box,
	Container,
	type ContainerProps,
	Typography,
} from "@vaporfi/uikit";
import { forwardRef, memo, useMemo } from "react";
import type { Address } from "viem";

export const Wrapper = forwardRef<HTMLDivElement, ContainerProps>(
	(props, ref) => {
		return (
			<Container
				stack="column"
				className="gap-2 rounded-lg bg-white p-3"
				fullWidth
				{...props}
				ref={ref}
			/>
		);
	},
);
export const Header = memo(
	({
		author,
		creationTime,
		isDev = false,
	}: {
		author?: Address;
		creationTime?: Date | string;
		isDev?: boolean;
	}) => {
		"use client";
		const createdAt = useMemo(() => {
			const date = new Date(creationTime ?? "");
			const value = `${date
				?.toLocaleDateString()
				?.replaceAll("/", ".")}, ${date?.toLocaleTimeString()}`;
			return value;
		}, [creationTime]);
		return (
			<Box className="flex items-center gap-2">
				<Box className="bg-purple-5 w-fit px-3 py-1">
					<Typography color="dark">{`${author?.slice(0, 6) ?? ""} ${
						isDev ? "(dev)" : ""
					}`}</Typography>
				</Box>
				<Typography color="dark">{createdAt}</Typography>
			</Box>
		);
	},
);
