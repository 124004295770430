import { Button, Typography } from "@vaporfi/uikit";
import Link from "next/link";
import type React from "react";
import { Season } from "./Season";

interface PastSeasonsViewProps {
	account: string;
	sortedSeasons: any[];
	handleDatesSorting: () => void;
	handleRankParticipantsSorting: (column: string) => void;
	sortingState: { column: string; direction: string };
	isLoadingNonUserSpecific: boolean;
	isLoadingUserSpecific: boolean;
}

export const PastSeasonsView: React.FC<PastSeasonsViewProps> = ({
	account,
	handleDatesSorting,
	handleRankParticipantsSorting,
	isLoadingNonUserSpecific,
	isLoadingUserSpecific,
	sortedSeasons,
	sortingState,
}) => {
	const mobileInfoItem = (
		label: string,
		value: string | JSX.Element,
		additionalStyles = "",
	) => (
		<div
			className={`flex ${
				label === "Season" ? "justify-start" : "justify-between"
			} ml-2 py-2 sm:hidden ${additionalStyles}`}
		>
			<Typography size="base" color="light">
				{label}
				{label !== "Season" && ":"}
			</Typography>
			<Typography size="base" color="light">
				&nbsp;{value}
			</Typography>
		</div>
	);

	return (
		<div className="relative mx-4 rounded-xl bg-black p-6">
			<Link href="/earn/vape">
				<div className="absolute right-2.5 top-2.5 transform transition-transform hover:scale-105 hover:shadow-lg sm:hidden">
					<Button color="gradient" size="xs">
						<Typography size="xs" color="light" weight="semibold">
							View Current Season
						</Typography>
					</Button>
				</div>
			</Link>

			<Typography
				size="sm"
				color="light"
				weight="bold"
				className="col-span-6 mb-4 sm:hidden md:mb-6 md:text-xl"
			>
				Past Seasons
			</Typography>

			<div className="hidden sm:block">
				<Link href="/earn/vape">
					<div className="absolute right-2.5 top-2.5 transform transition-transform hover:scale-105 hover:shadow-lg">
						<Button color="gradient" size="base">
							<Typography color="turquoise" weight="semibold">
								Back to Current Season
							</Typography>
						</Button>
					</div>
				</Link>

				<Typography
					size="xl"
					color="light"
					weight="bold"
					className="col-span-6 mb-6"
				>
					Past Seasons
				</Typography>

				<div
					style={{ borderBottom: "2px solid #2D3748" }}
					className={`grid ${account ? "grid-cols-8" : "grid-cols-4"} gap-4`}
				>
					{[
						"Dates",
						"Participants",
						...(account
							? ["Your VPND", "Your VAPE", "Your Points", "Your Rank"]
							: []),
						"Total VPND Locked",
						"Total VAPE Distributed",
					].map((header, index) => (
						<div
							key={`past-seasons-header-${header}`}
							className="cursor-pointer pb-2 text-center text-gray-500"
							onClick={() => {
								if (header === "Dates") handleDatesSorting();
								else if (
									[
										"Participants",
										"Your Rank",
										"Your VPND",
										"Your Points",
									].includes(header)
								) {
									handleRankParticipantsSorting(header);
								}
							}}
						>
							<Typography
								size="base"
								color="neutral"
								weight="normal"
								className="sm:hidden"
							>
								{header}:{" "}
							</Typography>
							<Typography
								size="xl"
								color="neutral"
								weight="normal"
								className="hidden sm:inline"
							>
								{header}
							</Typography>
							{sortingState.column === header &&
								(sortingState.direction === "asc" ? "⇅" : "⇵")}
						</div>
					))}
				</div>
			</div>

			{isLoadingNonUserSpecific ? (
				<Typography size="lg" color="light" className="mt-4 text-center">
					Loading data...
				</Typography>
			) : (
				sortedSeasons.map((seasonData) => (
					<Season
						key={seasonData.seasonId}
						seasonData={seasonData}
						account={account}
						isLoadingUserSpecific={isLoadingUserSpecific}
						mobileInfoItem={mobileInfoItem}
					/>
				))
			)}
		</div>
	);
};
