import GlobalSettings from "@components/Menu/GlobalSettings";
import NetworkSelector from "@components/Menu/NetworkSelector";
import UserMenu from "@components/Menu/UserMenu";
import AppBar from "@mui/material/AppBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { useUserIsStratosphereMember } from "@state/user/hooks";
import { ChevronRightIcon, useMatchBreakpointsContext } from "@vapordex/uikit";
import { Typography } from "@vaporfi/uikit";
import Link from "next/link";
import { forwardRef } from "react";
import SaveRewards from "./SaveRewards";
import StratIndicator from "./StratosphereIndicator";

const HeaderText = forwardRef<HTMLParagraphElement, { text: string }>(
	({ text }, ref) => {
		return (
			<Typography ref={ref} weight="semibold" capital pointer>
				{text}
			</Typography>
		);
	},
);

export default function Header({ breadcrumbs = [] }) {
	const { isMobile, isTablet } = useMatchBreakpointsContext();
	const isStratosphereMember = useUserIsStratosphereMember();
	return (
		<AppBar
			position="relative"
			elevation={0}
			sx={{
				alignItems: "center",
				backgroundColor: "transparent",
				borderBottom: "1px solid #262626",
				display: "flex",
				flexDirection: isMobile ? "column" : "row",
				gap: isMobile ? 1 : undefined,
				justifyContent: "space-between",
				paddingX: "30px",
				paddingY: "16px",
				zIndex: 19,
			}}
		>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
				}}
			>
				{!isMobile && !isTablet && (
					<>
						<Breadcrumbs
							aria-label="breadcrumb"
							sx={{
								minWidth: "30%",
							}}
						>
							{breadcrumbs.map(
								(crumb, index) =>
									index === 0 && (
										<Box
											display="flex"
											key={`${crumb?.page}-${index}`}
											data-testid="header-breadcrumbs"
										>
											<Link
												href={breadcrumbs[index]?.path}
												legacyBehavior
												data-testid="header-breadcrumbs-path"
											>
												<HeaderText text={crumb?.page} />
											</Link>
											{!!crumb?.currenciesLabel?.length && <ChevronRightIcon />}
											{!isMobile && (
												<HeaderText
													text={crumb?.currenciesLabel}
													data-testid="header-breadcrumbs-currencies-label"
												/>
											)}
										</Box>
									),
							)}
						</Breadcrumbs>

						<Grid
							spacing={2}
							container
							alignItems="center"
							justifyContent={"flex-end"}
						>
							<Grid item>
								<NetworkSelector color="#D6DADA" mr="0" />
							</Grid>
							{isStratosphereMember && (
								<Grid item>
									<StratIndicator />
								</Grid>
							)}
							<Grid item>
								<GlobalSettings color="#D6DADA" mr="0" />
							</Grid>
							{!isStratosphereMember && (
								<Grid item>
									<SaveRewards />
								</Grid>
							)}
							<Grid item>
								<UserMenu />
							</Grid>
						</Grid>
					</>
				)}

				{(isMobile || isTablet) && (
					<Box
						display="flex"
						width="100%"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box left={0}>
							<Breadcrumbs
								aria-label="breadcrumb"
								sx={{ marginLeft: -2, minWidth: "30%" }}
							>
								{breadcrumbs.map(
									(crumb, index) =>
										index === 0 && (
											<Box display="flex" key={`${crumb?.page}-${index}`}>
												<Link href={breadcrumbs[index]?.path} legacyBehavior>
													<HeaderText text={crumb?.page} />
												</Link>
												{!!crumb?.currenciesLabel?.length && (
													<ChevronRightIcon />
												)}
												<HeaderText text={crumb?.currenciesLabel} />
											</Box>
										),
								)}
							</Breadcrumbs>
						</Box>
						<Box display="flex" gap={2} alignItems="center">
							<Box>
								<NetworkSelector color="#D6DADA" />
							</Box>

							{isStratosphereMember && (
								<Box>
									<StratIndicator />
								</Box>
							)}

							<Box>
								<UserMenu />
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</AppBar>
	);
}
