import { AlertCard, Button, Container, Typography } from "@vaporfi/uikit";
import Link from "next/link";

export const LowBalanceInfo = ({
	balance,
	href,
	onBuyUSDC,
}: {
	balance: string;
	onBuyUSDC?: () => void;
	href?: string;
}) => {
	return (
		<AlertCard variant="info" showIcon>
			<Typography weight="semibold" size="base">
				Your available balance is {balance} USDC, which sets a maximum limit on
				the value of passes you can purchase.
			</Typography>
			<Link href={href || ""} legacyBehavior>
				<Button color="gradient" size="sm" onClick={onBuyUSDC} className="mt-2">
					<Typography color="turquoise">Buy USDC</Typography>
				</Button>
			</Link>
		</AlertCard>
	);
};

export const LowAllowanceInfo = ({
	isApprovalPending,
	loadingText,
	onApprove,
}: {
	onApprove?: () => void;
	isApprovalPending?: boolean;
	loadingText?: string | JSX.Element;
}) => {
	return (
		<AlertCard variant="info" showIcon>
			<Typography weight="semibold" className="leading-5">
				To get your first Mining Pass, you need to approve the contract for
				transferring your USDC tokens. The minimum approved amount should be 100
				USDC, which corresponds to the highest-priced mining pass tier.
			</Typography>
			<Button
				color="gradient"
				onClick={onApprove}
				disabled={isApprovalPending}
				size="sm"
				className="mt-2"
			>
				<Typography color="turquoise">
					{isApprovalPending ? loadingText : "Approve"}
				</Typography>
			</Button>
		</AlertCard>
	);
};

export const PriceDecayInfo = ({
	discountPercentage,
}: {
	discountPercentage: string;
}) => {
	return (
		<AlertCard variant="info" showIcon>
			<Container stack="column" className="gap-4">
				<Typography size="base" weight="semibold">
					All mining pass are currently {discountPercentage}% off.
				</Typography>
				<Typography size="base">
					Mining Pass prices decay over the course of the season to account for
					reduced mining timeframes.
				</Typography>
			</Container>
		</AlertCard>
	);
};
