import useAuth from "@hooks/useAuth";
import { WalletConnectModal } from "@vaporfi/features";
import { useModal } from "@vaporfi/hooks";
import { Button, type ButtonProps, Typography } from "@vaporfi/uikit";

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
	const { login } = useAuth();
	const [onPresentConnectModal] = useModal(
		<WalletConnectModal login={login} />,
	);

	return (
		<Button
			data-testid="connect-wallet-btn"
			onClick={onPresentConnectModal}
			color="gradient"
			size="base"
			css={{ height: "var(--space-10)" }}
			{...props}
		>
			<Typography color="turquoise" weight="semibold">
				{children || "Connect Wallet"}
			</Typography>
		</Button>
	);
};

export default ConnectWalletButton;
