"use client";

import { useEffect, useState } from "react";

export const useIsIntersecting = (ref: any) => {
	const [isIntersecting, setIntersecting] = useState<boolean>(false);

	useEffect(() => {
		if (ref?.current) {
			const observer = new IntersectionObserver(([entry]) =>
				setIntersecting(entry.isIntersecting),
			);
			observer.observe(ref.current);

			return () => {
				observer.disconnect();
			};
		}
	}, [ref]);

	return isIntersecting;
};
