import { SupportedChainId } from "@vaporfi/utils";

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
	SupportedChainId,
).filter((id) => typeof id === "number") as SupportedChainId[];

export function isSupportedChain(
	chainId: number | null | undefined,
): chainId is SupportedChainId {
	return !!chainId && !!SupportedChainId[chainId];
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
	SupportedChainId.AVALANCHE,
	SupportedChainId.AVALANCHE_TESTNET,
] as const;

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [] as const;

export const MAINNET_CHAIN_IDS = [
	SupportedChainId.AVALANCHE,
	SupportedChainId.TELOS,
] as const;

export type SupportedMainnetChainId = (typeof MAINNET_CHAIN_IDS)[number];

export const TESTNET_CHAIN_IDS = [
	SupportedChainId.AVALANCHE_TESTNET,
	SupportedChainId.TELOS_TESTNET,
	SupportedChainId.CURTIS,
	SupportedChainId.SKALE_EUROPA_TESTNET,
] as const;

export type SupportedTestnetChainId = (typeof TESTNET_CHAIN_IDS)[number];

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [SupportedChainId.AVALANCHE] as const;

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [] as const;

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number];

export { CHAIN_ID_TO_QUERY_NAME, SupportedChainId } from "@vaporfi/utils";
