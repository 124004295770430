"use client";

import { cn } from "@vaporfi/utils";
import type { ComponentProps } from "react";
import { DayPicker, type DayPickerProps } from "react-day-picker";
import { buttonStyles } from "../Button";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
	className,
	classNames,
	mode = "single",
	showOutsideDays = true,
	...props
}: Omit<DayPickerProps, "selected">) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			mode={mode}
			className={cn("p-3", className)}
			classNames={{
				...classNames,
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium text-light",
				cell: cn(
					"relative p-0 text-center text-black text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
					mode === "range"
						? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
						: "[&:has([aria-selected])]:rounded-md",
				),
				day: cn(
					buttonStyles({ size: "xs" }),
					"h-8 w-8 p-0 font-normal aria-selected:opacity-100 text-light",
				),
				day_disabled: "text-neutral-50 opacity-50",
				day_hidden: "invisible",
				day_outside:
					"day-outside text-neutral-50 opacity-50 aria-selected:bg-accent/50 aria-selected:text-neutral-50 aria-selected:opacity-30",
				day_range_end: "day-range-end",
				day_range_middle:
					"aria-selected:bg-accent aria-selected:text-accent-foreground",
				day_range_start: "day-range-start",
				day_selected:
					"bg-black border-2 border-solid border-light rounded-full font-semibold",
				day_today: "text-turquoise-500",
				head_cell: "text-neutral-50 rounded-md w-8 font-normal text-[0.8rem]",
				head_row: "flex",
				month: "space-y-4",
				months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
				nav: "space-x-1 flex items-center",
				nav_button: cn(
					buttonStyles({ variant: "outline" }),
					"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				),
				nav_button_next: "absolute right-1",
				nav_button_previous: "absolute left-1",
				row: "flex w-full mt-2",
				table: "w-full border-collapse",
			}}
			components={{
				IconLeft: () => <ChevronLeftIcon className="h-4 w-4" />,
				IconRight: () => <ChevronRightIcon className="h-4 w-4" />,
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
