import type { Currency, Token } from "@vapordex/sdk";
import { Button, Typography } from "@vaporfi/uikit";
import { memo, useMemo } from "react";

export interface TokenApprovalButtonProps {
	approveCallback: () => void;
	isApprovalDisabled: boolean;
	isApprovalPending: boolean;
	token: Token | Currency;
}
export const TokenApprovalButton = memo(
	({
		approveCallback,
		isApprovalDisabled,
		isApprovalPending,
		token,
		...rest
	}: TokenApprovalButtonProps) => {
		const approvalText = useMemo(() => {
			const symbol = token?.symbol;
			if (isApprovalPending) return `Approving ${symbol}`;
			return `Approve ${symbol}`;
		}, [isApprovalPending, token]);

		return (
			<Button
				color="blue"
				onClick={approveCallback}
				disabled={isApprovalDisabled}
				fullWidth
				size="base"
				{...rest}
			>
				<Typography>{approvalText}</Typography>
			</Button>
		);
	},
);
