import { SlippageTabsView } from "@vaporfi/features";
import {
	SLIPPAGE_TOLERANCE_HIGH,
	SLIPPAGE_TOLERANCE_MEDIUM,
	escapeRegExp,
} from "@vaporfi/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	useUserSlippageTolerance,
	useUserTransactionTTL,
} from "state/user/hooks";

enum SlippageError {
	InvalidInput = "InvalidInput",
	RiskyLow = "RiskyLow",
	RiskyHigh = "RiskyHigh",
}

enum DeadlineError {
	InvalidInput = "InvalidInput",
}
const THREE_DAYS_IN_SECONDS = 60 * 60 * 24 * 3;
const inputRegex = /^\d*(?:\\[.])?\d*$/;
export const SlippageTabs = () => {
	const [userSlippageTolerance, setUserSlippageTolerance] =
		useUserSlippageTolerance();
	const [transactionTTL, setTransactionTTL] = useUserTransactionTTL();
	const [slippageInput, setSlippageInput] = useState("");
	const [deadlineInput, setDeadlineInput] = useState("");
	const [deadlineError, setDeadlineError] = useState<DeadlineError>();

	const parseCustomSlippage = useCallback(
		(value: string) => {
			if (value === "" || inputRegex.test(escapeRegExp(value))) {
				setSlippageInput(value);

				try {
					const valueAsIntFromRoundedFloat = Number.parseInt(
						(Number.parseFloat(value) * 100).toString(),
					);
					if (
						!Number.isNaN(valueAsIntFromRoundedFloat) &&
						valueAsIntFromRoundedFloat < 5000
					) {
						setUserSlippageTolerance(valueAsIntFromRoundedFloat);
					}
				} catch (error) {
					console.error(error);
				}
			}
		},
		[setUserSlippageTolerance],
	);

	const isSlippageInputValid = useMemo(
		() => Boolean(slippageInput) || Boolean(userSlippageTolerance),
		[slippageInput, userSlippageTolerance],
	);

	const deadlineInputIsValid =
		deadlineInput === "" || (transactionTTL / 60).toString() === deadlineInput;

	const slippageError: SlippageError | undefined = useMemo(() => {
		if (slippageInput !== "" && !isSlippageInputValid) {
			return SlippageError.InvalidInput;
		}
		if (
			isSlippageInputValid &&
			userSlippageTolerance < SLIPPAGE_TOLERANCE_MEDIUM
		) {
			return SlippageError.RiskyLow;
		}
		if (
			isSlippageInputValid &&
			userSlippageTolerance > SLIPPAGE_TOLERANCE_HIGH
		) {
			return SlippageError.RiskyHigh;
		}
		return;
	}, [isSlippageInputValid, slippageInput, userSlippageTolerance]);

	useEffect(() => {
		if (deadlineInput !== "" && !deadlineInputIsValid) {
			setDeadlineError(DeadlineError.InvalidInput);
		}
	}, [deadlineInput, deadlineInputIsValid]);

	const parseCustomDeadline = useCallback(
		(value: string) => {
			setDeadlineInput(value);

			try {
				const valueAsInt: number = Number.parseInt(value) * 60;
				if (
					!Number.isNaN(valueAsInt) &&
					valueAsInt > 60 &&
					valueAsInt < THREE_DAYS_IN_SECONDS
				) {
					setTransactionTTL(valueAsInt);
				} else {
					setDeadlineError(DeadlineError.InvalidInput);
				}
			} catch (error) {
				console.error(error);
			}
		},
		[setTransactionTTL],
	);

	return (
		<SlippageTabsView
			userSlippageTolerance={userSlippageTolerance}
			slippageInput={slippageInput}
			setSlippageInput={setSlippageInput}
			isSlippageInputValid={isSlippageInputValid}
			slippageError={slippageError ? slippageError.toString() : null}
			setUserSlippageTolerance={setUserSlippageTolerance}
			parseCustomSlippage={parseCustomSlippage}
			transactionTTL={transactionTTL}
			setTransactionTTL={setTransactionTTL}
			deadlineInput={deadlineInput}
			deadlineError={deadlineError}
			parseCustomDeadline={parseCustomDeadline}
		/>
	);
};
