export const neutral = {
	50: "#FAFAFA",
	100: "#F5F5F5",
	200: "#E5E5E5",
	300: "#D4D4D4",
	400: "#A3A3A3",
	500: "#737373",
	600: "#525252",
	700: "#404040",
	800: "#262626",
	900: "#171717",
};

export const blue = {
	50: "#AAC6FA",
	100: "#83ADF7",
	200: "#5D93F5",
	300: "#367AF2",
	400: "#BDD3FB",
	500: "#1060F0",
	600: "#0C4BBC",
	700: "#083687",
	800: "#052153",
	900: "#020C1E",
};

export const turquoise = {
	50: "#ACEFF9",
	100: "#86E8F6",
	200: "#60E1F3",
	300: "#3ADAF0",
	400: "#BFF3FA",
	500: "#14D3ED",
	600: "#0EA6BB",
	700: "#0A7886",
	800: "#064952",
	900: "#021B1E",
};

export const green = {
	50: "#99F1E4",
	100: "#75EDDB",
	200: "#51E8D2",
	300: "#2DE3C8",
	400: "#ABF4E9",
	500: "#1BCCB2",
	600: "#149A87",
	700: "#0E695C",
	800: "#073730",
	900: "#010605",
};

export const named = {
	black: "#000000",
	blackBg: "#0f0f0f",
	error: "#ff1744",
	light: "#D6DADA",
	none: "transparent",
	warning: "#ffc400",
	white: "#ffffff",
};

export const colors = {
	blue,
	green,
	named,
	neutral,
	turquoise,
};
