import { CoreWalletIcon, GnosisSafeIcon } from "../../components/Svg";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import Metamask from "../../components/Svg/Icons/Metamask";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import { type Config, ConnectorNames } from "./types";

const connectors: Config[] = [
	{
		connectorId: ConnectorNames.Core,
		href: "https://core.app/",
		icon: CoreWalletIcon,
		priority: 1,
		title: "Core",
	},
	{
		connectorId: ConnectorNames.MetaMask,
		href: "https://metamask.io/download/",
		icon: Metamask,
		priority: 2,
		title: "Metamask",
	},
	{
		connectorId: ConnectorNames.Coinbase,
		icon: CoinbaseWallet,
		priority: 3,
		title: "Coinbase Wallet",
	},
	{
		connectorId: ConnectorNames.Injected,
		href: "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://app.vapordex.io/",
		icon: TrustWallet,
		priority: 4,
		title: "Trust Wallet",
	},
	// {
	//   connectorId: ConnectorNames.Ledger,
	//   icon: Ledger,
	//   priority: 5,
	//   title: 'Ledger',
	// },
	{
		connectorId: ConnectorNames.GnosisSafe,
		href: "https://safe.global/",
		icon: GnosisSafeIcon,
		priority: 6,
		title: "Safe",
	},
	{
		connectorId: ConnectorNames.WalletConnect,
		icon: WalletConnect,
		priority: 7,
		title: "WalletConnect",
	},
	// {
	//   title: "Opera Wallet",
	//   icon: Opera,
	//   connectorId: ConnectorNames.Injected,
	//   priority: () => {
	//     return typeof window !== "undefined" && Boolean(window?.ethereum?.isOpera)
	//       ? 0
	//       : 8;
	//   },
	//   href: "https://www.opera.com/crypto/next",
	// },
	// TODO: Stargazer only support Ethereum, it doesn't support network switching
	// {
	//   title: "Stargazer",
	//   icon: Stargazer,
	//   connectorId: ConnectorNames.Stargazer,
	//   priority: 6,
	// },
];

// eslint-disable-next-line turbo/no-undeclared-env-vars
if (process.env.VERCEL_ENV && process.env.VERCEL_ENV !== "production") {
	connectors.push({
		connectorId: ConnectorNames.Mock,
		href: "https://app.vapordex.io/",
		icon: CoreWalletIcon,
		priority: 0,
		title: "Mock",
	});
}

export default connectors;
export const connectorLocalStorageKey = "connectorIdv2";
export const walletLocalStorageKey = "wallet";

export const walletConnectConfig = connectors.find(
	(c) => c.title === "WalletConnect",
);
