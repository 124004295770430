import Image from "next/image";
import { type ComponentProps, forwardRef } from "react";
import { type VariantProps, tv } from "tailwind-variants";

const coinStyles = tv({
	base: "rounded-full h-[101px] w-[101px]",
});

type CoinVariants = VariantProps<typeof coinStyles>;

export type CoinProps = {
	alt?: string;
	src?: string;
	className?: string;
	width?: number;
} & CoinVariants &
	ComponentProps<"div">;

export const Coin = forwardRef<HTMLDivElement, CoinProps>(
	({ alt, className, src, width }, ref) => {
		return (
			<div className={coinStyles({ class: className })} ref={ref}>
				{src && <Image src={src} alt={alt || "coin image"} width={width} />}
			</div>
		);
	},
);
