import type { HTMLProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";

export const boxStyles = tv(
	{
		base: "box-border outline-none",
		variants: {
			bg: {
				black: "bg-black",
				blue: "bg-blue-500",
				ghost: "bg-transparent",
				gradient: "bg-gradient-stratosphere",
				gradientReverted: "bg-gradient-stratosphere-reverse",
				neutral: "bg-neutral-900",
				neutral50: "bg-neutral-50",
				neutral100: "bg-neutral-100",
			},
			fullWidth: { true: "w-full" },
			hideInMobile: { true: "max-lg:hidden" },
		},
	},
	{ responsiveVariants: true },
);
export interface BoxProps
	extends VariantProps<typeof boxStyles>,
		HTMLProps<HTMLDivElement> {}

export const Box = ({
	bg,
	children,
	className,
	fullWidth,
	hideInMobile,
	...rest
}: BoxProps) => {
	return (
		<div
			className={boxStyles({ bg, class: className, fullWidth, hideInMobile })}
			{...rest}
		>
			{children}
		</div>
	);
};
