import { ChainId, ChainKey } from "@vapordex/sdk";
import type { Chain } from "viem";

export const skaleEuropaTestnet = {
	blockExplorers: {
		default: {
			name: "Europa Liquidity Hub explorer",
			url: "https://juicy-low-small-testnet.explorer.testnet.skalenodes.com/",
		},
	},
	contracts: {
		multicall3: {
			address: "0x0852B26500Bf3625a1538FE22E245B9CCd826fbe",
			blockCreated: 3_206_107,
		},
	},
	id: ChainId.SKALE_EUROPA_TESTNET,
	name: "SKALE Europa",
	nativeCurrency: {
		decimals: 18,
		symbol: "sFUEL",
		name: "SKale Token",
	},
	network: ChainKey.CURTIS,
	rpcUrls: {
		default: {
			http: ["https://testnet.skalenodes.com/v1/juicy-low-small-testnet"],
		},
		public: {
			http: ["https://testnet.skalenodes.com/v1/juicy-low-small-testnet"],
		},
	} as const,
	testnet: true,
} as Chain;
