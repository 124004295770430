import { forwardRef } from "react";
import type { HTMLProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";

export const typographyStyles = tv({
	defaultVariants: { color: "light", weight: "normal" },
	variants: {
		capital: { true: "capitalize" },
		color: {
			black: "text-black",
			blue: "text-blue-500",
			dark: "text-dark",
			green: "text-green",
			light: "text-light",
			neutral: "text-neutral-500",
			purple1: "text-purple-1",
			purple3: "text-purple-3",
			red: "text-red",
			turquoise: "text-turquoise-500",
		},
		pointer: { true: "cursor-pointer" },
		size: {
			"2xl": "text-2xl",
			"3xl": "text-3xl",
			"4xl": "text-4xl",
			"5xl": "text-5xl",
			"6xl": "text-6xl",
			"7xl": "text-7xl",
			"8xl": "text-8xl",
			"9xl": "text-9xl",
			base: "text-base",
			default: "text-xs sm:text-sm lg:text-base",
			lg: "text-lg",
			sm: "text-sm",
			xl: "text-xl",
			xs: "text-xs",
		},
		underline: {
			true: "underline",
		},
		weight: {
			bold: "font-bold",
			normal: "font-normal",
			semibold: "font-semibold",
		},
	},
});

export interface TypographyProps
	extends VariantProps<typeof typographyStyles>,
		Omit<HTMLProps<HTMLParagraphElement>, "size" | "color" | "ref"> {}

export const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
	(
		{ capital, children, className, color, size, underline, weight, ...rest },
		ref,
	) => {
		return (
			<p
				className={typographyStyles({
					capital,
					class: className,
					color,
					size,
					underline,
					weight,
				})}
				ref={ref}
				{...rest}
			>
				{children}
			</p>
		);
	},
);
