import { env } from "@/env";
import type { MulticallABI } from "@config/abi/Multicall";
import { ZERO } from "@config/constants";
import { useStratosphereTokenIdOf } from "@hooks/useStratosphere";
import { updateUserIsStratosphereMember } from "@state/user/actions";
import useActiveWagmi from "hooks/useActiveWagmi";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useCurrentBlock } from "state/block/hooks";
import type { Abi, GetContractReturnType } from "viem";
import { useMulticallContract } from "../../hooks/useContract";
import useDebounce from "../../hooks/useDebounce";
import { type AppState, useAppDispatch } from "../index";
import {
	type Call,
	errorFetchingMulticallResults,
	fetchingMulticallResults,
	parseCallKey,
	updateMulticallResults,
} from "./actions";
import chunkArray from "./chunkArray";
import { CancelledError, RetryableError, retry } from "./retry";

// chunk calls so we do not exceed the gas limit
const CALL_CHUNK_SIZE = 500;

/**
 * Fetches a chunk of calls, enforcing a minimum block number constraint
 * @param multicallContract multicall contract to fetch against
 * @param chunk chunk of calls to make
 * @param minBlockNumber minimum block number of the result set
 */
async function fetchChunk<
	T extends GetContractReturnType<Abi> = GetContractReturnType<
		typeof MulticallABI
	>,
>(
	multicallContract: T,
	chunk: Call[],
	minBlockNumber: number,
): Promise<{ results: string[]; blockNumber: number }> {
	let resultsBlockNumber: number | undefined;
	let returnData: string[] | undefined;

	try {
		const args = chunk.map((obj) => {
			return { callData: obj.callData, target: obj.address };
		});

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[resultsBlockNumber, returnData] = await multicallContract.read.aggregate([
			args,
		]);
	} catch (error_) {
		const error = error_ as any;
		if (
			error.code === -32_000 ||
			(error?.data?.message &&
				error?.data?.message?.indexOf("header not found") !== -1) ||
			error.message?.indexOf("header not found") !== -1
		) {
			throw new RetryableError(
				`header not found for block number ${minBlockNumber}`,
			);
		}
		if (
			(error.code === -32_603 ||
				error.message?.indexOf("execution ran out of gas") !== -1) &&
			chunk.length > 1
		) {
			// eslint-disable-next-line turbo/no-undeclared-env-vars
			if (env.NEXT_PUBLIC_ENVIRONMENT === "development") {
				console.debug("Splitting a chunk in 2", chunk);
			}
			const half = Math.floor(chunk.length / 2);
			const [c0, c1] = await Promise.all([
				fetchChunk<typeof multicallContract>(
					multicallContract,
					chunk.slice(0, half),
					minBlockNumber,
				),
				fetchChunk<typeof multicallContract>(
					multicallContract,
					chunk.slice(half, chunk.length),
					minBlockNumber,
				),
			]);
			return {
				blockNumber: c1.blockNumber,
				results: [...c0.results, ...c1.results],
			};
		}
		console.debug(
			"Failed to fetch chunk inside retry",
			// eslint-disable-next-line turbo/no-undeclared-env-vars
			env.NEXT_PUBLIC_ENVIRONMENT === "development" ? error : "",
		);
		// throw error
	}
	const blockNumber = Number(resultsBlockNumber?.toString?.()) ?? 0;
	if (blockNumber < minBlockNumber) {
		console.debug(
			`Fetched results for old block number: ${blockNumber.toString()} vs. ${minBlockNumber}`,
		);
	}
	return { blockNumber, results: returnData };
}

/**
 * From the current all listeners state, return each call key mapped to the
 * minimum number of blocks per fetch. This is how often each key must be fetched.
 * @param allListeners the all listeners state
 * @param chainId the current chain id
 */
export function activeListeningKeys(
	allListeners: AppState["multicall"]["callListeners"],
	chainId?: number,
): { [callKey: string]: number } {
	if (!allListeners || !chainId) return {};
	const listeners = allListeners[chainId];
	if (!listeners) return {};

	return Object.keys(listeners).reduce<{ [callKey: string]: number }>(
		(memo, callKey) => {
			const keyListeners = listeners[callKey];

			memo[callKey] = Object.keys(keyListeners)
				.filter((key) => {
					const blocksPerFetch = Number.parseInt(key);
					if (blocksPerFetch <= 0) return false;
					return keyListeners[blocksPerFetch] > 0;
				})
				.reduce((previousMin, current) => {
					return Math.min(previousMin, Number.parseInt(current));
				}, Number.POSITIVE_INFINITY);
			return memo;
		},
		{},
	);
}

/**
 * Return the keys that need to be refetched
 * @param callResults current call result state
 * @param listeningKeys each call key mapped to how old the data can be in blocks
 * @param chainId the current chain id
 * @param currentBlock the latest block number
 */
export function outdatedListeningKeys(
	callResults: AppState["multicall"]["callResults"],
	listeningKeys: { [callKey: string]: number },
	chainId: number | undefined,
	currentBlock: number | undefined,
): string[] {
	if (!chainId || !currentBlock) return [];
	const results = callResults[chainId];
	// no results at all, load everything
	if (!results) return Object.keys(listeningKeys);

	return Object.keys(listeningKeys).filter((callKey) => {
		const blocksPerFetch = listeningKeys[callKey];

		const data = callResults[chainId][callKey];
		// no data, must fetch
		if (!data) return true;

		const minDataBlockNumber = currentBlock - (blocksPerFetch - 1);

		// already fetching it for a recent enough block, don't refetch it
		if (
			data.fetchingBlockNumber &&
			data.fetchingBlockNumber >= minDataBlockNumber
		)
			return false;

		// if data is older than minDataBlockNumber, fetch it
		return !data.blockNumber || data.blockNumber < minDataBlockNumber;
	});
}

export default function Updater(): null {
	const dispatch = useAppDispatch();
	const state = useSelector<AppState, AppState["multicall"]>(
		(s) => s.multicall,
	);

	// wait for listeners to settle before triggering updates
	const debouncedListeners = useDebounce(state.callListeners, 100);
	const currentBlock = useCurrentBlock();
	const { chainId, isValid } = useActiveWagmi();
	const stratosphereStats = useStratosphereTokenIdOf();

	const multicallContract = useMulticallContract(chainId);
	const cancellations = useRef<{
		blockNumber: number;
		cancellations: (() => void)[];
	}>();

	const listeningKeys: { [callKey: string]: number } = useMemo(() => {
		return activeListeningKeys(debouncedListeners, chainId);
	}, [debouncedListeners, chainId]);

	const unserializedOutdatedCallKeys = useMemo(() => {
		return outdatedListeningKeys(
			state.callResults,
			listeningKeys,
			chainId,
			currentBlock,
		);
	}, [chainId, state.callResults, listeningKeys, currentBlock]);

	const serializedOutdatedCallKeys = useMemo(
		() => JSON.stringify(unserializedOutdatedCallKeys.sort()),
		[unserializedOutdatedCallKeys],
	);

	useEffect(() => {
		if (!chainId)
			dispatch(updateUserIsStratosphereMember({ isStatophereMember: false }));
		const { data: tokenId } = stratosphereStats; //Destructuring here to avoid unnecessary variable assignments outside the effect.
		if (isValid) {
			if (tokenId && tokenId !== ZERO) {
				dispatch(updateUserIsStratosphereMember({ isStatophereMember: true })); //update once, use everywhere.
			} else {
				dispatch(updateUserIsStratosphereMember({ isStatophereMember: false }));
			}
		} else {
			dispatch(updateUserIsStratosphereMember({ isStatophereMember: false }));
		}
	}, [isValid, dispatch, stratosphereStats, chainId]);

	useEffect(() => {
		//multicalls
		if (!currentBlock || !multicallContract) return;

		const outdatedCallKeys: string[] = JSON.parse(serializedOutdatedCallKeys);
		if (outdatedCallKeys.length === 0) return;
		const calls = outdatedCallKeys.map((key) => parseCallKey(key));

		const chunkedCalls = chunkArray(calls, CALL_CHUNK_SIZE);

		if (
			cancellations.current?.blockNumber !== currentBlock &&
			cancellations.current?.cancellations
		)
			dispatch(
				fetchingMulticallResults({
					calls,
					chainId,
					fetchingBlockNumber: currentBlock,
				}),
			);

		cancellations.current = {
			blockNumber: currentBlock,
			cancellations: chunkedCalls.map((chunk, index) => {
				const { cancel, promise } = retry(
					() =>
						fetchChunk<typeof multicallContract>(
							multicallContract,
							chunk,
							currentBlock,
						),
					{
						maxWait: 3500,
						minWait: 2500,
						n: Number.POSITIVE_INFINITY,
					},
				);
				promise
					.then(({ blockNumber: fetchBlockNumber, results: returnData }) => {
						cancellations.current = {
							blockNumber: currentBlock,
							cancellations: [],
						};

						// accumulates the length of all previous indices
						const firstCallKeyIndex = chunkedCalls
							.slice(0, index)
							.reduce<number>((memo, curr) => memo + curr.length, 0);
						const lastCallKeyIndex =
							firstCallKeyIndex + (returnData?.length ?? 0);

						dispatch(
							updateMulticallResults({
								blockNumber: fetchBlockNumber,
								chainId,
								results: outdatedCallKeys
									.slice(firstCallKeyIndex, lastCallKeyIndex)
									.reduce<{ [callKey: string]: string | null }>(
										(memo, callKey, i) => {
											memo[callKey] = returnData[i] ?? null;
											return memo;
										},
										{},
									),
							}),
						);
					})
					.catch((error: any) => {
						if (error instanceof CancelledError) {
							console.debug("Cancelled fetch for blockNumber", currentBlock);
							return;
						}
						console.error(
							"Failed to fetch multicall chunk",
							chunk,
							chainId,
							error,
						);
						dispatch(
							errorFetchingMulticallResults({
								calls: chunk,
								chainId,
								fetchingBlockNumber: currentBlock,
							}),
						);
					});
				return cancel;
			}),
		};
	}, [
		isValid,
		chainId,
		stratosphereStats,
		multicallContract,
		dispatch,
		serializedOutdatedCallKeys,
		currentBlock,
	]);

	return null;
}
