"use client";

import { useIsIntersecting } from "@vaporfi/hooks";
import { Container, LoadingSpinner } from "@vaporfi/uikit";
import dynamic from "next/dynamic";
import { useEffect, useRef } from "react";
import type { Transaction } from "../types";
import { Skeleton } from "./Skeleton";

const Item = dynamic(() => import("./Item"), { loading: () => <Skeleton /> });

export default function Transactions({
	fetchNextPage,
	hasNextPage,
	transactions,
}: {
	fetchNextPage: () => void;
	transactions: Transaction[];
	hasNextPage: boolean;
}) {
	const ref = useRef<HTMLDivElement | null>(null);
	const isVisible = useIsIntersecting(ref);

	useEffect(() => {
		if (isVisible && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isVisible]);

	return (
		<>
			{transactions?.map((txn) => (
				<Item key={txn?.hash} transaction={txn} />
			))}
			{hasNextPage && (
				<Container ref={ref} className="flex justify-center" fullWidth>
					<LoadingSpinner />
				</Container>
			)}
		</>
	);
}
