/* eslint-disable unicorn/consistent-destructuring */
"use client";
import { format } from "d3-format";
import * as React from "react";
import {
	BarSeries,
	CandlestickSeries,
	ChartCanvas,
	CrossHairCursor,
	CurrentCoordinate,
	EdgeIndicator,
	LineSeries,
	MouseCoordinateY,
	MovingAverageTooltip,
	OHLCTooltip,
	Chart as RFChart,
	XAxis,
	YAxis,
	ZoomButtons,
	discontinuousTimeScaleProviderBuilder,
	ema,
	lastVisibleItemBasedZoomAnchor,
	withDeviceRatio,
	withSize,
} from "react-financial-charts";
import type { IOHLCData } from "./utils";

interface StockChartProps {
	readonly data: IOHLCData[];
	readonly height: number;
	readonly dateTimeFormat?: string;
	readonly width: number;
	readonly ratio: number;
}
const XScaleProvider =
	discontinuousTimeScaleProviderBuilder().inputDateAccessor(
		(d: IOHLCData) => new Date(d.date),
	);

class Chart extends React.Component<StockChartProps> {
	private readonly margin = { bottom: 0, left: 0, right: 48, top: 0 };
	private readonly pricesDisplayFormat = format(".2f");
	private readonly xScaleProvider = XScaleProvider;

	public render() {
		const { height, ratio, width } = this.props;
		let data = this.props.data;
		if (!data) {
			data = [];
		}
		const ema12 = ema()
			.id(1)
			.options({ windowSize: 12 })
			.merge((d: any, c: any) => {
				d.ema12 = c;
			})
			.accessor((d: any) => d?.ema12);

		const ema26 = ema()
			.id(2)
			.options({ windowSize: 26 })
			.merge((d: any, c: any) => {
				if (d) {
					d.ema26 = c;
				}
			})
			.accessor((d: any) => d?.ema26);

		const calculatedData = ema26(ema12(data));

		const { margin, xScaleProvider } = this;

		const {
			data: chartData,
			displayXAccessor,
			xAccessor,
			xScale,
		} = xScaleProvider(calculatedData);

		const max = xAccessor(chartData.at(-1));
		const min = xAccessor(chartData[Math.max(0, chartData.length - 100)]);
		const xExtents = [min, max + 5];

		const gridHeight = height - margin.top - margin.bottom;

		const elderRayHeight = 28;
		const barChartHeight = gridHeight / 4;
		const barChartOrigin = (_: number, h: number) => [
			0,
			h - barChartHeight - elderRayHeight,
		];
		const chartHeight = gridHeight - elderRayHeight;

		return (
			<ChartCanvas
				height={height}
				ratio={ratio}
				width={width}
				margin={margin}
				data={chartData}
				displayXAccessor={displayXAccessor}
				seriesName="Data"
				xScale={xScale}
				xAccessor={xAccessor}
				xExtents={xExtents}
				zoomAnchor={lastVisibleItemBasedZoomAnchor}
			>
				<RFChart
					id={2}
					height={barChartHeight}
					origin={barChartOrigin}
					yExtents={this.barChartExtents}
				>
					<BarSeries
						fillStyle={this.volumeColor}
						yAccessor={this.volumeSeries}
					/>
				</RFChart>
				<RFChart id={3} height={chartHeight} yExtents={this.candleChartExtents}>
					<XAxis showGridLines showTicks={false} showTickLabel={false} />
					<YAxis showGridLines tickFormat={this.pricesDisplayFormat} />
					<CandlestickSeries />
					<LineSeries
						yAccessor={ema26.accessor()}
						strokeStyle={ema26.stroke()}
					/>
					<CurrentCoordinate
						yAccessor={ema26.accessor()}
						fillStyle={ema26.stroke()}
					/>
					<LineSeries
						yAccessor={ema12.accessor()}
						strokeStyle={ema12.stroke()}
					/>
					<CurrentCoordinate
						yAccessor={ema12.accessor()}
						fillStyle={ema12.stroke()}
					/>
					<MouseCoordinateY
						rectWidth={margin.right}
						displayFormat={this.pricesDisplayFormat}
					/>
					<EdgeIndicator
						itemType="last"
						rectWidth={margin.right}
						fill={this.openCloseColor}
						lineStroke={this.openCloseColor}
						displayFormat={this.pricesDisplayFormat}
						yAccessor={this.yEdgeIndicator}
					/>
					<MovingAverageTooltip
						origin={[8, 24]}
						options={[
							{
								stroke: ema26.stroke(),
								type: "EMA",
								windowSize: ema26.options().windowSize,
								yAccessor: ema26.accessor(),
							},
							{
								stroke: ema12.stroke(),
								type: "EMA",
								windowSize: ema12.options().windowSize,
								yAccessor: ema12.accessor(),
							},
						]}
					/>

					<ZoomButtons />
					<OHLCTooltip origin={[8, 16]} />
				</RFChart>

				<CrossHairCursor />
			</ChartCanvas>
		);
	}

	private readonly barChartExtents = (data: IOHLCData) => {
		return data.volume;
	};

	private readonly candleChartExtents = (data: IOHLCData) => {
		return [data.high, data.low];
	};

	private readonly yEdgeIndicator = (data: IOHLCData) => {
		return data.close;
	};

	private readonly volumeColor = (data: IOHLCData) => {
		return data.close > data.open
			? "rgba(38, 166, 154, 0.3)"
			: "rgba(239, 83, 80, 0.3)";
	};

	private readonly volumeSeries = (data: IOHLCData) => {
		return data.volume;
	};

	private readonly openCloseColor = (data: IOHLCData) => {
		return data.close > data.open ? "#26a69a" : "#ef5350";
	};
}

export default withSize({ style: { minHeight: 426, padding: 0 } })(
	withDeviceRatio()(Chart),
);
