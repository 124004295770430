import { LoadingSpinner, Typography } from "@vaporfi/uikit";
import React from "react";
import { Header, Wrapper } from "./shared";

const Skeleton = () => {
	return (
		<Wrapper>
			<Header author={"0x..loading"} creationTime={new Date()} />
			<Typography color="dark">
				{"Loading message..."}
				<LoadingSpinner />
			</Typography>
		</Wrapper>
	);
};

export default Skeleton;
