import { Svg, type SvgProps } from "./Svg";

export const CashDepositIcon = ({
	height = "70pt",
	version = "1.1",
	viewBox = "0 0 700 700",
	width = "70pt",
	xmlns = "http://www.w3.org/2000/svg",
	...rest
}: SvgProps) => {
	return (
		<Svg {...rest} {...{ height, version, viewBox, width, xmlns }}>
			<g fillRule="evenodd">
				<path
					d="m70.035 455.24h425.26v237.21h-425.26z"
					fill="var(--colors-blue500)"
				/>
				<path
					d="m44.371 390.93h477.74v43.316h-477.74z"
					fill="var(--colors-blue500)"
				/>
				<path
					d="m392.07 316.32 38.086-59.305-17.172-10.18-47.453 19.23c-6.8398 2.7734-13 4.1172-18.844 4.1172-15.328 0-27.398-9.0312-36.891-27.602-4.4727-8.75-4.957-18.871-1.3711-28.496 2.7734-7.4258 7.8047-14.23 14.098-19.375l-24.59-15c-1.2734-0.77734-2.3164-1.793-3.1211-2.9414l-3.6484-2.1719-102.23 152.8 196.68-1.0273z"
					fill="var(--colors-blue500)"
				/>
				<path
					d="m440.1 316.32h-23.078l-7.1484 11.129h15.191v21h-245.43l-30.145 0.16016 0.11328-0.16016h-8.1562v-21h22.199l7.4453-11.129h-45.008l-68.559 53.613h451.55z"
					fill="var(--colors-blue500)"
				/>
				<path
					d="m494.23 68.367c-26.84 0-48.699-3.9766-82.09-11.809-2.1211-0.48828-4.2773-0.74219-6.4102-0.74219-14.891 0-31.215 12.781-43.664 34.203-14.945 25.691-19.844 34.301-24.754 42.926-4.3047 7.5664-8.6172 15.141-19.676 34.195l26.629 16.238 15.238-5.8672c2.8711-2.4648 8.3633-9.5703 12.84-15.344 4.4883-5.8164 9.1367-11.824 13.496-16.527 3.5703-3.8555 7.6445-7.7695 13.098-10.215 3.5938-1.6133 7.7656-2.4258 12.414-2.4258 11.418 0 27.039 5.4023 33.535 15.43 1.6523 2.5547 2.1211 5.7109 1.2617 8.6367-0.84766 2.9258-2.9414 5.3398-5.7109 6.6094l-71.953 32.859c-1.0508 0.46875-2.1992 0.92578-3.4844 1.4141l-20.453 8.2969-2.7773 1.1289c-5.9375 2.4141-11.418 8.0508-13.648 14.035-1.5547 4.1641-1.4219 8.0703 0.39062 11.613 8.2617 16.164 15.219 16.164 18.191 16.164 3.0508 0 6.7422-0.85938 10.953-2.5742l73.051-29.613c16.105-6.5312 31.074-8.7422 48.301-7.168 1.9531 0.17578 3.9414 0.27344 5.9648 0.27344 14.23 0 27.629-4.5703 36.734-12.551 4.332-3.7852 8.2617-6.7422 13.098-9.8555l49.961-32.129-51.473-89.141c-14.66 1.1992-27.414 1.9375-39.062 1.9375z"
					fill="var(--colors-blue500)"
				/>
				<path
					d="m537.78 32.191 42.656-24.621 75.203 130.29-42.656 24.621z"
					fill="var(--colors-blue500)"
				/>
			</g>
		</Svg>
	);
};
