import {
	BRIDGE_URL,
	DASHBOARD_URL,
	FIAT_URL,
	GENESIS_URL,
	LIQUID_MINING_URL,
	ONRAMPER_URL,
	PARTNERS_URL,
	POOL_URL,
	REFERRALS_URL,
	STAKE_XVAPE_URL,
	SWAP_URL,
	TOKENFACTORY_URL,
} from "@config/constants/url";
import { STRATOSPHERE_URL } from "@vaporfi/utils";
export interface MenuItem {
	featureFlagId: string;
	label: string;
	path: string;
	url: string;
}
export const menuItems: MenuItem[] = [
	{
		featureFlagId: "enableMemeFactory",
		label: "Token Factory",
		path: "/token-factory",
		url: TOKENFACTORY_URL,
	},
	{
		featureFlagId: "enableSwap",
		label: "Swap",
		path: "/swap",
		url: SWAP_URL,
	},
	{
		featureFlagId: "enableBridge",
		label: "Bridge",
		path: "/bridge",
		url: BRIDGE_URL,
	},
	{
		featureFlagId: "enableFiat",
		label: "Fiat",
		path: "/fiat",
		url: FIAT_URL,
	},
	{
		featureFlagId: "enableGenesis",
		label: "Genesis",
		path: "/genesis",
		url: GENESIS_URL,
	},
	{
		featureFlagId: "enableLiquidityPools",
		label: "Liquidity Pools",
		path: "/pool",
		url: POOL_URL,
	},
	{
		featureFlagId: "enableLiquidMining",
		label: "Earn VAPE",
		path: "/liquid-mining",
		url: LIQUID_MINING_URL,
	},

	{
		featureFlagId: "enableStaking",
		label: "Earn USDC",
		path: "/earn/usdc",
		url: STAKE_XVAPE_URL,
	},
	{
		featureFlagId: "enableReferrals",
		label: "Referrals",
		path: "/referrals",
		url: REFERRALS_URL,
	},
	{
		featureFlagId: "enableStratospherePreEnrollment",
		label: "Stratosphere",
		path: "/stratosphere",
		url: STRATOSPHERE_URL,
	},
	{
		featureFlagId: "enableOnramper",
		label: "Onramper",
		path: "/onramper",
		url: ONRAMPER_URL,
	},
	{
		featureFlagId: "enableGasBridge",
		label: "Gas Station",
		path: "/gas",
		url: "/gas",
	},
	{
		featureFlagId: "enableOffers",
		label: "Partners",
		path: "/stratosphere/partners",
		url: PARTNERS_URL,
	},
	{
		featureFlagId: "enableDashboard",
		label: "Dashboard",
		path: "/stratosphere/home",
		url: DASHBOARD_URL,
	},
];

export const tradeItems = menuItems.filter((item) =>
	["Swap", "Bridge", "Fiat", "Onramper", "Gas Station"].includes(item.label),
);

export const tokenListUpdaterUrls = ["/swap", "/pools", "/token-factory"];
export const earnItemsLabels = [
	"Liquidity Pools",
	"Earn VAPE",
	"Earn USDC",
	"Referrals",
];
export const earnItems = menuItems.filter((item) =>
	earnItemsLabels.includes(item.label),
);
export const rewardsItems = menuItems.filter((item) =>
	["Stratosphere", "Partners"].includes(item.label),
);
export const servicesItems = menuItems.filter((item) =>
	["Token Factory"].includes(item.label),
);
