import type { Dispatch } from "@reduxjs/toolkit";
import { ConnectorNames, connectorLocalStorageKey } from "@vapordex/uikit";
import { PREDICTION_TOOLTIP_DISMISS_KEY } from "config/constants";
import { resetUserState } from "state/global/actions";
import { connectorsByName } from "utils/wagmi";
import getLocalStorageItemKeys from "./getLocalStorageItemKeys";
import { LS_ORDERS } from "./localStorageOrders";

export const clearUserStates = (
	dispatch: Dispatch<any>,
	chainId: number,
	isDeactive = false,
) => {
	dispatch(resetUserState({ chainId }));
	// This localStorage key is set by @web3-react/walletconnect-connector
	if (window?.localStorage?.getItem("walletconnect")) {
		connectorsByName[ConnectorNames.WalletConnect].disconnect();
	}
	// Only clear localStorage when user disconnect,switch address no need clear it.
	if (isDeactive) {
		window?.localStorage?.removeItem(connectorLocalStorageKey);
	}
	const lsOrderKeys = getLocalStorageItemKeys(LS_ORDERS);

	for (const lsOrderKey of lsOrderKeys)
		window?.localStorage?.removeItem(lsOrderKey);

	window?.localStorage?.removeItem(PREDICTION_TOOLTIP_DISMISS_KEY);
};
