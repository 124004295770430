export const TelosMainnet = [
	{
		address: "0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905",
		chainId: 40,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905/logo.png",
		name: "Staked TLOS",
		symbol: "STLOS",
	},
	{
		address: "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
		chainId: 40,
		decimals: 18,
		logoURI: "https://static.vapordex.io/tokens/assets/40/WTLOS/logo.png",
		name: "Wrapped TLOS",
		symbol: "WTLOS",
	},
	{
		address: "0x975Ed13fa16857E83e7C493C7741D556eaaD4A3f",
		chainId: 40,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0x975Ed13fa16857E83e7C493C7741D556eaaD4A3f/logo.png",
		name: "USD Tether",
		symbol: "USDT",
	},
	{
		address: "0x8D97Cea50351Fb4329d591682b148D43a0C3611b",
		chainId: 40,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0x8D97Cea50351Fb4329d591682b148D43a0C3611b/logo.png",
		name: "USD Coin",
		symbol: "USDC",
	},
	{
		address: "0xA0fB8cd450c8Fd3a11901876cD5f17eB47C6bc50",
		chainId: 40,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0xA0fB8cd450c8Fd3a11901876cD5f17eB47C6bc50/logo.png",
		name: "Ether",
		symbol: "ETH",
	},
	{
		address: "0x7627b27594bc71e6Ab0fCE755aE8931EB1E12DAC",
		chainId: 40,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0x7627b27594bc71e6Ab0fCE755aE8931EB1E12DAC/logo.png",
		name: "Wrapped BTC",
		symbol: "BTCb",
	},
	{
		address: "0x26Ed0F16e777C94A6FE798F9E20298034930Bae8",
		chainId: 40,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/40/0x26Ed0F16e777C94A6FE798F9E20298034930Bae8/logo.png",
		name: "Binance Coin",
		symbol: "BNB",
	},
] as const;
