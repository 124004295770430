import Header from "@components/Header";
import Page from "@components/Layout/Page";
import NetworkModal from "@components/Menu/NetworkSelector/NetworkModal";
import useActiveWagmi from "@hooks/useActiveWagmi";
import { useMatchBreakpointsContext } from "@vapordex/uikit";
import { useModal } from "@vaporfi/hooks";
import { Box, Button, Container, Typography } from "@vaporfi/uikit";
import { Refresh } from "@vaporfi/uikit";
import Image from "next/image";

const UnsupportedChain = ({ children, supportedChainIds }) => {
	const { chain, isConnected } = useActiveWagmi();
	const chainId = chain?.id;
	const { isMobile } = useMatchBreakpointsContext();
	const [onPresentNetworkModal] = useModal(<NetworkModal />);
	const handleSwitchNetwork = () => {
		onPresentNetworkModal();
	};

	if (
		!supportedChainIds ||
		!isConnected ||
		supportedChainIds === "all" ||
		supportedChainIds?.includes(chainId)
	) {
		return children;
	}

	return (
		<>
			<Header
				breadcrumbs={[
					{
						currenciesLabel: "",
						page: "Unsupported Chain",
						path: "/unsupported-chain",
					},
				]}
			/>
			<Page>
				<Container stack="column" itemsCenter className="m-[30px]">
					<Box
						fullWidth
						className="flex flex-col items-center gap-4 p-[30px]"
						bg="black"
					>
						<Image src="/images/union.png" alt="Error" width={90} height={90} />

						<Typography
							className="font-manverse text-custom-linear-gradient"
							size="2xl"
						>
							CHAIN NOT SUPPORTED
						</Typography>
						<Typography size="sm">
							Please switch to a supported chain.
						</Typography>
						<Button
							color="blue"
							variant="filled"
							size="base"
							onClick={handleSwitchNetwork}
						>
							<Typography size="sm">{"Switch Network"}</Typography>
							{!isMobile && <Refresh />}
						</Button>
					</Box>
				</Container>
			</Page>
		</>
	);
};

export default UnsupportedChain;
