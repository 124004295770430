import React, { createContext, type ReactNode, useCallback } from "react";
import useSWR from "swr";

type Props = {
	children: ReactNode;
	chainId: number;
};

const defaultFeatures: string[] = [];

export const FeatureFlagsContext = createContext({
	enabledFeatures: defaultFeatures,
	loading: true,
});

export const FeatureProvider = ({ chainId, children }: Props) => {
	const fetcher = useCallback(async (url: string) => {
		return await fetch(url)
			.then((res) => res?.json())
			.catch((error) => {
				console.error(error);
				return [];
			});
	}, []);

	const { data: enabledFeatures, isLoading } = useSWR(
		`/api/features?chainId=${chainId}`,
		fetcher,
	);

	return (
		<FeatureFlagsContext.Provider
			value={{ enabledFeatures: enabledFeatures ?? [], loading: isLoading }}
		>
			{children}
		</FeatureFlagsContext.Provider>
	);
};
