import { WalletInfoView } from "@vaporfi/features";
import useActiveWagmi from "hooks/useActiveWagmi";
import useAuth from "hooks/useAuth";
import { useGetAVAXBalance } from "hooks/useTokenBalance";
import type React from "react";
import { ExplorerDataType, getExplorerLink } from "utils";
import { formatBigNumber } from "utils/formatBalance";
import { useNetwork } from "wagmi";
import CopyAddress from "./CopyAddress";

interface WalletInfoProps {
	hasLowAVAXBalance: boolean;
	onDismiss: () => void;
	UDID?: string;
}

const WalletInfo: React.FC<WalletInfoProps> = ({
	UDID,
	hasLowAVAXBalance,
	onDismiss,
}) => {
	const { account } = useActiveWagmi();
	const { chain } = useNetwork();
	const { balance, isFetched } = useGetAVAXBalance();
	const { logout } = useAuth();

	const handleLogout = () => {
		onDismiss?.();
		logout();
	};

	const handleViewExplorer = () => {
		const explorerLink = getExplorerLink(
			account,
			ExplorerDataType.ADDRESS,
			chain,
		);
		window.open(explorerLink, "_blank");
	};

	return (
		<>
			{UDID && (
				<div className="mb-6">
					<p className="text-sm font-bold text-neutral-900">
						Web3 Domain in Wallet
					</p>
					<CopyAddress account={UDID} className="mb-6" />
				</div>
			)}
			<div className="mb-6">
				<p className="mb-2 text-sm font-bold text-neutral-500">Your Address</p>
				<CopyAddress account={account} />
			</div>
			<WalletInfoView
				hasLowAVAXBalance={hasLowAVAXBalance}
				balance={formatBigNumber(balance, 6)}
				isFetched={isFetched}
				onLogout={handleLogout}
				onViewExplorer={handleViewExplorer}
			/>
		</>
	);
};

export default WalletInfo;
