import { type HTMLProps, forwardRef } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { boxStyles } from "../Box";

export const containerStyles = tv({
	extend: boxStyles,
	variants: {
		itemsCenter: { true: "items-center" },
		stack: { column: "flex flex-col", row: "flex flex-row" },
	},
});
export interface ContainerProps
	extends VariantProps<typeof containerStyles>,
		HTMLProps<HTMLDivElement> {}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
	(
		{
			bg,
			children,
			className,
			fullWidth,
			hideInMobile,
			itemsCenter,
			stack,
			...rest
		},
		ref,
	) => {
		return (
			<div
				className={containerStyles({
					bg,
					class: className,
					fullWidth,
					hideInMobile,
					itemsCenter,
					stack,
				})}
				{...rest}
				ref={ref}
			>
				{children}
			</div>
		);
	},
);

export { Container };
