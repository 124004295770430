import type { Tags, TokenInfo, TokenList } from "@uniswap/token-lists";
import { Token } from "@vapordex/sdk";
import { ChainId } from "@vapordex/sdk";
import { type Address, parseGwei } from "viem";
/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
	public readonly tokenInfo: TokenInfo;

	public readonly tags: TagInfo[];

	constructor(tokenInfo: TokenInfo, tags: TagInfo[]) {
		super(
			tokenInfo.chainId,
			tokenInfo.address as Address,
			tokenInfo.decimals,
			tokenInfo.symbol,
			tokenInfo.name,
		);
		this.tokenInfo = tokenInfo;
		this.tags = tags;
	}

	public get logoURI(): string | undefined {
		return this.tokenInfo.logoURI;
	}
}

export type TokenAddressMap = Readonly<{
	[chainId in ChainId]?: Readonly<{
		[tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList };
	}>;
}>;

type TagDetails = Tags[keyof Tags];
export interface TagInfo extends TagDetails {
	id: string;
}

/**
 * An empty result, useful as a default.
 */
export const EMPTY_LIST: TokenAddressMap = {
	[ChainId.AVALANCHE]: {},
	[ChainId.AVALANCHE_TESTNET]: {},
};

export enum GAS_PRICE {
	default = "25",
	fast = "26",
	instant = "27",
}

export const GAS_PRICE_GWEI = {
	default: parseGwei(GAS_PRICE.default).toString(),
	fast: parseGwei(GAS_PRICE.fast).toString(),
	instant: parseGwei(GAS_PRICE.instant).toString(),
	testnet: parseGwei(GAS_PRICE.default).toString(),
};

export const FIXED_GAS_PRICE = {
	[ChainId.TELOS_TESTNET]: "503807803686",
};

export interface BigNumberToJson {
	type: "BigNumber";
	hex: string;
}

export type SerializedBigNumber = string;

export enum VaultKey {
	CakeVaultV1 = "cakeVaultV1",
	CakeVault = "cakeVault",
	CakeFlexibleSideVault = "cakeFlexibleSideVault",
	IfoPool = "ifoPool",
}

// Ifo
export interface IfoState extends PublicIfoData {
	credit: string;
}

export interface PublicIfoData {
	ceiling: string;
}
