import { useCallback, useContext, useMemo } from "react";
import { FeatureFlagsContext } from "./provider";

export function useFeature() {
	const { enabledFeatures, loading } = useContext(FeatureFlagsContext);

	const isEnabled = useCallback(
		(featureName: string) => {
			return enabledFeatures.includes(featureName);
		},
		[enabledFeatures],
	);

	return useMemo(() => ({ isEnabled, loading }), [isEnabled, loading]);
}
