import { type Currency, Token } from "@vapordex/sdk";
import memoize from "lodash/memoize";
import type { TokenAddressMap } from "state/types";
import { type Abi, type GetContractReturnType, getAddress } from "viem";
import { getContract as getContractViem } from "viem";
import type { Address, Chain, PublicClient, WalletClient } from "wagmi";

/**
 * Filters an array and returns only the truthy values.
 * @param array - The array to be filtered.
 * @link https://developer.mozilla.org/en-US/docs/Glossary/Falsy
 * @returns An array containing only the truthy values from the original array.
 * @example
 * const array = [1, false, null, 3, undefined, 5, 0];
 * const filteredArray = filterTruthy(array);\
 * console.log(filteredArray); //[1, 3, 5]
 */

export const filterTruthy = <T>(
	array: (T | undefined | null | false)[],
): T[] => {
	return array.filter(Boolean) as T[];
};

// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = memoize((value: any): Address | false => {
	try {
		return getAddress(value);
	} catch {
		return false;
	}
});

export enum ExplorerDataType {
	TRANSACTION = "transaction",
	TOKEN = "token",
	ADDRESS = "address",
	BLOCK = "block",
	COUNTDOWN = "countdown",
}

export function getExplorerLink(
	data: string | number,
	type: ExplorerDataType,
	chain?: Chain,
): string {
	switch (type) {
		case "transaction": {
			return `${chain?.blockExplorers?.default.url}/tx/${data}`;
		}
		case "token": {
			return `${chain?.blockExplorers?.default.url}/token/${data}`;
		}
		case "block": {
			return `${chain?.blockExplorers?.default.url}/block/${data}`;
		}
		case "countdown": {
			return `${chain?.blockExplorers?.default.url}/block/countdown/${data}`;
		}
		default: {
			return `${chain?.blockExplorers?.default.url}/address/${data}`;
		}
	}
}

export const getExplorerName = (chain: Chain) =>
	chain?.blockExplorers?.default?.name || "Explorer";

export function getSnowtraceLinkForNft(
	collectionAddress: string,
	tokenId: string,
	chain?: Chain,
): string {
	return `https://${chain?.blockExplorers?.default}/token/${collectionAddress}?a=${tokenId}`;
}

// add 10%
export function calculateGasMargin(value: bigint, margin = 1000): bigint {
	return (value * BigInt(10_000 + margin)) / BigInt(10_000);
}

// account is optional
export function getContract<T extends Abi | readonly unknown[]>(
	address: Address,
	ABI: T,
	walletClient?: WalletClient,
	publicClient?: PublicClient,
): GetContractReturnType<T, typeof publicClient, typeof walletClient, Address> {
	return getContractViem({
		abi: ABI,
		address,
		publicClient,
		walletClient,
	});
}

export function escapeRegExp(string: string): string {
	return string.replaceAll(/[$()*+.?[\\\]^{|}]/g, "\\$&"); // $& means the whole matched string
}

export function isTokenOnList(
	defaultTokens: TokenAddressMap,
	currency?: Currency,
): boolean {
	if (currency?.isNative) return true;
	return Boolean(
		currency instanceof Token &&
			defaultTokens[currency.chainId]?.[currency.address],
	);
}
