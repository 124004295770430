// import styled from "styled-components";
import { styled } from "../../stitches.config";
import { Box } from "./Box";

export const Column = styled(Box, {
	flexDirection: "column",
	justifyContent: "flex-start",
	variants: {
		auto: {
			true: {
				display: "grid",
				gridAutoRows: "auto",
			},
		},
		center: {
			true: {
				alignItems: "center",
				width: "100%",
			},
		},
	},
});

// export const ColumnCenter = styled(Column)`
//   width: 100%;
//   align-items: center;
// `;

// export const AutoColumn = styled.div<{
//   gap?: "sm" | "md" | "lg" | string;
//   justify?:
//     | "stretch"
//     | "center"
//     | "start"
//     | "end"
//     | "flex-start"
//     | "flex-end"
//     | "space-between";
// }>`
//   display: grid;
//   grid-auto-rows: auto;
//   grid-row-gap: ${({ gap }) =>
//     (gap === "sm" && "8px") ||
//     (gap === "md" && "12px") ||
//     (gap === "lg" && "24px") ||
//     gap};
//   justify-items: ${({ justify }) => justify};
// `;
