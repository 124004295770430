import { Svg, type SvgProps } from "./Svg";

export const CoinPagesIcon = (props: SvgProps) => {
	return (
		<Svg
			width="64"
			height="57"
			viewBox="0 0 64 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M51.2782 56.8821H24.4741C24.4741 56.8821 0 59.0875 0 40.8847V16.0318C0 16.0318 0 32.1819 24.4741 32.1819H61.891V46.2794L51.2782 56.8821Z"
				fill="#90B2F0"
			/>
			<path
				opacity="0.9"
				d="M51.2773 56.8652L61.8902 46.2794H51.2773V56.8652Z"
				fill="#EBEDF0"
			/>
			<path
				d="M24.4741 0.0855952C24.4741 0.0855952 0 -2.13674 0 16.0661V40.9189C0 40.9189 0 24.7688 24.4741 24.7688H51.5844C58.4385 24.7688 64 19.2214 64 12.3848C64 5.54813 58.4385 0.034702 51.5844 0.034702L24.4741 0.0855952Z"
				fill="#EBEDF0"
			/>
		</Svg>
	);
};
