import type { Chain } from "viem";

const sourceId = 42_161; // Arbitrum One

export const apeChain = {
	id: 33139,
	name: "Ape Chain",
	network: "apechain",
	nativeCurrency: {
		name: "ApeCoin",
		symbol: "APE",
		decimals: 18,
	},
	rpcUrls: {
		public: { http: ["https://rpc.apechain.com/http"] },
		default: {
			http: ["https://rpc.apechain.com/http"],
			webSocket: ["wss://rpc.apechain.com/ws"],
		},
	},
	blockExplorers: {
		default: {
			name: "Apescan",
			url: "https://apescan.io",
			apiUrl: "https://api.apescan.io",
		},
	},
	contracts: {
		multicall3: {
			address: "0xcA11bde05977b3631167028862bE2a173976CA11",
			blockCreated: 20889,
		},
	},
	sourceId,
} as Chain;
