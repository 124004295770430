import { Svg, type SvgProps } from "./Svg";

export const ShieldIcon = (props: SvgProps) => {
	return (
		<Svg
			width="58"
			height="80"
			viewBox="0 0 58 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0.0748289 56.6376C0.191912 60.5997 0.821234 64.8087 2.83604 68.2138C4.44593 70.9567 6.80222 72.3597 9.32926 73.9309C12.4515 75.8699 15.7103 78.534 19.252 79.4851C22.7987 80.4573 26.6283 79.937 30.1164 78.8861C33.4483 77.893 35.5949 75.8541 38.0878 73.3161C42.3223 69.044 46.1177 64.1939 49.2204 58.8919C52.6695 52.9909 55.216 46.4908 56.3868 39.6544C56.5966 38.4984 56.7527 37.3213 56.8649 36.1443C57.2845 31.8091 57.1137 27.3531 57.1137 22.997V7.66372C57.1137 7.41675 57.1918 6.90179 57.1137 6.65482C56.7869 5.82983 56.138 6.01375 55.6209 5.54082C53.811 3.88033 51.7913 2.41952 49.7473 1.09533C49.0155 0.622408 48.1032 -0.176302 47.2153 0.296622C46.5616 0.643433 46.0494 1.51572 45.6396 2.13577C44.8542 3.31283 43.9712 4.4058 43.054 5.45675C41.1758 7.56389 39.1269 9.48186 36.9218 11.1739C34.9119 12.703 32.7458 14.127 30.4237 15.0308C28.487 15.8138 27.1112 15.6719 25.155 15.6719C22.8767 15.6509 21.2473 19.387 19.7155 20.9949C17.8373 22.9549 15.6713 24.6259 13.393 26.0079C10.2513 27.8839 5.11428 30.506 1.43592 28.856C0.957833 28.651 0.650487 28.835 0.440713 29.2711C-0.115431 30.3431 0.0553183 32.8811 0.0553183 33.4013V42.0506C0.0553183 46.9007 -0.0812817 51.7718 0.0748289 56.6429V56.6376Z"
				fill="#D8E2F0"
			/>
			<path
				d="M0.438477 29.2711L9.63436 35.3981L56.3846 39.6492C56.5944 38.4931 56.7505 37.3161 56.8627 36.139C57.2822 31.8039 57.1115 27.3479 57.1115 22.9917V7.65847C57.1115 7.41149 57.1895 6.89653 57.1115 6.64956C56.9895 6.26071 56.6773 6.07154 56.3504 5.92441C55.926 5.72998 55.6138 5.45149 55.2382 5.17299C54.5405 4.65277 53.8478 4.1273 53.1307 3.63335C51.9257 2.80311 50.6866 2.05169 49.5352 1.13737C49.1011 0.790554 48.6181 0.48578 48.1498 0.191516C47.6717 -0.108003 47.5156 -0.0502011 47.135 0.322883C46.5691 0.879883 46.0813 1.46316 45.6325 2.13577C44.847 3.31282 43.9641 4.4058 43.0469 5.45674C41.1687 7.56388 39.1197 9.48185 36.9147 11.1739C34.9048 12.703 32.7387 14.127 30.4166 15.0308C28.4798 15.8138 27.1041 15.6719 25.1479 15.6719C22.8696 15.6509 21.2402 19.387 19.7084 20.9949C17.8302 22.9549 15.6641 24.6259 13.3859 26.0079C10.2442 27.8839 5.10716 30.506 1.4288 28.856C0.950714 28.651 0.643367 28.835 0.433594 29.2711H0.438477Z"
				fill="#EBEDF0"
			/>
			<path
				d="M10.7468 34.652C15.2594 36.6698 26.8457 31.2206 32.0071 22.976C32.7194 21.8357 33.7585 21.1947 34.4707 21.4469C39.6322 23.2703 51.2185 14.311 55.731 6.68114C56.2726 5.76682 57.1165 5.76682 57.1165 6.65487V31.2627C57.1165 57.6519 36.0465 75.4864 33.4951 77.5462C33.3194 77.6881 33.1633 77.7827 32.9926 77.8615C30.4411 78.9702 9.37109 87.3462 9.37109 60.9571V36.3493C9.37109 35.4612 10.2151 34.4103 10.7566 34.652H10.7468Z"
				fill="#B2C8F0"
			/>
			<path
				d="M50.2694 35.5242C50.2694 51.8927 39.0977 63.8209 34.2339 68.2558H34.1948C33.829 68.6079 33.5265 68.8759 33.2387 69.1229C30.536 70.1738 24.155 72.055 20.0181 68.4187C18.735 67.3047 17.6813 65.6758 17.0081 63.3217C16.4909 61.5456 16.1836 59.3596 16.1836 56.6955V43.3853C18.6375 42.5393 21.0328 41.3202 23.1208 40.0223C25.9747 38.2462 29.7701 35.3981 33.2192 31.1891C36.6877 31.084 40.4832 29.2501 43.3371 27.474C44.7762 26.586 46.3471 25.451 47.9716 24.132C48.718 23.533 49.4839 22.8919 50.2498 22.2141L50.2694 35.5242Z"
				fill="#EBEDF0"
			/>
			<path
				d="M34.1946 68.261C33.8287 68.6131 33.5263 68.8811 33.2384 69.1281C30.5358 70.179 24.1547 72.0602 20.0178 68.4239C18.7348 67.3099 17.681 65.681 17.0078 63.3269L19.3836 60.8519L31.4188 62.5649C31.4188 62.5649 31.5163 64.919 32.1457 66.6321C32.5652 67.7461 33.1994 68.5921 34.1946 68.261Z"
				fill="#7D9BD1"
			/>
			<path
				d="M47.9716 24.132V34.1002C47.9716 50.2374 37.1463 62.0657 32.1459 66.6268C31.6873 67.042 31.2824 67.4098 30.9409 67.6988C28.7017 68.5868 23.9452 70.0109 20.0181 68.4187C18.735 67.3047 17.6813 65.6757 17.0081 63.3216C16.4909 61.5455 16.1836 59.3596 16.1836 56.6954V43.3852C18.6375 42.5392 21.0328 41.3201 23.1208 40.0222C25.9747 38.2461 29.7701 35.3981 33.2192 31.189C36.6877 31.084 40.4832 29.2501 43.3371 27.474C44.7762 26.5859 46.3471 25.4509 47.9716 24.132Z"
				fill="#90B2F0"
			/>
		</Svg>
	);
};
