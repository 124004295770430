type ButtonColorVariant =
	| "transparent"
	| "neutral"
	| "red"
	| "green"
	| "blue"
	| "purple"
	| "dark"
	| "gradient"
	| "light"
	| "turquoise";

const borderTextOutlineCombo = {
	"border-blue-500": "[&>p]:text-light",
	"border-light": "[&>p]:text-light",
	"border-neutral-800": "[&>p]:text-neutral-400",
	"border-turquoise-500": "[&>p]:text-black",
} as { [x: string]: string };

export const generateOutlineStyles = (...borderColors: string[]) => {
	const sizes = ["xs", "sm", "base"] as const;
	const result: {
		class: string;
		color: ButtonColorVariant;
		size: (typeof sizes)[number];
		variant: "outline";
	}[] = [];

	for (const curr of sizes) {
		for (const borderColor of borderColors) {
			const [, textColor] = borderColor.split("-");
			result.push({
				class: `${curr === "xs" ? "border-[1px]" : "border-2"} ${borderColor} ${
					borderTextOutlineCombo[borderColor]
				}`,
				color: textColor as ButtonColorVariant,
				size: curr,
				variant: "outline" as const,
			});
		}
	}
	return result;
};
