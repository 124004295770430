import type { MoarCandyToken } from "@vaporfi/db";
import { Box, Container } from "@vaporfi/uikit";
import dynamic from "next/dynamic";
import Link from "next/link";
import { Skeleton } from "./Item";

const Item = dynamic(() => import("./Item"), { loading: () => <Skeleton /> });

export default function TokenCarousel({
	tokens,
}: {
	tokens: (MoarCandyToken & { pricePercentChange: string })[];
}) {
	return (
		<Container className="overflow-hidden overflow-x-auto">
			<Box
				className={`grid w-full auto-cols-max grid-flow-col gap-4 ${
					tokens?.length > 8 ? "animate-infiniteScroll" : "px-4"
				}`}
			>
				{tokens?.map((token) => (
					<Link
						href={`/token/${token.address}`}
						key={`carousel-${token.address}`}
					>
						<Item
							name={token?.name}
							symbol={token?.symbol}
							logo={token?.logo}
							pricePercentChange={token?.pricePercentChange}
						/>
					</Link>
				))}
			</Box>
		</Container>
	);
}

export { default as TokenCarouselSkeleton } from "./Skeleton";
