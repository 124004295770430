import { Button, Modal, Typography } from "@vaporfi/uikit";
import type React from "react";
import type { ReactNode } from "react";

export enum CurrencyModalView {
	search = 0,
	manage = 1,
	importToken = 2,
	importList = 3,
}

export interface TokenSelectorModalWrapperProps {
	body: ReactNode;
	modalView: CurrencyModalView;
	onDismiss: () => void;
	title: string;
	showBackIcon?: boolean;
	onBack?: () => void;
}

export const TokenSelectorModalWrapper: React.FC<
	TokenSelectorModalWrapperProps
> = ({ body, modalView, onBack, onDismiss, showBackIcon, title }) => {
	const modalSizeClasses = {
		default: "w-full sm:min-w-[480px] sm:w-auto",
		manage: "w-full sm:min-w-[480px] sm:w-auto",
	};

	const currentModalSizeClass =
		modalView === CurrencyModalView.manage
			? modalSizeClasses.manage
			: modalSizeClasses.default;

	return (
		<Modal
			title={title}
			titleClassName="px-2 py-4"
			onDismiss={onDismiss}
			className={`border-cardBorder border border-solid bg-black ${currentModalSizeClass}`}
			showBackIcon={showBackIcon}
			onBack={onBack}
			body={body}
		/>
	);
};

interface ModalHeaderProps {
	title: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title }) => (
	<div className="flex w-full items-center justify-between border-b-0 p-0">
		<Typography className="text-lg font-semibold">{title}</Typography>
	</div>
);

export const ManageTokensButton: React.FC<{ onClick: () => void }> = ({
	onClick,
}) => (
	<Button color="blue" size="base" onClick={onClick}>
		<Typography weight="semibold">Manage Tokens</Typography>
	</Button>
);

export const CombinedListsTokensUI: React.FC<{
	showLists: boolean;
	onListsClick: () => void;
	onTokensClick: () => void;
}> = ({ onListsClick, onTokensClick, showLists }) => (
	<div className="flex justify-center border border-blue-500">
		<Button
			size="base"
			color={showLists ? "blue" : "transparent"}
			onClick={onListsClick}
			className="w-40"
		>
			<p>Lists</p>
		</Button>
		<Button
			size="base"
			color={showLists ? "transparent" : "blue"}
			onClick={onTokensClick}
			className="w-40"
		>
			<p>Tokens</p>
		</Button>
	</div>
);
