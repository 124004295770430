import { createReducer } from "@reduxjs/toolkit";

import { atomWithReducer } from "jotai/utils";
import {
	Field,
	resetMintState,
	setFullRange,
	setTokenToIncreaseLiquidityFor,
	typeInput,
	typeLeftRangeInput,
	typeRightRangeInput,
	typeStartPriceInput,
} from "./actions";

type FullRange = true;

interface MintState {
	readonly independentField: Field;
	readonly typedValue: string;
	readonly startPriceTypedValue: string; // for the case when there's no liquidity
	readonly leftRangeTypedValue: string | FullRange;
	readonly rightRangeTypedValue: string | FullRange;
	readonly tokenId: string;
}

const initialState: MintState = {
	independentField: Field.CURRENCY_A,
	leftRangeTypedValue: "",
	rightRangeTypedValue: "",
	startPriceTypedValue: "",
	tokenId: null,
	typedValue: "",
};

const reducer = createReducer<MintState>(initialState, (builder) =>
	builder
		.addCase(
			setTokenToIncreaseLiquidityFor,
			(state, { payload: { tokenId } }) => {
				return {
					...state,
					tokenId,
				};
			},
		)
		.addCase(resetMintState, () => initialState)
		.addCase(setFullRange, (state) => {
			return {
				...state,
				leftRangeTypedValue: true,
				rightRangeTypedValue: true,
			};
		})
		.addCase(typeStartPriceInput, (state, { payload: { typedValue } }) => {
			return {
				...state,
				startPriceTypedValue: typedValue,
			};
		})
		.addCase(typeLeftRangeInput, (state, { payload: { typedValue } }) => {
			return {
				...state,
				leftRangeTypedValue: typedValue,
			};
		})
		.addCase(typeRightRangeInput, (state, { payload: { typedValue } }) => {
			return {
				...state,
				rightRangeTypedValue: typedValue,
			};
		})
		.addCase(
			typeInput,
			(state, { payload: { field, noLiquidity, typedValue } }) => {
				if (noLiquidity) {
					// they're typing into the field they've last typed in
					// eslint-disable-next-line unicorn/prefer-ternary
					if (field === state.independentField) {
						return {
							...state,
							independentField: field,
							typedValue,
						};
					}
					// they're typing into a new field, store the other value

					return {
						...state,
						independentField: field,
						typedValue,
					};
				}
				return {
					...state,
					independentField: field,
					typedValue,
				};
			},
		),
);
export const createFormAtom = () => atomWithReducer(initialState, reducer);

export default reducer;
