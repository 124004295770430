function wait(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function waitRandom(min: number, max: number): Promise<void> {
	return wait(min + Math.round(Math.random() * Math.max(0, max - min)));
}

/**
 * This error is thrown if the function is cancelled before completing
 */
export class CancelledError extends Error {
	constructor() {
		super("Cancelled");
	}
}

/**
 * Throw this error if the function should retry
 */
export class RetryableError extends Error {}

/**
 * Retries the function that returns the promise until the promise successfully resolves up to n retries
 * @param fn function to retry
 * @param n how many times to retry
 * @param minWait min wait between retries in ms
 * @param maxWait max wait between retries in ms
 */
export function retry<T>(
	fn: () => Promise<T>,
	{ maxWait, minWait, n }: { n: number; minWait: number; maxWait: number },
): { promise: Promise<T>; cancel: () => void } {
	let completed = false;
	let rejectCancelled: (error: Error) => void;
	const promise = new Promise<T>((resolve, reject) => {
		rejectCancelled = reject;

		function attempt() {
			if (completed) return;

			fn()
				.then((result) => {
					if (!completed) {
						resolve(result);
						completed = true;
					}
				})
				.catch((error) => {
					console.error("multicall - retry error", error);
					if (completed) return;
					if (n <= 0 || !(error instanceof RetryableError)) {
						reject(error);
						completed = true;
					} else {
						n--;
						waitRandom(minWait, maxWait).then(attempt);
					}
				});
		}

		attempt();
	});
	return {
		cancel: () => {
			if (completed) return;
			completed = true;
			rejectCancelled(new CancelledError());
		},
		promise,
	};
}

/* eslint-enable */
