import { ZERO } from "@config/constants";
import { formatUnits } from "viem";
import { getFullDecimalMultiplier } from "./getFullDecimalMultiplier";

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: bigint, decimals = 18) => {
	return amount * getFullDecimalMultiplier(decimals);
};

export const getBalanceAmount = (amount: bigint, decimals = 18) => {
	return amount / getFullDecimalMultiplier(decimals);
};

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: bigint, decimals = 18) => {
	const number = Number(getBalanceAmount(balance, decimals).toString());
	return Number.isNaN(number) ? 0 : number;
};

export const getFullDisplayBalance = (
	balance: bigint,
	decimals = 18,
	displayDecimals?: number,
): string => {
	return Number(getBalanceAmount(balance, decimals).toString())?.toFixed(
		displayDecimals,
	);
};

/**
 * Don't use the result to convert back to number.
 * It uses undefined locale which uses host language as a result.
 * Languages have different decimal separators which results in inconsistency when converting back this result to number.
 */
export const formatNumber = (
	number: number,
	minPrecision = 2,
	maxPrecision = 2,
) => {
	const options = {
		maximumFractionDigits: maxPrecision,
		minimumFractionDigits: minPrecision,
	};
	return number.toLocaleString(undefined, options);
};

/**
 * Method to format the display of wei given a BigInt object
 * Note: does NOT round
 */
export const formatBigNumber = (
	number: bigint,
	displayDecimals = 18,
	decimals = 18,
) => {
	const remainder = BigInt(
		+number?.toString() % 10 ** (decimals - displayDecimals),
	);
	return formatUnits(number - remainder, decimals);
};

/**
 * Method to format the display of wei given an bigint object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (
	number: bigint,
	displayDecimals = 18,
	decimals = 18,
) => {
	const formattedString = formatUnits(number, decimals);
	return (+formattedString).toFixed(displayDecimals);
};

export const formatLpBalance = (balance: bigint) => {
	const stakedBalanceBigNumber = getBalanceAmount(balance);
	if (stakedBalanceBigNumber > ZERO && stakedBalanceBigNumber < 0.000_01) {
		return "< 0.00001";
	}
	return Number(stakedBalanceBigNumber?.toString()).toFixed(5);
};
