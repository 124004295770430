"use client";
import type { MoarCandyToken } from "@vaporfi/db";
import { useCommify } from "@vaporfi/hooks";
import {
	Box,
	Button,
	Container,
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
	Typography,
} from "@vaporfi/uikit";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type React from "react";
import { useMemo, useState } from "react";
import {
	FacebookIcon,
	FacebookShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	XIcon,
} from "react-share";
import AddressLink from "../Link/Address";
import TokenCardSlider from "./components/shared";

interface TradeSidebarComponentProps {
	token: MoarCandyToken;
	date: string;
	time: string;
	dexName: string;
	maxMcap: number;
}

const truncateAddress = (address?: string) => {
	if (!address) return "";
	return `${address?.slice(0, 6)}....${address?.slice(
		Math.max(0, address?.length - 4),
	)}`;
};

const SocialLink: React.FC<{ url?: string; label: string }> = ({
	label,
	url,
}) =>
	url ? (
		<Link href={url} passHref target="_blank" rel="noopener noreferrer">
			<Typography size="xs" className="text-center">
				[{label}]
			</Typography>
		</Link>
	) : (
		<Typography size="xs" className="text-center">
			[{label}]
		</Typography>
	);

const TradeSidebarComponent: React.FC<TradeSidebarComponentProps> = ({
	date,
	dexName,
	maxMcap,
	time,
	token,
}) => {
	const pathname = usePathname();
	const shareUrl = `${
		typeof window === "undefined" ? "" : window?.location?.origin
	}${pathname}`;
	const shareMessage = `Check out this new token that just launched on Moar Candy! ${token?.symbol}!
  ${shareUrl}
  #${token?.symbol} #CandyFactory #MemeCoin #Avalanche`;
	const commify = useCommify();
	const [activeTab, setActiveTab] = useState<"holders" | "dev">("holders");

	const { devWalletsWithPercentages, holders } = useMemo(() => {
		const holdersList = token?.holders || [];

		const devWallets = [token?.deployer, ...(token?.teamWallets || [])];
		const devWalletsWithPercentages =
			devWallets?.map((wallet, index) => {
				const percentageValue =
					token?.holdersBalancePercentage?.[
						token?.holders?.findIndex((holder) => holder?.holderName === wallet)
					];

				const percentage = percentageValue
					? `${Number.parseFloat(percentageValue)?.toFixed(2)}%`
					: "0%";

				return {
					label: index === 0 ? "Creator: " : `Wallet ${index + 1}: `,
					percentage,
					wallet,
				};
			}) || [];

		return { devWalletsWithPercentages, holders: holdersList };
	}, [
		token?.holders,
		token?.deployer,
		token?.teamWallets,
		token?.holdersBalancePercentage,
	]);

	return (
		<Container>
			<Box className="flex items-center justify-around gap-2">
				<SocialLink url={token?.website} label="Website" />
				<SocialLink url={token?.telegram} label="Telegram" />
				<SocialLink url={token?.x} label="Twitter" />
			</Box>
			<Box className="ml-4 mt-4 flex flex-row items-center gap-6">
				<Image
					src={token?.logo}
					alt={token?.name}
					width={64}
					height={64}
					className="h-16 w-16"
					loading="lazy"
				/>
				<Box className="flex flex-1 flex-col justify-center">
					<Typography size="xl" weight="bold" className="text-left">
						{token?.name}
					</Typography>
					<Typography size="sm" className="text-left">
						Ticker: {token?.symbol}
					</Typography>
					<Typography size="sm" className="whitespace-nowrap text-left">
						Market Cap: ${token?.marketCap?.toLocaleString()}
					</Typography>
				</Box>
				<HoverCard>
					<HoverCardTrigger asChild>
						<Button
							variant="squared"
							color="turquoise"
							size="sm"
							className="-mt-3.5"
						>
							<Typography color="light">Share</Typography>
						</Button>
					</HoverCardTrigger>
					<HoverCardContent className="rounded-md bg-white p-4 shadow-md">
						<Typography color="purple1" size="sm" className="mb-2">
							Share this token on social media!
						</Typography>
						<Box className="grid grid-cols-4 gap-4">
							<FacebookShareButton url={shareMessage}>
								<FacebookIcon size={32} round />
							</FacebookShareButton>
							<TelegramShareButton url={shareMessage}>
								<TelegramIcon size={32} round />
							</TelegramShareButton>
							<TwitterShareButton url={shareMessage}>
								<XIcon size={32} round />
							</TwitterShareButton>
							<WhatsappShareButton url={shareMessage}>
								<WhatsappIcon size={32} round />
							</WhatsappShareButton>
						</Box>
					</HoverCardContent>
				</HoverCard>
			</Box>
			<Box className="mt-4 flex flex-col rounded-md p-2">
				<Typography size="sm" weight="bold" className="text-left">
					Bonding curve progress: {token?.progress?.toFixed(2)}%
				</Typography>
				<TokenCardSlider progress={token?.progress} />
				<Box className="mt-4">
					<Typography size="xs" className="text-left">
						When the market cap reaches {commify(maxMcap, { prefix: "$" })} all
						the liquidity from the bonding curve will be deposited into{" "}
						{dexName} and burned. Progression increases as the price goes up.
					</Typography>
					<Typography size="xs" className="mt-2 text-left">
						There are{" "}
						{token?.maxSupply && token?.circulatingSupply
							? commify(
									Math.floor(
										(+token?.maxSupply - +token?.circulatingSupply) / 1e18,
									),
								)
							: "0"}{" "}
						tokens still available for sale in the bonding curve and there is{" "}
						{commify(token?.currentEthContribution?.toFixed(3) ?? "0")} AVAX in
						the bonding curve.
					</Typography>
				</Box>
			</Box>
			<Box className="mt-4 flex flex-row items-center gap-8">
				<Image
					src={token?.logo}
					alt={token?.name}
					width={64}
					height={64}
					className="h-16 w-16"
					loading="lazy"
				/>
				<Box className="flex flex-col justify-center">
					<Typography size="base" weight="bold" className="text-left">
						Crowned {token?.symbol} on {date}, {time}
					</Typography>
				</Box>
			</Box>
			<Box className="mt-4">
				<Box className="mt-4">
					<Box className="flex flex-row items-center gap-4">
						<Typography
							size="base"
							weight={activeTab === "holders" ? "bold" : "normal"}
							className="cursor-pointer"
							onClick={() => setActiveTab("holders")}
						>
							Holders
						</Typography>
						<Typography size="base">|</Typography>
						<Typography
							size="base"
							weight={activeTab === "dev" ? "bold" : "normal"}
							className="cursor-pointer"
							onClick={() => setActiveTab("dev")}
						>
							Dev
						</Typography>
					</Box>
					<Box className="mt-4">
						{activeTab === "holders" && holders?.length > 0
							? holders?.map((holder, index) => (
									<Box
										key={`holder-${holder?.holderName}`}
										className="flex items-center justify-between text-xs"
									>
										<AddressLink address={holder?.holderName}>
											<Box className="flex cursor-pointer items-center space-x-1">
												<Typography size="sm" className="text-left">
													{index + 1}. {truncateAddress(holder?.holderName)}
												</Typography>
												{holder?.isBondingCurve && (
													<Typography
														color="purple3"
														size="sm"
														as="span"
														className="ml-1"
													>
														(bonding curve)
													</Typography>
												)}
												{holder?.isDev && (
													<Typography
														color="purple3"
														size="sm"
														as="span"
														className="ml-1"
													>
														DEV
													</Typography>
												)}
											</Box>
										</AddressLink>
										<Typography size="sm" className="text-right">
											{holder?.holderPercent}
										</Typography>
									</Box>
								))
							: devWalletsWithPercentages?.length > 0 &&
								devWalletsWithPercentages?.map((walletInfo, index) => (
									<Box
										key={`dev-wallet-${walletInfo?.wallet}`}
										className="flex items-center justify-between text-xs"
									>
										<Link
											href={`https://snowtrace.io/address/${walletInfo?.wallet}`}
											passHref
											target="_blank"
											rel="noopener noreferrer"
										>
											<Box className="flex cursor-pointer items-center">
												<Typography size="sm" className="text-left">
													{index === 0 ? "Creator: " : `Wallet ${index}: `}
													{truncateAddress(walletInfo?.wallet)}
												</Typography>
											</Box>
										</Link>
										<Typography size="sm" className="text-right">
											{walletInfo?.percentage}
										</Typography>
									</Box>
								))}
					</Box>
				</Box>
			</Box>
		</Container>
	);
};

export default TradeSidebarComponent;
