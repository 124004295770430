import { useMatchBreakpointsContext } from "@vapordex/uikit";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Sidebar = () => {
	const { isMobile, isTablet } = useMatchBreakpointsContext();

	return isMobile || isTablet ? <Mobile /> : <Desktop />;
};

export default Sidebar;
