import { darkColors, lightColors } from "../../theme/colors";
import type { ModalTheme } from "./types";

export const light: ModalTheme = {
	background: lightColors.backgroundAlt,
};

export const dark: ModalTheme = {
	background: darkColors.backgroundAlt,
};
