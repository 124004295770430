import { useCallback } from "react";

export const useAddTokenToWallet = (signer: any) => {
	const addTokenToWallet = useCallback(
		async (token: {
			address: string;
			decimals: number;
			logoURI: string;
			symbol: string;
		}) => {
			if (signer?.watchAsset) {
				return await signer.watchAsset({
					options: {
						address: token.address,
						decimals: token.decimals,
						image: token.logoURI,
						symbol: token.symbol,
					},
					type: "ERC20",
				});
			}
			const ethereum = window.ethereum;
			if (ethereum?.request) {
				return await ethereum.request({
					method: "wallet_watchAsset",
					params: {
						options: {
							address: token.address,
							decimals: token.decimals,
							image: token.logoURI,
							symbol: token.symbol,
						},
						type: "ERC20",
					},
				});
			}
			throw new Error("No crypto wallet found");
		},
		[signer],
	);

	return { addTokenToWallet };
};
