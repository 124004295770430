import {
	Box,
	Button,
	ChevronDownIcon,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Select,
	Typography,
	useWatch,
} from "@vaporfi/uikit";
import { removeNonNumbers } from "@vaporfi/utils";
import { memo, useMemo } from "react";
import type { CommonFieldsProps } from "../../types";
import {
	CancelButton,
	FieldsContainer,
	useWithFieldsValidation,
} from "./shared";

export const TokenInfoFields = memo(
	({ availableChains, chainId, form, onCancel, onNext }: CommonFieldsProps) => {
		const onNextWithValidate = useWithFieldsValidation(
			chainId,
			"tokenInfo",
			onNext,
		);
		const initialSupply = useWatch({ name: ["initialSupply"] });

		const selectOptions = useMemo(() => {
			if (!availableChains) return [];

			return availableChains?.map((chain) => ({
				label: chain?.name,
				value: chain?.id?.toString(),
			}));
		}, [availableChains]);

		return (
			<FieldsContainer>
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Token Name (*)</FormLabel>
							<FormControl>
								<Input
									className="max-h-10 bg-neutral-900 p-2"
									placeholder="eg Vapor"
									inputMode="text"
									type="text"
									pattern="(.*?)"
									{...field}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="symbol"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Token Symbol (*)</FormLabel>
							<FormControl>
								<Input
									type="text"
									inputMode="text"
									className="bg-neutral-900 p-2"
									placeholder="eg VPND"
									pattern="(.*?)"
									{...field}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="logo"
					render={({ field: { value, ...rest } }) => {
						return (
							<FormItem className="lg:col-span-2">
								<FormLabel className="w-full">
									<Box className="flex h-[152px] w-full  flex-col items-center justify-center gap-4 rounded-2xl border-[3px] border-dotted border-neutral-400">
										<Typography> {value?.name || ""}</Typography>
										<Typography>
											Drop your logo or{" "}
											<span className="cursor-pointer text-blue-500">
												browse
											</span>
										</Typography>
									</Box>
								</FormLabel>
								<FormControl>
									<Input
										{...rest}
										type="file"
										accept="image/*"
										className="hidden"
										onChange={(event) => {
											const file = event.target.files?.[0];
											return form.setValue("logo", file, {
												shouldValidate: true,
											});
										}}
									/>
								</FormControl>

								<FormMessage />
							</FormItem>
						);
					}}
				/>

				<FormField
					control={form.control}
					name="chain"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Chain (*)</FormLabel>
							<FormControl>
								<Select
									{...field}
									options={selectOptions}
									selectedValue={field.value}
									onSelect={field.onChange}
									icon={<ChevronDownIcon className="scale-125" />}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="decimals"
					disabled
					render={({ field }) => (
						<FormItem>
							<FormLabel>Token Decimals (*)</FormLabel>
							<FormControl>
								<Input
									className="bg-neutral-900 p-2"
									placeholder=""
									{...field}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="initialSupply"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Initial Supply (*)</FormLabel>
							<FormControl>
								<Input
									className="bg-neutral-900 p-2"
									placeholder="eg: 10000"
									{...field}
									pattern="(.*?)"
									inputMode="text"
									onChange={(event) => {
										const num = event.target.value;
										form.setValue(
											"initialSupply",
											removeNonNumbers(
												Number(removeNonNumbers(num))?.toLocaleString(),
												" ",
											),
										);
									}}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="maximumSupply"
					disabled
					render={({ field }) => (
						<FormItem>
							<FormLabel>Maximum Supply</FormLabel>
							<FormControl>
								<Input
									className="bg-neutral-900 p-2"
									{...field}
									value={initialSupply}
									readOnly
									placeholder="Same as initial supply."
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>

				<Box className="col-span-2 flex justify-between">
					<CancelButton onCancel={onCancel} />
					<Button
						color="blue"
						size="sm"
						className="w-fit"
						onClick={onNextWithValidate}
					>
						<Typography>Next Step</Typography>
					</Button>
				</Box>
			</FieldsContainer>
		);
	},
);
