import Svg from "../Svg";
import type { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
	return (
		<Svg
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 1.75C0 1.05977 0.559766 0.5 1.25 0.5H16.25C16.9414 0.5 17.5 1.05977 17.5 1.75C17.5 2.44141 16.9414 3 16.25 3H1.25C0.559766 3 0 2.44141 0 1.75ZM2.5 8C2.5 7.30859 3.05977 6.75 3.75 6.75H18.75C19.4414 6.75 20 7.30859 20 8C20 8.69141 19.4414 9.25 18.75 9.25H3.75C3.05977 9.25 2.5 8.69141 2.5 8ZM16.25 15.5H1.25C0.559766 15.5 0 14.9414 0 14.25C0 13.5586 0.559766 13 1.25 13H16.25C16.9414 13 17.5 13.5586 17.5 14.25C17.5 14.9414 16.9414 15.5 16.25 15.5Z" />
		</Svg>
	);
};

export default Icon;
