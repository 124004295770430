export const IVaporDEXV2PoolStateABI = [
	{
		inputs: [],
		name: "feeGrowthGlobal0X128",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "feeGrowthGlobal1X128",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "liquidity",
		outputs: [
			{
				internalType: "uint128",
				name: "",
				type: "uint128",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "index",
				type: "uint256",
			},
		],
		name: "observations",
		outputs: [
			{
				internalType: "uint32",
				name: "blockTimestamp",
				type: "uint32",
			},
			{
				internalType: "int56",
				name: "tickCumulative",
				type: "int56",
			},
			{
				internalType: "uint160",
				name: "secondsPerLiquidityCumulativeX128",
				type: "uint160",
			},
			{
				internalType: "bool",
				name: "initialized",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "key",
				type: "bytes32",
			},
		],
		name: "positions",
		outputs: [
			{
				internalType: "uint128",
				name: "_liquidity",
				type: "uint128",
			},
			{
				internalType: "uint256",
				name: "feeGrowthInside0LastX128",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "feeGrowthInside1LastX128",
				type: "uint256",
			},
			{
				internalType: "uint128",
				name: "tokensOwed0",
				type: "uint128",
			},
			{
				internalType: "uint128",
				name: "tokensOwed1",
				type: "uint128",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "protocolFees",
		outputs: [
			{
				internalType: "uint128",
				name: "token0",
				type: "uint128",
			},
			{
				internalType: "uint128",
				name: "token1",
				type: "uint128",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "slot0",
		outputs: [
			{
				internalType: "uint160",
				name: "sqrtPriceX96",
				type: "uint160",
			},
			{
				internalType: "int24",
				name: "tick",
				type: "int24",
			},
			{
				internalType: "uint16",
				name: "observationIndex",
				type: "uint16",
			},
			{
				internalType: "uint16",
				name: "observationCardinality",
				type: "uint16",
			},
			{
				internalType: "uint16",
				name: "observationCardinalityNext",
				type: "uint16",
			},
			{
				internalType: "uint8",
				name: "feeProtocol",
				type: "uint8",
			},
			{
				internalType: "bool",
				name: "unlocked",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "int16",
				name: "wordPosition",
				type: "int16",
			},
		],
		name: "tickBitmap",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "int24",
				name: "tick",
				type: "int24",
			},
		],
		name: "ticks",
		outputs: [
			{
				internalType: "uint128",
				name: "liquidityGross",
				type: "uint128",
			},
			{
				internalType: "int128",
				name: "liquidityNet",
				type: "int128",
			},
			{
				internalType: "uint256",
				name: "feeGrowthOutside0X128",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "feeGrowthOutside1X128",
				type: "uint256",
			},
			{
				internalType: "int56",
				name: "tickCumulativeOutside",
				type: "int56",
			},
			{
				internalType: "uint160",
				name: "secondsPerLiquidityOutsideX128",
				type: "uint160",
			},
			{
				internalType: "uint32",
				name: "secondsOutside",
				type: "uint32",
			},
			{
				internalType: "bool",
				name: "initialized",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
] as const;
