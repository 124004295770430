import { Button, Typography } from "@vaporfi/uikit";
import { STRATOSPHERE_URL } from "@vaporfi/utils";
import Link from "next/link";
import React from "react";

const SaveRewards = () => {
	return (
		<Link href={STRATOSPHERE_URL} legacyBehavior>
			<Button
				fullWidth
				color="turquoise"
				size="base"
				css={{
					height: "var(--space-9)",
				}}
			>
				<Typography size="base" weight="semibold" color="black">
					Save & Earn Rewards
				</Typography>
			</Button>
		</Link>
	);
};

export default SaveRewards;
