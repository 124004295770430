import { Container } from "@vaporfi/uikit";
import { getStratosphereAvatarUrl } from "@vaporfi/utils";
import type React from "react";
import { Profile } from "../Profile";
import ClaimPoints from "./components/ClaimPoints";
import KPIs from "./components/KPIs";
import OneTimeEvents from "./components/OneTimeEvents";
import Progress from "./components/Progress";
import type { StratosphereDashboardProps } from "./types";

export const StratosphereDashboard: React.FC<StratosphereDashboardProps> = ({
	account,
	availablePoints,
	claimablePoints,
	currentTier,
	expiringPoints,
	onClaimPointsClick,
	oneTimeEventStatus,
	pendingPoints,
	progressPercentage,
	tokenId,
	totalPoints,
	weeklyPoints,
}) => {
	return (
		<Container stack="column" className="gap-[30px] p-4 lg:p-[30px]" fullWidth>
			<Profile
				isReadOnly
				isAvatarManagementEnabled
				tokenId={tokenId}
				validAvatar={getStratosphereAvatarUrl(tokenId, account)}
			/>
			<Progress
				{...{
					currentTier,
					pendingPoints,
					progressPercentage,
				}}
			/>
			<ClaimPoints
				claimablePoints={claimablePoints}
				onClaimPointsClick={onClaimPointsClick}
			/>
			<KPIs
				{...{ availablePoints, expiringPoints, totalPoints, weeklyPoints }}
			/>
			<OneTimeEvents eventStatus={oneTimeEventStatus} />
		</Container>
	);
};

export * from "./constants";
export * from "./types";
