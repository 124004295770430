"use client";

import { useEffect, useMemo, useState } from "react";
import { config } from "../stitches.config";

type MediaKeys = keyof typeof config.media;

export const useMedia = () => {
	const media = useMemo(() => {
		return Object.keys(config.media).reduce(
			(acc, curr) => {
				if (curr.endsWith("Max") || curr === "2xl" || curr === "lg") {
					acc[curr as keyof typeof acc] = config.media[curr as MediaKeys];
				}
				return acc;
			},
			{} as {
				lg: string;
				"2xl": string;
				smMax: string;
				mdMax: string;
				lgMax: string;
				xlMax: string;
				"2xlMax": string;
			},
		);
	}, []);

	const [currentBreakpoint, setCurrentBreakpoint] = useState<MediaKeys>("lg");
	const breakpoints = useMemo(() => Object.keys(media), [media]);

	useEffect(() => {
		const handleWindowResize = () => {
			const matchingBreakpoint = breakpoints.find((breakpoint) => {
				const mediaQuery = window.matchMedia(
					media[breakpoint as keyof typeof media],
				);

				return mediaQuery.matches;
			});
			setCurrentBreakpoint(matchingBreakpoint as MediaKeys);
		};

		window.addEventListener("resize", handleWindowResize);
		handleWindowResize();

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, [media, breakpoints]);

	const matchingKeyValues = useMemo(
		() => ({
			/* if breakpoint isn't found during processing,
			 * will always be in case of big screen like 4k
			 */
			isBigScreen: currentBreakpoint === undefined,
			// if width is greater than 1024 or 1536
			isDesktop: ["2xl", "lg"].includes(currentBreakpoint),
			// if width is less than or equal to 640px
			isMobile: currentBreakpoint === "smMax",
			// if width is less than or equal to 1280px
			isTablet: currentBreakpoint === "xlMax",
		}),
		[currentBreakpoint],
	);

	return matchingKeyValues;
};
