import { createAction } from "@reduxjs/toolkit";

export enum Field {
	CURRENCY_A = "CURRENCY_A",
	CURRENCY_B = "CURRENCY_B",
}

export enum Bound {
	LOWER = "LOWER",
	UPPER = "UPPER",
}

export const typeInput = createAction<{
	field: Field;
	typedValue: string;
	noLiquidity: boolean;
}>("mintV2/typeInputMint");
export const typeStartPriceInput = createAction<{ typedValue: string }>(
	"mintV2/typeStartPriceInput",
);
export const typeLeftRangeInput = createAction<{ typedValue: string }>(
	"mintV2/typeLeftRangeInput",
);
export const typeRightRangeInput = createAction<{ typedValue: string }>(
	"mintV2/typeRightRangeInput",
);
export const setTokenToIncreaseLiquidityFor = createAction<{
	tokenId: string;
}>("mintV2/setPositionToIncreaseLiquidityFor");
export const resetMintState = createAction<void>("mintV2/resetMintState");
export const setFullRange = createAction<void>("mintV2/setFullRange");
