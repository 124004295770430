import { Box, Container } from "@vaporfi/uikit";
import { Skeleton } from "./Item";

export default function TokenCarouselSkeleton() {
	const skeletonItems = Array.from({ length: 8 }, (_, index) => (
		<Skeleton key={`token-carousel-skeleton-${_}`} />
	));

	return (
		<Container className="overflow-hidden overflow-x-auto">
			<Box className="auto-cols-fit grid w-full grid-flow-col gap-4 px-4">
				{skeletonItems}
			</Box>
		</Container>
	);
}
