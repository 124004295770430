import { type Chain, telosTestnet as _telosTestnet } from "viem/chains";

export const telosTestnet = {
	..._telosTestnet,
	blockExplorers: {
		default: {
			name: "Teloscan (testnet)",
			url: "https://testnet.teloscan.io",
		},
	} as const,
	contracts: {
		multicall3: {
			address: "0xAE96D72FE112a9eB21C5627222F9173E9FF9b285",
			blockCreated: 278_551_551,
		},
	},
} as Chain;
