import {
	Box,
	Button,
	CoinPagesIcon,
	Container,
	type ContainerProps,
	ContractIcon,
	DoubleRingIcon,
	Grid,
	LockIcon,
	RocketIcon,
	ShieldIcon,
	Span,
	Typography,
	WhaleIcon,
} from "@vaporfi/uikit";
import {
	COINPAGES_URL,
	SABLIER_URL,
	STRATOSPHERE_URL,
	cn,
} from "@vaporfi/utils";
import Link from "next/link";
import { type HTMLAttributes, forwardRef, memo } from "react";
import React from "react";

export const GradientBlueContainer = forwardRef<HTMLDivElement, ContainerProps>(
	({ className, ...props }, ref) => {
		return (
			<Container
				{...props}
				ref={ref}
				className={cn("bg-gradient-to-br from-blue-600 to-blue-500", className)}
			/>
		);
	},
);

const InfoContainer = forwardRef<HTMLDivElement, ContainerProps>(
	(props, ref) => (
		<GradientBlueContainer
			className="flex items-center gap-8 p-4 lg:p-8"
			{...props}
			ref={ref}
		/>
	),
);
export const MemeFactoryInfo = memo(
	({ isStratosphereMember }: { isStratosphereMember: boolean }) => {
		return (
			<Container stack="column" className="gap-4 lg:gap-[30px]" fullWidth>
				<Box bg="gradient" className="rounded-[32px] p-5 lg:p-8">
					<Typography className="text-3xl leading-[36px] lg:text-[40px]">
						<Span font="manverse">TOKEN</Span>
						<Span font="manverseOutline">FACTORY</Span>
					</Typography>
				</Box>

				<Box>
					<Typography className="font-manverse text-lg lg:text-2xl">
						WELCOME TO THE <Span color="turquoise">TOKENFACTORY</Span>
					</Typography>
					<Typography>
						The TokenFactory is your source for the highest-quality coins
						launching on Avalanche.
					</Typography>
				</Box>

				{!isStratosphereMember && (
					<GradientBlueContainer className="inline-flex gap-4 rounded-lg px-4 py-4 lg:gap-8 lg:px-8">
						<DoubleRingIcon />
						<Box className="flex flex-col gap-2">
							<Typography>
								<span className="font-manverse font-semibold">
									Stratosphere
								</span>{" "}
								members have exclusive early access to all projects for the
								first hour during the launch.
							</Typography>

							<Link href={STRATOSPHERE_URL} legacyBehavior>
								<Button color="light" size="sm" className="w-fit">
									<Typography>Join Stratosphere</Typography>
								</Button>
							</Link>
						</Box>
					</GradientBlueContainer>
				)}

				<Grid isContainer className="h-fit grid-cols-1 gap-4 md:grid-cols-2">
					<InfoContainer>
						<Box className="w-[80px]">
							<ContractIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							Contract created securely by VaporFi and automatically renounced.
							No need to hire devs.
						</Typography>
					</InfoContainer>
					<InfoContainer>
						<Box className="w-[80px]">
							<LockIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							Liquidity automatically locked for the time period of your choice,
							or burned if you choose.
						</Typography>
					</InfoContainer>
					<InfoContainer>
						<Box className="w-[80px]">
							<WhaleIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							Protect your token from whales with a 1% buy cap at launch.
						</Typography>
					</InfoContainer>
					<InfoContainer>
						<Box className="w-[80px]">
							<ShieldIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							Assure your community that your coin is not a rug.
						</Typography>
					</InfoContainer>
					<InfoContainer>
						<Box className="w-[80px]">
							<RocketIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							Launching with TokenFactory is as easy as completing a simple
							form.
						</Typography>
					</InfoContainer>
					<InfoContainer>
						<Box className="w-[80px]">
							<CoinPagesIcon />
						</Box>
						<Typography size="lg" weight="semibold">
							25% discount on CoinPages.xyz with discount code VAPORFI25. Learn
							more{" "}
							<Link
								href={COINPAGES_URL}
								target="_blank"
								referrerPolicy="no-referrer"
							>
								<Span color="turquoise">here</Span>
							</Link>
							.
						</Typography>
					</InfoContainer>
				</Grid>
			</Container>
		);
	},
);

export const CreateTokenInfo = memo(
	({
		isLiquidityBurn,
		isStratosphereWhitelist,
		lockDuration,
	}: {
		isLiquidityBurn: boolean;
		isStratosphereWhitelist: boolean;
		lockDuration: string;
	}) => {
		return (
			<Container stack="column" className="gap-4 lg:gap-7">
				<Box>
					<Typography className="pb-4">
						By clicking on the Create Token button, a 250 USDC fee is collected.
						This fee is utilized for the following purposes:
					</Typography>
					<ul style={{ listStyle: "inside" }}>
						<Li>Purchasing 125 USDC worth of VAPE tokens.</Li>
						<Li>Adding liquidity to the USDC/VAPE liquidity pool (LP).</Li>
					</ul>
				</Box>
				<Box>
					<Typography className="pb-4">
						Additionally, the smart contract for the token is created with the
						following specifications:
					</Typography>
					<ul style={{ listStyle: "inside" }}>
						<Li>Contract renounced</Li>
						{isLiquidityBurn ? (
							<Li>LP burned (at launch) </Li>
						) : (
							<Li>
								LP locked (at launch) for {lockDuration} using{" "}
								<Link
									href={SABLIER_URL}
									target="_blank"
									referrerPolicy="no-referrer"
								>
									<Span color="turquoise">Sablier</Span>
								</Link>
							</Li>
						)}

						{isStratosphereWhitelist && (
							<Li>Stratosphere whitelist will initiate 1 hour from launch</Li>
						)}
						<Li>
							Prevent users of buying more than 1% of the total supply during{" "}
							{isStratosphereWhitelist ? "2h" : "1h"} from the launch date
						</Li>
						<Li>Contract verified on Snowtrace </Li>
						<Li>Contract verified on Avascan</Li>
					</ul>
				</Box>
			</Container>
		);
	},
);
const Li = (props: HTMLAttributes<HTMLLIElement>) => (
	<li color="white" {...props} className="text-white" />
);
