"use client";
import {
	Box,
	Button,
	Container,
	type ContainerProps,
	Typography,
	useWatch,
} from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import {
	type ReactNode,
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { tv } from "tailwind-variants";
import { fields } from "../../constants";
import { validateFields } from "../../utils";

export const CancelButton = memo(({ onCancel }: { onCancel: () => void }) => (
	<Button
		type="submit"
		color="red"
		size="sm"
		className="w-fit"
		onClick={onCancel}
	>
		<Typography>Cancel</Typography>
	</Button>
));

export const useWithFieldsValidation = (
	chainId: number,
	fieldKey: keyof typeof fields,
	callback?: () => void,
) => {
	const tokenInfoFields = fields[fieldKey];
	const fieldValues = useWatch({
		name: tokenInfoFields,
	});

	return useCallback(() => {
		const isValid = validateFields(chainId, tokenInfoFields, fieldValues);

		if (isValid) return callback?.();
		return;
	}, [callback, chainId, fieldValues, tokenInfoFields]);
};

export const FieldsContainer = forwardRef<HTMLDivElement, ContainerProps>(
	({ className, ...rest }, ref) => (
		<Container
			{...rest}
			ref={ref}
			bg="black"
			className={cn(
				"flex flex-col gap-8 px-4 py-4 pb-4 lg:grid lg:grid-cols-2 lg:px-8",
				className,
			)}
		/>
	),
);

export const formStepStyles = tv({
	slots: {
		container: "flex items-center gap-2 py-2 pl-2 pr-4",
		label: "flex h-8 w-8 items-center justify-center rounded-[32px]",
	},
	variants: {
		isClickable: {
			false: { container: "bg-neutral-500 cursor-not-allowed" },
			true: { container: "bg-gradient-to-br from-blue-600 to-blue-500" },
		},
		isExpanded: {
			false: {
				container: "rounded-2xl",
				label: "bg-neutral-50",
			},
			true: {
				container: "bg-neutral-50 rounded-t-2xl",
				label: "bg-gradient-coin",
			},
		},
	},
});

export const FormStep = ({
	children,
	currentStep,
	onExpand,
	step,
	title,
}: {
	step: number;
	title: string;
	currentStep: number;
	children: ReactNode;
	onExpand: (num: number) => void;
}) => {
	const isClickable = currentStep > step;

	const ref = useRef<any>(null);

	const isExpanded = useMemo(() => {
		return currentStep === step;
	}, [currentStep, step]);

	useEffect(() => {
		if (isExpanded) {
			ref?.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [isExpanded]);

	const { container, label } = useMemo(
		() => formStepStyles({ isClickable, isExpanded }),
		[isExpanded, isClickable],
	);

	return (
		<Container stack="column" className="gap-4">
			<Box className={container()} onClick={() => onExpand(step)}>
				<Box className={label()}>
					<Typography
						color={isExpanded ? "light" : "blue"}
						size="base"
						weight="semibold"
					>
						{step}
					</Typography>
				</Box>
				<Typography
					color={isExpanded ? "black" : "light"}
					size="lg"
					weight="semibold"
				>
					{title}
				</Typography>
			</Box>
			{isExpanded && <div ref={ref}>{children}</div>}
		</Container>
	);
};
