import styled from "styled-components";
import {
	background,
	border,
	color,
	layout,
	position,
	space,
} from "styled-system";
import type { BoxProps } from "./types";

const Box = styled.div<BoxProps>`
  ${background}
  ${border}
  ${layout}
  ${position}
  ${space}
  ${color}
`;

export default Box;
