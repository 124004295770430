import Svg from "../Svg";
import type { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
	return (
		<Svg viewBox="0 0 16 15" {...props}>
			<path
				d="M0 12.5C0 11.9469 0.447812 11.5 1 11.5H2.70813C3.09375 10.6156 3.975 10 5 10C6.025 10 6.87813 10.6156 7.29063 11.5H15C15.5531 11.5 16 11.9469 16 12.5C16 13.0531 15.5531 13.5 15 13.5H7.29063C6.87813 14.3844 6.025 15 5 15C3.975 15 3.09375 14.3844 2.70813 13.5H1C0.447812 13.5 0 13.0531 0 12.5ZM6 12.5C6 11.9469 5.55312 11.5 5 11.5C4.44688 11.5 4 11.9469 4 12.5C4 13.0531 4.44688 13.5 5 13.5C5.55312 13.5 6 13.0531 6 12.5ZM11 5C12.025 5 12.8781 5.61562 13.2906 6.5H15C15.5531 6.5 16 6.94687 16 7.5C16 8.05312 15.5531 8.5 15 8.5H13.2906C12.8781 9.38437 12.025 10 11 10C9.975 10 9.09375 9.38437 8.70938 8.5H1C0.447812 8.5 0 8.05312 0 7.5C0 6.94687 0.447812 6.5 1 6.5H8.70938C9.09375 5.61562 9.975 5 11 5ZM12 7.5C12 6.94687 11.5531 6.5 11 6.5C10.4469 6.5 10 6.94687 10 7.5C10 8.05312 10.4469 8.5 11 8.5C11.5531 8.5 12 8.05312 12 7.5ZM15 1.5C15.5531 1.5 16 1.94781 16 2.5C16 3.05312 15.5531 3.5 15 3.5H8.29062C7.87812 4.38437 7.025 5 6 5C4.975 5 4.09375 4.38437 3.70937 3.5H1C0.447812 3.5 0 3.05312 0 2.5C0 1.94781 0.447812 1.5 1 1.5H3.70937C4.09375 0.617187 4.975 0 6 0C7.025 0 7.87812 0.617187 8.29062 1.5H15ZM5 2.5C5 3.05312 5.44688 3.5 6 3.5C6.55312 3.5 7 3.05312 7 2.5C7 1.94781 6.55312 1.5 6 1.5C5.44688 1.5 5 1.94781 5 2.5Z"
				fill="#D6DADA"
			/>
		</Svg>
	);
};

export default Icon;
