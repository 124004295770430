import type { HTMLProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { Checkbox } from "../Checkbox";
import { Typography } from "../Typography";

const radioTileStyles = tv({
	slots: {
		textContainer: "flex flex-col text-start",
		wrapper:
			"w-full items-center flex gap-4 h-[52px] pl-4 pr-2 pb-2 pt-2 text-light box-border border-solid border border-neutral-700",
	},
});

export interface RadioTileProps
	extends VariantProps<typeof radioTileStyles>,
		HTMLProps<HTMLDivElement> {
	description: string;
}

export const RadioTile = ({
	checked,
	children,
	className,
	description,
	label,
	value,
	...rest
}: RadioTileProps) => {
	const { textContainer, wrapper } = radioTileStyles();
	return (
		<div className={`${wrapper({ class: className })}`} {...rest}>
			<Checkbox value={value} checked={checked} />
			<div className={textContainer()}>
				<Typography weight="semibold" size="sm">
					{label}
				</Typography>
				<Typography color="neutral" size="xs">
					{description}
				</Typography>
			</div>
		</div>
	);
};
