import { Svg, type SvgProps } from "./Svg";

export const SortIcon = ({
	fill = "#EEF2F2",
	height = "13",
	viewBox = "0 0 16 13",
	width = "16",
	xmlns = "http://www.w3.org/2000/svg",
	...rest
}: SvgProps) => {
	return (
		<Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
			<path
				d="M11.4929 7.21133H8.81402C8.31627 7.21133 7.91261 7.615 7.91261 8.11275C7.91261 8.6105 8.31627 9.01416 8.81402 9.01416H11.4929C11.9907 9.01416 12.3943 8.6105 12.3943 8.11275C12.3943 7.615 11.9915 7.21133 11.4929 7.21133ZM15.0986 0H8.81402C8.31627 0 7.91261 0.403665 7.91261 0.901416C7.91261 1.39917 8.31627 1.80283 8.81402 1.80283H15.0986C15.5963 1.80283 16 1.39917 16 0.901416C16 0.403665 15.5972 0 15.0986 0ZM9.69009 10.817H8.78867C8.29092 10.817 7.88725 11.2207 7.88725 11.7184C7.88725 12.2162 8.29092 12.6198 8.78867 12.6198H9.69009C10.1878 12.6198 10.5662 12.2162 10.5662 11.7184C10.5662 11.2207 10.1887 10.817 9.69009 10.817ZM13.2958 3.60566H8.81402C8.31627 3.60566 7.91261 4.00933 7.91261 4.50708C7.91261 5.00483 8.31627 5.4085 8.81402 5.4085H13.2958C13.7935 5.4085 14.1972 5.00483 14.1972 4.50708C14.1972 4.00933 13.7943 3.60566 13.2958 3.60566ZM5.19427 8.41416L4.28159 9.41135V0.902261C4.28159 0.403665 3.87877 0 3.38017 0C2.88158 0 2.47876 0.403665 2.47876 0.902261V9.40938L1.56607 8.41416C1.38827 8.22027 1.14522 8.12176 0.900997 8.12176C0.683559 8.12176 0.465219 8.2001 0.29226 8.35878C-0.0747857 8.6954 -0.0992929 9.26639 0.236794 9.634L2.69034 12.3408C3.03175 12.7143 3.67795 12.7143 4.01964 12.3408L6.47319 9.634C6.80953 9.26639 6.78474 8.69568 6.41772 8.35878C6.10132 8.0226 5.5323 8.04796 5.19427 8.41416Z"
				fill={fill}
			/>
		</Svg>
	);
};
