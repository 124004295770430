import { Container } from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import React, { type ReactNode } from "react";
import { TradesThreadsTabs } from "./components";

const TradesThreads = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) => {
	return (
		<Container
			stack="column"
			className={cn("relative gap-4 overflow-hidden", className)}
		>
			<TradesThreadsTabs />
			{children}
		</Container>
	);
};

export default TradesThreads;
