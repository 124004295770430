import type { HTMLProps } from "react";
import { type VariantProps, tv } from "tailwind-variants";

const badgeStyles = tv({
	slots: {
		container: "rounded-[5px] py-1 px-2 w-fit",
		label: "text-[14px] leading-5 font-normal",
	},
	variants: {
		color: {
			blue: { container: "bg-blue-500", label: "text-light" },
			green: { container: "bg-green", label: "text-neutral-900" },
			neutral: { container: "bg-neutral-500", label: "text-neutral-900" },
			turquoise: { container: "bg-turquoise-500", label: "text-neutral-900" },
		},
	},
});

export interface BadgeProps
	extends VariantProps<typeof badgeStyles>,
		Omit<HTMLProps<HTMLDivElement>, "color"> {}
export const Badge = ({
	className,
	color,
	label: text,
	...rest
}: BadgeProps) => {
	const { container, label } = badgeStyles({ class: className, color });
	return (
		<div className={container()} {...rest}>
			<p className={label()}>{text}</p>
		</div>
	);
};
