import useActiveWagmi from "@/hooks/useActiveWagmi";
import { fetchStatusMiddleware } from "@/hooks/useSWRContract";
import { persistor, useStore } from "@/state";
import { usePollBlockNumber } from "@/state/block/hooks";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalStyle from "@styles/Global";
import darkThemeOptions from "@styles/theme/darkThemeOptions";
import { MatchBreakpointsProvider, dark } from "@vapordex/uikit";
import { FeatureProvider as VaporFeatureProvider } from "@vaporfi/feature-flags";
import { ModalProvider } from "@vaporfi/uikit";
import { ToastsProvider } from "contexts/ToastsContext";
import type { FC, PropsWithChildren } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider as StyledProvider } from "styled-components";
import { SWRConfig } from "swr";
import { client } from "utils/wagmi";
import { WagmiConfig } from "wagmi";
import Blocklist from "./Blocklist";
import RouterProvider from "./RouterProvider";
import Updaters from "./Updaters";

const darkTheme = createTheme(darkThemeOptions);

const FeatureProvider = ({ children }) => {
	const { chainId } = useActiveWagmi();

	return (
		<VaporFeatureProvider chainId={chainId}>{children}</VaporFeatureProvider>
	);
};

function GlobalHooks() {
	usePollBlockNumber();
	// useThemeCookie()
	return null;
}

const RootProvider: FC<PropsWithChildren> = ({ children, ...pageProps }) => {
	// @ts-expect-error: TODO
	const store = useStore(pageProps.initialReduxState);

	return (
		<RouterProvider>
			<ThemeProvider theme={darkTheme}>
				<StyledProvider theme={dark}>
					<GlobalStyle />
					<WagmiConfig config={client}>
						<Blocklist>
							<MatchBreakpointsProvider>
								<ReduxProvider store={store}>
									<PersistGate loading={null} persistor={persistor}>
										<ToastsProvider>
											<GlobalHooks />
											<Updaters />
											<SWRConfig
												value={{
													use: [fetchStatusMiddleware],
												}}
											>
												<FeatureProvider>
													<ModalProvider>{children}</ModalProvider>
												</FeatureProvider>
											</SWRConfig>
										</ToastsProvider>
									</PersistGate>
								</ReduxProvider>
							</MatchBreakpointsProvider>
						</Blocklist>
					</WagmiConfig>
				</StyledProvider>
			</ThemeProvider>
		</RouterProvider>
	);
};

export default RootProvider;
