"use client";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormFieldContext, FormItemContext } from "./Context";

export const useFormField = () => {
	const fieldContext = useContext(FormFieldContext);
	const itemContext = useContext(FormItemContext);
	const { formState, getFieldState } = useFormContext();

	if (!fieldContext) {
		throw new Error("useFormField should be used within <FormField>");
	}
	const fieldState = getFieldState(fieldContext.name, formState);

	const { id } = itemContext;

	return {
		formDescriptionId: `${id}-form-item-description`,
		formItemId: `${id}-form-item`,
		formMessageId: `${id}-form-item-message`,
		id,
		name: fieldContext.name,
		...fieldState,
	};
};
