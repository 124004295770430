export const AvalancheTokens = [
	{
		address: "0x800BdCE6CaA3fE2bfDB738383321278536e258f8",
		chainId: 43_114,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x800BdCE6CaA3fE2bfDB738383321278536e258f8/logo.png",
		name: "Wrapped Thought",
		symbol: "wTHT",
	},
	{
		address: "0x7a842a6F4580EDD3df41C1F31E0395044De6Bc75",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7a842a6F4580EDD3df41C1F31E0395044De6Bc75/logo.png",
		name: "AVAX GO HIGHER",
		symbol: "HIGHER",
	},
	{
		address: "0xC139Aa91399600f6b72975AC3317b6d49Cb30a69",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC139Aa91399600f6b72975AC3317b6d49Cb30a69/logo.png",
		name: "Avax Meme Index",
		symbol: "AMI",
	},
	{
		address: "0xc8E7fB72B53D08C4f95b93b390ed3f132d03f2D5",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xc8E7fB72B53D08C4f95b93b390ed3f132d03f2D5/logo.png",
		name: "SQRCAT",
		symbol: "SQRCAT",
	},
	{
		address: "0xfe07cab00DFB08C348De861FDDCBBdB9E9e49702",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfe07cab00DFB08C348De861FDDCBBdB9E9e49702/logo.png",
		name: "DOGWIFHAT",
		symbol: "$WIF",
	},
	{
		address: "0xeD0d09ee0F32f7b5AFaE6f2d728189C5E355b52a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xeD0d09ee0F32f7b5AFaE6f2d728189C5E355b52a/logo.png",
		name: "LIZARD",
		symbol: "LIZARD",
	},
	{
		address: "0x0D8938E105AF7786c17F162cD36db2d953fa0a2f",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0D8938E105AF7786c17F162cD36db2d953fa0a2f/logo.png",
		name: "SUS",
		symbol: "SUS",
	},
	{
		address: "0x19c79F282d151995D91f6dbdda2739701F9C47AA",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x19c79F282d151995D91f6dbdda2739701F9C47AA/logo.png",
		name: "BEAR",
		symbol: "BEAR",
	},
	{
		address: "0x369814a8688998789c64ef84972c2312679ef5c7",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x369814a8688998789c64ef84972c2312679ef5c7/logo.png",
		name: "OCCUPIE",
		symbol: "PIE",
	},
	{
		address: "0x982C2715CA041F2f1B85b5C81Fc8981ba48Fe62C",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x982C2715CA041F2f1B85b5C81Fc8981ba48Fe62C/logo.png",
		name: "Snow Inu",
		symbol: "SNOW",
	},
	{
		address: "0x570c091ba99668d873f99e0f8a49d8291d7a107f",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x570c091ba99668d873f99e0f8a49d8291d7a107f/logo.png",
		name: "VAGENE",
		symbol: "VAG",
	},
	{
		address: "0xf1d76e562C726ed6EE269BeedCA5E67d45e05A37",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf1d76e562C726ed6EE269BeedCA5E67d45e05A37/logo.png",
		name: "Rizz Coin",
		symbol: "RIZZ",
	},
	{
		address: "0x2dA8312e2C08B79104c6B18bA26Bc7065ABEC704",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x2dA8312e2C08B79104c6B18bA26Bc7065ABEC704/logo.jpg",
		name: "Bawls Onu",
		symbol: "BAWLS",
	},
	{
		address: "0x799a0f809BA7FBa8CA2f0563Fe2659973714beB9",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x799a0f809BA7FBa8CA2f0563Fe2659973714beB9/logo.png",
		name: "SNOWLORD",
		symbol: "LORD",
	},
	{
		address: "0xA4F282457980856c36380174283d2CB278eA2B37",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xA4F282457980856c36380174283d2CB278eA2B37/logo.png",
		name: "RAIN",
		symbol: "RAIN",
	},
	{
		address: "0xa77e70d0Af1Ac7fF86726740dB1Bd065c3566937",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xa77e70d0Af1Ac7fF86726740dB1Bd065c3566937/logo.png",
		name: "w3ULL",
		symbol: "w3ULL",
	},
	{
		address: "0x420fca0121dc28039145009570975747295f2329",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x420FcA0121DC28039145009570975747295f2329/logo_24.png",
		name: "COQ Inu",
		symbol: "COQ",
	},
	{
		address: "0xd402298a793948698b9a63311404fbbee944eafd",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd402298a793948698b9a63311404fbbee944eafd/logo_24.png",
		name: "Shrapnel",
		symbol: "SHRAP",
	},
	{
		address: "0x7D57f563db93F257BD556D86e6FEe7079c80226e",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7D57f563db93F257BD556D86e6FEe7079c80226e/logo.png",
		name: "Shirak",
		symbol: "SHK",
	},
	{
		address: "0x03E8D118A1864c7Dc53bf91e007ab7D91f5A06fA",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x03E8D118A1864c7Dc53bf91e007ab7D91f5A06fA/logo_24.png",
		name: "DEXTF Token",
		symbol: "DEXTF",
	},
	{
		address: "0xF36de0989d83FDc554FC7b93E5D10722677CCFBC",
		chainId: 43_114,

		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xF36de0989d83FDc554FC7b93E5D10722677CCFBC/logo_24.png",
		name: "Asgard",
		symbol: "ASG",
	},
	{
		address: "0x88F89BE3E9b1dc1C5F208696fb9cABfcc684bD5F",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x88F89BE3E9b1dc1C5F208696fb9cABfcc684bD5F/logo_24.png",
		name: "Fold",
		symbol: "FLD",
	},
	{
		address: "0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD/logo.png",
		name: "Euro Coin",
		symbol: "EUROC",
	},
	{
		address: "0x502580fc390606b47FC3b741d6D49909383c28a9",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x502580fc390606b47FC3b741d6D49909383c28a9/logo.png",
		name: "Hatchy",
		symbol: "HATCHY",
	},
	{
		address: "0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55/logo.png",
		name: "VapeToken",
		symbol: "VAPE",
	},
	{
		address: "0x83a283641C6B4DF383BCDDf807193284C84c5342",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x83a283641C6B4DF383BCDDf807193284C84c5342/logo.png",
		name: "VaporNodes",
		symbol: "VPND",
	},
	{
		address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo_24.png",
		name: "Wrapped AVAX",
		symbol: "WAVAX",
	},
	{
		address: "0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE/logo_24.png",
		name: "Avaware",
		symbol: "AVE",
	},
	{
		address: "0xdb333724fAE72b4253FC3d44c8270CBBC86d147b",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xdb333724fAE72b4253FC3d44c8270CBBC86d147b/logo_24.png",
		name: "Cabbage.Cash",
		symbol: "CABAG",
	},
	{
		address: "0x488F73cddDA1DE3664775fFd91623637383D6404",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x488F73cddDA1DE3664775fFd91623637383D6404/logo_24.png",
		name: "YetiSwap",
		symbol: "YTS",
	},
	{
		address: "0xC38f41A296A4493Ff429F1238e030924A1542e50",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC38f41A296A4493Ff429F1238e030924A1542e50/logo_24.png",
		name: "Snowball",
		symbol: "SNOB",
	},
	{
		address: "0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985",
		chainId: 43_114,
		decimals: 9,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985/logo_24.png",
		name: "Spore",
		symbol: "SPORE",
	},

	{
		address: "0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c/logo_24.png",
		name: "PenguinToken",
		symbol: "PEFI",
	},
	{
		address: "0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50",
		chainId: 43_114,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50/logo_24.png",
		name: "Blocknet",
		symbol: "aaBLOCK",
	},
	{
		address: "0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084/logo_24.png",
		name: "LydiaFinance Token",
		symbol: "LYD",
	},
	{
		address: "0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a/logo_24.png",
		name: "VersoToken",
		symbol: "VSO",
	},
	{
		address: "0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed/logo_24.png",
		name: "AVME",
		symbol: "AVME",
	},
	{
		address: "0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654/logo_24.png",
		name: "Husky",
		symbol: "HUSKY",
	},
	{
		address: "0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142/logo_24.png",
		name: "Gondola",
		symbol: "GDL",
	},
	{
		address: "0x81440C939f2C1E34fc7048E518a637205A632a74",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x81440C939f2C1E34fc7048E518a637205A632a74/logo_24.png",
		name: "Cycle Token",
		symbol: "CYCLE",
	},
	{
		address: "0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4/logo_24.png",
		name: "Avalaunch",
		symbol: "XAVA",
	},
	{
		address: "0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf/logo_24.png",
		name: "Canary",
		symbol: "CNR",
	},
	{
		address: "0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6/logo_24.png",
		name: "Sherpa",
		symbol: "SHERPA",
	},
	{
		address: "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17/logo_24.png",
		name: "DeFiYieldProtocol",
		symbol: "DYP",
	},
	{
		address: "0x264c1383EA520f73dd837F915ef3a732e204a493",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x264c1383EA520f73dd837F915ef3a732e204a493/logo_24.png",
		name: "Binance",
		symbol: "BNB",
	},
	{
		address: "0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D/logo_24.png",
		name: "Weble Ecosystem Token",
		symbol: "WET",
	},
	{
		address: "0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7/logo_24.png",
		name: "Yak Token",
		symbol: "YAK",
	},
	{
		address: "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5/logo_24.png",
		name: "BENQI",
		symbol: "QI",
	},
	{
		address: "0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd/logo_24.png",
		name: "Wrapped AllianceBlock Token",
		symbol: "WALBT",
	},
	{
		address: "0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1/logo_24.png",
		name: "TundraToken",
		symbol: "TUNDRA",
	},
	{
		address: "0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8/logo_24.png",
		name: "Gaj Finance",
		symbol: "GAJ",
	},
	{
		address: "0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC/logo_24.png",
		name: "TEDDY",
		symbol: "TEDDY",
	},
	{
		address: "0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd/logo_24.png",
		name: "JoeToken",
		symbol: "JOE",
	},
	{
		address: "0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0/logo_24.png",
		name: "MarginSwap",
		symbol: "MFI",
	},
	{
		address: "0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1/logo_24.png",
		name: "SHIBAVAX",
		symbol: "SHIBX",
	},
	{
		address: "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454/logo_24.png",
		name: "Frax Share",
		symbol: "FXS",
	},
	{
		address: "0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31/logo_24.png",
		name: "Blue Token",
		symbol: "BLUE",
	},
	{
		address: "0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa/logo_24.png",
		name: "YAY Games",
		symbol: "YAY",
	},
	{
		address: "0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1/logo_24.png",
		name: "Avaxtars Token",
		symbol: "AVXT",
	},
	{
		address: "0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347",
		chainId: 43_114,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347/logo_24.png",
		name: "TOK",
		symbol: "TOK",
	},
	{
		address: "0xb54f16fB19478766A268F172C9480f8da1a7c9C3",
		chainId: 43_114,
		decimals: 9,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb54f16fB19478766A268F172C9480f8da1a7c9C3/logo_24.png",
		name: "Time",
		symbol: "TIME",
	},
	{
		address: "0x90842eb834cFD2A1DB0b1512B254a18E4D396215",
		chainId: 43_114,
		decimals: 9,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x90842eb834cFD2A1DB0b1512B254a18E4D396215/logo_24.png",
		name: "Good Bridging",
		symbol: "GB",
	},
	{
		address: "0x0ebd9537A25f56713E34c45b38F421A1e7191469",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0ebd9537A25f56713E34c45b38F421A1e7191469/logo_24.png",
		name: "OpenOcean",
		symbol: "OOE",
	},
	{
		address: "0x3709E8615E02C15B096f8a9B460ccb8cA8194e86",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x3709E8615E02C15B096f8a9B460ccb8cA8194e86/logo_24.png",
		name: "Vee",
		symbol: "VEE",
	},
	{
		address: "0x938FE3788222A74924E062120E7BFac829c719Fb",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x938FE3788222A74924E062120E7BFac829c719Fb/logo_24.png",
		name: "Ape In",
		symbol: "APEIN",
	},
	{
		address: "0xfC108f21931576a21D0b4b301935DAc80d9E5086",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfC108f21931576a21D0b4b301935DAc80d9E5086/logo_24.png",
		name: "Iron Finance ICE Token",
		symbol: "IronICE",
	},
	{
		address: "0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b/logo_24.png",
		name: "WOWSwap",
		symbol: "WOW",
	},
	{
		address: "0xCE1bFFBD5374Dac86a2893119683F4911a2F7814",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xCE1bFFBD5374Dac86a2893119683F4911a2F7814/logo_24.png",
		name: "Spell Token",
		symbol: "SPELL",
	},
	{
		address: "0xb27c8941a7Df8958A1778c0259f76D1F8B711C35",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb27c8941a7Df8958A1778c0259f76D1F8B711C35/logo_24.png",
		name: "Kalao Token",
		symbol: "KLO",
	},
	{
		address: "0x027dbcA046ca156De9622cD1e2D907d375e53aa7",
		chainId: 43_114,
		decimals: 9,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x027dbcA046ca156De9622cD1e2D907d375e53aa7/logo_24.png",
		name: "Ampleforth",
		symbol: "AMPL",
	},
	{
		address: "0x8B1d98A91F853218ddbb066F20b8c63E782e2430",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x8B1d98A91F853218ddbb066F20b8c63E782e2430/logo_24.png",
		name: "OrcaDAO",
		symbol: "ORCA",
	},
	{
		address: "0x544c42fBB96B39B21DF61cf322b5EDC285EE7429",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x544c42fBB96B39B21DF61cf322b5EDC285EE7429/logo_24.png",
		name: "INSUR Token",
		symbol: "INSUR",
	},
	{
		address: "0x6C6f910A79639dcC94b4feEF59Ff507c2E843929",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6C6f910A79639dcC94b4feEF59Ff507c2E843929/logo_24.png",
		name: "Ankr Avalanche Reward Earning Bond",
		symbol: "aAVAXb",
	},
	{
		address: "0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0/logo_24.png",
		name: "Boo Finance Token",
		symbol: "BOOFI",
	},
	{
		address: "0x45C13620B55C35A5f539d26E88247011Eb10fDbd",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x45C13620B55C35A5f539d26E88247011Eb10fDbd/logo_24.png",
		name: "Hurricane Token",
		symbol: "HCT",
	},
	{
		address: "0x214DB107654fF987AD859F34125307783fC8e387",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x214DB107654fF987AD859F34125307783fC8e387/logo_24.png",
		name: "Frax Share",
		symbol: "FXS",
	},
	{
		address: "0xb2a85C5ECea99187A977aC34303b80AcbDdFa208",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb2a85C5ECea99187A977aC34303b80AcbDdFa208/logo_24.png",
		name: "ROCO",
		symbol: "ROCO",
	},
	{
		address: "0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef/logo_24.png",
		name: "IMX",
		symbol: "IMX.a",
	},
	{
		address: "0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A/logo_24.png",
		name: "Orbs",
		symbol: "ORBS",
	},
	{
		address: "0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed/logo_24.png",
		name: "CRA",
		symbol: "CRA",
	},
	{
		address: "0x885d748C00A279B67A7749EC6b03301700dd0455",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x885d748C00A279B67A7749EC6b03301700dd0455/logo_24.png",
		name: "Maximus",
		symbol: "MAXI",
	},
	{
		address: "0x7C08413cbf02202a1c13643dB173f2694e0F73f0",
		chainId: 43_114,
		decimals: 9,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7C08413cbf02202a1c13643dB173f2694e0F73f0/logo_24.png",
		name: "Maximizer",
		symbol: "MAXI",
	},
	{
		address: "0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6/logo_24.png",
		name: "Jewels",
		symbol: "JEWEL",
	},
	{
		address: "0x8aE8be25C23833e0A01Aa200403e826F611f9CD2",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x8aE8be25C23833e0A01Aa200403e826F611f9CD2/logo_24.png",
		name: "CRAFT",
		symbol: "CRAFT",
	},
	{
		address: "0x321E7092a180BB43555132ec53AaA65a5bF84251",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x321E7092a180BB43555132ec53AaA65a5bF84251/logo_24.png",
		name: "Governance OHM",
		symbol: "gOHM",
	},
	{
		address: "0x637afeff75ca669fF92e4570B14D6399A658902f",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x637afeff75ca669fF92e4570B14D6399A658902f/logo_24.png",
		name: "Poly-Peg COOK",
		symbol: "COOK",
	},
	{
		address: "0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF/logo_24.png",
		name: "NFTrade Token",
		symbol: "NFTD",
	},
	{
		address: "0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6/logo_24.png",
		name: "Colony Token",
		symbol: "CLY",
	},
	{
		address: "0xf69c2fcd9128d49DfA22348C69177f9380438eB8",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf69c2fcd9128d49DfA22348C69177f9380438eB8/logo_24.png",
		name: "NFTSoccerGames",
		symbol: "NFSG",
	},
	{
		address: "0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb/logo_24.png",
		name: "Skill Token",
		symbol: "SKILL",
	},
	{
		address: "0xBD100d061E120b2c67A24453CF6368E63f1Be056",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xBD100d061E120b2c67A24453CF6368E63f1Be056/logo_24.png",
		name: "iDeFiYieldProtocol",
		symbol: "iDYP",
	},
	{
		address: "0x22d4002028f537599bE9f666d1c4Fa138522f9c8",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x22d4002028f537599bE9f666d1c4Fa138522f9c8/logo_24.png",
		name: "Platypus",
		symbol: "PTP",
	},
	{
		address: "0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED/logo_24.png",
		name: "BiAltin",
		symbol: "GLDB",
	},
	{
		address: "0x7086e045b78E1e72F741F25231c08d238812CF8a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7086e045b78E1e72F741F25231c08d238812CF8a/logo_24.png",
		name: "RaceX",
		symbol: "RACEX",
	},
	{
		address: "0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1/logo_24.png",
		name: "Tomb",
		symbol: "TOMB",
	},
	{
		address: "0xbca7f1998Dc9FFB70b086543a808960a460aBcA7",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xbca7f1998Dc9FFB70b086543a808960a460aBcA7/logo_24.png",
		name: "Kitty",
		symbol: "KITTY",
	},
	{
		address: "0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09/logo_24.png",
		name: "MilkyWay Token by SpaceSwap v2",
		symbol: "MILK2",
	},
	{
		address: "0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2/logo_24.png",
		name: "SHAKE token by SpaceSwap v2",
		symbol: "SHAKE",
	},
	{
		address: "0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D/logo_24.png",
		name: "Defrost Finance Token",
		symbol: "MELT",
	},
	{
		address: "0xe0Ce60AF0850bF54072635e66E79Df17082A1109",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xe0Ce60AF0850bF54072635e66E79Df17082A1109/logo_24.png",
		name: "IceToken",
		symbol: "ICE",
	},
	{
		address: "0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172/logo_24.png",
		name: "Treasure Under Sea",
		symbol: "TUS",
	},
	{
		address: "0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6/logo_24.png",
		name: "Imperium Empires Token",
		symbol: "IME",
	},
	{
		address: "0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C/logo_24.png",
		name: "HERMES",
		symbol: "HERMES",
	},
	{
		address: "0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2/logo_24.png",
		name: "HERMES Shares",
		symbol: "HSHARES",
	},
	{
		address: "0x1DB749847C4abB991d8B6032102383e6BfD9B1c7",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1DB749847C4abB991d8B6032102383e6BfD9B1c7/logo_24.png",
		name: "Dogeon Token",
		symbol: "DON",
	},
	{
		address: "0x3EeFb18003D033661f84e48360eBeCD181A84709",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x3EeFb18003D033661f84e48360eBeCD181A84709/logo_24.png",
		name: "Islander",
		symbol: "ISA",
	},
	{
		address: "0x7f041ce89A2079873693207653b24C15B5e6A293",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7f041ce89A2079873693207653b24C15B5e6A293/logo_24.png",
		name: "LOOT",
		symbol: "LOOT",
	},
	{
		address: "0xE6B9d092223f39013656702A40dbE6B7DeCc5746",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xE6B9d092223f39013656702A40dbE6B7DeCc5746/logo_24.png",
		name: "ANGLE",
		symbol: "ANGLE",
	},
	{
		address: "0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A/logo_24.png",
		name: "MetaBrands",
		symbol: "MAGE",
	},
	{
		address: "0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269/logo_24.png",
		name: "Hertz Token",
		symbol: "HTZ",
	},
	{
		address: "0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE/logo_24.png",
		name: "Hoopoe Ventures",
		symbol: "HOOP",
	},
	{
		address: "0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c/logo_24.png",
		name: "HeroesChained",
		symbol: "HeC",
	},
	{
		address: "0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf/logo_24.png",
		name: "Pollen",
		symbol: "PLN",
	},
	{
		address: "0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611/logo_24.png",
		name: "chikn egg",
		symbol: "EGG",
	},
	{
		address: "0xab592d197ACc575D16C3346f4EB70C703F308D1E",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xab592d197ACc575D16C3346f4EB70C703F308D1E/logo_24.png",
		name: "chikn feed",
		symbol: "FEED",
	},
	{
		address: "0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/logo_24.png",
		name: "Dragon Crypto Aurum",
		symbol: "DCAU",
	},
	{
		address: "0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb/logo_24.png",
		name: "Axelar Wrapped LUNA",
		symbol: "LUNA",
	},
	{
		address: "0xf32398dae246C5f672B52A54e9B413dFFcAe1A44",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf32398dae246C5f672B52A54e9B413dFFcAe1A44/logo_24.png",
		name: "Kassandra",
		symbol: "KACY",
	},
	{
		address: "0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d/logo_24.png",
		name: "Catoshi",
		symbol: "CATS",
	},
	{
		address: "0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12/logo_24.png",
		name: "ArgonToken",
		symbol: "ARGON",
	},
	{
		address: "0x7F29e7086453ed97283893E763aE0989F4B70f8d",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7F29e7086453ed97283893E763aE0989F4B70f8d/logo_24.png",
		name: "DGC Token",
		symbol: "DGC",
	},
	{
		address: "0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f/logo_24.png",
		name: "ENXT Token",
		symbol: "ENXT",
	},
	{
		address: "0xE06fba763C2104dB5027F57f6A5Be0a0D86308af",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xE06fba763C2104dB5027F57f6A5Be0a0D86308af/logo_24.png",
		name: "Akitavax",
		symbol: "AKITAX",
	},
	{
		address: "0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356/logo_24.png",
		name: "YDragon",
		symbol: "YDR",
	},
	{
		address: "0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB/logo_24.png",
		name: "HonToken",
		symbol: "HON",
	},
	{
		address: "0xd9D90f882CDdD6063959A9d837B05Cb748718A05",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd9D90f882CDdD6063959A9d837B05Cb748718A05/logo_24.png",
		name: "More Token",
		symbol: "MORE",
	},
	{
		address: "0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2",
		chainId: 43_114,
		decimals: 12,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2/logo_24.png",
		name: "SwapXI Token",
		symbol: "SWAPXI",
	},
	{
		address: "0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5/logo_24.png",
		name: "FIRE",
		symbol: "FIRE",
	},
	{
		address: "0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136/logo_24.png",
		name: "BRIBE",
		symbol: "BRIBE",
	},
	{
		address: "0xe19A1684873faB5Fb694CfD06607100A632fF21c",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xe19A1684873faB5Fb694CfD06607100A632fF21c/logo_24.png",
		name: "BavaToken",
		symbol: "BAVA",
	},
	{
		address: "0x63a72806098Bd3D9520cC43356dD78afe5D386D9",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x63a72806098Bd3D9520cC43356dD78afe5D386D9/logo_24.png",
		name: "Aave Token",
		symbol: "AAVE.e",
	},
	{
		address: "0x5947BB275c521040051D82396192181b413227A3",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x5947BB275c521040051D82396192181b413227A3/logo_24.png",
		name: "Chainlink Token",
		symbol: "LINK.e",
	},
	{
		address: "0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76/logo_24.png",
		name: "SushiToken",
		symbol: "SUSHI.e",
	},
	{
		address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
		chainId: 43_114,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x50b7545627a5162F82A992c33b87aDc75187B218/logo_24.png",
		name: "Wrapped BTC",
		symbol: "WBTC.e",
	},
	{
		address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB/logo_24.png",
		name: "Wrapped Ether",
		symbol: "WETH.e",
	},
	{
		address: "0x00EE200Df31b869a321B10400Da10b561F3ee60d",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x00EE200Df31b869a321B10400Da10b561F3ee60d/logo_24.png",
		name: "Arable Protocol",
		symbol: "ACRE",
	},
	{
		address: "0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE/logo_24.png",
		name: "Staked AVAX",
		symbol: "sAVAX",
	},
	{
		address: "0x0659133127749Cc0616Ed6632912ddF7cc8D7545",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0659133127749Cc0616Ed6632912ddF7cc8D7545/logo_24.png",
		name: "DefiLaunch Token",
		symbol: "DLAUNCH",
	},
	{
		address: "0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3/logo_24.png",
		name: "Augmented Finance",
		symbol: "AGF",
	},
	{
		address: "0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E/logo_24.png",
		name: "OddzToken",
		symbol: "ODDZ",
	},
	{
		address: "0xf3EC49ACb3084618121741e4BBB20996D383E9b2",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xf3EC49ACb3084618121741e4BBB20996D383E9b2/logo_24.png",
		name: "KING",
		symbol: "KING",
	},
	{
		address: "0x94d649E017625dE1Aa04733aA046dE0161DF18eF",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x94d649E017625dE1Aa04733aA046dE0161DF18eF/logo_24.png",
		name: "King Shares",
		symbol: "KSHARE",
	},
	{
		address: "0x4960bc6032351ab87627B8F36b37B4938A44C7eF",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4960bc6032351ab87627B8F36b37B4938A44C7eF/logo_24.png",
		name: "RICH",
		symbol: "RICH",
	},
	{
		address: "0x44754455564474A89358B2C2265883DF993b12F0",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x44754455564474A89358B2C2265883DF993b12F0/logo_24.png",
		name: "ZeroSwapToken",
		symbol: "ZEE",
	},
	{
		address: "0xD4d026322C88C2d49942A75DfF920FCfbC5614C1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xD4d026322C88C2d49942A75DfF920FCfbC5614C1/logo_24.png",
		name: "DEAPCOIN",
		symbol: "DEP",
	},
	{
		address: "0x15c841043e13fFAA9a99FabEa236D40F45615623",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x15c841043e13fFAA9a99FabEa236D40F45615623/logo_24.png",
		name: "BUSINESSES",
		symbol: "BUSINESSES",
	},
	{
		address: "0x4B322765D761984C0f9E72200B62a9b445F38caC",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4B322765D761984C0f9E72200B62a9b445F38caC/logo_24.png",
		name: "dolla",
		symbol: "DOLLA",
	},
	{
		address: "0xc69Eba65e87889f0805dB717Af06797055A0BA07",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xc69Eba65e87889f0805dB717Af06797055A0BA07/logo_24.png",
		name: "NitroNetwork",
		symbol: "ncash",
	},
	{
		address: "0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47/logo_24.png",
		name: "Omlira",
		symbol: "OML",
	},
	{
		address: "0xE16253892F126D068E711C2fdde6DB56969dBCf6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4ec58f9D205F9c919920313932cc71EC68d123C7/logo_24.png",
		name: "Splash Token",
		symbol: "SPLASH",
	},
	{
		address: "0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da/logo_24.png",
		name: "Wave Token",
		symbol: "WAVE",
	},
	{
		address: "0x97E7044ab98B7891546485f5f66EDcc2F58be106",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x97E7044ab98B7891546485f5f66EDcc2F58be106/logo_24.png",
		name: "EMERALD",
		symbol: "EM",
	},
	{
		address: "0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12/logo_24.png",
		name: "Axelar Wrapped ATOM",
		symbol: "axlATOM",
	},
	{
		address: "0x449674B82F05d498E126Dd6615a1057A9c088f2C",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x449674B82F05d498E126Dd6615a1057A9c088f2C/logo_24.png",
		name: "LostToken",
		symbol: "LOST",
	},
	{
		address: "0x1B88D7aD51626044Ec62eF9803EA264DA4442F32",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1B88D7aD51626044Ec62eF9803EA264DA4442F32/logo_24.png",
		name: "ZooToken",
		symbol: "ZOO",
	},
	{
		address: "0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B/logo_24.png",
		name: "KyteOne",
		symbol: "KTE",
	},
	{
		address: "0x1111111111182587795eF1098ac7da81a108C97a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1111111111182587795eF1098ac7da81a108C97a/logo_24.png",
		name: "Bold Point Token",
		symbol: "BPT",
	},
	{
		address: "0xd13eB71515DC48a8a367D12F844e5737bab415dF",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd13eB71515DC48a8a367D12F844e5737bab415dF/logo_24.png",
		name: "Spice",
		symbol: "SFI",
	},
	{
		address: "0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8/logo_24.png",
		name: "Snail Trail",
		symbol: "SLIME",
	},
	{
		address: "0x714f020C54cc9D104B6F4f6998C63ce2a31D1888",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x714f020C54cc9D104B6F4f6998C63ce2a31D1888/logo_24.png",
		name: "STEP.APP",
		symbol: "FITFI",
	},
	{
		address: "0x4156F18bF7C1ef04248632C66Aa119De152D8f2E",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4156F18bF7C1ef04248632C66Aa119De152D8f2E/logo_24.png",
		name: "Zeus Node Finance",
		symbol: "ZEUS",
	},
	{
		address: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/logo_24.png",
		name: "Tether USD",
		symbol: "USDT.e",
	},
	{
		address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/logo_24.png",
		name: "USD Coin",
		symbol: "USDC.e",
	},
	{
		address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/logo_24.png",
		name: "Dai Stablecoin",
		symbol: "DAI.e",
	},
	{
		address: "0x564A341Df6C126f90cf3ECB92120FD7190ACb401",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x564A341Df6C126f90cf3ECB92120FD7190ACb401/logo_24.png",
		name: "BiLira",
		symbol: "TRYB",
	},
	{
		address: "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/logo_24.png",
		name: "Binance USD",
		symbol: "BUSD.e",
	},
	{
		address: "0x4fbf0429599460D327BD5F55625E30E4fC066095",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4fbf0429599460D327BD5F55625E30E4fC066095/logo_24.png",
		name: "TSD Stablecoin",
		symbol: "TSD",
	},
	{
		address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x130966628846BFd36ff31a822705796e8cb8C18D/logo_24.png",
		name: "Magic Internet Money",
		symbol: "MIM",
	},
	{
		address: "0x783C08b5F26E3daf8C4681F3bf49844e425b6393",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x783C08b5F26E3daf8C4681F3bf49844e425b6393/logo_24.png",
		name: "Avaware USD",
		symbol: "AUSD",
	},
	{
		address: "0x346A59146b9b4a77100D369a3d18E8007A9F46a6",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x346A59146b9b4a77100D369a3d18E8007A9F46a6/logo_24.png",
		name: "AVAI",
		symbol: "AVAI",
	},
	{
		address: "0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64/logo_24.png",
		name: "Frax",
		symbol: "FRAX",
	},
	{
		address: "0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b/logo_24.png",
		name: "miMatic",
		symbol: "MAI",
	},
	{
		address: "0x026187BdbC6b751003517bcb30Ac7817D5B766f8",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x026187BdbC6b751003517bcb30Ac7817D5B766f8/logo_24.png",
		name: "Defrost Finance H2O",
		symbol: "H2O",
	},
	{
		address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo_24.png",
		name: "USD Coin",
		symbol: "USDC",
	},
	{
		address: "0x6feFd97F328342a8A840546A55FDcfEe7542F9A8",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6feFd97F328342a8A840546A55FDcfEe7542F9A8/logo_24.png",
		name: "agEUR",
		symbol: "agEUR",
	},
	{
		address: "0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11/logo_24.png",
		name: "Axelar Wrapped UST",
		symbol: "axlUST",
	},
	{
		address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo_24.png",
		name: "TetherToken",
		symbol: "USDt",
	},
	{
		address: "0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948/logo_24.png",
		name: "Moremoney USD",
		symbol: "MONEY",
	},
	{
		address: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB/logo_24.png",
		name: "JPY Coin",
		symbol: "JPYC",
	},
	{
		address: "0xb599c3590F42f8F995ECfa0f85D2980B76862fc1",
		chainId: 43_114,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1/logo_24.png",
		name: "Wormhole UST",
		symbol: "UST",
	},
	{
		address: "0x61eCd63e42C27415696e10864d70ecEA4aA11289",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x61eCd63e42C27415696e10864d70ecEA4aA11289/logo_24.png",
		name: "Rugpull Prevention",
		symbol: "RUGPULL",
	},
	{
		address: "0xcF8419A615c57511807236751c0AF38Db4ba3351",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xcF8419A615c57511807236751c0AF38Db4ba3351/logo_24.png",
		name: "AxialToken",
		symbol: "AXIAL",
	},
	{
		address: "0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f",
		chainId: 43_114,
		decimals: 4,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f/logo_24.png",
		name: "BRZ",
		symbol: "BRZ",
	},
	{
		address: "0x827eb4bada6cb76c90f887969b3fe5faD585FFe3",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x827eb4bada6cb76c90f887969b3fe5faD585FFe3/logo_24.png",
		name: "XETA",
		symbol: "XETA",
	},
	{
		address: "0x6B56Ec4a92765203508FB40feC9fa23E549B705a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6B56Ec4a92765203508FB40feC9fa23E549B705a/logo_24.png",
		name: "Unbound Dollar",
		symbol: "UND",
	},
	{
		address: "0xF915fDDa4c882731C0456a4214548Cd13A822886",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xF915fDDa4c882731C0456a4214548Cd13A822886/logo_24.png",
		name: "Unbound",
		symbol: "UNB",
	},
	{
		address: "0x51e48670098173025C477D9AA3f0efF7BF9f7812",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x51e48670098173025C477D9AA3f0efF7BF9f7812/logo_24.png",
		name: "DegenX",
		symbol: "DGNX",
	},
	{
		address: "0xcf799767d366d789e8B446981C2D578E241fa25c",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xcf799767d366d789e8B446981C2D578E241fa25c/logo_24.png",
		name: "Decentralized USD",
		symbol: "USDD",
	},
	{
		address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd00ae08403B9bbb9124bB305C09058E32C39A48c/logo.png",
		name: "Wrapped AVAX",
		symbol: "WAVAX",
	},
	{
		address: "0xFc7215C9498Fc12b22Bc0ed335871Db4315f03d3",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xFc7215C9498Fc12b22Bc0ed335871Db4315f03d3/logo.png",
		name: "Dai Stablecoin",
		symbol: "DAI",
	},
	{
		address: "0x73b4C0C45bfB90FC44D9013FA213eF2C2d908D0A",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x73b4C0C45bfB90FC44D9013FA213eF2C2d908D0A/logo.png",
		name: "ChainLink Token",
		symbol: "LINK",
	},
	{
		address: "0x09C85Ef96e93f0ae892561052B48AE9DB29F2458",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x09C85Ef96e93f0ae892561052B48AE9DB29F2458/logo.png",
		name: "Wrapped BTC",
		symbol: "WBTC",
	},
	{
		address: "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png",
		name: "Elk",
		symbol: "ELK",
	},
	{
		address: "0x82FE038Ea4b50f9C957da326C412ebd73462077C",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x82FE038Ea4b50f9C957da326C412ebd73462077C/logo.png",
		name: "JoeHatToken",
		symbol: "HAT",
	},
	{
		address: "0x1f1E7c893855525b303f99bDF5c3c05Be09ca251",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x1f1E7c893855525b303f99bDF5c3c05Be09ca251/logo.png",
		name: "Synapse",
		symbol: "SYN",
	},
	{
		address: "0xd6070ae98b8069de6B494332d1A1a81B6179D960",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xd6070ae98b8069de6B494332d1A1a81B6179D960/logo.png",
		name: "Beefy Finance",
		symbol: "BIFI",
	},
	{
		address: "0xfB98B335551a418cD0737375a2ea0ded62Ea213b",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfB98B335551a418cD0737375a2ea0ded62Ea213b/logo.png",
		name: "Pendle",
		symbol: "PENDLE",
	},
	{
		address: "0x2147EFFF675e4A4eE1C2f918d181cDBd7a8E208f",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x2147EFFF675e4A4eE1C2f918d181cDBd7a8E208f/logo.png",
		name: "Alpha",
		symbol: "ALPHA.e",
	},
	{
		address: "0x62edc0692BD897D2295872a9FFCac5425011c661",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x62edc0692BD897D2295872a9FFCac5425011c661/logo.png",
		name: "GMX",
		symbol: "GMX",
	},
	{
		address: "0x250bdca7D1845cd543BB55E7D82dcA24D48E9f0F",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x250bdca7D1845cd543BB55E7D82dcA24D48E9f0F/logo.png",
		name: "Dragon Crypto Argenti",
		symbol: "DCAR",
	},
	{
		address: "0x8F47416CaE600bccF9530E9F3aeaA06bdD1Caa79",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x8F47416CaE600bccF9530E9F3aeaA06bdD1Caa79/logo.png",
		name: "THOR v2",
		symbol: "THOR",
	},
	{
		address: "0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB/logo.png",
		name: "Hon Token",
		symbol: "HON",
	},
	{
		address: "0xFc6Da929c031162841370af240dEc19099861d3B",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xFc6Da929c031162841370af240dEc19099861d3B/logo.png",
		name: "Domi",
		symbol: "DOMI",
	},
	{
		address: "0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5/logo.png",
		name: "FIRE",
		symbol: "FIRE",
	},
	{
		address: "0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4/logo.png",
		name: "Vector",
		symbol: "VTX",
	},
	{
		address: "0x060556209E507d30f2167a101bFC6D256Ed2f3e1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x060556209E507d30f2167a101bFC6D256Ed2f3e1/logo.png",
		name: "Vector PTP",
		symbol: "xPTP",
	},
	{
		address: "0x769bfeb9fAacD6Eb2746979a8dD0b7e9920aC2A4",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x769bfeb9fAacD6Eb2746979a8dD0b7e9920aC2A4/logo.png",
		name: "Vector JOE",
		symbol: "zJOE",
	},
	{
		address: "0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd/logo.png",
		name: "Echidna",
		symbol: "ECD",
	},
	{
		address: "0xb2C5172E5C15aF6aDD1ec92e518A5Ea1c7DeD2ad",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb2C5172E5C15aF6aDD1ec92e518A5Ea1c7DeD2ad/logo.png",
		name: "Echidna PTP",
		symbol: "ecdPTP",
	},
	{
		address: "0x490bf3ABcAb1fB5c88533d850F2a8d6D38298465",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x490bf3ABcAb1fB5c88533d850F2a8d6D38298465/logo.png",
		name: "Playmates",
		symbol: "PLAYMATES",
	},
	{
		address: "0x6121191018BAf067c6Dc6B18D42329447a164F05",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6121191018BAf067c6Dc6B18D42329447a164F05/logo.png",
		name: "Pizza",
		symbol: "PIZZA",
	},
	{
		address: "0xAfE3d2A31231230875DEe1fa1eEF14a412443d22",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xAfE3d2A31231230875DEe1fa1eEF14a412443d22/logo.png",
		name: "DeFiato",
		symbol: "DFIAT",
	},
	{
		address: "0x78Ea3fef1c1f07348199Bf44f45b803b9B0Dbe28",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x78Ea3fef1c1f07348199Bf44f45b803b9B0Dbe28/logo.png",
		name: "FLY",
		symbol: "FLY",
	},
	{
		address: "0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645/logo.png",
		name: "DegisToken",
		symbol: "DEG",
	},
	{
		address: "0x0802d66f029c46E042b74d543fC43B6705ccb4ba",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x0802d66f029c46E042b74d543fC43B6705ccb4ba/logo.png",
		name: "ApeCoin",
		symbol: "APE",
	},
	{
		address: "0x4Bfc90322dD638F81F034517359BD447f8E0235a",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x4Bfc90322dD638F81F034517359BD447f8E0235a/logo.png",
		name: "New Order",
		symbol: "NEWO",
	},
	{
		address: "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590/logo.png",
		name: "StargateToken",
		symbol: "STG",
	},
	{
		address: "0x9466Ab927611725B9AF76b9F31B2F879Ff14233d",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9466Ab927611725B9AF76b9F31B2F879Ff14233d/logo.png",
		name: "Ripae",
		symbol: "PAE",
	},
	{
		address: "0x6C1c0319d8dDcb0ffE1a68C5b3829Fd361587DB4",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x6C1c0319d8dDcb0ffE1a68C5b3829Fd361587DB4/logo.png",
		name: "POLAR",
		symbol: "POLAR",
	},
	{
		address: "0x111111111111ed1D73f860F57b2798b683f2d325",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x111111111111ed1D73f860F57b2798b683f2d325/logo.png",
		name: "YUSD Stablecoin",
		symbol: "YUSD",
	},
	{
		address: "0x77777777777d4554c39223C354A05825b2E8Faa3",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x77777777777d4554c39223C354A05825b2E8Faa3/logo.png",
		name: "Yeti Finance",
		symbol: "YETI",
	},
	{
		address: "0x5085434227aB73151fAd2DE546210Cbc8663dF96",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x5085434227aB73151fAd2DE546210Cbc8663dF96/logo.png",
		name: "Metaderby token",
		symbol: "DBY",
	},
	{
		address: "0xe0bb6feD446A2dbb27F84D3C27C4ED8EA7603366",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xe0bb6feD446A2dbb27F84D3C27C4ED8EA7603366/logo.png",
		name: "Metaderby game token",
		symbol: "HOOF",
	},
	{
		address: "0x9C846D808A41328A209e235B5e3c4E626DAb169E",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9C846D808A41328A209e235B5e3c4E626DAb169E/logo.png",
		name: "Chikn fert",
		symbol: "FERT",
	},
	{
		address: "0xb279f8DD152B99Ec1D84A489D32c35bC0C7F5674",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xb279f8DD152B99Ec1D84A489D32c35bC0C7F5674/logo.png",
		name: "STEAK",
		symbol: "STEAK",
	},
	{
		address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
		chainId: 43_114,
		decimals: 8,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x152b9d0FdC40C096757F570A51E494bd4b943E50/logo.png",
		name: "Bitcoin",
		symbol: "BTC.b",
	},
	{
		address: "0xcCf719c44e2C36E919335692E89d22Cf13D6aaEB",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xcCf719c44e2C36E919335692E89d22Cf13D6aaEB/logo.png",
		name: "Openblox Token",
		symbol: "OBX",
	},
	{
		address: "0xA1AfCC973d44cE1C65a21D9e644CB82489d26503",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xA1AfCC973d44cE1C65a21D9e644CB82489d26503/logo.png",
		name: "RunBlox Token",
		symbol: "RUX",
	},
	{
		address: "0x48f88A3fE843ccb0b5003e70B4192c1d7448bEf0",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x48f88A3fE843ccb0b5003e70B4192c1d7448bEf0/logo.png",
		name: "Colony Avalanche Index",
		symbol: "CAI",
	},
	{
		address: "0x60781C2586D68229fde47564546784ab3fACA982",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x60781C2586D68229fde47564546784ab3fACA982/logo_24.png",
		name: "Pangolin",
		symbol: "PNG",
	},
	{
		address: "0x184ff13B3EBCB25Be44e860163A5D8391Dd568c1",
		chainId: 43_114,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x184ff13B3EBCB25Be44e860163A5D8391Dd568c1/logo.png",
		name: "Kimbo",
		symbol: "KIMBO",
	},
] as const;
