"use client";

import type { MoarCandyToken } from "@vaporfi/db";
import { useIsIntersecting } from "@vaporfi/hooks";
import {
	Container,
	Grid,
	type GridProps,
	LoadingSpinner,
} from "@vaporfi/uikit";
import { cn } from "@vaporfi/utils";
import dynamic from "next/dynamic";
import type React from "react";
import { forwardRef, useEffect, useRef } from "react";
import type { Address } from "viem";

const TokenCard = dynamic(() => import("./Card"), {
	loading: () => <div>Loading</div>,
});

export type GetTokensResponse = {
	after: string | undefined;
	data: MoarCandyToken[];
	hasNextPage: boolean;
};

interface UikitCardListProps extends GridProps {
	replyCounts: Record<Address, number>;
	fetchNextPage: () => void;
	result: GetTokensResponse;
	renderWatchlistToggle?: (address: string) => React.ReactNode;
}

const TokenCardList = forwardRef<HTMLDivElement, UikitCardListProps>(
	(
		{ fetchNextPage, renderWatchlistToggle, replyCounts, result, ...rest },
		ref,
	) => {
		const spinnerRef = useRef<HTMLDivElement | null>(null);
		const isVisible = useIsIntersecting(spinnerRef);

		useEffect(() => {
			if (isVisible && result?.hasNextPage) {
				fetchNextPage();
			}
		}, [fetchNextPage, isVisible, result]);

		return (
			<Grid
				{...rest}
				ref={ref}
				isContainer
				className={cn(
					"grid-cols-1 gap-4 max-sm:justify-center md:grid-cols-2 lg:grid-cols-3 lg:gap-6",
				)}
			>
				{result?.data?.map((token) => (
					<TokenCard
						key={token?.id}
						{...token}
						deployed={String(token.deployed)}
						buys={token.buys.toString()}
						sells={token.sells.toString()}
						replies={
							token?.id
								? (replyCounts?.[token.id as keyof typeof replyCounts] ?? 0)
								: 0
						}
						renderWatchlistToggle={renderWatchlistToggle}
					/>
				))}
				{result?.hasNextPage && (
					<Container ref={spinnerRef} className="flex justify-center" fullWidth>
						<LoadingSpinner />
					</Container>
				)}
			</Grid>
		);
	},
);

export default TokenCardList;
