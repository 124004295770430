import JSBI from 'jsbi';
import { Rounding, _100 } from '../../constants';
import { Fraction } from './fraction';

const _100_PERCENT = new Fraction(_100);

export class Percent extends Fraction {
  public toSignificant(
    significantDigits: number = 5,
    format?: object,
    rounding?: Rounding,
  ): string {
    return this.multiply(_100_PERCENT).toSignificant(
      significantDigits,
      format,
      rounding,
    );
  }

  public toFixed(
    decimalPlaces: number = 2,
    format?: object,
    rounding?: Rounding,
  ): string {
    return this.multiply(_100_PERCENT).toFixed(decimalPlaces, format, rounding);
  }
}

// one basis point
export const BIPS_BASE = JSBI.BigInt(10_000);
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);
