import type { Hash } from "@wagmi/core";
import useActiveWagmi from "hooks/useActiveWagmi";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useSWRConfig } from "swr";
import {
	type Abi,
	type Address,
	decodeFunctionResult,
	encodeFunctionData,
} from "viem";
import { type AppState, useAppDispatch } from "../index";
import {
	type Call,
	type ListenerOptions,
	addMulticallListeners,
	parseCallKey,
	removeMulticallListeners,
	toCallKey,
} from "./actions";

export type Result = any;

type MethodArg = string | number | bigint;
type MethodArgs = Array<MethodArg | MethodArg[]>;

type OptionalMethodInputs =
	| Array<MethodArg | MethodArg[] | undefined>
	| undefined;

function isMethodArg(x: unknown): x is MethodArg {
	return ["string", "number"].includes(typeof x);
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
	return (
		x === undefined ||
		(Array.isArray(x) &&
			x.every(
				(xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg)),
			))
	);
}

interface CallResult {
	readonly valid: boolean;
	readonly data: string | undefined;
	readonly blockNumber: number | undefined;
}

const INVALID_RESULT: CallResult = {
	blockNumber: undefined,
	data: undefined,
	valid: false,
};

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
	blocksPerFetch: Number.POSITIVE_INFINITY,
};

// the lowest level call for subscribing to contract data
function useCallsData(
	calls: (Call | undefined)[],
	options?: ListenerOptions,
): CallResult[] {
	const { chainId } = useActiveWagmi();
	const callResults = useSelector<
		AppState,
		AppState["multicall"]["callResults"]
	>((state) => state.multicall.callResults);
	const dispatch = useAppDispatch();

	const serializedCallKeys: string = useMemo(
		() => JSON.stringify(calls?.filter(Boolean)?.map(toCallKey)?.sort() ?? []),
		[calls],
	);

	// update listeners when there is an actual change that persists for at least 100ms
	useEffect(() => {
		const callKeys: string[] = JSON.parse(serializedCallKeys);
		if (!chainId || callKeys.length === 0) return;
		const calls = callKeys.map((key) => parseCallKey(key));

		dispatch(
			addMulticallListeners({
				calls,
				chainId,
				options,
			}),
		);

		return () => {
			dispatch(
				removeMulticallListeners({
					calls,
					chainId,
					options,
				}),
			);
		};
	}, [chainId, dispatch, options, serializedCallKeys]);

	return useMemo(
		() =>
			calls.map<CallResult>((call) => {
				if (!chainId || !call) return INVALID_RESULT;

				const result = callResults[chainId]?.[toCallKey(call)];
				let data: string | undefined;
				if (result?.data && result?.data !== "0x") {
					// eslint-disable-next-line prefer-destructuring
					data = result.data;
				}

				return { blockNumber: result?.blockNumber, data, valid: true };
			}),
		[callResults, calls, chainId],
	);
}

interface CallState {
	readonly valid: boolean;
	// the result, or undefined if loading or errored/no data
	readonly result: Result | undefined;
	// true if the result has never been fetched
	readonly loading: boolean;
	// true if the result is not for the latest block
	readonly syncing: boolean;
	// true if the call was made and is synced, but the return data is invalid
	readonly error: boolean;
}

const INVALID_CALL_STATE: CallState = {
	error: false,
	loading: false,
	result: undefined,
	syncing: false,
	valid: false,
};
const LOADING_CALL_STATE: CallState = {
	error: false,
	loading: true,
	result: undefined,
	syncing: true,
	valid: true,
};

function toCallState(
	callResult: CallResult | undefined,
	abi: Abi,
	functionName: string,
	latestBlockNumber: number | undefined,
): CallState {
	if (!callResult) return INVALID_CALL_STATE;
	const { blockNumber, data, valid } = callResult;

	if (!valid) return INVALID_CALL_STATE;
	if (valid && !blockNumber) return LOADING_CALL_STATE;
	if (!abi || !functionName || !latestBlockNumber) return LOADING_CALL_STATE;
	const success = data && data.length > 2;
	const syncing = (blockNumber ?? 0) < latestBlockNumber;
	let result: Result | undefined;

	if (success && data) {
		try {
			result = decodeFunctionResult({
				abi,
				data: data as `0x${string}`,
				functionName: functionName,
			});
		} catch {
			console.debug("Result data parsing failed", functionName, data);
			return {
				error: true,
				loading: false,
				result,
				syncing,
				valid: true,
			};
		}
	}
	return {
		error: !success,
		loading: false,
		result,
		syncing,
		valid: true,
	};
}

export function useSingleContractMultipleData(
	address: Address,
	abi: Abi,
	methodName: string,
	callInputs: OptionalMethodInputs[],
	options?: ListenerOptions,
): CallState[] {
	const calls = useMemo(
		() =>
			address && abi && callInputs && callInputs.length > 0
				? callInputs.map<Call>((inputs) => {
						return {
							address: address,
							callData: encodeFunctionData({
								abi,
								args: inputs,
								functionName: methodName,
							}),
						};
					})
				: [],
		[callInputs, address, methodName, abi],
	);

	const results = useCallsData(calls, options);

	const { cache } = useSWRConfig();

	return useMemo(() => {
		const currentBlockNumber = cache.get("blockNumber") as number;
		return results.map((result) =>
			toCallState(result, abi, methodName, currentBlockNumber),
		);
	}, [abi, cache, results, methodName]);
}

export function useMultipleContractSingleData(
	addresses: (Address | undefined)[],
	abi: Abi,
	methodName: string,
	callInputs?: OptionalMethodInputs,
	options?: ListenerOptions,
): CallState[] {
	const callData: Hash | undefined = useMemo(
		() =>
			abi && isValidMethodArgs(callInputs)
				? encodeFunctionData({
						abi,
						args: callInputs,
						functionName: methodName,
					})
				: undefined,
		[callInputs, abi, methodName],
	);

	const calls = useMemo(
		() =>
			addresses && addresses.length > 0 && callData
				? addresses.map<Call | undefined>((address) => {
						return address && callData
							? {
									address,
									callData,
								}
							: undefined;
					})
				: [],
		[addresses, callData],
	);

	const results = useCallsData(calls, options);

	const { cache } = useSWRConfig();
	const result = useMemo(() => {
		const currentBlockNumber = cache.get("blockNumber") as number;

		return results.map((result) =>
			toCallState(result, abi, methodName, currentBlockNumber),
		);
	}, [results, abi, cache, methodName]);

	return result;
}

export function useSingleCallResult(
	address: Address,
	abi: Abi,
	methodName: string,
	inputs?: OptionalMethodInputs,
	options?: ListenerOptions,
): CallState {
	const calls = useMemo<Call[]>(() => {
		return abi && address && methodName && isValidMethodArgs(inputs)
			? [
					{
						address,
						callData: encodeFunctionData({
							abi,
							args: inputs,
							functionName: methodName,
						}),
					},
				]
			: [];
	}, [address, abi, inputs, methodName]);

	const result = useCallsData(calls, options)[0];
	const { cache } = useSWRConfig();

	return useMemo(() => {
		const currentBlockNumber = cache.get("blockNumber") as number;
		return toCallState(result, abi, methodName, currentBlockNumber);
	}, [cache, result, abi, methodName]);
}
