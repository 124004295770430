import {
	type Currency,
	ONE_BIPS,
	type Percent,
	type Price,
} from "@vapordex/sdk";
import { useCommify } from "@vaporfi/hooks";
import { Container } from "@vaporfi/uikit";
import { type ReactNode, memo, useMemo } from "react";
import {
	NoLiquidityAlert,
	RebalanceAlert,
	type RebalanceAlertProps,
	ZapAlert,
	type ZapAlertProps,
} from "../Alerts";
import { Field } from "../RemoveLiquidity";
import { SingleRowStats } from "../Stats";

export interface PoolPriceBarProps {
	currencies: { [field in Field]?: Currency };
	noLiquidity?: boolean;
	poolTokenPercentage?: Percent;
	price?: Price<Currency, Currency>;
}
function PoolPriceBar({
	currencies,
	noLiquidity,
	poolTokenPercentage,
	price,
}: PoolPriceBarProps) {
	const format = useCommify();

	const items = useMemo(() => {
		return [
			{
				label: `${currencies[Field.CURRENCY_B]?.symbol} per
  ${currencies[Field.CURRENCY_A]?.symbol}`,
				value: format(price?.toSignificant?.(6) || "0") ?? "-",
			},
			{
				label: `${currencies[Field.CURRENCY_A]?.symbol} per
  ${currencies[Field.CURRENCY_B]?.symbol}`,
				value: format(price?.invert?.()?.toSignificant?.(6) || "0") ?? "-",
			},
			{
				label: "Share of Pool",
				value: `${
					noLiquidity && price
						? "100"
						: poolTokenPercentage?.lessThan(ONE_BIPS)
							? "<0.01"
							: format(poolTokenPercentage?.toFixed?.(2) ?? "0")
				}
      %`,
			},
		];
	}, [currencies, format, noLiquidity, poolTokenPercentage, price]);
	return <SingleRowStats items={items} />;
}

export interface AddLiquidityProps {
	showPoolPriceBar: boolean;
	showZapWarning: boolean;
	showRebalancingConvert: boolean;
	poolPriceBarProps: PoolPriceBarProps;
	rebalanceProps: RebalanceAlertProps;
	zapAlertProps: ZapAlertProps;
	noLiquidity: boolean;
	buttons: () => ReactNode;
	currencyInputPanelA: () => JSX.Element;
	currencyInputPanelB: () => JSX.Element;
}
export const AddLiquidity = memo(
	({
		buttons,
		currencyInputPanelA,
		currencyInputPanelB,
		noLiquidity,
		poolPriceBarProps,
		rebalanceProps,
		showPoolPriceBar,
		showRebalancingConvert,
		showZapWarning,
		zapAlertProps,
	}: AddLiquidityProps) => {
		return (
			<Container stack="column" className="gap-5 lg:gap-[30px]">
				{noLiquidity && <NoLiquidityAlert />}
				{currencyInputPanelA?.()}
				{currencyInputPanelB?.()}
				{showZapWarning && <ZapAlert {...zapAlertProps} />}
				{showRebalancingConvert && <RebalanceAlert {...rebalanceProps} />}
				{showPoolPriceBar && <PoolPriceBar {...poolPriceBarProps} />}
				{buttons?.()}
			</Container>
		);
	},
);
