import {
	earnItems,
	earnItemsLabels,
	menuItems,
	servicesItems,
	tradeItems,
} from "@config/constants/menuItems";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
	type AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import MuiListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import { ArrowRefreshIcon, ChartIcon, MoneyBagIcon } from "@vapordex/uikit";
import { useFeature } from "@vaporfi/feature-flags/useFeature";
import { Tools } from "@vaporfi/uikit";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { type SyntheticEvent, useState } from "react";

const Accordion = styled(MuiAccordion)`
  && {
    background: transparent;
    box-shadow: none;
    margin: 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;

  a {
    color: ${({ theme }) => theme.palette.secondary.main};
    display: block;
    font-size: 13px;
    padding-left: 18px;
    padding-bottom: 8px;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
const EarnAccordionDetails = styled(AccordionDetails)`
  padding: 0;

  a {
    color: ${({ theme }) => theme.palette.secondary.main};
    display: block;
    font-size: 13px;
    padding-left: 18px;
    padding-bottom: 8px;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & > div {
    margin-top: -4px;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)<
	{ highlight?: string } & AccordionSummaryProps
>`
  font-weight: 600;
  color: ${({ highlight, theme }) =>
		highlight === "true"
			? theme.palette.primary.main
			: theme.palette.secondary.main};
  border-top: 1px brown;

  [data-testid='ExpandMoreIcon'] {
    transform: rotate(270deg);
  }

  &:hover,
  &[aria-expanded='true'] {
    &,
    [data-testid='ExpandMoreIcon'] {
      color: ${({ highlight, theme }) =>
				highlight === "true"
					? theme.palette.primary.main
					: theme.palette.secondary.main};
    }
  }
  &[aria-expanded='true'] {
    [data-testid='ExpandMoreIcon'] {
      transform: rotate(180deg);
    }
  }
`;

const ListItem = styled(MuiListItem)`
  cursor: pointer;
  font-weight: 600;

  &.Mui-selected {
    background-color: transparent;

    & > * {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const MenuContainer = styled(Box)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const MenuItems = () => {
	const { pathname } = useRouter();
	const { isEnabled } = useFeature();
	const earnURLs = earnItems.map((item) => item.url);
	const tradeURLs = tradeItems.map((item) => item.url);
	const rewardsStatsItems = menuItems.filter((item) =>
		["Profile", "Portfolio", "Stratosphere", "Partners"].includes(item.label),
	);
	const servicesURLs = servicesItems.map((item) => item.url);
	const isServicesEnabled = servicesItems?.some((item) =>
		isEnabled(item?.featureFlagId),
	);
	const [expanded, setExpanded] = useState({
		// Default all sections to open
		earn: true,
		rewardsStats: true,
		services: true,
		trade: true,
	});

	const handleChange =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setExpanded((prevExpanded) => ({
				...prevExpanded,
				[panel]: isExpanded,
			}));
		};

	return (
		<MenuContainer>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
				}}
			>
				<Accordion
					elevation={0}
					sx={{
						"&.MuiAccordion-root:before": {
							backgroundColor: "black",
						},
					}}
					expanded={expanded.earn}
					onChange={handleChange("earn")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="earn-content"
						id="earn-header"
						data-testid="drawer-earn-header"
						highlight={`${earnURLs.some((item) => pathname.includes(item))}`}
					>
						<Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
							<MoneyBagIcon />
							{"Earn"}
						</Box>
					</AccordionSummary>
					<EarnAccordionDetails>
						<Box sx={{ paddingLeft: 2.5 }}>
							{menuItems.map((item) => {
								if (
									earnItemsLabels.includes(item.label) &&
									isEnabled(item.featureFlagId)
								) {
									return (
										<Link
											href={item.url}
											passHref
											legacyBehavior
											key={item.featureFlagId}
										>
											<ListItem selected={pathname.includes(item.url)}>
												<Typography
													fontSize={13}
													fontWeight={600}
													paddingLeft={2}
												>
													{item.label}
												</Typography>
											</ListItem>
										</Link>
									);
								}
							})}
						</Box>
					</EarnAccordionDetails>
				</Accordion>
				<Accordion
					elevation={0}
					sx={{
						"&.MuiAccordion-root:before": {
							backgroundColor: "black",
						},
					}}
					disableGutters
					expanded={expanded.trade}
					onChange={handleChange("trade")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="trade-content"
						id="trade-header"
						data-testid="drawer-trade-header"
						highlight={`${
							pathname === "/" ||
							tradeURLs.some((item) => pathname.includes(item))
						}`}
					>
						<Box
							sx={{
								alignItems: "center",
								border: "none",
								display: "flex",
								gap: 2.3,
							}}
						>
							<ArrowRefreshIcon width="18px" height="19px" />
							{"Trade"}
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ paddingLeft: 3 }}>
							{menuItems.map((item) => {
								if (
									tradeItems.includes(item) &&
									isEnabled(item.featureFlagId)
								) {
									let isSelected = pathname.includes(item.url);

									if (item.label === "Fiat") {
										isSelected =
											isSelected ||
											pathname === item.path ||
											(item.label === "Fiat" &&
												pathname.includes(
													menuItems.find((i) => i.label === "Onramper").url,
												));
									}

									return (
										<Link
											href={item.url}
											passHref
											legacyBehavior
											key={item.featureFlagId}
										>
											<ListItem selected={isSelected}>
												<Typography
													fontSize={13}
													fontWeight={600}
													paddingLeft={2}
												>
													{item.label}
												</Typography>
											</ListItem>
										</Link>
									);
								}
							})}
						</Box>
					</AccordionDetails>
				</Accordion>
				{isServicesEnabled && (
					<Accordion
						elevation={0}
						sx={{
							"&.MuiAccordion-root:before": {
								backgroundColor: "black",
							},
						}}
						disableGutters
						expanded={expanded.services}
						onChange={handleChange("services")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="services-content"
							id="services-header"
							data-testid="drawer-services-header"
							highlight={`${servicesURLs.some((url) =>
								pathname.includes(url),
							)}`}
						>
							<Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
								<Tools fill="#ffffff" />
								{"Services"}
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ paddingLeft: 2.5 }}>
								{servicesItems.map((item) => {
									if (isEnabled(item.featureFlagId)) {
										return (
											<Link
												href={item.url}
												passHref
												legacyBehavior
												key={item.featureFlagId}
											>
												<ListItem selected={pathname.includes(item.url)}>
													<Typography
														fontSize={13}
														fontWeight={600}
														paddingLeft={2}
													>
														{item.label}
													</Typography>
												</ListItem>
											</Link>
										);
									}
								})}
							</Box>
						</AccordionDetails>
					</Accordion>
				)}

				{isEnabled("enableRewardsStatsSection") && (
					<Accordion
						disableGutters
						elevation={0}
						sx={{
							"&.MuiAccordion-root:before": {
								backgroundColor: "black",
							},
						}}
						expanded={expanded.rewardsStats}
						onChange={handleChange("rewardsStats")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="rewards-stats-content"
							id="rewards-stats-header"
							data-testid="drawer-reward-stats-header"
							highlight={`${rewardsStatsItems.some((item) =>
								pathname.includes(item.path),
							)}`}
						>
							<Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
								<ChartIcon />
								{"Rewards & Stats"}
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ paddingLeft: 2 }}>
								{rewardsStatsItems.map((item) => {
									if (isEnabled(item.featureFlagId)) {
										const isSelected = pathname === item.path;
										return (
											<Box key={item.featureFlagId}>
												<ListItem
													data-testid={`drawer-${item.label.toLowerCase()}`}
													selected={isSelected}
												>
													<Link
														href={item.url}
														data-testid={`drawer-${item.label.toLowerCase()}-text`}
														passHref
														legacyBehavior
													>
														{item.label}
													</Link>
												</ListItem>
											</Box>
										);
									}
								})}
							</Box>
						</AccordionDetails>
					</Accordion>
				)}
			</Box>
		</MenuContainer>
	);
};

export default MenuItems;
