import { Svg, type SvgProps } from "./Svg";

export const WarningIcon = ({
	fill = "none",
	height = "18",
	viewBox = "0 0 20 18",
	width = "20",
	xmlns = "http://www.w3.org/2000/svg",
	...rest
}: SvgProps) => {
	return (
		<Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
			<g id="icon" clipPath="url(#clip0_11870_4858)">
				<g id="icons">
					<path
						id="Vector"
						d="M19.7544 15.0672L11.5721 0.900051C10.8707 -0.303923 9.11738 -0.303923 8.42772 0.900051L0.245375 15.0672C-0.455969 16.2829 0.420711 17.8024 1.8234 17.8024H18.1764C19.5791 17.8024 20.4441 16.2829 19.7544 15.0672ZM8.82515 6.86147C8.82515 6.21858 9.33947 5.69257 9.99406 5.69257C10.6486 5.69257 11.163 6.21858 11.163 6.86147V10.3565C11.163 10.9994 10.637 11.5254 9.99406 11.5254C9.35116 11.5254 8.82515 10.9994 8.82515 10.3565V6.86147ZM9.99406 14.9269C9.3044 14.9269 8.74333 14.3659 8.74333 13.6645C8.74333 12.9632 9.3044 12.4138 9.99406 12.4138C10.6837 12.4138 11.2565 12.9749 11.2565 13.6645C11.2565 14.3542 10.6954 14.9269 9.99406 14.9269Z"
						fill="var(--colors-yellow)"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_11870_4858">
					<rect width="20" height="17.8024" fill="white" />
				</clipPath>
			</defs>
		</Svg>
	);
};
