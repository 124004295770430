//source: https://github.com/safe-global/safe-apps-sdk/tree/master/packages/safe-apps-wagmi
import { SafeAppProvider } from "@safe-global/safe-apps-provider";
import SafeAppsSDK, {
	type Opts as SafeOpts,
	type SafeInfo,
} from "@safe-global/safe-apps-sdk";
import { type Chain, Connector, ConnectorNotFoundError } from "@wagmi/core";
import {
	type WalletClient,
	createWalletClient,
	custom,
	getAddress,
} from "viem";

function normalizeChainId(chainId: string | number) {
	if (typeof chainId === "string") {
		const isHex = chainId.trim().slice(0, 2);

		return Number.parseInt(chainId, isHex === "0x" ? 16 : 10);
	}
	return chainId;
}

const __IS_SERVER__ = typeof window === "undefined";
const __IS_IFRAME__ = !__IS_SERVER__ && window?.parent !== window;

export class SafeConnector extends Connector<
	SafeAppProvider,
	SafeOpts | undefined
> {
	readonly id = "safe";
	readonly name = "Safe";
	ready = !__IS_SERVER__ && __IS_IFRAME__;

	#provider?: SafeAppProvider;
	#sdk: SafeAppsSDK;
	#safe?: SafeInfo;

	constructor(config: { chains?: Chain[]; options?: SafeOpts }) {
		super({ ...config, options: config?.options });

		this.#sdk = new SafeAppsSDK(config.options);
	}

	async connect() {
		const runningAsSafeApp = await this.#isSafeApp();
		if (!runningAsSafeApp) {
			throw new ConnectorNotFoundError();
		}

		const provider = await this.getProvider();
		if (provider.on) {
			provider.on("accountsChanged", this.onAccountsChanged);
			provider.on("chainChanged", this.onChainChanged);
			provider.on("disconnect", this.onDisconnect);
		}

		const account = await this.getAccount();
		const id = await this.getChainId();

		return {
			account,
			chain: { id, unsupported: this.isChainUnsupported(id) },
			provider,
		};
	}

	async disconnect() {
		const provider = await this.getProvider();
		if (!provider?.removeListener) return;

		provider.removeListener("accountsChanged", this.onAccountsChanged);
		provider.removeListener("chainChanged", this.onChainChanged);
		provider.removeListener("disconnect", this.onDisconnect);
	}

	async getAccount() {
		if (!this.#safe) {
			throw new ConnectorNotFoundError();
		}

		return getAddress(this.#safe.safeAddress);
	}

	async getChainId() {
		if (!this.#provider) {
			throw new ConnectorNotFoundError();
		}

		return normalizeChainId(this.#provider.chainId);
	}

	async #getSafeInfo(): Promise<SafeInfo> {
		if (!this.#sdk) {
			throw new ConnectorNotFoundError();
		}
		if (!this.#safe) {
			this.#safe = await this.#sdk.safe.getInfo();
		}
		return this.#safe;
	}

	async #isSafeApp(): Promise<boolean> {
		if (!this.ready) {
			return false;
		}

		const safe = await Promise.race([
			this.#getSafeInfo(),
			new Promise<void>((resolve) => setTimeout(resolve, 300)),
		]);
		return !!safe;
	}

	async getProvider() {
		if (!this.#provider) {
			const safe = await this.#getSafeInfo();
			if (!safe) {
				throw new Error("Could not load Safe information");
			}

			this.#provider = new SafeAppProvider(safe, this.#sdk as any);
		}
		return this.#provider;
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	async getWalletClient({
		chainId,
	}: { chainId?: number } = {}): Promise<WalletClient> {
		const provider = await this.getProvider();
		const account = await this.getAccount();
		const chain = this.chains.find((x) => x.id === chainId);
		if (!provider) throw new Error("provider is required.");
		return createWalletClient({
			account,
			chain,
			transport: custom(provider),
		});
	}

	async isAuthorized() {
		try {
			const account = await this.getAccount();
			return !!account;
		} catch {
			return false;
		}
	}

	protected onAccountsChanged(accounts: string[]) {
		if (accounts.length === 0) this.emit("disconnect");
		else this.emit("change", { account: getAddress(accounts[0]) });
	}

	protected isChainUnsupported(chainId: number) {
		return !this.chains.some((x) => x.id === chainId);
	}

	protected onChainChanged(chainId: string | number) {
		const id = normalizeChainId(chainId);
		const unsupported = this.isChainUnsupported(id);
		this.emit("change", { chain: { id, unsupported } });
	}

	protected onDisconnect() {
		this.emit("disconnect");
	}
}
