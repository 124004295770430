import { Svg, type SvgProps } from "./Svg";

export const GradientCrownIcon = ({
	fill = "none",
	height = "15",
	viewBox = "0 0 21 15",
	width = "21",
	xmlns = "http://www.w3.org/2000/svg",
	...rest
}: SvgProps) => {
	return (
		<Svg {...rest} {...{ fill, height, viewBox, width, xmlns }}>
			<path
				d="M20.8571 6.80894C20.8571 6.80894 19.9126 11.9387 19.5512 13.8973C19.4758 14.3057 19.0822 14.5733 18.6744 14.4953C16.2976 14.0398 13.6651 13.7867 10.8947 13.7867C8.12424 13.7867 5.46614 14.0423 3.08022 14.5019C2.67246 14.5804 2.27862 14.313 2.20284 13.9046C1.83889 11.9391 0.89922 6.77448 0.89922 6.77448C0.748284 6.12008 1.46954 5.61412 2.03342 5.9789L5.63804 9.22413C5.85728 9.36614 6.12698 9.40663 6.37861 9.33542C6.62982 9.26441 6.83827 9.08815 6.951 8.8525L10.2255 1.07897C10.4965 0.511763 11.3039 0.511763 11.5746 1.07897L14.8491 8.85292C14.9618 9.08856 15.1703 9.26462 15.4215 9.33583C15.6727 9.40704 15.9426 9.36614 16.1621 9.22455L19.7243 6.00693C20.2907 5.64049 21.0145 6.15288 20.8571 6.80894Z"
				fill="url(#paint0_linear_15494_12966)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_15494_12966"
					x1="10.8786"
					y1="0.000122109"
					x2="10.3786"
					y2="14.5001"
					gradientUnits="userSpaceOnUse"
				>
					{/* this color isn't in design system */}
					<stop stopColor="#1BCCB2" />
					<stop offset="0.488478" stopColor="var(--colors-turquoise500)" />
					<stop offset="1" stopColor="var(--colors-blue500)" />
				</linearGradient>
			</defs>
		</Svg>
	);
};
