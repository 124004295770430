import { ChainId } from '../constants';
import { validateAndParseAddress, checkValidAddress } from '../utils';
import { Currency } from './currency';
import invariant from 'tiny-invariant';
import { BaseCurrency } from './baseCurrency';
import { Address } from 'viem';
/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends BaseCurrency {
  public readonly chainId: ChainId;
  public readonly address: Address;
  public readonly projectLink?: string;
  public readonly isNative = false as const;
  public readonly isToken = true as const;

  public constructor(
    chainId: ChainId,
    address: Address,
    decimals: number,
    symbol?: string,
    name?: string,
    projectLink?: string,
    bypassChecksum?: boolean,
  ) {
    super(chainId, decimals, symbol, name);
    this.address = bypassChecksum
      ? checkValidAddress(address)
      : validateAndParseAddress(address);

    this.chainId = chainId;
    this.projectLink = projectLink;
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Currency): boolean {
    return (
      other.isToken &&
      this.chainId === other.chainId &&
      this.address.toLowerCase() === other.address.toLowerCase()
    );
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS');
    invariant(this.address.toLowerCase() !== other.address, 'ADDRESSES');
    return this.address.toLowerCase() < other.address.toLowerCase();
  }

  /**
   * Return this token, which does not need to be wrapped
   */

  public get wrapped(): Token {
    return this;
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(
  currencyA: Currency,
  currencyB: Currency,
): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB);
  } else if (currencyA instanceof Token) {
    return false;
  } else if (currencyB instanceof Token) {
    return false;
  } else {
    return currencyA === currencyB;
  }
}

export const WETH: { [chainId: number]: Token } = {
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://www.avax.network',
  ),
  [ChainId.AVALANCHE_TESTNET]: new Token(
    ChainId.AVALANCHE_TESTNET,
    '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://www.avax.network',
  ),
};
