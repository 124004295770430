import AuthenticationControllerABI from "@config/abi/AuthenticationController";
import StratosphereABI from "@config/abi/Stratosphere";
import { ADDRESS_ZERO, ZERO } from "@config/constants";
import useActiveWagmi from "@hooks/useActiveWagmi";
import { useContractWriteWithTransactionWatch } from "@hooks/useContract";
import {
	getStratosphereAddress,
	getStratosphereAuthenticationControllerAddress,
} from "@utils/addressHelpers";
import { useCommify } from "@vaporfi/hooks";
import { useCallback, useMemo } from "react";
import { type Address, useContractRead, useContractReads } from "wagmi";

export const useStratosphereTokenIdOf = (chainIdOverrides?: number) => {
	const { account, chainId: wagmiChainId, isConnected } = useActiveWagmi();
	const chainId = chainIdOverrides ?? wagmiChainId;

	const stratosphereContract = {
		abi: StratosphereABI,
		address: getStratosphereAddress(chainId),
		chainId,
	};

	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
		chainId,
	};

	const { data, error, isLoading, ...rest } = useContractReads({
		allowFailure: true,
		contracts: [
			{
				...stratosphereContract,
				args: [account],
				functionName: "tokenIdOf",
			},
			{
				...authenticationControllerContract,
				args: [account],
				functionName: "authorizedTokenOf",
			},
		],
	});

	const tokenId = useMemo(() => {
		if (isConnected) {
			const firstData =
				typeof data?.[0] === "object" ? data?.[0].result : data?.[0];
			return firstData === ZERO ? data?.[1].result : firstData;
		}
		return BigInt(0);
	}, [isConnected, data]);

	return { data: tokenId, error, isLoading, ...rest };
};

export const useRegistrationOwnerOf = (
	tokenId: string,
	account: string = ADDRESS_ZERO,
) => {
	const { chainId } = useActiveWagmi();
	const stratosphereContract = {
		abi: StratosphereABI,
		address: getStratosphereAddress(chainId),
	};

	const res = useContractRead({
		...stratosphereContract,
		args: [BigInt(tokenId)],
		functionName: "ownerOf",
		watch: true,
	});

	return {
		isOwner: res?.data && account && res?.data?.toString?.() === account,
		...res,
	};
};

export const useRegistrationAuthorizedAccountsOf = (tokenId: string) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	const { data: _data, ...rest } = useContractRead({
		...authenticationControllerContract,
		args: [BigInt(tokenId)],
		functionName: "authorizedAccountsOf",
		watch: true,
	});

	const data =
		_data && _data.length > 0
			? _data.filter((item) => item !== ADDRESS_ZERO)
			: [];

	return {
		data,
		...rest,
	};
};

export const useRegistrationLinkedAccountsOf = (tokenId: string) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	const { data: _data, ...rest } = useContractRead({
		...authenticationControllerContract,
		args: [BigInt(tokenId)],
		functionName: "linkedAccountsOf",
		watch: true,
	});

	const data =
		_data && _data.length > 0
			? _data.filter((item) => item !== ADDRESS_ZERO)
			: [];

	return {
		data,
		...rest,
	};
};

export const useAuthorizedTokenOf = (account: Address) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	return useContractRead({
		...authenticationControllerContract,
		args: [account],
		functionName: "authorizedTokenOf",
		watch: true,
	});
};

export const useRegistrationLinkAccount = (tokenId: string) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	const { write, ...rest } = useContractWriteWithTransactionWatch({
		...authenticationControllerContract,
		args: [tokenId],
		functionName: "linkAccount",
		mode: "recklesslyUnprepared",
	});

	const onLinkAccount = useCallback(async () => {
		return write();
	}, [write]);

	return {
		onLinkAccount,
		...rest,
	};
};

export const useRegistrationUnlinkAccount = (tokenId: bigint) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	const { write, ...rest } = useContractWriteWithTransactionWatch({
		...authenticationControllerContract,
		args: [tokenId],
		functionName: "unlinkAccount",
	});

	const onUnlinkAccount = useCallback(async () => {
		return write();
	}, [write]);

	return {
		onUnlinkAccount,
		...rest,
	};
};

export const useRegistrationAuthorizeAccount = (
	tokenId: string,
	secondAccount: string,
	isAuthorized = true,
) => {
	const { chainId } = useActiveWagmi();
	const authenticationControllerContract = {
		abi: AuthenticationControllerABI,
		address: getStratosphereAuthenticationControllerAddress(chainId),
	};

	const { write, ...rest } = useContractWriteWithTransactionWatch({
		...authenticationControllerContract,
		args: [tokenId, secondAccount, isAuthorized],
		functionName: "authorize",
	});

	const onAuthorizeAccount = useCallback(async () => {
		return write();
	}, [write]);

	return {
		onAuthorizeAccount,
		...rest,
	};
};

export const useRegistrationMint = () => {
	const { chainId } = useActiveWagmi();
	const stratosphereContract = {
		abi: StratosphereABI,
		address: getStratosphereAddress(chainId),
	};

	const { write, ...rest } = useContractWriteWithTransactionWatch({
		...stratosphereContract,
		functionName: "mint",
		mode: "recklesslyUnprepared",
	});

	const onMint = useCallback(async () => {
		return write();
	}, [write]);

	return {
		onMint,
		...rest,
	};
};

export const useMemberCount = (isCommify?: boolean) => {
	const { chainId } = useActiveWagmi();
	const stratosphereContract = {
		abi: StratosphereABI,
		address: getStratosphereAddress(chainId),
	};

	const { data } = useContractRead({
		...stratosphereContract,
		functionName: "totalSupply",
	});
	const commify = useCommify();
	if (isCommify) {
		return commify(data?.toString());
	}
	return data?.toString() || "0";
};

export default useRegistrationMint;
