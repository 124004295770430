"use client";

import { Root, Thumb } from "@radix-ui/react-switch";
import { type HTMLProps, useCallback } from "react";
import { type VariantProps, tv } from "tailwind-variants";
import { Typography } from "../Typography";

const switchStyles = tv({
	defaultVariants: { size: "large", state: "enabled", theme: "DarkTheme" },
	slots: {
		root: "rounded-full relative outline-none",
		thumb:
			"block bg-neutral-400 rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform",
	},
	variants: {
		size: {
			default: {
				root: "w-7 h-4",
				thumb: "w-3 h-3",
			},
			large: {
				root: "w-16 h-8",
				thumb: "w-7 h-7",
			},
		},
		state: {
			disabled: {
				root: "cursor-not-allowed",
				thumb: "",
			},
			enabled: {
				root: "cursor-pointer data-[state=checked]:",
				thumb: "data-[state=checked]:translate-x-[120%]",
			},
		},
		theme: {
			DarkTheme: {
				root: "bg-neutral-700 data-[state=checked]:bg-blue-500",
				thumb: "bg-neutral-900 data-[state=checked]:bg-neutral-800",
			},
			LightTheme: {
				root: "bg-neutral-300 data-[state=checked]:bg-turquoise-500",
				thumb: "bg-neutral-400 data-[state=checked]:bg-neutral-50",
			},
		},
	},
});

export interface SwitchProps
	extends VariantProps<typeof switchStyles>,
		Omit<HTMLProps<HTMLButtonElement>, "onChange" | "size" | "type" | "ref"> {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	label?: string;
	theme?: "LightTheme" | "DarkTheme";
}

const Switch = ({
	checked,
	className,
	disabled,
	label,
	onChange,
	size,
	theme = "DarkTheme",
	...rest
}: SwitchProps) => {
	const handleChange = useCallback(() => {
		if (!disabled && onChange) {
			return onChange(!checked);
		}
	}, [disabled, onChange, checked]);

	const { root, thumb } = switchStyles({
		class: className,
		size,
		state: disabled ? "disabled" : "enabled",
		theme,
	});

	return (
		<div className="flex items-center gap-2">
			<Root
				{...rest}
				checked={checked}
				className={root()}
				id="switch"
				onClick={handleChange}
			>
				<Thumb className={thumb()} />
			</Root>
			{label && <Typography weight="semibold">{label}</Typography>}
		</div>
	);
};

export { Switch };
