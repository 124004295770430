"use client";
import type * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@vaporfi/utils";
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	type HTMLAttributes,
	forwardRef,
	useId,
} from "react";
import {
	Controller,
	type ControllerProps,
	type FieldPath,
	type FieldValues,
	FormProvider,
	useForm as useReactForm,
} from "react-hook-form";
import { Label } from "../Label";
import { Typography } from "../Typography";
import { FormFieldContext, FormItemContext } from "./Context";
import { useFormField } from "./hooks";

const Form = FormProvider;

const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	...props
}: ControllerProps<TFieldValues, TName>) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	);
};

const FormItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const id = useId();

		return (
			<FormItemContext.Provider value={{ id }}>
				<div
					ref={ref}
					className={cn("flex flex-col gap-2", className)}
					{...props}
				/>
			</FormItemContext.Provider>
		);
	},
);
FormItem.displayName = "FormItem";

const FormLabel = forwardRef<
	ElementRef<typeof LabelPrimitive.Root>,
	ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
	const { formItemId } = useFormField();

	return (
		<Label
			ref={ref}
			className={cn("text-light", className)}
			htmlFor={formItemId}
			{...props}
		/>
	);
});
FormLabel.displayName = "FormLabel";

const FormControl = forwardRef<
	ElementRef<typeof Slot>,
	ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
	const { error, formDescriptionId, formItemId, formMessageId } =
		useFormField();

	return (
		<Slot
			ref={ref}
			id={formItemId}
			aria-describedby={
				error ? `${formDescriptionId} ${formMessageId}` : `${formDescriptionId}`
			}
			aria-invalid={!!error}
			{...props}
		/>
	);
});
FormControl.displayName = "FormControl";

const FormDescription = forwardRef<
	ElementRef<typeof Typography>,
	ComponentPropsWithoutRef<typeof Typography>
>(({ className, ...props }, ref) => {
	const { formDescriptionId } = useFormField();

	return (
		<Typography
			ref={ref}
			id={formDescriptionId}
			className={cn("text-[0.8rem]", className)}
			{...props}
		/>
	);
});
FormDescription.displayName = "FormDescription";

const FormMessage = forwardRef<
	ElementRef<typeof Typography>,
	ComponentPropsWithoutRef<typeof Typography>
>(({ children, className, ...props }, ref) => {
	const { error, formMessageId } = useFormField();
	const body = error ? String(error?.message) : children;

	if (!body) {
		return null;
	}

	return (
		<Typography
			ref={ref}
			id={formMessageId}
			color="red"
			size="xs"
			className={className}
			{...props}
		>
			{body}
		</Typography>
	);
});
FormMessage.displayName = "FormMessage";

const useForm: typeof useReactForm = (props) => useReactForm(props);
export {
	Form,
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
	FormMessage,
	FormField,
	useForm,
};

export { zodResolver } from "@hookform/resolvers/zod";

export { useFormField } from "./hooks";

export * from "react-hook-form";
