import { type Currency, Token } from "@vapordex/sdk";
import { commify } from "@vaporfi/hooks";
import {
	AlertCard,
	Avatar,
	Box,
	Button,
	ChevronDownIcon,
	CurrencyInputPanel,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	RadioGroup,
	RadioGroupItem,
	Select,
	Typography,
	numericalInputRegex,
	useWatch,
} from "@vaporfi/uikit";
import { removeNonNumbers } from "@vaporfi/utils";
import { memo, useCallback, useMemo } from "react";
import { formatEther, parseEther, parseUnits } from "viem";
import {
	SingleRowStats,
	TOKEN_FACTORY_TOKEN_DECIMALS,
	lockDurations,
} from "../../..";
import type { CommonFieldsProps } from "../../types";
import {
	CancelButton,
	FieldsContainer,
	useWithFieldsValidation,
} from "./shared";

const ONE_ETHER = parseEther("1");

const calculateNativePerToken = (
	nativeAmount: bigint,
	initialSupply: bigint,
) => {
	if (!initialSupply || !nativeAmount) {
		return "0";
	}

	const multiplier = nativeAmount < initialSupply ? ONE_ETHER : BigInt(1);
	const numerator = nativeAmount * multiplier;
	const value = Number.parseFloat(
		(
			+(numerator / initialSupply)?.toString?.() / +multiplier?.toString?.()
		)?.toString(),
	)?.toFixed?.(10);
	return commify(value || "0");
};

const calculateTokenPerNative = (
	nativeAmount: bigint,
	initialSupply: bigint,
) => {
	if (!initialSupply || !nativeAmount) {
		return "0";
	}

	const multiplier = nativeAmount > initialSupply ? ONE_ETHER : BigInt(1);
	const numerator = initialSupply * multiplier;
	const value = (
		+(numerator / nativeAmount)?.toString?.() / +multiplier?.toString?.()
	)?.toFixed?.(10);
	return commify(value || "0");
};
const calculateMarketCap = (nativeAmount: bigint, nativePrice: bigint) => {
	const price = BigInt(nativePrice ?? "0");
	return Number.parseFloat(
		(
			Number(formatEther(BigInt(nativeAmount ?? "0") * price)) / 1e18
		)?.toString(),
	);
};

export const LiquidityFields = memo(
	({
		CurrencyInputPanel0,
		chainId,
		form,
		nativeCurrency,
		nativePrice,
		onCancel,
		onNext,
	}: CommonFieldsProps & {
		CurrencyInputPanel0: any;
		nativePrice: number;
		nativeCurrency: Currency;
	}) => {
		const nativeSymbol = nativeCurrency?.symbol ?? "AVAX";
		const onNextWithValidate = useWithFieldsValidation(
			chainId,
			"liquidityInfo",
			onNext,
		);
		const [name, symbol, logoFile, _initialSupply, type, nativeAmount] =
			useWatch({
				name: [
					"name",
					"symbol",
					"logo",
					"initialSupply",
					"type",
					"nativeAmount",
				],
			});

		const initialSupply = useMemo(
			() => removeNonNumbers(_initialSupply),
			[_initialSupply],
		);

		const logo = useCallback(() => {
			if (logoFile) {
				const image = URL.createObjectURL(logoFile);
				return (
					<Avatar src={image} alt="token-logo-liquidity-section" size={20} />
				);
			}
			return <Avatar src="" alt="token-logo-liquidity-section" size={20} />;
		}, [logoFile]);

		const isLpBurn = useMemo(() => type === "burned", [type]);

		const token = useMemo(
			() =>
				new Token(
					nativeCurrency?.chainId,
					nativeCurrency?.wrapped?.address,
					TOKEN_FACTORY_TOKEN_DECIMALS,
					symbol,
					name,
				),
			[name, nativeCurrency?.chainId, nativeCurrency?.wrapped?.address, symbol],
		);

		const { marketCap, nativePerToken, tokenPerNative } = useMemo(() => {
			if (!nativeAmount || !initialSupply) {
				return { marketCap: "0", nativePerToken: "0", tokenPerNative: "0" };
			}
			const parsedNativeAmount = parseEther(nativeAmount ?? "0");

			const parsedTokenAmount = parseUnits(
				initialSupply ?? "0",
				TOKEN_FACTORY_TOKEN_DECIMALS,
			);

			const nativePerToken = calculateNativePerToken(
				parsedNativeAmount,
				parsedTokenAmount,
			);
			const tokenPerNative = calculateTokenPerNative(
				parsedNativeAmount,
				parsedTokenAmount,
			);
			const marketCap = calculateMarketCap(
				parsedNativeAmount,

				Number.isNaN(nativePrice)
					? BigInt("0")
					: parseEther(nativePrice?.toString()),
			);

			return { marketCap, nativePerToken, tokenPerNative };
		}, [nativeAmount, initialSupply, nativePrice]);

		return (
			<FieldsContainer>
				<AlertCard variant="info" showIcon className="col-span-2">
					<Typography>
						Pair the token supply with AVAX to fund the liquidity pool. The
						minimum AVAX is 10, the more AVAX you supply, the stronger will be
						the pairing and the higher will be the price of your token at
						launch.
					</Typography>
				</AlertCard>
				<FormField
					control={form.control}
					rules={{ pattern: numericalInputRegex }}
					name="nativeAmount"
					render={({ field: { onChange, ...rest } }) => (
						<FormItem>
							<FormLabel>{nativeCurrency?.symbol} Amount</FormLabel>
							<FormControl>
								<CurrencyInputPanel0 {...rest} />
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					rules={{ pattern: numericalInputRegex }}
					name="totalSupply"
					render={({ field: { onChange, ...rest } }) => (
						<FormItem>
							<FormLabel>Total Supply</FormLabel>
							<FormControl>
								<CurrencyInputPanel
									id="meme-factory-token-amount"
									currency={token}
									disableCurrencySelect
									disabledInput
									{...rest}
									value={initialSupply}
									logo={logo()}
									balanceOverrides={{ label: "Balance", value: "-" }}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>

				<Box className="col-span-2">
					<Box className="flex flex-col gap-2">
						<Typography weight="semibold">
							Based on the selected {nativeSymbol} amount, your token will
							launch with the following starting market cap and the starting
							price.
						</Typography>
						<SingleRowStats
							items={[
								{
									label: "Launch Market Cap",
									value: `${nativeAmount || "0"} ${nativeSymbol} (${
										marketCap || "0"
									} USDC)`,
								},
								{
									label: `${nativeSymbol} per ${symbol}`,
									value: nativePerToken?.toString?.() || "0",
								},
								{
									label: `${symbol} per ${nativeSymbol}`,
									value: tokenPerNative?.toString?.() || "0",
								},
							]}
						/>
					</Box>
				</Box>
				<FormField
					control={form.control}
					name="type"
					render={({ field }) => (
						<FormItem className="space-y-2">
							<FormLabel>LP Burned or Locked? (*)</FormLabel>
							<FormControl>
								<RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="grid grid-cols-2"
								>
									<FormItem className="flex flex-row items-center">
										<FormControl>
											<RadioGroupItem value="burned" />
										</FormControl>
										<FormLabel>Burned</FormLabel>
									</FormItem>
									<FormItem className="flex flex-row items-center">
										<FormControl>
											<RadioGroupItem value="locked" />
										</FormControl>
										<FormLabel>Locked</FormLabel>
									</FormItem>
								</RadioGroup>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				{!isLpBurn && (
					<FormField
						control={form.control}
						disabled={isLpBurn}
						rules={{ required: !isLpBurn }}
						name="lockDuration"
						render={({ field }) => (
							<FormItem>
								<FormLabel htmlFor="lock-duration">Lock Duration (*)</FormLabel>
								<FormControl>
									<Box className="flex justify-between bg-neutral-900">
										<Select
											{...field}
											options={lockDurations}
											onSelect={field.onChange}
											icon={<ChevronDownIcon width="30px" color="white" />}
										/>
									</Box>
								</FormControl>

								<FormMessage />
							</FormItem>
						)}
					/>
				)}
				<Box className="col-span-2 flex justify-between">
					<CancelButton onCancel={onCancel} />
					<Button
						color="blue"
						size="sm"
						className="w-fit"
						onClick={onNextWithValidate}
					>
						<Typography>Next Step</Typography>
					</Button>
				</Box>
			</FieldsContainer>
		);
	},
);
