import {
	BlockIcon,
	CheckmarkCircleIcon,
	Flex,
	Link,
	OpenNewIcon,
	RefreshIcon,
} from "@vapordex/uikit";
import type { TransactionDetails } from "state/transactions/reducer";
import styled from "styled-components";
import { ExplorerDataType, getExplorerLink } from "utils";
import { useNetwork } from "wagmi";

interface TransactionRowProps {
	txn: TransactionDetails;
}

const TxnIcon = styled(Flex)`
  align-items: center;
  flex: none;
  width: 24px;
`;

const Summary = styled.div`
  flex: 1;
  padding: 0 8px;
`;

const TxnLink = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const renderIcon = (txn: TransactionDetails) => {
	if (!txn.receipt) {
		return <RefreshIcon spin width="24px" />;
	}

	return txn.receipt?.status === 1 || txn.receipt?.status === undefined ? (
		<CheckmarkCircleIcon color="success" width="24px" />
	) : (
		<BlockIcon color="failure" width="24px" />
	);
};

const TransactionRow: React.FC<
	React.PropsWithChildren<TransactionRowProps>
> = ({ txn }) => {
	const { chain } = useNetwork();

	if (!txn) {
		return null;
	}

	return (
		<TxnLink
			href={getExplorerLink(txn.hash, ExplorerDataType.TRANSACTION, chain)}
			external
		>
			<TxnIcon>{renderIcon(txn)}</TxnIcon>
			<Summary>{txn.summary ?? txn.hash}</Summary>
			<TxnIcon>
				<OpenNewIcon width="24px" color="primary" />
			</TxnIcon>
		</TxnLink>
	);
};

export default TransactionRow;
