import { darkColors, lightColors } from "../../theme/colors";
import type { AlertTheme } from "./types";

export const light: AlertTheme = {
	background: lightColors.backgroundAlt,
};

export const dark: AlertTheme = {
	background: darkColors.backgroundAlt,
};
