import * as React from "react";
import { type VariantProps, tv } from "tailwind-variants";

const textAreaStyles = tv({
	base: "appearance-none focus-visible:ring-ring flex min-h-[60px] w-full rounded-lg border focus:border-2 bg-transparent px-3 py-2 text-sm shadow-sm focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
	variants: {
		border: {
			purple: "border-purple-1",
		},
		text: { dark: "text-dark" },
	},
});

export interface TextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		VariantProps<typeof textAreaStyles> {}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ border, className, text, ...props }, ref) => {
		return (
			<textarea
				className={textAreaStyles({ border, class: className, text })}
				ref={ref}
				{...props}
			/>
		);
	},
);
TextArea.displayName = "TextArea";

export { TextArea };
