import {
	Box,
	Button,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Typography,
} from "@vaporfi/uikit";
import {
	DISCORD_URL,
	SOCIAL_TWITTER_URL,
	SOCIAL_VAPORDEX_URL,
} from "@vaporfi/utils";
import type { CommonFieldsProps } from "../../types";
import {
	CancelButton,
	FieldsContainer,
	useWithFieldsValidation,
} from "./shared";

export const SocialFields = ({
	chainId,
	form,
	nextButtonLabel,
	onCancel,
	onNext,
}: CommonFieldsProps) => {
	const onNextWithValidate = useWithFieldsValidation(
		chainId,
		"socials",
		onNext,
	);
	return (
		<FieldsContainer>
			<FormField
				control={form.control}
				name="telegram"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Telegram URL (Optional)</FormLabel>
						<FormControl>
							<Input
								type="text"
								className="bg-neutral-900 p-2"
								placeholder="https://t.me/your-username"
								pattern="(.*?)"
								inputMode="text"
								{...field}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="x"
				render={({ field }) => (
					<FormItem>
						<FormLabel>X/Twitter URL (Optional)</FormLabel>
						<FormControl>
							<Input
								type="text"
								className="bg-neutral-900 p-2"
								placeholder={SOCIAL_TWITTER_URL}
								pattern="(.*?)"
								inputMode="text"
								{...field}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="discord"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Discord URL (Optional)</FormLabel>
						<FormControl>
							<Input
								type="text"
								className="bg-neutral-900 p-2"
								placeholder={DISCORD_URL}
								pattern="(.*?)"
								inputMode="text"
								{...field}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="website"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Website URL (Optional)</FormLabel>
						<FormControl>
							<Input
								type="text"
								className="bg-neutral-900 p-2"
								placeholder={SOCIAL_VAPORDEX_URL}
								pattern="(.*?)"
								inputMode="text"
								{...field}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			{onNextWithValidate && onCancel && (
				<Box className="col-span-2 flex justify-between">
					<CancelButton onCancel={onCancel} />
					<Button
						color="blue"
						size="sm"
						className="w-fit"
						onClick={onNextWithValidate}
					>
						<Typography>{nextButtonLabel || "Next Step"}</Typography>
					</Button>
				</Box>
			)}
		</FieldsContainer>
	);
};
