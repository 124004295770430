import { Modal, Switch, Typography } from "@vaporfi/uikit";
import type React from "react";
import { QuestionHelper } from "./QuestionHelper";

export interface SettingsModalViewProps {
	showConfirmExpertModal: boolean;
	setShowConfirmExpertModal: (show: boolean) => void;
	onDismiss: () => void;
	setShowExpertModeAcknowledgement: (show: boolean) => void;
	expertMode: boolean;
	toggleExpertMode: () => void;
	singleHopOnly: boolean;
	setSingleHopOnly: (value: boolean) => void;
	zapMode: boolean;
	toggleZapMode: () => void;
	GasSettingsComponent?: React.ComponentType<any>;
	SlippageTabsComponent?: React.ComponentType<any>;
}

export const SettingsModalView: React.FC<SettingsModalViewProps> = ({
	GasSettingsComponent,
	SlippageTabsComponent,
	expertMode,
	onDismiss,
	setSingleHopOnly,
	singleHopOnly,
	toggleExpertMode,
	toggleZapMode,
	zapMode,
}) => {
	const modalBody = (
		<>
			<div className="pb-6 pt-14">
				<Typography weight="bold" size="xs" color="turquoise" className="mb-6">
					GLOBAL
				</Typography>
				{GasSettingsComponent && <GasSettingsComponent />}
			</div>
			<div className="pt-6">
				<Typography weight="bold" size="xs" color="turquoise" className="mb-6">
					SWAPS & LIQUIDITY
				</Typography>
				{SlippageTabsComponent && <SlippageTabsComponent />}
			</div>
			<div className="mb-6 flex items-center justify-between">
				<div className="flex items-center">
					<Typography className="mr-1">Zap (Beta)</Typography>
					<QuestionHelper text="Zap enables simple liquidity provision. Add liquidity with one token and one click, without manual swapping or token balancing." />
				</div>
				<Switch checked={zapMode} onChange={toggleZapMode} size="large" />
			</div>
			<div className="mb-6 flex items-center justify-between">
				<div className="flex items-center">
					<Typography className="mr-1">Expert Mode</Typography>
					<QuestionHelper text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
				</div>
				<Switch checked={expertMode} onChange={toggleExpertMode} size="large" />
			</div>
			<div className="mb-6 flex items-center justify-between">
				<div className="flex items-center">
					<Typography className="mr-1">Disable Multihops</Typography>
					<QuestionHelper text="Restricts swaps to direct pairs only." />
				</div>
				<Switch
					checked={singleHopOnly}
					onChange={() => setSingleHopOnly(!singleHopOnly)}
					size="large"
				/>
			</div>
		</>
	);

	return (
		<Modal
			title="Settings"
			titleClassName="text-lg font-semibold"
			onDismiss={onDismiss}
			body={modalBody}
			className="border-cardBorder max-w-[420px] border border-solid bg-black"
		/>
	);
};
