/* eslint-disable perfectionist/sort-objects */
import { ChainId } from "@vapordex/sdk";
import type { FormShapeKeys } from ".";
export const TOKEN_FACTORY_TOKEN_DECIMALS = 18;
export const MemeFactoryLaunchFeeUSDC = {
	[ChainId.AVALANCHE]: "250",
	[ChainId.AVALANCHE_TESTNET]: "1",
};
export const lockDurations = [
	{ label: "90 days", value: "90" },
	{ label: "180 days", value: "180" },
	{ label: "365 days", value: "365" },
];
export const labelByField: { [key in FormShapeKeys]: string } = {
	name: "Token name",
	symbol: "Token symbol",
	chain: "Chain ID",
	decimals: "Token decimals",
	initialSupply: "Initial supply",
	maximumSupply: "Maximum supply",
	isStratosphereWhitelist: "Stratosphere whitelist",
	launchDate: "Launch date",
	lockDuration: "Lock duration",
	logo: "Logo",
	nativeAmount: "AVAX (Liquidity)",
	website: "Website",
	discord: "Discord",
	telegram: "Telegram",
	type: "Liquidity state",
	x: "X/Twitter",
} as const;

export const fields = {
	launch: ["isStratosphereWhitelist", "launchDate"],
	liquidityInfo: ["nativeAmount", "type", "lockDuration"],
	socials: ["telegram", "twitter", "discord", "website"],
	tokenInfo: [
		"name",
		"symbol",
		"logo",
		"chain",
		"initialSupply",
		"maximumSupply",
	],
};
