// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx
import { getSettings } from ".";

import { opacify } from "./utils";

export const colors = {
	white: "#FFFFFF",
	black: "#000000",
	gray50: "#F5F6FC",
	gray100: "#E8ECFB",
	gray150: "#D2D9EE",
	gray200: "#B8C0DC",
	gray250: "#A6AFCA",
	gray300: "#98A1C0",
	gray350: "#888FAB",
	gray400: "#7780A0",
	gray450: "#6B7594",
	gray500: "#5D6785",
	gray550: "#505A78",
	gray600: "#404A67",
	gray650: "#333D59",
	gray700: "#293249",
	gray750: "#1B2236",
	gray800: "#131A2A",
	gray850: "#0E1524",
	gray900: "#0D111C",
	gray950: "#080B11",
	pink50: "#F9ECF1",
	pink100: "#FFD9E4",
	pink200: "#FBA4C0",
	pink300: "#FF6FA3",
	pink400: "#FB118E",
	pink500: "#C41969",
	pink600: "#8C0F49",
	pink700: "#55072A",
	pink800: "#350318",
	pink900: "#2B000B",
	pinkVibrant: "#F51A70",
	red50: "#FAECEA",
	red100: "#FED5CF",
	red200: "#FEA79B",
	red300: "#FD766B",
	red400: "#FA2B39",
	red500: "#C4292F",
	red600: "#891E20",
	red700: "#530F0F",
	red800: "#380A03",
	red900: "#240800",
	redVibrant: "#F14544",
	yellow50: "#F6F2D5",
	yellow100: "#DBBC19",
	yellow200: "#DBBC19",
	yellow300: "#BB9F13",
	yellow400: "#A08116",
	yellow500: "#866311",
	yellow600: "#5D4204",
	yellow700: "#3E2B04",
	yellow800: "#231902",
	yellow900: "#180F02",
	yellowVibrant: "#FAF40A",
	// TODO: add gold 50-900
	gold200: "#EEB317",
	gold400: "#B17900",
	goldVibrant: "#FEB239",
	green50: "#E3F3E6",
	green100: "#BFEECA",
	green200: "#76D191",
	green300: "#40B66B",
	green400: "#209853",
	green500: "#0B783E",
	green600: "#0C522A",
	green700: "#053117",
	green800: "#091F10",
	green900: "#09130B",
	greenVibrant: "#5CFE9D",
	blue50: "#EDEFF8",
	blue100: "#DEE1FF",
	blue200: "#ADBCFF",
	blue300: "#869EFF",
	blue400: "#4C82FB",
	blue500: "#1267D6",
	blue600: "#1D4294",
	blue700: "#09265E",
	blue800: "#0B193F",
	blue900: "#040E34",
	blueVibrant: "#587BFF",
	// TODO: add magenta 50-900
	magentaVibrant: "#FC72FF",
	purple300: "#8440F2",
	purple900: "#1C0337",
	// TODO: add all other vibrant variations
	networkEthereum: "#627EEA",
	networkOptimism: "#FF0420",
	networkOptimismSoft: "rgba(255, 4, 32, 0.16)",
	networkPolygon: "#A457FF",
	networkArbitrum: "#28A0F0",
	networkBsc: "#F0B90B",
	networkPolygonSoft: "rgba(164, 87, 255, 0.16)",
	networkEthereumSoft: "rgba(98, 126, 234, 0.16)",
};
