export const V2MigratorABI = [
	{
		inputs: [
			{
				internalType: "address",
				name: "_factory",
				type: "address",
			},
			{
				internalType: "address",
				name: "_WETH9",
				type: "address",
			},
			{
				internalType: "address",
				name: "_nonfungiblePositionManager",
				type: "address",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		inputs: [],
		name: "WETH9",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token0",
				type: "address",
			},
			{
				internalType: "address",
				name: "token1",
				type: "address",
			},
			{
				internalType: "uint24",
				name: "fee",
				type: "uint24",
			},
			{
				internalType: "uint160",
				name: "sqrtPriceX96",
				type: "uint160",
			},
		],
		name: "createAndInitializePoolIfNecessary",
		outputs: [
			{
				internalType: "address",
				name: "pool",
				type: "address",
			},
		],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [],
		name: "factory",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: "address",
						name: "pair",
						type: "address",
					},
					{
						internalType: "uint256",
						name: "liquidityToMigrate",
						type: "uint256",
					},
					{
						internalType: "uint8",
						name: "percentageToMigrate",
						type: "uint8",
					},
					{
						internalType: "address",
						name: "token0",
						type: "address",
					},
					{
						internalType: "address",
						name: "token1",
						type: "address",
					},
					{
						internalType: "uint24",
						name: "fee",
						type: "uint24",
					},
					{
						internalType: "int24",
						name: "tickLower",
						type: "int24",
					},
					{
						internalType: "int24",
						name: "tickUpper",
						type: "int24",
					},
					{
						internalType: "uint256",
						name: "amount0Min",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "amount1Min",
						type: "uint256",
					},
					{
						internalType: "address",
						name: "recipient",
						type: "address",
					},
					{
						internalType: "uint256",
						name: "deadline",
						type: "uint256",
					},
					{
						internalType: "bool",
						name: "refundAsETH",
						type: "bool",
					},
				],
				internalType: "struct IV3Migrator.MigrateParams",
				name: "params",
				type: "tuple",
			},
		],
		name: "migrate",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes[]",
				name: "data",
				type: "bytes[]",
			},
		],
		name: "multicall",
		outputs: [
			{
				internalType: "bytes[]",
				name: "results",
				type: "bytes[]",
			},
		],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [],
		name: "nonfungiblePositionManager",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "deadline",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "s",
				type: "bytes32",
			},
		],
		name: "selfPermit",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "nonce",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "expiry",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "s",
				type: "bytes32",
			},
		],
		name: "selfPermitAllowed",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "nonce",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "expiry",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "s",
				type: "bytes32",
			},
		],
		name: "selfPermitAllowedIfNecessary",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "deadline",
				type: "uint256",
			},
			{
				internalType: "uint8",
				name: "v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "s",
				type: "bytes32",
			},
		],
		name: "selfPermitIfNecessary",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		stateMutability: "payable",
		type: "receive",
	},
] as const;
