export const FujiTokens = [
	{
		address: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
		chainId: 43_113,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo_24.png",
		name: "Wrapped AVAX",
		symbol: "WAVAX",
	},
	{
		address: "0x4BB08faf9AF783e2AF3A2E2Dfdb5F0E197acc5e0",
		chainId: 43_113,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo_24.png",
		name: "TetherToken",
		symbol: "USDT",
	},
	{
		address: "0x3bD01B76BB969ef2D5103b5Ea84909AD8d345663",
		chainId: 43_113,
		decimals: 18,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55/logo.png",
		name: "VapeToken",
		symbol: "VAPE",
	},

	{
		address: "0xeA42E3030ab1406a0b6aAd077Caa927673a2c302",
		chainId: 43_113,
		decimals: 6,
		logoURI:
			"https://static.vapordex.io/tokens/assets/43114/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo_24.png",
		name: "USD Coin",
		symbol: "USDC",
	},
] as const;
