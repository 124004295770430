import { type VariantProps, tv } from "tailwind-variants";
import { Box, type BoxProps } from "../Layout";

const overlayStyles = tv({
	base: "bg-[#01010099] fixed top-0 left-0 w-full h-full z-[20] will-change-[opacity]",
	defaultVariants: { isUnmounting: false },
	variants: {
		isUnmounting: {
			false: "animate-[overlayShow 350ms ease forwards]",
			true: "animate-[overlayHide 350ms ease forwards]",
		},
	},
});
export interface OverlayProps
	extends VariantProps<typeof overlayStyles>,
		BoxProps {}

export const Overlay = ({
	className,
	isUnmounting = false,
	...props
}: OverlayProps) => {
	return (
		<Box
			{...props}
			className={overlayStyles({ class: className, isUnmounting })}
		/>
	);
};
