/* eslint-disable turbo/no-undeclared-env-vars */
import { type FeatureFlag, getDirectusClient, readItems } from "@vaporfi/db";

export { FeatureProvider } from "./provider";
export { useFeature } from "./useFeature";

const projects = ["vapordex", "vapornodes"] as const;

export type Project = (typeof projects)[number];

export const getFeature = async (key: `enable${string}`) => {
	const features = await getFeatures();

	return features[key];
};

export const directusFlagsResolver = <
	T extends Record<string, boolean | Array<string>>,
>(
	data: T,
	chainKey?: string,
): T => {
	if (typeof data !== "object" || !data) {
		return data;
	}

	let flags: T = data;

	const value = chainKey ? data?.[chainKey] : null;
	if (value && Array.isArray(value)) {
		const overrides = value.reduce(
			(acc: Record<string, boolean>, curr: string) => {
				const [feauture, flag] = curr.split("-");
				acc[feauture] = JSON.parse(flag);
				return acc;
			},
			{},
		);

		flags = { ...flags, ...overrides };
	}
	return flags;
};

export const getFeatures = async (chainId?: string) => {
	try {
		const client = getDirectusClient(true);

		const [featureFlagChains, allChains, items] = await Promise.all([
			client.request(readItems("feature_flags_chains")),
			client.request(readItems("chains")),
			client.request(readItems("feature_flags")),
		]);

		const featureFlagIdByChainId = allChains.reduce((acc, chain) => {
			const featureFlagChain = featureFlagChains.find(
				(item) => item.chains_id === chain.id,
			);
			acc[chain.chain_id] = featureFlagChain?.id;
			return acc;
		}, {});

		const data = items.reduce(
			(acc: Record<string, boolean>, curr: FeatureFlag) => {
				acc[curr.name] = chainId
					? !curr.disabled_chains?.includes(featureFlagIdByChainId[chainId])
					: curr.is_enabled;
				return acc;
			},
			{},
		);
		return data;
	} catch (error) {
		console.error(
			"An error occurred during querying Directus for feature flags:",
			error,
		);
		return {};
	}
};
