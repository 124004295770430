import type { Address } from "viem";

export type StratosphereTierName =
	| "Basic"
	| "Diamond"
	| "Gold"
	| "Obsidian"
	| "Silver"
	| "Platinum";

// progress -> percentage of points accumulated till yet for this event
export type EventStatus = { isClaimed: boolean; progress?: number };

export interface EventDetails extends Partial<EventStatus> {
	name: string;
	points: number;
	description: string;
}

export type Events = Record<OneTimeEvent, EventDetails>;

export enum StratosphereTiers {
	BASIC = 1,
	SILVER = 2,
	GOLD = 3,
	PLATINUM = 4,
	DIAMOND = 5,
	OBSIDIAN = 6,
}

export type StratosphereAttributes<T> = Record<StratosphereTiers, T>;

export enum OneTimeEvent {
	ENROLLMENT = 0,
	ADD_PROFILE_PICTURE = 1,
	FIRST_SWAP = 2,
	SWAP_1K_CUMULATED = 3,
	SWAP_10K_CUMULATED = 4,
	SWAP_100K_CUMULATED = 5,
	FIRST_DEPOSIT_IN_VPND_LM = 6,
	FIRST_DEPOSIT_IN_VAPE_LM = 7,
	FIRST_WALLET_IN_VPND_LM = 8,
	FIRST_WALLET_IN_VAPE_LM = 9,
	LOCKED_1_SEASON_IN_VPND_LM = 10,
	LOCKED_3_SEASONS_IN_VPND_LM = 11,
	LOCKED_6_SEASONS_IN_VPND_LM = 12,
	LOCKED_1_YEAR_IN_VPND_LM = 13,
	EARLY_MEMBER = 14,
	CHAIN_FIRST_WALLET = 15,
	// REFERRAL_POINTS,
	// SOCIAL_MEDIA_POST_ON_X,
}

export interface StratosphereDashboardProps {
	account: Address;
	claimablePoints: number;
	onClaimPointsClick: () => void;
	tokenId: string;
	claimedPoints: number;
	weeklyPoints: number;
	totalPoints: number;
	availablePoints: number;
	expiringPoints: number;
	progressPercentage: number;
	pendingPoints: number;
	currentTier: StratosphereTiers;
	oneTimeEventStatus: Partial<Record<OneTimeEvent, EventStatus>>;
	currentDate?: Date;
}
