import getExternalLinkProps from "../../util/getExternalLinkProps";
import Text from "../Text/Text";
import type { LinkProps } from "./types";

import styled from "styled-components";

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = ({ external, ...props }: LinkProps) => {
	const internalProps = external ? getExternalLinkProps() : {};
	return <StyledLink as="a" bold {...internalProps} {...props} />;
};

/* eslint-disable react/default-props-match-prop-types */
Link.defaultProps = {
	color: "primary",
};

export default Link;
