import type { Token } from "@vapordex/sdk";
import type { ChainId } from "@vapordex/sdk";

// a list of tokens by chain
export type ChainTokenList = {
	readonly [chainId in ChainId]?: Token[];
};

export type TranslatableText =
	| string
	| {
			key: string;
			data?: {
				[key: string]: string | number;
			};
	  };
export interface AddressMap {
	[chainId: number]: string;
}

export interface SerializedToken {
	chainId: number;
	address: string;
	decimals: number;
	symbol?: string;
	name?: string;
	projectLink?: string;
	logoURI?: string;
}

export enum PoolIds {
	poolBasic = "poolBasic",
	poolUnlimited = "poolUnlimited",
}
export enum FetchStatus {
	Idle = "IDLE",
	Fetching = "FETCHING",
	Fetched = "FETCHED",
	Failed = "FAILED",
}

export type PageMeta = {
	title: string;
	description?: string;
	image?: string;
};
